import { useEffect, useState } from 'react';
import { UseDirectusContext } from '../../contexts';
import { TDepositsPage } from '../../typedefs';
import { selectDepositsPageData } from './DepositsPageDataSelector';

/*
 The main goal of this hook is to select and return a TWelcomeOfferPage object.
 @returns {TWelcomeOfferPage} welcomeOfferPageData what gives us the data that we need.
*/
export const UseGetDepositPage = () => {
  const { registrationFlowsData } = UseDirectusContext(); // Loads directus cms data.
  const [depositsPageData, setDepositsPageData] =
    useState<TDepositsPage | null>(null);

  useEffect(() => {
    setDepositsPageData(selectDepositsPageData(registrationFlowsData));
  }, [registrationFlowsData]);

  return { depositsPageData };
};
