import React, { useRef } from 'react';
import classNames from 'classnames';
import { Flex, Label } from 'Src/atoms';
import { TOptionRadioBtton } from 'Src/typedefs';
import useScrollIntoViewOnError from 'Src/hooks/useScrollIntoViewOnError';
import { TEST_ID } from 'Src/constants';

interface RadioButtonsProps {
  options: TOptionRadioBtton[];
  direction?: 'flex-row' | 'flex-col';
  onSelect: (value: string) => void;
  selectedValue?: string;
  error?: string;
  testID?: string;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  onSelect,
  selectedValue,
  direction = 'flex-row',
  error,
  testID,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useScrollIntoViewOnError(error, inputRef);

  const handleSelection = (value: string) => {
    onSelect(value);
  };

  testID = testID ? testID + '-' : '';

  return (
    <Flex direction="flex-col">
      <Flex direction={direction} className="gap-xs">
        {options?.map((option) => (
          <React.Fragment key={option.value}>
            <Label
              htmlFor={option.value}
              className={classNames(
                'w-full text-center cursor-pointer text-black bg-grey-0 font-bold transition-all py-[13px] rounded-2xl',
                {
                  'bg-purple-60 text-white': selectedValue === option.value,
                }
              )}
            >
              <>
                <input
                  ref={selectedValue === option.value ? inputRef : null}
                  type="radio"
                  id={option.value}
                  name="gender"
                  value={option.value}
                  onChange={() => handleSelection(option.value)}
                  className="hidden"
                  data-testid={
                    TEST_ID.SELECT + testID + option.value.toLocaleLowerCase()
                  }
                />
                {option.label}
              </>
            </Label>
          </React.Fragment>
        ))}
      </Flex>
      <Flex className="text-red-40 text-xs">{error ? error : ''}</Flex>
    </Flex>
  );
};

export default RadioButtons;
