import { useState, useRef } from 'react';
import classNames from 'classnames';
import ClearInput from '../../assets/svgs/clearInputButton.svg';
import HidePasswordIcon from '../../assets/svgs/hideInputButton.svg';
import ShowPasswordIcon from '../../assets/svgs/showInputButton.svg';
import { Flex, Image, Input, Label, Span } from '../../atoms';
import { INPUT_TYPES } from 'Src/constants';
import { InputMode } from 'Src/typedefs';
import useScrollIntoViewOnError from 'Src/hooks/useScrollIntoViewOnError';

type Props = {
  currency?: string;
  value?: string | number;
  hasFocus: boolean;
  changeValue?: (value) => void;
  placeholder?: string;
  setHasFocus?: (value) => void;
  setTriggerGTMEvent?: (value) => void;
  clearValue?: () => void;
  error?: string;
  phoneError?: string;
  type?: string;
  inputMode?: InputMode;
  testID?: string;
};

export const RegistrationInput = ({
  value,
  hasFocus,
  changeValue,
  placeholder,
  setHasFocus,
  setTriggerGTMEvent,
  clearValue,
  error,
  phoneError,
  type,
  testID,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useScrollIntoViewOnError(error, inputRef);
  const errorMessage = error ?? ""
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isWhitespace =
      inputValue.trim().length === 0 && inputValue.length > 0;
    if (!isWhitespace) {
      changeValue && changeValue(inputValue);
    } else {
      changeValue && changeValue('');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Flex>
      <Flex className="h-14 overflow-hidden">
        <Label
          className={classNames('relative left-md w-sm h-0', {
            invisible: !value,
            'top-[33px]': hasFocus || value,
            'text-red-40': error,
          })}
        >
          <Span className="invisible">{value}</Span>
        </Label>
        <Label
          className={classNames(
            'absolute top-0.5 left-[18px] text-grey-50 text-xs font-bold',
            {
              invisible: !hasFocus && !value,
            }
          )}
        >
          {placeholder || 'Deposit Amount'}
        </Label>

        <Input
          ref={inputRef}
          className={classNames(
            'h-3xl pl-sm bg-grey-0 border-grey-5 border-2 rounded-2xl caret-blue-50 focus:border-2 font-bold',
            {
              'pt-2xs': hasFocus || value,
              "pr-2xl": value,
              "pr-sm": !value,
              'focus:border-purple-80': !error && !phoneError,
              'text-red-40 border-2 border-red-40': error || phoneError
            }
          )}
          onChange={handleChange}
          onBlur={() => setHasFocus(false)}
          onFocus={() => {
            setHasFocus(true);
            setTriggerGTMEvent && setTriggerGTMEvent(true);
          }}
          value={value?.toString() || ''}
          placeholder={hasFocus ? '' : placeholder || ''}
          type={
            showPassword && type === INPUT_TYPES.PASSWORD
              ? INPUT_TYPES.TEXT
              : type
          }
          testID={testID}
        />
        {type === INPUT_TYPES.PASSWORD ? (
          <Flex className="w-full h-0 items-end">
            <Image
              src={showPassword ? ShowPasswordIcon : HidePasswordIcon}
              className={classNames(
                'relative right-4 bottom-10 w-md cursor-pointer',
                {
                  invisible: !value,
                }
              )}
              onClick={togglePasswordVisibility}
              alt='Show Password'
            />
          </Flex>
        ) : (
          <Flex className="w-full h-0 items-end">
            <Image
              src={ClearInput}
              className={classNames(
                'relative bottom-10 right-4 w-md cursor-pointer',
                {
                  invisible: !value,
                }
              )}
              onClick={clearValue}
              title='Clear'
              alt='Delete'
            />
          </Flex>
        )}
      </Flex>
      <Flex className="text-red-40 text-xs">
        <span title={errorMessage} className='whitespace-nowrap w-full overflow-hidden text-ellipsis'>
          {errorMessage}
        </span>
      </Flex>
    </Flex>
  );
};
