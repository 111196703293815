import { GTM_ACTIONS, TEST_ID } from 'Src/constants';
import { Flex, Span } from '../../atoms';
import classNames from 'classnames';

type DrawerData = {
  title: string;
  description: String;
};

type Props = {
  termsText?: string;
  privacyText?: string;
  drawersData?: {
    termsAndConditionsData: DrawerData;
    PrivacyPolicyData?: DrawerData;
    sportTermsData?: DrawerData;
  };
  onOpenDrawer: (textContent: DrawerData) => void;
  className?: string;
  leftAligned?: boolean;
  gtmEvent?: (gtmAction: string) => void;
};

export const TermsComponent = ({
  termsText,
  privacyText,
  onOpenDrawer,
  drawersData,
  className,
  leftAligned = false,
  gtmEvent,
}: Props) => {
  return (
    <Flex
      className={classNames(`${className}`, {
        'items-center': !leftAligned,
        'items-left': leftAligned,
      })}
    >
      <div className="text-2xs" data-testid={TEST_ID.PREFIX + 'termsAndConditions'}
      >
        <Span>{termsText}</Span>
        <Span
          onClick={() => {
            if (gtmEvent) gtmEvent(GTM_ACTIONS.SHOW_TERMS);
            onOpenDrawer(drawersData.termsAndConditionsData);
          }}
          className="px-3xs cursor-pointer"
          testID={TEST_ID.SELECT + 'termsAndConditions'}
        >
          <u>
            {drawersData.termsAndConditionsData.title || 'Terms and Conditions'}
            {drawersData?.sportTermsData && ','}
          </u>
        </Span>
        {drawersData?.sportTermsData && (
          <Span
            onClick={() => {
              onOpenDrawer(drawersData.sportTermsData);
            }}
            className="pr-3xs cursor-pointer"
            testID={TEST_ID.SELECT + 'sportTermsAndConditions'}
          >
            <u>
              {drawersData.sportTermsData.title ||
                'Sportsbook Terms and Conditions'}
            </u>
          </Span>
        )}
        {drawersData?.PrivacyPolicyData && (
          <>
            <Span>{privacyText}</Span>
            <Span
              onClick={() => {
                if (gtmEvent) gtmEvent(GTM_ACTIONS.SHOW_PRIVACY_POLICY);
                onOpenDrawer(drawersData.PrivacyPolicyData);
              }}
              className="px-3xs cursor-pointer"
              testID={TEST_ID.SELECT + 'privacyPolicy'}
            >
              <u>
                {drawersData.PrivacyPolicyData.title || 'Terms and Conditions'}
              </u>
            </Span>
          </>
        )}
      </div>
    </Flex>
  );
};
