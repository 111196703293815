import { TPlayOkLimits } from 'Src/typedefs';
import { playOkServiceClient } from './client';


const getFilteredLimit = (
  response: any,
  limitType: 'minAllowedLimits' | 'maxAllowedLimits',
  timePeriod: 'Monthly' | 'Weekly' | 'Daily',
  currency: string
) => {
  const limits = response?.data?.limits?.money?.deposit?.[limitType]?.[timePeriod];
  return limits?.find((item: any) => item.iso4217CurrencyCode === currency)?.amount;
};

export const getDepositLimits = async (currency: string, jurisdiction: string): Promise<TPlayOkLimits | null> => {
  try {
    const response = await playOkServiceClient.get(`/config/${jurisdiction}`);

    if (response?.status === 200) {
      const depositLimits = ['Monthly', 'Weekly', 'Daily'].reduce((acc, period) => {
        acc.minAllowedLimits[period.toLowerCase()] = getFilteredLimit(response, 'minAllowedLimits', period as 'Monthly' | 'Weekly' | 'Daily', currency);
        acc.maxAllowedLimits[period.toLowerCase()] = getFilteredLimit(response, 'maxAllowedLimits', period as 'Monthly' | 'Weekly' | 'Daily', currency);
        return acc;
      }, {
        minAllowedLimits: { monthly: null, weekly: null, daily: null },
        maxAllowedLimits: { monthly: null, weekly: null, daily: null },
      });

      return { depositLimits } as TPlayOkLimits;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
