import { Flex } from '../atoms';
import { TFlags, TNationalityPage, TOptionRadioBtton } from '../typedefs';
import {
  PaymentWo,
  DepositWidget,
  NationalityComponent,
  NationalitySelector,
} from '../components';
import { INPUT_TYPES, OPTED_OUT_WELCOME_OFFER_ID } from 'Src/constants';
import { ActionButtons } from 'Components/ActionButtons';
import classNames from 'classnames';

type Props = {
  description: string;
  pageData: TNationalityPage;
  selectedFlagUuid: string;
  nationality: string;
  openCountrySelector: () => void;
  onConfirm: () => void;
  countryFlagsData: TFlags[];
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: () => void;
  changeValue: (value, inputType) => void;
  clearValue: (inputType: any) => void;
  searchValue: string;
  setNationality: (countryName: string) => void;
  userValues: any;
  stepNumber?: string;
  firstName?: string;
  lastName?: string;
  nationalityError?: string;
  firstNameError?: string;
  lastNameError?: string;
  firstNameHasFocus?: boolean;
  lastNameHasFocus?: boolean;
  setFirstNameHasFocus?: (value) => void;
  setLastNameHasFocus?: (value) => void;
  gender: string | null;
  genderList: TOptionRadioBtton[];

};

export const NationalityPageMgaGra = ({
  description,
  pageData,
  selectedFlagUuid,
  nationality,
  openCountrySelector,
  onConfirm,
  countryFlagsData,
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeValue,
  searchValue,
  setNationality,
  userValues,
  stepNumber,
  clearValue,
  firstName,
  lastName,
  nationalityError,
  firstNameError,
  lastNameError,
  firstNameHasFocus,
  lastNameHasFocus,
  setFirstNameHasFocus,
  setLastNameHasFocus,
  gender,
  genderList
}: Props) => {
  return (
    <Flex className="flex-1">
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          <PaymentWo
            welcomeOffer={userValues?.selectedWelcomeOffer}
            description={description}
          />

          {userValues?.selectedWelcomeOffer?.name ===
            OPTED_OUT_WELCOME_OFFER_ID &&
            userValues?.depositAmount && (
              <DepositWidget
                depositAmount={userValues?.depositAmount}
                currency={userValues?.currency}
                pageData={pageData}
              />
            )}
          <Flex className="justify-between">
            <NationalityComponent
              firstName={firstName}
              lastName={lastName}
              selectedFlagUuid={selectedFlagUuid}
              nationalityPageData={pageData}
              nationality={nationality}
              openCountrySelector={openCountrySelector}
              stepNumber={stepNumber}
              isMgaGra
              changeValue={changeValue}
              clearValue={clearValue}
              nationalityError={nationalityError}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              firstNameHasFocus={firstNameHasFocus}
              lastNameHasFocus={lastNameHasFocus}
              setFirstNameHasFocus={setFirstNameHasFocus}
              setLastNameHasFocus={setLastNameHasFocus}
              searchInputID="nationality"
              gender={gender}
              genderList={genderList}
            />
            <Flex>
              <Flex className="w-full mb-md px-md tablet:px-2xl bottom-fixed-button bg-grey-0 shadow-[0_-15px_33px_44px_rgba(239,242,244,1)]">
                <fabric-registration-button
                  id="btnNationality"
                  onClick={onConfirm}
                  primary
                  {...(nationality && firstName && lastName
                    ? null
                    : { disabled: true })}
                >
                  {pageData?.btnContinue || 'Continue'}
                </fabric-registration-button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {enableDrawer && isDrawerOpen && (
          <NationalitySelector
            drawerTitle={pageData?.flagDrawerTitle}
            countryFlagsData={countryFlagsData}
            enableDrawer={enableDrawer}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            changeValue={(value) => changeValue(value, INPUT_TYPES.SEARCH)}
            placeholder={pageData?.searchPlaceholder}
            searchValue={searchValue}
            setNationality={setNationality}
            selectedNationality={nationality}
            isMgaGra
          />
        )}
      </Flex>
      <ActionButtons
        primaryBtnText={pageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        enabled={Boolean(nationality && firstName && lastName)}
      />
    </Flex>
  );
};
