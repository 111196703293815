import { getDepositLimitsPageData } from '../../service';
import { TDepositLimitsPage } from 'Src/typedefs';

export const selectDepositLimitsPageData = (migrationFlowsData) => {
  const rawDepositLimitsPageData = getDepositLimitsPageData(migrationFlowsData);

  return {
    header_title: rawDepositLimitsPageData?.header_title,
    message: rawDepositLimitsPageData?.message,
    woMessage: rawDepositLimitsPageData?.woMessage,
    paymentMessage: rawDepositLimitsPageData?.paymentMessage,
    btnContinue: rawDepositLimitsPageData?.btnContinue,
    monthlyDepositPlaceholder:
      rawDepositLimitsPageData?.monthlyDepositPlaceholder,
    weeklyDepositPlaceholder:
      rawDepositLimitsPageData?.weeklyDepositPlaceholder,
    dailyDepositPlaceholder: rawDepositLimitsPageData?.dailyDepositPlaceholder,
    monthlyText: rawDepositLimitsPageData?.monthlyText,
    weeklyText: rawDepositLimitsPageData?.weeklyText,
    dailyText: rawDepositLimitsPageData?.dailyText,
    generalTermsAndConditionsText:
      rawDepositLimitsPageData?.generalTermsAndConditionsText,
    privacyPolicyText: rawDepositLimitsPageData?.privacyPolicyText,
    description: rawDepositLimitsPageData?.description,
    setUpLater: rawDepositLimitsPageData?.setUpLater,
  } as TDepositLimitsPage;
};
