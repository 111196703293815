import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { TFlags } from '../../typedefs';
import { Flex, Image, Span } from '../../atoms';
import { Drawer } from '..';
import { getAssetURL, sortCountries } from '../../utilities';
import { TEST_ID } from 'Src/constants';

type ItemProps = {
  countryCode?: string;
  setNationality?: Dispatch<SetStateAction<string>>;
  selected?: boolean;
  countryName?: string;
  imageUuid?: string;
};

const NationalityItem = ({
  countryName,
  setNationality,
  selected,
  imageUuid,
}: ItemProps) => {
  return (
    <Flex
      direction="flex-row"
      className={classNames(
        'h-3xl p-sm mb-2xs rounded-2xl items-center text-h-sm font-bold cursor-pointer',
        {
          'bg-purple-80': !selected,
          'bg-purple-60': selected,
        }
      )}
      onClick={() => setNationality(countryName)}
      testID={TEST_ID.COUNTRY + countryName}
    >
      <Flex className="w-md mr-2xs overflow-hidden">
        <Image
          src={getAssetURL(imageUuid)}
          className="max-w-none"
          width="auto"
          height="full"
          title={`${countryName} Flag`}
          alt={`${countryName}`}
        />
      </Flex>
      <Span>{countryName}</Span>
    </Flex>
  );
};

type Props = {
  enableDrawer?: boolean;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: () => void;
  phonePrefix?: string;
  changeValue?: (value) => void;
  placeholder?: string;
  searchValue?: string;
  setNationality?: Dispatch<SetStateAction<string>>;
  selectedNationality?: string;
  countryFlagsData?: Array<TFlags>;
  drawerTitle: string;
  isMgaGra?: boolean;
  isCountryOfBirth?: boolean;
};

export const NationalitySelector = ({
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeValue,
  placeholder,
  searchValue,
  setNationality,
  selectedNationality,
  countryFlagsData,
  drawerTitle,
  isMgaGra,
  isCountryOfBirth
}: Props) => {
  const countries = countryFlagsData
    .filter((country) => {
      const value = searchValue.toLowerCase();
      if (
        !searchValue || // Return the full list of countries.
        country?.name.toLowerCase().includes(value) // CountryName contains search value.
      ) {
        return true;
      }
      return false;
    })
    .sort((a, b) => sortCountries(a, b, searchValue.toLowerCase()));
  const currentTestID = isCountryOfBirth ? 'countryOfBirth' : isMgaGra ? 'nationality' : 'countryOfResidence'
  return (
    <Flex>
      {enableDrawer && (
        <Drawer
          title={drawerTitle}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          titleSize='text-h-lg font-bold'
          testID={currentTestID}
          changeValue={changeValue}
          placeholder={placeholder}
          searchValue={searchValue}
          autoFocus
          enableSearch
        >
          {countries.map((country) => {
            return (
              <NationalityItem
                key={`${country.uuid}-${country.name}`}
                imageUuid={country.uuid}
                countryName={country.name}
                countryCode={country.countryCode}
                setNationality={setNationality}
                selected={country.name === selectedNationality}
              />
            );
          })}
        </Drawer>
      )}
    </Flex>
  );
};
