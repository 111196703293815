import { Button, Flex } from '../atoms';
import { LimitsComponentMgaGra, PaymentWo, DepositWidget } from '../components';
import {
  TDepositLimitsPage,
  TOptionRadioBtton,
  TRegistrationUserDetails,
} from 'Src/typedefs';
import { TermsContainer } from 'Components/termsAndConditions/termsContainer';
import { ActionButtons } from 'Components/ActionButtons';
import classNames from 'classnames';

type Props = {
  userValues: TRegistrationUserDetails;
  onConfirm: () => void;
  description: string;
  depositLimitsPageData: TDepositLimitsPage;
  changeAmount: (value, limitType) => void;
  clearLimit: (limitType) => void;
  depositLimit: number | null;
  depositLimitError: string | null;
  depositType: string | null;
  despositLimitList: TOptionRadioBtton[];
  validateLimits: (onFinalValidation: boolean) => void;
  termsGTM: () => void;
  privacyPolicyGTM: () => void;
  stepNumber?: string;
  isMgaGra?: boolean;
  setUpLaterClick?: () => void;
  currency?: string;
};

export const DepositLimitsPageMgaGra = ({
  onConfirm,
  userValues,
  description,
  depositLimitsPageData,
  changeAmount,
  clearLimit,
  depositLimit,
  depositLimitError,
  depositType,
  despositLimitList,
  validateLimits,
  privacyPolicyGTM,
  stepNumber,
  isMgaGra,
  setUpLaterClick,
  currency,
}: Props) => {
  return (
    <Flex className='flex-1'>
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          <Flex className="bg-grey-0">
            {userValues?.selectedWelcomeOffer?.name && (
              <PaymentWo
                welcomeOffer={userValues.selectedWelcomeOffer}
                description={description}
              />
            )}
            <Flex className="justify-between">
              <LimitsComponentMgaGra
                depositLimitsPageData={depositLimitsPageData}
                currency={currency}
                changeAmount={changeAmount}
                clearLimit={clearLimit}
                depositLimit={depositLimit}
                depositLimitError={depositLimitError}
                depositType={depositType}
                despositLimitList={despositLimitList}
                validateLimits={validateLimits}
                stepNumber={stepNumber}
                isMgaGra={isMgaGra}
              />
            </Flex>
            {!isMgaGra && (
              <Flex className="mb-sm mt-2xs mx-xs items-baseline">
                <TermsContainer
                  className="my-sm text-grey-35"
                  gtmEvent={privacyPolicyGTM}
                  termsText={
                    depositLimitsPageData?.generalTermsAndConditionsText || ''
                  }
                  privacyText={depositLimitsPageData?.privacyPolicyText || ''}
                  isPrivacyPolicy={true}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ActionButtons
        primaryBtnText={depositLimitsPageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        secondaryBtnText={depositLimitsPageData?.setUpLater}
        secondaryBtnOnClick={setUpLaterClick}
        enabled={depositLimit && !depositLimitError}
        secondaryBtnEnabled={true}
      />
    </Flex>
  );
};
