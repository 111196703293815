import { useEffect, useRef } from 'react';
import { Flex } from '../../atoms';
import { useHeader, UseRegistrationContext } from '../../contexts';
import { ROUTE_NAMES } from '../../constants';
import { DepositAmountContainer } from '../../markets/sweden/containers/depositAmountContainer';
import { WelcomeOfferContainer } from '../../markets/sweden/containers/welcomeOfferContainer';
import { DepositLimitsContainer } from '../../markets/sweden/containers/depositLimitsContainer';
import { EmailAndPhoneContainer } from '../../markets/sweden/containers/emailAndPhoneContainer';
import { MarketingConsentContainer } from '../../markets/sweden/containers/marketingConsentContainer';
import { NationalityContainer } from '../../markets/sweden/containers/nationalityContainer';
import classNames from 'classnames';

// This is not a real router. We are not going to change the url because of the microFE setup
// but we will display the requested page from the flow based on the page name.
export const SwedishRegistrationRouter = () => {
  const { routeName } = UseRegistrationContext();
  const scrollElementRef = useRef(null);
  const { setHideHeader } = useHeader();
  useEffect(() => {
    if (scrollElementRef?.current) {
      scrollElementRef.current.scrollTop = 0;
    }

    if (routeName === ROUTE_NAMES.WELCOME_OFFER) {
      setHideHeader(true)
    } else {
      setHideHeader(false)
    }

  }, [routeName]);

  const registrationPageSelector = () => {
    switch (routeName) {
      case ROUTE_NAMES.WELCOME_OFFER:
        return <WelcomeOfferContainer />;

      case ROUTE_NAMES.DEPOSIT_AMOUNT:
        return <DepositAmountContainer />;

      case ROUTE_NAMES.LIMITS:
        return <DepositLimitsContainer />;

      case ROUTE_NAMES.EMAIL_AND_PHONE:
        return <EmailAndPhoneContainer />;

      case ROUTE_NAMES.NATIONALITY:
        return <NationalityContainer />;

      case ROUTE_NAMES.MARKETING_CONSENT:
        return <MarketingConsentContainer />;

      default:
        return <WelcomeOfferContainer />;
    }
  };

  return (
    <Flex
      reference={scrollElementRef}
      className={classNames(
        'flex-1 bg-grey-0 modalScrollbar w-full overflow-y-auto',
        {
          'tablet:h[500px] pt-sm px-sm tablet:pt-lg tablet:px-2xl':
            routeName !== ROUTE_NAMES.MARKETING_CONSENT,
          "!p-0 tablet:!p-0 no-scrollbar !bg-[#6E28FA]": routeName === ROUTE_NAMES.WELCOME_OFFER
        }
      )}
    >
      {registrationPageSelector()}
    </Flex>
  );
};
