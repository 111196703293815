import { AnimatedButton } from 'Components/animatedButton';
import { Flex } from 'Src/atoms/flex';
import { Span } from 'Src/atoms/span';
import { TEST_ID } from '../../constants';

type Props = {
  changePostcode: () => void;
  proceedPostcode: () => void;
  changePostcodePlaceholder: string;
  proceedPostcodePlaceholder: string;
  postcodeMessagePlaceholder?: string;
};

export const PostCodeMessage = ({
  proceedPostcode,
  changePostcode,
  changePostcodePlaceholder,
  proceedPostcodePlaceholder,
  postcodeMessagePlaceholder,
}: Props) => {
  return (
    <Flex className="tablet:justify-between grow">
      <Flex className="pb-sm">
        {postcodeMessagePlaceholder ||
          `Double check your postcode before proceeding. Otherwise click on
        “Proceed” to go to the go to the next step.`}
      </Flex>
      <Flex
        direction="flex-col"
        className="gap-xs tablet:gap-sm pt-md tablet:py-0 w-full tablet:flex-row"
      >
        <AnimatedButton
          id="changePostcode"
          onClick={changePostcode}
          testID={TEST_ID.PREFIX + 'postcode-change'}
        >
          <Flex direction="flex-row justify-center items-center">
            <Span className="mr-3xs">
              {changePostcodePlaceholder || 'Change Postcode'}
            </Span>
          </Flex>
        </AnimatedButton>
        <AnimatedButton
          styles="bg-teal-50 text-purple-60 font-bold"
          id="proceedPostcode"
          onClick={proceedPostcode}
          testID={TEST_ID.BUTTON + 'postcode-continue'}
        >
          <Flex direction="flex-row justify-center items-center">
            <Span className="mr-3xs">
              {proceedPostcodePlaceholder || 'Proceed'}
            </Span>
          </Flex>
        </AnimatedButton>
      </Flex>
    </Flex>
  );
};
