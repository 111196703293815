import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ClearInput from '../../assets/svgs/clearInputButton.svg';
import SearchIcon from '../../assets/svgs/searchIcon.svg';
import { Flex, Image, Input } from '../../atoms';
import { scrollToTop } from 'Src/utilities';
import { TEST_ID } from 'Src/constants';

type Props = {
  value?: string;
  autoFocus?: boolean;
  changeValue?: (value) => void;
  placeholder?: string;
  setHasFocus?: (value) => void;
  isError?: boolean;
  testID: string;
};

export const SearchInput = ({
  value,
  changeValue,
  placeholder,
  autoFocus,
  isError,
  testID,
}: Props) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 640px)').matches;
    if (isMobile) {
      setTimeout(() => {
        scrollToTop();
      }, 100);
    }
  }, []);

  return (
    <Flex>
      <Flex className="h-20 overflow-hidden">
        <Image
          src={SearchIcon}
          className="relative top-10 left-4 w-md"
          title={'Clear'}
          alt={'Delete'}
        />
        <Input
          className={classNames(
            'h-3xl pr-sm pl-14 bg-purple-100 bg-opacity-20 rounded-2xl caret-blue-50',
            'placeholder-purple-0 placeholder-opacity-50 border-2 border-solid border-purple-60 focus:border-white ',
            { 'focus:border-red-30  text-red-30': isError }
          )}
          onChange={(event) => changeValue(event?.target?.value)}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          value={value?.toString() || ''}
          placeholder={hasFocus ? '' : placeholder || 'Search country'}
          autoFocus={autoFocus}
          testID={TEST_ID.SEARCH + testID}
        />
        {value && (
          <Flex className="w-full h-0 items-end">
            <Image
              src={ClearInput}
              className="relative bottom-10 right-4 w-md"
              onClick={() => changeValue('')}
              title={'Clear'}
              alt={'Delete'}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
