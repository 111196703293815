import React, { useEffect, useState } from 'react';
import {
  useHeader,
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  COOKIE_NAMES,
  REGISTRATION_FLOW_ENDPOINTS,
} from '../../../constants';
import { storeDataInLocalStorage, getCookieByName } from '../../../utilities';
import { getMarketingConsentPageData } from '../../../service';
import { adaptUKGCRegistrationUserData } from '../../../service/registration/adapters';
import useInitiateRegistration from '../../../hooks/marketingConsent';
import { setMarketingConsentGtmEvent } from '../../../gtm';
import { gatekeeper } from 'Src/gatekeeper';
import { MarketingConsentPageUKGC } from 'Pages/marketingConsentPageUKGC';

export const MarketingConsentContainer: React.FC = () => {
  const { routeName, userValues, setPreviousRouteName } =
    UseRegistrationContext();
  const { registrationFlowsData } = UseDirectusContext();
  const { setHeaderTitle } = useHeader();
  const marketingConsentPageData = getMarketingConsentPageData(
    registrationFlowsData
  );
  const [isNotificationDrwaerOpen, setIsNotificationDrwaerOpen] = useState(false)
  const [notifications, setNotification] = useState({ sms: false, email: false, phone: false });
  const {
    toggleDrawer,
    isDrawerOpen
  } = useDrawer();
  const handleOpenDrawer = () => { setIsNotificationDrwaerOpen(true); toggleDrawer() }

  const { initiateRegistration, isLoader, setIsLoader } =
    useInitiateRegistration();

  useEffect(() => {
    setPreviousRouteName(userValues?.depositLimits ? ROUTE_NAMES.LIMITS : ROUTE_NAMES.BIRTH_DETAILS);
    storeDataInLocalStorage(ROUTE_NAMES.MARKETING_CONSENT, userValues);
    setHeaderTitle(marketingConsentPageData.header_title);
  }, []);

  const onConfirm = async (accepted: boolean, isAllNotificationsActive?: boolean) => {
    let currentActiveNotifications = { ...notifications }

    if (isAllNotificationsActive) {
      const allNotificationsActive = { sms: true, email: true, phone: true };
      setNotification(allNotificationsActive)
      currentActiveNotifications = allNotificationsActive
    }
    const marketingConsent = {
      casino: {
        contactBySms: currentActiveNotifications.sms,
        contactByEmail: currentActiveNotifications.email,
        contactByPhone: currentActiveNotifications.phone,
        contactByPost: false,
        contactByPushNotification: false
      },
      sports: {
        contactBySms: currentActiveNotifications.sms,
        contactByEmail: currentActiveNotifications.email,
        contactByPhone: currentActiveNotifications.phone,
        contactByPost: false,
        contactByPushNotification: false
      }
    }
    setTimeout(() => setIsLoader(true), 500);
    const userValuesPayload = { ...userValues, marketingConsent, }
    const userRegistrationData = adaptUKGCRegistrationUserData(userValuesPayload, getCookieByName(COOKIE_NAMES.KAZUMO_TRACKING_ID));
    const values = {
      ...userRegistrationData,
      subscribeToMarketingMaterial: accepted,
    };

    if (accepted) {
      const marketing_channels = Object.keys(currentActiveNotifications).filter((key) => currentActiveNotifications[key]).join(',');
      setMarketingConsentGtmEvent(accepted, true, marketing_channels);
    } else setMarketingConsentGtmEvent(accepted)

    await initiateRegistration(
      values,
      REGISTRATION_FLOW_ENDPOINTS.UKGC_REGISTRATION,
      gatekeeper.localisation.marketSlug
    );
    if (accepted && isDrawerOpen) toggleDrawer()
  };
  const handleChangeNotification = (type: string, value: boolean) => setNotification({ ...notifications, [type]: value });

  if (routeName !== ROUTE_NAMES.MARKETING_CONSENT) {
    return null;
  }
  return (
    <MarketingConsentPageUKGC
      notifications={notifications}
      handleChangeNotification={handleChangeNotification}
      marketingConsentPageData={marketingConsentPageData}
      onConfirm={onConfirm}
      isLoader={isLoader}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      enableDrawer={isNotificationDrwaerOpen}
      handleOpenDrawer={handleOpenDrawer}
    />
  );
};
