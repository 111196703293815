/* eslint-disable prettier/prettier */
import { Flex } from '../atoms';
import { AddressComponent, PaymentWo, AddressSelector } from '../components';
import { areAllFieldsPresent } from 'Src/utilities';
import {
  TAddress,
  TAddressErrors,
  TAddressPage,
  TAddressPrediction,
  TErrorMessage,
} from 'Src/typedefs';
import { REGISTRATION_ERRORS } from 'Src/constants';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  description: string;
  pageData: TAddressPage;
  changeValue: (value: any, addressComponent: string) => void;
  onConfirm: () => void;
  drawerTitle: string;
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  changeSearchValue: (value: any) => void;
  placeholder: string;
  searchValue: string;
  searchLoader: boolean;
  userValues: any;
  stepNumber: string;
  manualAddressOpened: boolean;
  setManualAddressOpened: (value) => void;
  setAddress: (value) => void;
  addresses: Array<TAddressPrediction>;
  detailedAddress: TAddress | null;
  selectedFlagUuid?: string;
  countryOfResidence?: string;
  error: TAddressErrors;
  dirictusErrors: TErrorMessage[]
};

export const AddressPage = ({
  description,
  pageData,
  changeValue,
  onConfirm,
  enableDrawer,
  isDrawerOpen,
  toggleDrawer,
  changeSearchValue,
  searchValue,
  searchLoader,
  userValues,
  stepNumber,
  manualAddressOpened = false,
  setManualAddressOpened,
  setAddress,
  addresses,
  detailedAddress,
  selectedFlagUuid,
  countryOfResidence,
  error,
  placeholder,
  drawerTitle,
  dirictusErrors
}: Props) => {
  const { province, hidePostalCodePopup, ...restOfAddress } = detailedAddress;

  // we check here if there's only one error and that error is that the Postal code is Invalid, but it's filled, so we could escape it
  const escapePostcodeError = (errors) =>
    errors && errors?.length === 1 && errors[0].errorName === REGISTRATION_ERRORS.INVALID_POSTAL_CODE;

  const areAddressFieldsValid = !error?.addressErrors || error?.addressErrors?.length === 0 || escapePostcodeError(error?.addressErrors);

  return (
    <Flex className='flex-1'>
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          <PaymentWo
            welcomeOffer={userValues?.selectedWelcomeOffer}
            description={description}
          />
          <Flex className="justify-between">
            <AddressComponent
              address={detailedAddress}
              stepNumber={stepNumber}
              addressPageData={pageData}
              changeValue={changeValue}
              manualAddressOpened={manualAddressOpened}
              setManualAddressOpened={setManualAddressOpened}
              toggleDrawer={toggleDrawer}
              selectedFlagUuid={selectedFlagUuid}
              countryOfResidence={countryOfResidence}
              error={error}
            />
          </Flex>
        </Flex>
        {enableDrawer && isDrawerOpen && (
          <AddressSelector
            pageData={pageData}
            enableDrawer={enableDrawer}
            drawerTitle={drawerTitle}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            setManualAddressOpened={setManualAddressOpened}
            changeValue={changeSearchValue}
            placeholder={placeholder}
            searchValue={searchValue}
            searchLoader={searchLoader}
            setAddress={setAddress}
            addresses={addresses}
            dirictusErrors={dirictusErrors}
          />
        )}
      </Flex>
      <ActionButtons
        primaryBtnText={pageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        enabled={areAllFieldsPresent(restOfAddress) && (areAddressFieldsValid)}
      />
    </Flex>
  );
};
