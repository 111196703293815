/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
//@ts-expect-error: We know we're a script
const host = new URL(document.currentScript?.src).origin;

export type TOnConnection = (
  root: HTMLElement,
  attributes: Record<string, string>,
  host: string,
  onReady: () => void
) => void;

export type TOnDisconnection = (node: HTMLElement) => void;

export function createWebComponent(
  name: string,
  onConnection: TOnConnection,
  onDisconnection: TOnDisconnection
) {
  customElements.define(
    name,
    class extends HTMLElement {
      connectedCallback() {
        const attributes = this.getAttributeNames().reduce(
          (obj, name) => ({
            ...obj,
            [name]: this.getAttribute(name),
          }),
          {}
        );

        onConnection(
          this,
          attributes,
          host,
          () => this.dispatchEvent(new Event('micro-frontend-attached')) // https://casumo.atlassian.net/browse/PBOB-295
        );
      }
      disconnectedCallback() {
        onDisconnection(this);
      }
    }
  );
}

export async function createStylesLink(node: HTMLElement, href: string) {
  return process.env.STANDALONE
    ? createStandaloneStylesLink(node)
    : createInjectedStylesLink(node, href);
}

export async function createStandaloneStylesLink(node: HTMLElement) {
  try {
    // @ts-ignore: we do the try-catch here
    return createInjectedStylesLink(node, node.host.querySelector('link').href);
  } catch (error) {
    console.error(
      'you need to put your tailwind index.css file in the index.html file for standalone development'
    );
  }
}

export async function createInjectedStylesLink(
  node: HTMLElement,
  href: string
) {
  await new Promise((resolve, reject) => {
    let styles = document.createElement('link');

    styles.href = href;
    styles.type = 'text/css';
    styles.rel = 'stylesheet';
    styles.onload = resolve;
    styles.onerror = reject;

    node.appendChild(styles);
  });
}

export async function createApplicationContainer(node: HTMLElement) {
  const container = document.createElement('main');

  node.appendChild(container);

  return container;
}
