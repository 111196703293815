import classNames from 'classnames';
import { Flex } from '../../atoms';
import { DrawerHeader } from './drawerHeader';

type Props = {
  isOpen: boolean;
  setIsOpen: () => void;
  isDefaultView?: boolean;
  onClick: (value) => void;
  children?: JSX.Element | JSX.Element[] | string;
  title?: string;
  buttonText?: string;
};

/*
  The main goal of this component is to display a component what is sliding from bottom to top and take 1/3 of the screen to display specific information.
  @param {JSX.Element} children - the children components that will be rendered.
  @param {string} title - the title that will be displayed in the header.
  @param {boolean} isOpen - the state that will be used to determine if the drawer should be open or not.
  @param {() => void} setIsOpen - the function that will be used to set the state of the drawer.
  @param {() => void} onClick - the function that will be used to trigger a function if the button is clicked.
  @param {string} buttonText - the text that will be displayed in the button.
  @returns {JSX.Element} that displays the drawer component.
*/

export const DrawerNotifications = ({
  children,
  title,
  isOpen,
  isDefaultView,
  onClick,
  setIsOpen,
  buttonText,
}: Props) => {
  const handleButtonClick = () => {
    onClick(null);
    setIsOpen();
  };

  return (
    <Flex
      className={classNames(
        'bg-white pt-md px-md text-grey-80 rounded-t-3xl tablet:rounded-3xl items-center',
        { drawerDefault: isDefaultView && !isOpen },
        {
          drawerNotifications: isOpen,
        },
        { drawerNotificationsInvisible: !isDefaultView && !isOpen }
      )}
    >
      <Flex
        className={
          'bg-white h-full tablet:h-[345px] text-grey-80 items-center overflow-y-auto modalScrollbar'
        }
      >
        <Flex className="notificationContainer items-center">
          <DrawerHeader title={title} onClose={() => setIsOpen()} />
          <Flex className="py-sm">
            <Flex>{children}</Flex>
            <Flex className="defaultButton mt-md">
              <fabric-registration-button
                onClick={() => handleButtonClick()}
                id="btnDrawer"
                primary
              >
                {buttonText}
              </fabric-registration-button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
