import { Dispatch, SetStateAction } from "react";
import { Flex, Span } from "Src/atoms";
import { TEST_ID } from "Src/constants";
import { setselectAddressGtmEvent } from "Src/gtm";
import { TAddressPrediction } from "Src/typedefs";

type ItemProps = {
  setAddress?: Dispatch<SetStateAction<TAddressPrediction>>;
  address?: TAddressPrediction;
};

export const AddressItem = ({ setAddress, address }: ItemProps) => {
  if (!address) return;

  const selectAddress = () => {
    setselectAddressGtmEvent(address);
    setAddress(address);
  };

  return (
    <Flex
      direction="flex-col"
      className="p-sm mb-2xs rounded-2xl items-start text-h-sm font-bold cursor-pointer bg-purple-80"
      onClick={selectAddress}
      testID={TEST_ID.SEARCH_RESULT + 'address'}
    >
      <Span>{`${address.description}`}</Span>
    </Flex>
  );
};