import { GOOGLE_ADDRESS_VALIDATION_API_KEY } from 'Src/constants';

const isDevEnv = () =>
  window.location.origin.includes('casumo.local') ||
  window.location.origin.includes('localhost');

const isCasumoTestEnv = () => window.location.origin.includes('casumotest');

export const shouldLoadTestEnvVars = isDevEnv() || isCasumoTestEnv();

export const CASUMO_BRAND_ID = shouldLoadTestEnvVars
  ? 'c4168490-0842-11e2-b0fd-005056bf4a60'
  : '1551d910-0d4b-11e2-98eb-0050568c183b';

export const DIRECTUS_URL = shouldLoadTestEnvVars
  ? 'https://casumotest.directus.app'
  : 'https://casumo.directus.app';

export const IMGIX_URL = shouldLoadTestEnvVars
  ? 'https://images-cdn.casumotest.com/casumotest'
  : 'https://images-cdn.casumo.com/casumo';

export const MIGRATION_SERVICE_URL = '/player/se-player-lifecycle/api';

export const PAYMENT_SERVICE_URL = shouldLoadTestEnvVars
  ? 'https://api.casumotest.com/player-api/payment-methods/api'
  : 'https://api.casumo.com/player-api/payment-methods/api';

export const PLAY_OK_SERVICE_URL = shouldLoadTestEnvVars
  ? 'https://api.casumotest.com/player-api/playok/api'
  : 'https://api.casumo.com/player-api/playok/api';

export const REGISTRATION_SERVICE_URL = shouldLoadTestEnvVars
  ? 'https://www.casumotest.com/casino-player/acquisition-gateway/api/v1'
  : 'https://www.casumo.com/casino-player/acquisition-gateway/api/v1';

export const GOOGLE_ADDRESS_VALIDATION_SERVICE_URL = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${GOOGLE_ADDRESS_VALIDATION_API_KEY}`;
