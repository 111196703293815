import { getTermsData, getWelcomeOfferPageData } from '../../service';
import { TTerm, TWelcomeOfferPage } from 'Src/typedefs';
import { TERMS_AND_CONDITIONS_TYPES } from 'Src/constants';

/*
 The main goal of this hook is to return the clean welcomeOffer page data considering if user must withdraw or not.
 @returns {TWelcomeOfferPage} object that contains the data that we need.
*/
export const selectWelcomeOfferPageData = (
  registrationFlowsData,
  termsData
) => {
  const rawWelcomeOfferPageData = getWelcomeOfferPageData(
    registrationFlowsData
  );

  const generalTermsAndConditionsData: TTerm = getTermsData(
    termsData,
    TERMS_AND_CONDITIONS_TYPES.BONUS_TERMS
  );

  return {
    header_title: rawWelcomeOfferPageData.header_title,
    message: rawWelcomeOfferPageData?.message,
    dontWant: rawWelcomeOfferPageData?.dontWant,
    terms: generalTermsAndConditionsData,
    casinoWidgetTitle: rawWelcomeOfferPageData?.casinoWidgetTitle,
    sportWidgetTitle: rawWelcomeOfferPageData?.sportWidgetTitle,
    welcomeOfferMessageTitle: rawWelcomeOfferPageData?.welcomeOfferMessageTitle,
    playResponsibly: rawWelcomeOfferPageData?.playResponsibly,
    readTermsDescription: rawWelcomeOfferPageData?.readTermsDescription,
    selectOfferBtnText: rawWelcomeOfferPageData?.selectOfferBtnText,
  } as TWelcomeOfferPage;
};
