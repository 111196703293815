import { TImgixOptions } from './types'

const IMGIX_DEFAULT_OPTIONS = {
  auto: 'format,compress'
} satisfies TImgixOptions

const stripCmsUrlParts = (url: string) => {
  if (!url) {
    return ''
  }

  url = url.replace(/^cms.casumo.com/, 'images-cms.casumo.com')
  url = url.replace('/cms.casumo.com', '/images-cms.casumo.com')
  url = url.replace('data-cms-casumo-com.storage.googleapis.com', 'images-cms.casumo.com') // Needed after CMS migration on Dec 2 2021
  url = url.replace('wp-content/uploads/', '')

  return url
}

export const convertCmsUrlToImgixUrl = (url: string, options: TImgixOptions = {}): string => {
  if (url?.startsWith('data:')) {
    return url
  }

  const imgixUrl = stripCmsUrlParts(url)

  const params = {
    ...IMGIX_DEFAULT_OPTIONS,
    ...options
  } satisfies TImgixOptions

  const textParams = Object.entries(params).map(([key, value]) => [key, value?.toString()])
  const urlParams = new URLSearchParams(textParams).toString()

  return imgixUrl.includes('?') ? `${imgixUrl}&${urlParams}` : `${imgixUrl}?${urlParams}`
}

export const getImgixUrl = (imageUrl: string, markUrl?: string, options?: TImgixOptions) => {
  const image = stripCmsUrlParts(imageUrl)
  const mark = markUrl ? stripCmsUrlParts(markUrl) : undefined
  const defaultParameters = {
    ...IMGIX_DEFAULT_OPTIONS,
    w: 250,
    fit: 'crop',
    markscale: 95,
    markalign: 'top,center',
    markfit: 'max',
    crop: 'top,faces',
    dpr: 1
  } satisfies TImgixOptions

  const params = {
    ...defaultParameters,
    ...options,
    ...(mark ? { mark } : {})
  } satisfies TImgixOptions

  const textParams = Object.entries(params).map(([key, value]) => [key, value?.toString()])
  const urlParams = new URLSearchParams(textParams).toString()

  return `${image}?${urlParams}`
}

export const getResponsiveImagesSources = (
  src: string,
  mark?: string,
  opts?: TImgixOptions,
  maxDpr = 1
) => {
  return [1, 2, 3]
    .filter((dpr) => dpr === 1 || dpr <= maxDpr)
    .map((dpr) => ({ dpr, imageUrl: getImgixUrl(src, mark, { ...opts, dpr }) }))
}
