import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import { ROUTE_NAMES, GTM_FEATURES, GTM_ACTIONS, OPTED_OUT_WELCOME_OFFER_ID } from '../../../constants';
import { getNationalityPageData } from '../../../service/directus/nationality';
import { TErrorMessage } from 'Src/typedefs';
import {
  getCountryCodeFromCountryName,
  getCountryDataFromCookie,
  getErrorMessage,
  getKeyByValue,
  redirectPlayerToCorrectMarket,
  storeDataInLocalStorage,
} from '../../../utilities';
import { validateCountryOfResidence } from '../../../validations';
import { getErrorData } from '../../../service/directus/errors';
import {
  sendGtmEvent,
  setCountryResidenceSelectorGtmEvent,
} from '../../../gtm';
import {
  acceptedCountries,
  adaptCountryFlags,
  countryHasWelcomeOffer,
} from 'Src/service/directus/countryFlags/adapters';
import { NationalityPage } from '../../../pages/nationalityPage';
import { CANADA_PROVNCES } from 'Src/countryCodesAndPrefixes';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const CountryOfResidenceContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { registrationFlowsData, errorData, countryFlagsData } =
    UseDirectusContext();

  const { setHeaderTitle } = useHeader();

  const countryOfResidencePageData = getNationalityPageData(
    registrationFlowsData
  );
  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData).filter(
    (country) => {
      return acceptedCountries.includes(country?.name);
    }
  );

  const allErrorData: TErrorMessage[] = getErrorData(errorData);

  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [countryOfResidence, setCountryOfResidence] = useState<string | null>(
    null
  );
  const [selectedFlagUuid, setSelectedFlagUuid] = useState<string | null>(null);
  const [countryOfResidenceCountryCode, setCountryOfResidenceCountryCode] =
    useState<string | null>(null);
  const [enableNationalitySelector, setEnableNationalitySelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { isFlagDrawerOpen, toggleFlagDrawer, isProvinceDrawerOpen, toggleProvinceDrawer } = useDrawer();

  const [enableProvinceSelector, setEnableProvinceSelector] =
    useState<boolean>(false);

  const [selectedProvince, setSelectedProvince] = useState<string>('');
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string>('');

  const [provinceError, setProvinceError] = useState<string>('');

  const openProvinceSelector = () => {
    setSearchValue('');
    setEnableProvinceSelector(true);
    toggleProvinceDrawer()
  };

  const setProvince = (province: string) => {
    setSelectedProvince(province);
    const provinceCode = getKeyByValue(CANADA_PROVNCES, province);
    setSelectedProvinceCode(provinceCode);
    toggleProvinceDrawer()
    setProvinceError('');
  };

  useEffect(() => {
    setPreviousRouteName(null);

    // Read stored data and set it if there is any.
    if (userValues?.countryOfResidence) {
      setCountryOfResidence(userValues?.countryOfResidence);
      setSelectedFlagUuid(
        selectedCountry(userValues?.countryOfResidence)?.uuid
      );
      if (userValues?.selectedProvince) {
        setSelectedProvince(userValues?.selectedProvince);
        setSelectedProvinceCode(userValues?.selectedProvinceCode);
      }
    } else {
      setCountryOfResidence(selectedCountry()?.name);
      setSelectedFlagUuid(selectedCountry()?.uuid);
    }

    setHeaderTitle(countryOfResidencePageData.header_title);
  }, []);

  const selectedCountry = (selectedCountry = '') => {
    let userRegion: string;
    if (!selectedCountry) {
      const { userRegionName, userCountryName } =
        getCountryDataFromCookie(adaptedCountryFlags);
      userRegion = userRegionName;
      selectedCountry = userCountryName;
    }

    setEnableProvinceSelector(selectedCountry?.toLowerCase() === 'canada');

    return adaptedCountryFlags.find(
      (country) => country.name === selectedCountry
    );
  };

  useEffect(() => {
    setCountryOfResidenceCountryCode(
      getCountryCodeFromCountryName(countryOfResidence)
    );
    //BE and GTM expect country code instead of country name as countryOfResidence
  }, [countryOfResidence]);

  useEffect(() => {
    if (isFlagDrawerOpen) {
      setCountryResidenceSelectorGtmEvent(GTM_ACTIONS.COUNTRY_OF_RESIDENCE);
    }
  }, [isFlagDrawerOpen]);

  const changeCountryOfResidence = (countryName: string) => {
    if (!countryName) {
      return;
    }
    const country = countryName ? countryName : selectedCountry().name;
    if (countryName) {
      setCountryOfResidence(country);
      setSelectedFlagUuid(selectedCountry(country)?.uuid);
      setError(null);
    }
    toggleFlagDrawer();
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  if (routeName !== ROUTE_NAMES.COUNTRY_OF_RESIDENCE) {
    return null;
  }

  const countryOfResidenceValidation = () => {
    const validationResult = validateCountryOfResidence(
      countryOfResidence,
      selectedProvince
    );
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult.error
      );
      validationResult.reason === 'country'
        ? setError(message?.message)
        : setProvinceError(message?.message);

      return false;
    }
    return true;
  };

  const onConfirm = () => {
    if (countryOfResidenceValidation()) {
      const isCountryRedirected = countryOfResidence !== userValues?.countryOfResidence;
      const hasWelcomeOffer = countryHasWelcomeOffer(countryFlagsData, countryOfResidence);
      const resetUserValues = isCountryRedirected
        ? {
          address: null,
          depositLimits: null,
          ...(userValues?.birthDetails && {
            birthDetails: { ...userValues.birthDetails, birthDate: null },
          }),
        }
        : {};

      const provinceValues = enableProvinceSelector ? { selectedProvince, selectedProvinceCode } : { selectedProvince: '', selectedProvinceCode: '' };
      const selectedWelcomeOffer = hasWelcomeOffer ? { ...userValues?.selectedWelcomeOffer } : { name: OPTED_OUT_WELCOME_OFFER_ID };

      redirectPlayerToCorrectMarket(
        countryOfResidenceCountryCode,
        selectedProvinceCode
      ).then((isPlayerRedirected) => {
        const newUserValues = {
          ...userValues,
          countryOfResidence,
          countryOfResidenceCountryCode,
          selectedFlagUuid,
          selectedWelcomeOffer,
          ...provinceValues,
          ...resetUserValues,
          countryRedirect: isPlayerRedirected,
        };
        setUserValues(newUserValues);
        storeDataInLocalStorage(hasWelcomeOffer ? ROUTE_NAMES.WELCOME_OFFER : ROUTE_NAMES.EMAIL_AND_PHONE, newUserValues);
        sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.COUNTRY_OF_RESIDENCE, {
          country_of_residence: countryOfResidenceCountryCode?.toLowerCase(),
          country_redirect: isPlayerRedirected,
          step: GTM_ACTIONS.COUNTRY_OF_RESIDENCE,
        });

        if (!isPlayerRedirected) {
          if (hasWelcomeOffer) {
            setRouteName(ROUTE_NAMES.WELCOME_OFFER);
          } else {
            setRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
          }
        }
      });
    }
  };

  const dependencyArray = [
    isProvinceDrawerOpen,
    isFlagDrawerOpen,
    countryOfResidence,
    countryOfResidenceCountryCode,
    enableNationalitySelector,
  ];
  UseHandleKeyDown(
    dependencyArray,
    !isFlagDrawerOpen && !isProvinceDrawerOpen,
    onConfirm
  );

  return (
    <NationalityPage
      description={description}
      pageData={countryOfResidencePageData}
      selectedFlagUuid={selectedFlagUuid}
      nationality={countryOfResidence}
      openCountrySelector={() => {
        setSearchValue('');
        setEnableNationalitySelector(true);
        toggleFlagDrawer();
      }}
      onConfirm={onConfirm}
      error={error}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enableNationalitySelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      changeValue={changeSearchValue}
      searchValue={searchValue}
      setNationality={changeCountryOfResidence}
      userValues={userValues}
      enableProvinceSelector={enableProvinceSelector}
      isProvinceDrawerOpen={isProvinceDrawerOpen}
      setEnableProvinceDrawer={() => {
        toggleProvinceDrawer()
      }}
      provincePlaceholder={countryOfResidencePageData?.provincePlaceholder}
      setProvince={setProvince}
      selectedProvince={selectedProvince}
      provinceDrawerTitle={
        countryOfResidencePageData?.provinceSearchHeaderTitle
      }
      openProvinceSelector={openProvinceSelector}
      provinceError={provinceError}
    />
  );
};
