import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  position?: string;
  direction?: string;
  onClick?: () => void;
  onMouseDown?: () => void;
  onScroll?: () => void;
  style?: object;
  id?: string;
  children?: JSX.Element | JSX.Element[] | string | string[] | number;
  reference?: React.MutableRefObject<any>;
  testID?: string;
}

export const Flex: React.FC<Props> = ({
  position,
  className,
  direction = 'flex-col',
  onClick,
  onMouseDown,
  onScroll,
  style = {},
  children,
  id,
  reference,
  testID,
}) => {
  const flexClass = classNames(
    { relative: !position },
    'flex',
    `${direction}`,
    'flex-shrink-0',
    className
  );
  return (
    <div
      onScroll={onScroll}
      className={flexClass}
      onClick={onClick}
      onMouseDown={onMouseDown}
      id={id}
      style={style}
      ref={reference}
      data-testid={testID}
    >
      {children}
    </div>
  );
};
