import { getDepositsPageData } from '../../service';
import { TDepositsPage } from 'Src/typedefs';

/*
 The main goal of this hook is to return the clean welcomeOffer page data considering if user must withdraw or not.
 @returns {TWelcomeOfferPage} object that contains the data that we need.
*/
export const selectDepositsPageData = (registrationFlowsData) => {
  const rawDepositsPageData = getDepositsPageData(registrationFlowsData);

  return {
    header_title: rawDepositsPageData.header_title,
    message: rawDepositsPageData.message,
    bonusMessage: rawDepositsPageData.bonusMessage,
    placeholder: rawDepositsPageData.placeholder,
    depositText: rawDepositsPageData.depositText,
    caviatesText: rawDepositsPageData.caviatesText,
    noDepositText: rawDepositsPageData.noDepositText,
    defaultBonusMessage: rawDepositsPageData.defaultBonusMessage,
  } as TDepositsPage;
};
