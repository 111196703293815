export const CURRENCY_BY_COUNTRY_CODE = Object.freeze({
  SE: "SEK",
  NO: "EUR",
  FI: "EUR",
  DE: "EUR",
  CA: "CAD",
  DK: "DKK",
  NZ: "NZD",
  MT: "EUR",
  IN: "INR",
  JP: "USD",
  AT: "EUR",
  IE: "EUR",
  SB: "USD",
  TH: "USD",
  PH: "USD",
  ID: "USD",
  DZ: "USD",
  EG: "USD",
  LB: "USD",
  QA: "USD",
  AE: "USD",
  KW: "USD",
  VN: "USD",
  SA: "USD",
  BQ: "USD",
  CR: "USD",
  NA: "USD",
  BM: "USD",
  BO: "USD",
  KN: "USD",
  BB: "USD",
  PE: "USD",
  ZA: "USD",
  UY: "USD",
  RW: "USD",
  MV: "USD",
  PG: "USD",
  CU: "USD",
  LS: "USD",
  AI: "USD",
  GT: "USD",
  TL: "USD",
  CL: "USD",
  MY: "USD",
  TZ: "USD",
  TC: "USD",
  FJ: "USD",
  KP: "USD",
  TT: "USD",
  LC: "USD",
  NR: "USD",
  CW: "USD",
  MD: "USD",
  SC: "USD",
  SH: "USD",
  NU: "USD",
  IO: "USD",
  CV: "USD",
  GD: "USD",
  HN: "USD",
  SZ: "USD",
  DO: "USD",
  GW: "USD",
  MZ: "USD",
  IR: "USD",
  MH: "USD",
  ML: "USD",
  MK: "USD",
  SX: "USD",
  RU: "USD",
  MW: "USD",
  TK: "USD",
  GQ: "USD",
  TV: "USD",
  MG: "USD",
  AW: "USD",
  KR: "USD",
  JM: "USD",
  LA: "USD",
  MS: "USD",
  FK: "USD",
  BR: "USD",
  BW: "USD",
  JE: "EUR",
  GG: "EUR",
  IM: "EUR",
  PN: "USD",
  VC: "USD",
  AM: "USD",
  ST: "USD",
  VG: "USD",
  CI: "USD",
  GE: "USD",
  MU: "USD",
  GS: "USD",
  FM: "USD",
  MX: "USD",
  ZM: "USD",
  KY: "USD",
  TO: "USD",
  KI: "USD",
  CC: "USD",
  AR: "USD",
  MN: "USD",
  GB: "GBP",
  ES: "EUR",
  NL: "EUR",
});
