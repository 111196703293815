import { useEffect, useRef, useState } from 'react';
import { UseGetPaymentsApiData } from '../../../hooks';
import {
  UseDirectusContext,
  UseRegistrationContext,
  useHeader,
} from '../../../contexts';
import {
  depositBonusCalculator,
  notValidIntegerValue,
  storeDataInLocalStorage,
  getErrorMessage,
  getPaymentWoDescription,
  getStepNumberTranslation,
} from '../../../utilities';

import {
  ROUTE_NAMES,
  DEFAULT_MAX_DEPOSIT,
  MARKETS,
  REGISTRATION_ERRORS,
  API_LOCALES_BY_MARKET,
  GTM_PAYLOAD,
  DISPLAYED_CURRENCIES,
} from '../../../constants';
import { TErrorMessage } from 'Src/typedefs';
import { setDepositGtmEvent, setAlertGtmEvent } from '../../../gtm';
import { getErrorData } from '../../../service/directus/errors';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { UseGetDepositPage } from 'Src/hooks/deposit';
import { DepositAmountPage } from '../../../pages/depositAmountPage';
import { CURRENCY_BY_COUNTRY_CODE } from 'Src/currencyByCountryCode';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const DepositAmountContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { setHeaderTitle } = useHeader();

  const { errorData } = UseDirectusContext();
  const { depositsPageData } = UseGetDepositPage();
  const allErrorData = getErrorData(errorData);
  const { paymentsApiData } = UseGetPaymentsApiData(
    API_LOCALES_BY_MARKET[MARKETS.SV]
  );

  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [depositError, setDepositError] = useState<string | null>(null);
  const [bonusAmount, setBonusAmount] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const validateRef = useRef(false);

  const displayedCurrency: string = DISPLAYED_CURRENCIES[MARKETS.SV];
  const stepNumber = getStepNumberTranslation('1', '4')?.sv;

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.WELCOME_OFFER);
    storeDataInLocalStorage(ROUTE_NAMES.DEPOSIT_AMOUNT, userValues);
    setHeaderTitle(depositsPageData?.header_title);

    // Read stored data and set it if there is any.
    if (userValues?.depositAmount) {
      setDepositAmount(userValues?.depositAmount);
    }
  }, [depositsPageData]);

  useEffect(() => {
    if (paymentsApiData === null) {
      setRouteName(ROUTE_NAMES.LIMITS);
      return;
    }
    if (userValues?.paymentMethodId) {
      setSelectedMethod(userValues?.paymentMethodId);
    } else if (paymentsApiData) {
      const preselectedPaymentMethod =
        paymentsApiData?.find((data) => data.recommended === true) ||
        paymentsApiData[0];
      setSelectedMethod(preselectedPaymentMethod?.id);
    }
  }, [paymentsApiData]);

  useEffect(() => {
    if (userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getPaymentWoDescription(
          userValues?.selectedWelcomeOffer,
          depositsPageData?.defaultBonusMessage,
          depositsPageData?.bonusMessage,
          depositAmount,
          displayedCurrency
        )
      );
    }

    if (
      !depositAmount ||
      !userValues?.selectedWelcomeOffer?.maxBonusAmount ||
      !userValues?.selectedWelcomeOffer?.percentage
    ) {
      return;
    }

    if (
      depositBonusCalculator(
        depositAmount,
        userValues?.selectedWelcomeOffer?.percentage
      ) <= userValues?.selectedWelcomeOffer?.maxBonusAmount
    ) {
      setBonusAmount(depositAmount);
    } else {
      setBonusAmount(userValues?.selectedWelcomeOffer?.maxBonusAmount);
    }
  }, [depositAmount, depositsPageData]);

  // We need this to ensure validateDepositAmount is triggered on buton clicks but not on value change.
  useEffect(() => {
    if (validateRef.current) {
      validateDepositAmount();
      // Reset the flag after validation
      validateRef.current = false;
    }
  }, [depositAmount]);

  const changeAmount = (value, fromButton: boolean) => {
    if (notValidIntegerValue(value, DEFAULT_MAX_DEPOSIT)) {
      return;
    }
    setDepositAmount(Number(value) || null);
    if (!fromButton) {
      setDepositError(null);
    } else {
      validateRef.current = fromButton;
    }
  };

  const clearDeposit = () => {
    setDepositAmount(null);
    setDepositError(null);
  };

  const depositClick = () => {
    if (!validateDepositAmount(true)) {
      return;
    }
    setDepositGtmEvent(depositAmount);
    setUserValues({
      ...userValues,
      depositAmount,
      currency: displayedCurrency,
      bonusAmount,
      paymentMethodId: selectedMethod,
      paymentMethodName: paymentsApiData?.find(
        (data) => data.id === selectedMethod
      )?.name,
    });
    setRouteName(ROUTE_NAMES.LIMITS);
  };

  const depositLaterClick = () => {
    setDepositGtmEvent(null);
    setUserValues({
      ...userValues,
      depositAmount: null,
      currency: displayedCurrency,
      bonusAmount: null,
      paymentMethodId: null,
      paymentMethodName: null,
    });
    setRouteName(ROUTE_NAMES.LIMITS);
  };

  const validateDepositAmount = (onConfirm?: boolean) => {
    if (!paymentsApiData || paymentsApiData.length < 1) {
      return;
    }
    if (!depositAmount && !onConfirm) {
      setDepositError(null);
      return false;
    }
    const selectedPaymentMethod = paymentsApiData?.find(
      (data) => data?.id === selectedMethod
    );
    const maxLimit = selectedPaymentMethod?.limits?.maxAmount?.amount;
    const minLimit = selectedPaymentMethod?.limits?.minAmount?.amount;
    const currency =
      userValues.currency ||
      selectedPaymentMethod?.limits?.maxAmount?.iso4217CurrencyCode ||
      CURRENCY_BY_COUNTRY_CODE['SE'];
    // To-do update this logic according ot incoming data and selected method.
    if (!depositAmount) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        REGISTRATION_ERRORS.DEPOSIT_REQUIRED
      );
      setDepositError(`${message?.message}`);
      setAlertGtmEvent(GTM_PAYLOAD.DEPOSIT_REQUIRED);
      return false;
    }
    if (depositAmount > maxLimit) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        REGISTRATION_ERRORS.DEPOSIT_TOO_HIGH
      );
      setDepositError(`${message?.message} ${maxLimit} ${currency}`);
      setAlertGtmEvent(GTM_PAYLOAD.DEPOSIT_TOO_HIGH);
      return false;
    }
    // To-do update this logic according ot incoming data and selected method.
    if (depositAmount < minLimit) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        REGISTRATION_ERRORS.DEPOSIT_TOO_LOW
      );
      setDepositError(`${message?.message} ${minLimit} ${displayedCurrency}`);
      setAlertGtmEvent(GTM_PAYLOAD.DEPOSIT_TOO_LOW);
      return false;
    }

    setDepositError(null);
    return true;
  };

  const dependencyArray = [
    depositAmount,
    selectedMethod,
    depositsPageData,
    paymentsApiData,
  ];
  UseHandleKeyDown(dependencyArray, true, depositClick);

  if (routeName !== ROUTE_NAMES.DEPOSIT_AMOUNT) {
    return null;
  }

  return (
    <DepositAmountPage
      userValues={userValues}
      description={description}
      depositsPageData={depositsPageData}
      paymentsApiData={paymentsApiData}
      depositClick={depositClick}
      currency={displayedCurrency}
      depositAmount={depositAmount}
      changeAmount={changeAmount}
      clearDeposit={clearDeposit}
      depositLaterClick={depositLaterClick}
      depositError={depositError}
      validateDepositAmount={validateDepositAmount}
      setSelectedMethod={setSelectedMethod}
      selectedMethod={selectedMethod}
      stepNumber={stepNumber}
    />
  );
};
