import { useEffect, RefObject } from 'react';

const useScrollIntoViewOnError = (error: string | undefined, ref: RefObject<HTMLElement>) => {
    useEffect(() => {
        if (error && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [error, ref]);
};

export default useScrollIntoViewOnError;