import classNames from 'classnames';
import { Flex } from '../../atoms';

type Props = {
  children?: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
};

export const DisableLayout = ({ children, isOpen }: Props) => {
  return (
    <Flex className={classNames({ disableLayout: isOpen })}>{children}</Flex>
  );
};
