import { TAddressPage } from '../../../typedefs';

interface IAddressPage {
  button_text: string;
  cant_find_text: string;
  city_placeholder: string;
  description: string;
  drawer_search_placeholder: string;
  enter_manually_text: string;
  header_title: string;
  house_number_placeholder: string;
  message: string;
  open_manual_address_text: string;
  postcode_placeholder: string;
  search_drawer_title: string;
  search_placeholder: string;
  street_placeholder: string;
  search_address_button: string;
  welcome_message: string;
  postal_code_error_message_title?: string;
  change_postcode_button: string;
  proceed_postcode_button: string;
  postcode_error_description: string;
}

export const adaptAddressPage = (data: IAddressPage) => {
  const result: TAddressPage = {
    headerTitle: data?.header_title,
    message: data?.message,
    description: data?.description,
    woMessage: data?.welcome_message,
    btnContinue: data?.button_text,
    cantFindText: data?.cant_find_text,
    cityPlaceholder: data?.city_placeholder,
    searchDrawerTitle: data?.search_drawer_title,
    searchPlaceholder: data?.search_placeholder,
    enterManuallyText: data?.enter_manually_text,
    searchAdressButton: data?.search_address_button,
    houseNumberPlaceholder: data?.house_number_placeholder,
    openManualAddressText: data?.open_manual_address_text,
    postcodePlaceholder: data?.postcode_placeholder,
    streetPlaceholder: data?.street_placeholder,
    postalCodeErrorMessageTitle: data?.postal_code_error_message_title,
    changePostcodeButton: data?.change_postcode_button,
    proceedPostcodeButton: data?.proceed_postcode_button,
    postcodeErrorDescription: data?.postcode_error_description
  };
  return result;
};
