import Cookies from 'js-cookie';

const decode = (cookieValue) => {
  try {
    return window.atob(cookieValue.replace(/-/g, '='));
  } catch (err) {
    // eslint-disable-next-line fp/no-throw
    throw new Error(
      'CookieManager: Failed to decode cookie value in fabric-registration'
    );
  }
};

const encode = (cookieValue) => {
  try {
    return window.btoa(JSON.stringify(cookieValue)).replace(/=/g, '-');
  } catch (err) {
    // eslint-disable-next-line fp/no-throw
    throw new Error(
      'CookieManager: Failed to encode cookie value in fabric-registration'
    );
  }
};

export const hasCookie = (cookieName) => Boolean(Cookies.get(cookieName));

export const removeCookie = (cookieName, options) =>
  Cookies.remove(cookieName, options);

export const readCookie = (
  cookieName,
  { decode: decodeFlag = true, parse: parseFlag = true } = {}
) => {
  const cookieValue = Cookies.get(cookieName);

  if (!cookieValue) {
    return parseFlag ? {} : '';
  } else {
    const cookieValueProcessed = decodeFlag ? decode(cookieValue) : cookieValue;
    if (!parseFlag) {
      return cookieValueProcessed;
    }
    if (cookieValueProcessed) {
      try {
        return JSON.parse(cookieValueProcessed);
      } catch (err) {
        // eslint-disable-next-line fp/no-throw
        throw new Error(`CookieManager: Failed to read cookie ${cookieName}`);
      }
    }
    return;
  }
};

export const storeInCookie = (
  cookieName,
  key,
  value,
  options = {},
  { encode: encodeFlag = true, simpleString = false } = {} // By default the cookie is always an object. With simpleString flag you can create a cookie with string value...
) => {
  try {
    const newCookieValue = readCookie(cookieName, { decode: encodeFlag });
    // eslint-disable-next-line fp/no-mutation
    newCookieValue[key] = value;

    const encodedCookie = encodeFlag ? encode(newCookieValue) : newCookieValue;
    simpleString
      ? storeInRawCookie(cookieName, value, options)
      : storeInRawCookie(cookieName, encodedCookie, options);
  } catch (err) {
    console.error('CookieManager: Error storing cookie', err);
  }
};

export const storeInRawCookie = (cookieName, value, options) => {
  Cookies.set(cookieName, value, {
    path: '/',
    expires: 365,
    secure: window.location.protocol === 'https:',
    sameSite: 'lax',
    ...options,
  });
};
