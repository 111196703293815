import { TMarketSlug, TUrlPrefix } from '../gate/localisation'

const URL_PREFIX_TO_MARKET_MAP: Record<TUrlPrefix, TMarketSlug> = Object.freeze({
  en: '___en',
  'en-gb': 'gb_en',
  'en-ca': 'ca_en',
  'en-nz': 'nz_en',
  'en-in': 'in_en',
  es: 'es_es',
  on: 'on_en',
  fi: 'fi_fi',
  sv: 'se_sv',
  ie: 'ie_en',
  no: 'no_no',
  rog: '___en',
  row: '___en'
})

export const urlPrefixToMarketMapper = (marketPrefix: TUrlPrefix): TMarketSlug => {
  return URL_PREFIX_TO_MARKET_MAP[marketPrefix]
}
