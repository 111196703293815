import { TCountryFlags, TFlags } from '../../../typedefs';

export const acceptedCountries = [
  'Canada',
  'Finland',
  'Ireland',
  'New Zealand',
  'Norway',
  'South Africa',
  'Sweden',
  'United Kingdom',
  'Aland Islands',
  'Argentina',
  'Armenia',
  'Aruba',
  'Bermuda',
  'Chile',
  'Dominican Republic',
  'Egypt',
  'Falkland Islands',
  'Georgia',
  'Gibraltar',
  'Grenada',
  'Guernsey',
  'Iceland',
  'Indonesia',
  'Isle of Man',
  'Jersey',
  'Kuwait',
  'Liechtenstein',
  'Luxembourg',
  'Malawi',
  'Malta',
  'Mexico',
  'Monaco',
  'Nauru',
  'Papua New Guinea',
  'Qatar',
  'San Marino',
  'Solomon Islands',
  'Svalbard and Jan Mayen',
  'Eswatini',
  'Thailand',
  'Tuvalu',
  'Uruguay',
  'Zambia',
]

export const adaptCountryFlags = (data: Array<TCountryFlags>) => {
  return data
    .map((country) => {
      const result: TFlags = {
        name: country.name,
        countryCode: country.country_code?.[0]?.calling_code, // Optional chaining for safety
        uuid: country.images?.[0]?.directus_files_id.filename_disk, // Corrected path with optional chainings
      };
      return result;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const countryHasWelcomeOffer = (data: Array<TCountryFlags>, selectedCountry: string) => {
  return data.some((country) => country.name === selectedCountry && country.has_welcome_offer);
};
