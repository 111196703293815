import { match as isMarket } from "../../../src/params/isMarket.ts";
import { match as isPlayParamPage } from "../../../src/params/isPlayParamPage.ts";
import { match as isPlayerHubContentUrl } from "../../../src/params/isPlayerHubContentUrl.ts";
import { match as isValidContentOverlayUrl } from "../../../src/params/isValidContentOverlayUrl.ts";
import { match as isValidHubContentUrl } from "../../../src/params/isValidHubContentUrl.ts";
import { match as isValidMarket } from "../../../src/params/isValidMarket.ts";
import { match as isValidNoShellUrl } from "../../../src/params/isValidNoShellUrl.ts";
import { match as isValidSportsHubContentUrl } from "../../../src/params/isValidSportsHubContentUrl.ts";
import { match as isWalletHubContentUrl } from "../../../src/params/isWalletHubContentUrl.ts";

export const matchers = { isMarket, isPlayParamPage, isPlayerHubContentUrl, isValidContentOverlayUrl, isValidHubContentUrl, isValidMarket, isValidNoShellUrl, isValidSportsHubContentUrl, isWalletHubContentUrl };