import { useRef, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { Flex } from '../../atoms';
import { DrawerHeader } from './drawerHeader';
import { SearchInput } from '..';

type Props = {
  isOpen: boolean;
  setIsOpen: () => void;
  isDefaultView?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  title: string;
  titleSize?: string;
  enableSearch?: boolean;
  changeValue?: (value) => void;
  placeholder?: string;
  searchValue?: string;
  autoFocus?: boolean;
  isError?: boolean;
  testID?: string;
};

/*
  The main goal of this component is to display a component what is sliding from bottom to top and take 3/4 of the screen to display specific information.
  @param {JSX.Element} children - the children components that will be rendered.
  @param {string} title - the title that will be displayed in the header.
  @param {boolean} isOpen - the state that will be used to determine if the drawer should be open or not.
  @param {() => void} setIsOpen - the function that will be used to set the state of the drawer.
  @param {string} titleSize - the size of the title.
  @param {boolean} enableSearch - the state that will be used to determine if the search input should be displayed or not.
  @param {(value) => void} changeValue - the function that will be used to trigger a function if search input value is changed.
  @param {string} placeholder - the placeholder that will be displayed in the search input.
  @param {string} searchValue - the value that will be displayed in the search input.
  @returns {JSX.Element} that displays the drawer component.
*/

export const Drawer = ({
  children,
  title,
  isOpen,
  setIsOpen,
  isDefaultView,
  titleSize = 'text-h-lg',
  enableSearch = false,
  changeValue,
  placeholder,
  searchValue,
  autoFocus,
  isError,
  testID,
}: Props) => {
  const drawerRef = useRef(null);
  const drawerContainerRef = useRef<HTMLElement>(null);
  const handleResetScrollTop = () => {
    if (drawerRef?.current) {
      drawerRef.current.scrollTop = 0;
    }
  };
  const [offset, setOffset] = useState(0);

  useLayoutEffect(() => {
    if (drawerContainerRef.current) {
      setOffset(drawerContainerRef.current.offsetHeight);
    }
  }, [drawerContainerRef.current]);

  return (
    <Flex
      className={classNames(
        `text-lg bg-purple-90 p-md text-white rounded-t-3xl gap-sm`,
        { drawerDefault: isDefaultView && !isOpen },
        {
          drawerInvisible: !isDefaultView && !isOpen,
        },
        { drawer: isOpen }
      )}
    >
      <Flex reference={drawerContainerRef}>
        {title && (
          <DrawerHeader
            title={title}
            titleSize={titleSize}
            onClose={() => {
              handleResetScrollTop();
              setIsOpen();
            }}
          />
        )}
        {enableSearch && (
          <SearchInput
            value={searchValue}
            changeValue={changeValue}
            placeholder={placeholder}
            autoFocus={autoFocus}
            isError={isError}
            testID={testID}
          />
        )}
      </Flex>

      <Flex
        reference={drawerRef}
        className="drawerScrollbar"
        style={{
          height: `calc(100% - ${offset}px - 16px)`,
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
