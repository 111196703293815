import { useEffect, useState } from 'react';
import { Flex, Span } from '../../atoms';
import { TEmailAndPhonePage, TFlags } from 'Src/typedefs';
import { RegistrationInput, PhoneInput } from '..';
import { INPUT_TYPES, TEST_ID } from '../../constants';

type Props = {
  isMgaGra: boolean;
  emailAndPhonePageData: TEmailAndPhonePage;
  selectedCountry: TFlags;
  changeValue: (value, inputType) => void;
  clearValue: (inputType) => void;
  email: string | null;
  password?: string | null;
  phoneNumber: string | null;
  validateEmail: (onContinue) => void;
  validatePassword?: (onContinue) => void;
  validatePhone: (onContinue) => void;
  emailError?: string;
  passwordError?: string;
  phoneNumberError?: string;
  openPrefixSelector: () => void;
  stepNumber: string;
};

export const EmailAndPhoneComponent = ({
  isMgaGra,
  emailAndPhonePageData,
  selectedCountry,
  changeValue,
  clearValue,
  email,
  password,
  phoneNumber,
  validateEmail,
  validatePassword,
  validatePhone,
  emailError,
  passwordError,
  phoneNumberError,
  openPrefixSelector,
  stepNumber,
}: Props) => {
  const [emailHasFocus, setEmailHasFocus] = useState<boolean>(false);
  const [passwordHasFocus, setPasswordHasFocus] = useState<boolean>(false);
  const [phoneHasFocus, setPhoneHasFocus] = useState<boolean>(false);

  const onContinue = false;
  useEffect(() => {
    if (!emailHasFocus) {
      validateEmail(onContinue);
    }
  }, [emailHasFocus]);

  useEffect(() => {
    if (isMgaGra && !passwordHasFocus) {
      validatePassword(onContinue);
    }
  }, [passwordHasFocus]);

  useEffect(() => {
    if (isMgaGra && !passwordHasFocus) {
      validatePassword(onContinue);
    }
  }, [passwordHasFocus]);

  useEffect(() => {
    if (!phoneHasFocus) {
      validatePhone(onContinue);
    }
  }, [phoneHasFocus]);

  const changeEmail = (value) => {
    changeValue(value, INPUT_TYPES.EMAIL);
  };

  const changePassword = (value) => {
    changeValue(value, INPUT_TYPES.PASSWORD);
  };

  const changePhone = (value) => {
    changeValue(value, INPUT_TYPES.PHONE);
  };

  const clearEmail = () => {
    clearValue(INPUT_TYPES.EMAIL);
  };

  const clearPassword = () => {
    clearValue(INPUT_TYPES.PASSWORD);
  };

  const clearPhone = () => {
    clearValue(INPUT_TYPES.PHONE);
  };

  return (
    <Flex className="p-sm tablet:p-md gap-sm bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Span className="text-purple-80">{stepNumber || ''}</Span>
        <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>
          {emailAndPhonePageData?.message}
        </Flex>
        {isMgaGra && (
          <Flex className="text-black text-sm">
            {emailAndPhonePageData?.description}
          </Flex>
        )}
      </Flex>
      <Flex className="text-black justify-between">
        {/* Email input */}
        <Flex className="mb-sm">
          <RegistrationInput
            currency={null}
            value={email}
            hasFocus={emailHasFocus}
            changeValue={changeEmail}
            placeholder={
              emailAndPhonePageData?.emailPlaceholder || 'Email address'
            }
            setHasFocus={setEmailHasFocus}
            clearValue={clearEmail}
            error={emailError}
            testID={TEST_ID.INPUT + 'email'}
          />
        </Flex>
        {/* Password input for MgaGra */}
        {isMgaGra && (
          <Flex className="mb-sm">
            <RegistrationInput
              currency={null}
              value={password}
              hasFocus={passwordHasFocus}
              changeValue={changePassword}
              placeholder={
                emailAndPhonePageData?.passwordPlaceholder || 'Password'
              }
              setHasFocus={setPasswordHasFocus}
              clearValue={clearPassword}
              error={passwordError}
              type={INPUT_TYPES.PASSWORD}
              testID={TEST_ID.INPUT + 'plainTextPassword'}
            />
          </Flex>
        )}
        {/* Phone Input */}
        <PhoneInput
          selectedCountry={selectedCountry}
          open={openPrefixSelector}
          error={phoneNumberError}
          changeValue={changePhone}
          clearValue={clearPhone}
          phoneNumber={phoneNumber}
          placeholder={
            emailAndPhonePageData?.phonePlaceholder || 'Phone number'
          }
          setHasFocus={setPhoneHasFocus}
          hasFocus={phoneHasFocus}
        />
      </Flex>
    </Flex>
  );
};
