import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';
import { TMarketingConsentPage } from '../../../typedefs';
import { adaptMarketingConsentPage } from './adapters';

export const getMarketingConsentPageData = (registrationFlowsData: unknown) => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.MARKETING_CONSENT_STEP
  );
  const marketingConsentPage: TMarketingConsentPage =
    adaptMarketingConsentPage(data);
  return marketingConsentPage;
};
