import { Flex, Span } from "Src/atoms";
import { TAddressPage } from "Src/typedefs";

type TErrorMEssage = {
    pageData: TAddressPage;
    errorMessage: string;
    setManualAddress: Function;
}

export const NotFoundAddress = ({ errorMessage, setManualAddress, pageData }: TErrorMEssage) => {
    return (
        <Flex
            className="text-sm"
        >
            <Flex direction='flex-row' className='w-full justify-center items-center'>
                <Flex>
                    {errorMessage}&nbsp;
                </Flex>
                <Span className="underline underline-offset-2 cursor-pointer" onClick={() => {
                    setManualAddress(true)
                }}>
                    {pageData?.enterManuallyText || `Enter address manually`}
                </Span>
            </Flex>
        </Flex>
    );
};