import { AnimatedButton } from 'Components/animatedButton';
import { Flex } from 'Src/atoms/flex';
import { Span } from 'Src/atoms/span';

type Props = {
  description: string;
  primaryBtnText: string;
  secondaryBtnText: string;
  primaryBtnClick: () => void;
  secondaryBtnClick: () => void;
};

export const EmailMessage = ({
  description,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnClick,
  secondaryBtnClick,
}: Props) => {
  return (
    <Flex className="tablet:justify-between grow">
      <Flex className="pb-sm">
        {description ||
          'It seems like you already have an account registered with Casumo. Would you like to continue with your Login?'}
      </Flex>
      <Flex
        direction="flex-col"
        className="gap-xs tablet:gap-sm pt-md tablet:py-0 w-full tablet:flex-row"
      >
        <AnimatedButton id="tryAnotherEmail" onClick={primaryBtnClick}>
          <Flex direction="flex-row justify-center items-center">
            <Span className="mr-3xs">
              {primaryBtnText || 'Try another email'}
            </Span>
          </Flex>
        </AnimatedButton>
        <AnimatedButton
          styles="bg-teal-50 text-purple-60 font-bold"
          id="emailLogin"
          onClick={secondaryBtnClick}
        >
          <Flex direction="flex-row justify-center items-center">
            <Span className="mr-3xs">
              {secondaryBtnText || 'Login'}
            </Span>
          </Flex>
        </AnimatedButton>
      </Flex>
    </Flex>
  );
};
