import { TBirthDetailsPage, TOptionRadioBtton } from '../../../typedefs';

interface IBirthDetailsPage {
  header_title?: string;
  message?: string;
  welcome_message?: string;
  button_text?: string;
  birth_date_placeholder?: string;
  birth_place_placeholder?: string;
  gender_list?: TOptionRadioBtton[];
  gender_male: string;
  gender_female: string;
  gender_other: string;
  deposit_limit_redirect_text?: string;
  t_and_c_caveat?: string;
  privacy_policy_caveat?: string;
  country_of_birth?: string
}

export const adaptBirthDetailsPage = (data: IBirthDetailsPage) => {
  const result: TBirthDetailsPage = {
    headerTitle: data?.header_title,
    message: data?.message,
    woMessage: data?.welcome_message,
    btnContinue: data?.button_text,
    birthDatePlaceholder: data?.birth_date_placeholder,
    birthPlacePlaceholder: data?.birth_place_placeholder,
    countryOfBirthPlaceholder: data?.country_of_birth,
    genderMale: data?.gender_male,
    genderFemale: data?.gender_female,
    genderOther: data?.gender_other,
    depositLimitRedirectText: data?.deposit_limit_redirect_text,
    generalTermsAndConditionsText: data?.t_and_c_caveat,
    privacyPolicyText: data?.privacy_policy_caveat,
  };
  return result;
};
