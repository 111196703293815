import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { TFlags } from '../../typedefs';
import { Flex, Image, Span } from '../../atoms';
import { Drawer } from '..';
import { getAssetURL, sortCountries } from '../../utilities';
import { TEST_ID } from 'Src/constants';

type ItemProps = {
  country: TFlags;
  setPhoneCountry?: Dispatch<SetStateAction<TFlags>>;
  selected?: boolean;
};

const PhonePrefixItem = ({ country, setPhoneCountry, selected }: ItemProps) => {
  return (
    <Flex
      direction="flex-row"
      className={classNames(
        'h-3xl p-sm rounded-2xl text-h-sm font-bold items-center cursor-pointer',
        {
          'bg-purple-80': !selected,
          'bg-purple-60': selected,
        }
      )}
      onClick={() => {
        setPhoneCountry(country);
      }}
      testID={TEST_ID.PHONE_PREFIX + country.name}
    >
      <Flex className="w-md mr-2xs overflow-hidden">
        <Image
          src={getAssetURL(country.uuid)}
          className="max-w-none"
          width="auto"
          height="full"
          title={`${country.name} Flag`}
          alt={`${country.name}`}
        />
      </Flex>
      <Span className="w-fit">{country.name}</Span>
      <Flex className={'ml-3xs'}>{`(${country.countryCode})`}</Flex>
    </Flex>
  );
};

type Props = {
  enableDrawer?: boolean;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: () => void;
  phonePrefix?: string;
  changeValue?: (value) => void;
  placeholder?: string;
  searchValue?: string;
  setPhoneCountry?: Dispatch<SetStateAction<TFlags>>;
  selectedCountry?: TFlags;
  countryFlagsData?: Array<TFlags>;
  drawerTitle: string;
};

export const PhonePrefixSelector = ({
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeValue,
  placeholder,
  searchValue,
  setPhoneCountry,
  selectedCountry,
  countryFlagsData,
  drawerTitle,
}: Props) => {
  const countries = countryFlagsData
    .filter((country: TFlags) => {
      const name = country?.name.toLowerCase();
      const code = country?.countryCode.toLowerCase();
      const search = searchValue.toLowerCase();
      return !searchValue || name.includes(search) || code.includes(search);
    })
    .sort((a, b) => sortCountries(a, b, searchValue.toLowerCase()));

  return (
    <Flex>
      {enableDrawer && (
        <Drawer
          title={drawerTitle}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          titleSize={'text-h-lg font-bold'}
          enableSearch={true}
          changeValue={changeValue}
          placeholder={placeholder}
          searchValue={searchValue}
          autoFocus={true}
          testID="phonePrefix"
        >
          <Flex className="gap-2xs">
            {countries.map((country) => {
              return (
                <PhonePrefixItem
                  key={`${country.uuid}-${country.name}`}
                  country={country}
                  setPhoneCountry={setPhoneCountry}
                  selected={country.name === selectedCountry.name}
                />
              );
            })}
          </Flex>
        </Drawer>
      )}
    </Flex>
  );
};
