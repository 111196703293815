import { TEmailAndPhonePage } from '../../../typedefs';
import { adaptEmailAndPhonePage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getEmailAndPhonePageData = (registrationFlowsData: unknown) => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP
  );

  const emailAndPhonePage: TEmailAndPhonePage = adaptEmailAndPhonePage(data);
  return emailAndPhonePage;
};
