import React from 'react';

export enum InputMode {
  Search = 'search',
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Url = 'url',
  None = 'none',
  Numeric = 'numeric',
  Decimal = 'decimal',
}
export enum DateFormat {
  dd_MM_yyyy = 'dd/MM/yyyy',
  MM_dd_yyyy = 'MM/dd/yyyy',
  yyyy_MM_dd = 'yyyy/MM/dd',
  ddMMyyyy = 'dd.MM.yyyy',
  yyyy_mm_dd = 'yyyy-MM-dd',
}

export type TRealmAccess = { roles: string[] };

export type TResourceAccess = { broker: TRealmAccess; account: TRealmAccess };

export type TDevCodeProviders = {
  spar: {
    success: boolean;
    error: string;
    address: {
      returned: boolean;
      reason: string;
      changed: boolean;
    };
  };
};

export type TAuthorization = {
  casumo_session: string;
};

export type TKeycloakUser = {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  sub: string;
  typ: string;
  azp: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  scope: string;
  sid: string;
  email_verified: boolean;
  name: string;
  is_self_excluded: boolean;
  birthdate: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  // Address payload has been updated according to keycloak requirements.
  address_street_name: string;
  address_postal_code: string;
  address_country: string;
  address_city: string;
  address_is_verified: boolean;
  providers: TDevCodeProviders;
  gender: string;
  birthdate_verified: string;
  ssn: string;
  player_id: string;
  is_protected_identity: string;
  nationality: string;
  authorization: TAuthorization;
  phone_number: string;
  is_fully_registered: boolean;
};

export type TImage = {
  src: string;
};

export type TWoImage = {
  src: string;
};

export type TWelcomeOffer = {
  id: string;
  name?: string;
  valuable?: IWelcomeOfferValuable;
  vertical?: string;
  widgetTitle?: string;
  bigImage?: string;
  smallImage?: string;
  type?: string;
  title?: string;
  shortDesc?: string;
  minDepositAmount?: number;
  maxBonusAmount?: number;
  percentage?: number;
  significantTerms?: string | null;
  fullTerms?: string | null;
  backgroundSportsFreeBetId?: string;
  backgroundDepositBonus?: string;
  thumbnailImage?: DirectusImage | null;
  tokenImage?: DirectusImage | null;
};

export interface IWelcomeOfferValuable {
  collection: string | null;
  id: number | null;
  translations: Array<IValuableItemData>;
}

export interface IValuableItemData {
  caveat: string | null;
  content: string | null;
  thumbnail_image: { filename_disk?: string | null };
  token_image: { filename_disk?: string | null };
  languages_code: { code?: string | null };
  title: string | null;
}

export type TWelcomeOfferPage = {
  header_title?: string;
  message?: string;
  dontWant?: string;
  casinoWidgetTitle?: string;
  sportWidgetTitle?: string;
  terms?: TTerm;
  welcomeOfferMessageTitle?: string;
  playResponsibly?: string;
  readTermsDescription?: string;
  selectOfferBtnText: string;
};

export type TUserDepositLimits = {
  daily: number;
  weekly: number;
  monthly: number;
  depositLimit?: number;
  depositType?: string;
};

export type TBirthDetailsComponentUKGCProps = {
  userValues: TRegistrationUserDetails;
  onConfirm: (path: string) => void;
  description: string;
  birthDetailsPageData: TBirthDetailsPage;
  changeValue: (value: string, inputType: string) => void;
  clearInput: (inputType: string) => void;
  birthDate: string | null;
  placeOfBirth: string | null;
  birthDateError: string;
  placeOfBirthError: string;
  validateBirthDate: (onContinue: boolean) => void;
  validateBirthPlace: (onContinue: boolean) => void;
  sendTermsGtmEvent: (gtmAction: string) => void;
  redirectToDepositLimit: () => void;
  stepNumber?: string;
  dateFormat: DateFormat;
  termsAndPolicy: TTermsAndPrivacy;
  handleChangeTermsAndPolicy: (key: string, value: boolean) => void
  termsAndPrivacyError: boolean
  selectedFlagUuid: string;
  countryOfBirth: string;
  countryOfBirthCode?: string;
  openCountrySelector: () => void;
  countryFlagsData: TFlags[];
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: () => void;
  searchValue: string;
  setCountryOfBirth: (countryName: string) => void;
  countryOfBirthError?: string;
};

export type TTermsAndPrivacy = { termsAndConditions: boolean, privacyPolicy: boolean }

export type TUserBirthDetails = {
  birthDate: string;
  placeOfBirth: {
    city: string | null,
    country: string | null,
  },
  termsAndPrivacy?: TTermsAndPrivacy
};

export type TPhonePrefix = {
  prefix?: string;
};

export type TFlagUuid = {
  flagUuid?: string;
};

export type TOptionRadioBtton = {
  label: string;
  value: string;
};

export type TPhoneNumber = {
  country: TFlags;
  number: string;
  countryCode?: string;
};

export type TRegistrationUserDetails = {
  selectedWelcomeOffer: TWelcomeOffer | null;
  depositAmount: number | null;
  bonusAmount: number | null;
  currency: string | null;
  depositLimits: TUserDepositLimits | null;
  birthDetails: TUserBirthDetails | null;
  paymentMethodId: string | null;
  paymentMethodName: string | null;
  payAndPlayId: string | null;
  email: string;
  password: string | null;
  phoneNumber: TPhoneNumber | null;
  marketingConsent?: { [key: string]: { [key: string]: boolean } } | boolean | null;
  nationality: string | null;
  gender: string;
  nationalityCountryCode: string | null;
  countryOfResidence: string | null;
  countryOfResidenceCountryCode: string | null;
  selectedProvince: string | null;
  selectedProvinceCode: string | null;
  countryRedirect: boolean;
  residentialAddress: boolean;
  selectedFlagUuid: string | null;
  address: TAddress | null;
  firstName: string | null;
  lastName: string | null;
  isUserSelectedCountryManually?: false
  countryOfBirth?: null
  countryOfBirthCode?: null
};

export type TUserRegistrationData = {
  email: string;
  firstName: string;
  lastName: string;
  phonePrefix: string;
  phoneNumber: string;
  phoneVerified: boolean;
  socialSecurityNumber?: string;
  dayOfBirth: number;
  monthOfBirth: number;
  yearOfBirth: number;
  placeOfBirth?: {
    city: string | null;
    country: string | null;
  };
  address: {
    country: string;
    city: string;
    postalCode: string;
    streetName: string;
    isVerified: boolean;
    //the example in Swagger shows 'verified' but actually expects 'isVerfied"
  };
  nationality: string;
  welcomeOfferId: string;
  trackingId: string;
  subscribeToMarketingMaterial?: { [key: string]: { [key: string]: boolean } } | boolean | null;
  marketingConsent?: { [key: string]: { [key: string]: boolean } } | boolean | null;
};

export type TRegistrationDoneGTM = {
  email: string | undefined;
  lang: string | undefined;
  address_country: string | undefined;
  address_city: string | undefined;
  address_postal_code: string | undefined;
  address_street_name: string | undefined;
  address_house_number: string | undefined;
  day_of_birth: string | number | undefined;
  month_of_birth: string | number | undefined;
  year_of_birth: string | number | undefined;
  place_of_birth: {
    city: string | undefined;
    country: string | undefined;
  };
  birth_details_gender: string | undefined;
  nationality: string | undefined;
  country_residence: string | undefined;
  country_residence_country_code: string | undefined;
  deposit_amount?: number | undefined;
  deposit_defined?: boolean;
  deposit_limit?: number | undefined;
  deposit_type?: string | undefined;
  daily_limit_sv?: number | undefined;
  weekly_limit_sv?: number | undefined;
  monthly_limit_sv?: number | undefined;
  phone_country_prefix: string | undefined;
  phone_number: string | undefined;
  time_spent: number | undefined;
  number_of_occasions: number | undefined;
  country_redirect: boolean | undefined;
  wo_used: boolean;
  wo_id: string | undefined;
  welcome_offer_vertical: string | undefined;
  residential_address: string;
  marketing_consent: boolean | undefined;
  userId?: string | undefined; // Need to be camecase because of gtm setup.
  kazumoTtrackingId: string | undefined; // Need to be camecase because of gtm setup.
  status_code?: string | number | undefined;
  failed_attempts?: number | undefined;
};

export type TDepositsPage = {
  header_title?: string;
  message?: string;
  bonusMessage?: string;
  placeholder?: string;
  depositText?: string;
  caviatesText?: string;
  noDepositText?: string;
  defaultBonusMessage?: string;
};

export type TTerm = {
  termId: string;
  title?: string;
  description?: string;
};

export type TTermsTranslations = {
  content?: string;
  id: number;
  title?: string;
  languages_code?: {
    code: string;
    direction: string;
    name: string;
  };
  terms_and_conditions_id?: object;
};

export type TTermsAndConditions = {
  date_created?: string;
  date_updated?: string;
  id: string;
  name?: string;
  sort?: number;
  status?: string;
  translations?: TTermsTranslations[];
};

export type TCountryFlags = {
  date_created?: string;
  date_updated?: string;
  id?: string;
  name?: string;
  sort?: number;
  status?: string;
  has_welcome_offer?: boolean;
  is_restricted?: boolean;
  country_code?: [{ calling_code: string }];
  images?: TCountryFlagsImage;
};

export type TFlags = {
  name?: string;
  countryCode?: string;
  uuid: string;
};

export type TCountryFlagsImage = {
  id: number;
  directus_file_id: { filename_disk: string };
  country_flages_id: number;
};

export type TBirthDetailsPage = {
  headerTitle?: string;
  message?: string;
  woMessage?: string;
  btnContinue?: string;
  birthDatePlaceholder: string;
  birthPlacePlaceholder: string;
  countryOfBirthPlaceholder: string;
  genderMale: string;
  genderFemale: string;
  genderOther: string;
  depositLimitRedirectText: string;
  generalTermsAndConditionsText: string;
  privacyPolicyText: string;
};

export type TDepositLimitsPage = {
  header_title?: string;
  message?: string;
  woMessage?: string;
  paymentMessage?: string;
  btnContinue?: string;
  monthlyDepositPlaceholder: string;
  weeklyDepositPlaceholder: string;
  dailyDepositPlaceholder: string;
  monthlyText?: string;
  weeklyText?: string;
  dailyText?: string;
  generalTermsAndConditionsText: string;
  privacyPolicyText: string;
  description?: string;
  setUpLater?: string;
};

export type TEmailAndPhonePage = {
  header_title?: string;
  message?: string;
  description?: string;
  woMessage?: string;
  btnContinue?: string;
  emailPlaceholder?: string;
  passwordPlaceholder?: string;
  phonePlaceholder?: string;
  caviatesText?: string;
  paymentMessage?: string;
  searchPlaceholder?: string;
  flagDrawerTitle?: string;
  loginRedirectText?: string;
};
export type TMessageDrawer = {
  title: string;
  description?: string;
  primaryBtn?: {
    onClick: () => void;
    title: string;
  };
  secondaryBtn?: {
    onClick: () => void;
    title: string;
  };
  loginRedirectText?: string;
  messageComponent?: React.ReactNode;
  enableCloseButton?: boolean;
};

export type TNationalityPage = {
  header_title?: string;
  message?: string;
  description?: string;
  woMessage?: string;
  btnContinue?: string;
  nationalityPlaceholder?: string;
  caviatesText?: string;
  paymentMessage?: string;
  searchPlaceholder?: string;
  flagDrawerTitle?: string;
  firstNamePlaceholder?: string;
  lastNamePlaceholder?: string;
  provincePlaceholder?: string;
  provinceSearchHeaderTitle?: string;
};

export type TMarketingConsentPage = {
  header_title?: string;
  title?: string;
  description?: string;
  message?: string;
  image?: TImage;
  loaderText?: string;
  btnContinue: string;
  btnLater: string;
  marketing_consent_drawer_title: string;
  marketing_consent_drawer_description: string;
  marketing_consent_notifications_email: string;
  marketing_consent_notifications_phone: string;
  marketing_consent_notifications_sms: string;
  marketing_consent_primary_button: string;
  marketing_consent_secondary_button: string;
  marketing_consent_subtitle: string;
};

export type TEmailValidationResponse = {
  success: boolean;
  error?: string;
};

export type TPasswordValidationResponse = {
  success: boolean;
  error?: string;
};

export type TPhoneValidationResponse = {
  success: boolean;
  error?: string;
};

export type TValidationResult = {
  success: boolean;
  error: string | null;
  value: string;
};

export type TLimitsValidationResult = {
  dailyResult: TValidationResult;
  weeklyResult: TValidationResult;
  monthlyResult: TValidationResult;
};

export type TRegistrationResponse = {
  success: boolean;
  error?: string;
  errorData?: TregistartionErrorData;
  statusCode?: string | number;
  failedAttempts?: number;
  data?: {
    playerId?: string;
    payAndPlayId?: string;
  };
};

export type TTDirectusErrorTranslation = {
  btn_main_text?: string;
  btn_secondary_text?: string;
  id?: number;
  message?: string;
  title?: string;
  languages_code: {
    code?: string;
    direction?: string;
    name?: string;
  };
  flow_messages_id?: object;
};

export type TDirectusError = {
  date_created?: string;
  date_updated?: string;
  id?: number;
  key?: string;
  sort?: number;
  status?: string;
  type?: string;
  icon?: object;
  translations: TTDirectusErrorTranslation[];
};

export type TErrorMessage = {
  errorName: string;
  title: string;
  message: string;
  buttonText: string;
  buttonSecondaryText?: string;
};

// To-do update...
export type TSuggestedAmount = {
  amount: number;
  iso4217CurrencyCode: string;
};

export type TPaymentMethod = {
  id: string;
  type: string;
  logoPrimary: string;
  logoSecondary: string;
  name: string;
  displayName: string;
  suggestedAmounts: TSuggestedAmount[];
  limits: {
    maxAmount: {
      amount: number;
      iso4217CurrencyCode: string;
    };
    minAmount: {
      amount: number;
      iso4217CurrencyCode: string;
    };
    minLimitType: string;
    maxLimitType: string;
  };
  group?: {
    id: string;
    name: string;
    logoPrimary: string;
    logoSecondary: string;
  };
  provider: string;
  recommended: boolean;
};

export type TDepositLimits = {
  minAllowedLimits: {
    monthly: number;
    weekly: number;
    daily: number;
  };
  maxAllowedLimits: {
    monthly: number;
    weekly: number;
    daily: number;
  };
};

export type TPlayOkLimits = {
  depositLimits: TDepositLimits;
};

export type TLanguagesCode = 'en-GB' | 'sv-SE';

export type TPredictions = {
  description: string;
  place_id: string;
  reference: string;
  terms: { offset: number; value: string }[];
  types: string[];
  matched_substrings: { offset: number; length: number }[];
  structured_formatting: {
    secondary_text: string;
    main_text: string;
    main_text_matched_substrings: { offset: number; length: number }[];
  };
}[];

export type TAddress = {
  country?: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
  houseNumber?: string;
  province?: string;
  hidePostalCodePopup?: boolean
};

export type TAddressPrediction = {
  description?: string;
  placeId?: string;
};

export type TGoogleAddressOptions =
  | google.maps.places.AutocompletePrediction[]
  | null;
export type TAddressError = {
  message?: string;
  type?: string;
};

export type TAddressErrors = {
  addressErrors?: Array<TAddressError>;
};

export type TAddressPage = {
  headerTitle?: string;
  message?: string;
  description?: string;
  woMessage?: string;
  btnContinue?: string;
  cantFindText?: string;
  cityPlaceholder?: string;
  drawerSearchPlaceholder?: string;
  searchPlaceholder?: string;
  enterManuallyText?: string;
  searchAdressButton?: string;
  houseNumberPlaceholder?: string;
  openManualAddressText?: string;
  postcodePlaceholder?: string;
  searchDrawerTitle?: string;
  streetPlaceholder?: string;
  postalCodeErrorMessageTitle?: string;
  changePostcodeButton?: string;
  proceedPostcodeButton?: string;
  postcodeErrorDescription?: string;
};

export type DirectusImage = {
  charset: string | null;
  description: string | null;
  duration: string | null;
  embed: string | null;
  filename_disk: string;
  filename_download: string;
  filesize: string;
  focal_point_x: number | null;
  focal_point_y: number | null;
  height: number | null;
  id: string;
  location: string | null;
  metadata: Record<string, any> | null;
  modified_on: string;
  storage: string;
  tags: string[] | null;
  title: string | null;
  tus_data: Record<string, any> | null;
  tus_id: string | null;
  type: string;
  uploaded_on: string;
  width: number | null;
};

export type TCheckBoxMolecule = {
  birthDetailsPageData: TBirthDetailsPage;
  termsAndPolicy: TTermsAndPrivacy;
  sendTermsGtmEvent?: (gtmAction: string) => void;
  handleChangeTermsAndPolicy: (name: string, value: boolean) => void;
  termsAndPrivacyError: boolean
}

export type TMarketingConsentPageUKGCProps = {
  marketingConsentPageData: TMarketingConsentPage;
  onConfirm: (value: boolean) => void;
  isLoader: boolean;
  isDrawerOpen: boolean;
  enableDrawer: boolean;
  toggleDrawer: () => void;
  handleOpenDrawer: () => void;
  handleChangeNotification: (type: string, value: boolean) => void;
  notifications: { [key: string]: boolean }
};

export type TMarketingConsentUKGCComponentProps = {
  marketingConsentPageData: TMarketingConsentPage;
  onConfirm: (value: boolean) => void;
  isLoader: boolean;
  handleOpenDrawer: () => void
};
export type TMarketingConsentDrawerUKGCProps = {
  marketingConsentPageData: TMarketingConsentPage;
  onConfirm: (value: boolean, isAllActive?: boolean) => void;
  isDrawerOpen: boolean;
  enableDrawer: boolean;
  setIsDrawerOpen: () => void;
  handleChangeNotification: (type: string, value: boolean) => void;
  notifications: { [key: string]: boolean }
};

export type TregistartionErrorData = {
  token?: string,
  pid?: string
}

export interface MarketingConsentGtmPayload {
  marketing_consent: boolean;
  step: string;
  casino_marketing_consent?: string;
  sports_marketing_consent?: string;
  marketing_channels?: string;
}
