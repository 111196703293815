import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  REGISTRATION_FLOW_STEPS,
  GTM_FEATURES,
  GTM_ACTIONS,
  INPUT_TYPES,
  EVENT_TYPES,
  REGISTRATION_FLOW_STEP_NAMES,
  MARKETS,
} from '../../../constants';
import { getNationalityPageData } from '../../../service/directus/nationality';
import { TErrorMessage, TOptionRadioBtton } from 'Src/typedefs';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
  getCountryCodeFromCountryName,
} from '../../../utilities';
import {
  validateNationality,
  validateFirstName,
  validateLastName,
} from '../../../validations';
import { getErrorData } from '../../../service/directus/errors';
import { sendGtmEvent, setNationalitySelectorGtmEvent } from '../../../gtm';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { adaptCountryFlags } from 'Src/service/directus/countryFlags/adapters';
import { NationalityPageMgaGra } from '../../../pages/nationalityPageMgaGra';
import { gatekeeper } from 'Src/gatekeeper';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';
import { getBirthDetailsPageData } from 'Src/service/directus/birthDetails';

export const NationalityContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const {
    registrationFlowsData,
    errorData,
    countryFlagsData,
    welcomeOffersData,
  } = UseDirectusContext();
  const birthDetailsPageData = getBirthDetailsPageData(registrationFlowsData);
  const { setHeaderTitle } = useHeader();
  const nationalityPageData = getNationalityPageData(
    registrationFlowsData,
    gatekeeper.localisation.marketSlug === MARKETS.ENGB ? REGISTRATION_FLOW_STEP_NAMES.UKGC_NATIONALITY_STEP : REGISTRATION_FLOW_STEP_NAMES.MGA_GRA_NATIONALITY_STEP
  );
  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData);
  const allErrorData: TErrorMessage[] = getErrorData(errorData);

  const [nationalityError, setNationalityError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [genderList, setGenderList] = useState<TOptionRadioBtton[]>([]);
  const [gender, setGender] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [nationality, setNationality] = useState<string | null>(null);
  const [selectedFlagUuid, setSelectedFlagUuid] = useState<string | null>(null);
  const [nationalityCountryCode, setNationalityCountryCode] = useState<
    string | null
  >(null);
  const [enableNationalitySelector, setEnableNationalitySelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [firstNameHasFocus, setFirstNameHasFocus] = useState<boolean>(false);
  const [lastNameHasFocus, setLastNameHasFocus] = useState<boolean>(false);

  const { isFlagDrawerOpen, toggleFlagDrawer } = useDrawer();
  const stepNumber =
    getStepNumberTranslation('3', '4')[gatekeeper.localisation.marketSlug] ||
    getStepNumberTranslation('3', '4')?.default;

  useEffect(() => {
    setGenderList([
      { value: 'male', label: birthDetailsPageData.genderMale },
      { value: 'female', label: birthDetailsPageData.genderFemale },
      { value: 'other', label: birthDetailsPageData.genderOther },
    ]);

    setPreviousRouteName(ROUTE_NAMES.ADDRESS);
    storeDataInLocalStorage(ROUTE_NAMES.NATIONALITY, userValues);

    // Read stored data and set it if there is any.
    if (userValues?.nationality) {
      setNationality(userValues?.nationality);
      setSelectedFlagUuid(
        selectedNationality(userValues?.nationality)[0]?.uuid
      );
    } else {
      setNationality(selectedNationality()[0]?.name);
      setSelectedFlagUuid(selectedNationality()[0]?.uuid);
    }
    if (userValues?.firstName) setFirstName(userValues?.firstName);
    if (userValues?.lastName) setLastName(userValues?.lastName);
    if (userValues?.gender) setGender(userValues?.gender)
    else setGender('male')
    setHeaderTitle(nationalityPageData.header_title);
  }, []);
  const selectedNationality = (
    nationality = userValues?.countryOfResidence
  ) => {
    return adaptedCountryFlags.filter(
      (country) => country.name === nationality
      //Once we add more markets this needs to be updated to set the correct default market
    );
  };

  useEffect(() => {
    setNationalityCountryCode(getCountryCodeFromCountryName(nationality));
    //BE and GTM expect country code instead of country name as nationality
  }, [nationality]);

  useEffect(() => {
    if (isFlagDrawerOpen) {
      setNationalitySelectorGtmEvent(REGISTRATION_FLOW_STEPS.NATIONALITY_STEP);
    }
  }, [isFlagDrawerOpen]);

  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          nationalityPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [nationalityPageData, welcomeOffersData]);

  const changeNationality = (countryName: string) => {
    if (!countryName) return;

    const nationality = countryName
      ? countryName
      : selectedNationality()[0].name;
    if (countryName) {
      setNationality(nationality);
      setSelectedFlagUuid(selectedNationality(nationality)[0]?.uuid);

      setNationalityError(null);
    }
    toggleFlagDrawer();
  };

  if (routeName !== ROUTE_NAMES.NATIONALITY) {
    return null;
  }

  const onConfirm = () => {
    if (allFieldsValidation()) {
      setUserValues({
        ...userValues,
        nationality,
        nationalityCountryCode,
        firstName,
        lastName,
        gender
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.NATIONALITY, {
        event: EVENT_TYPES.FULL_NAME_NATIONALITY,
        country_nationality: nationalityCountryCode.toLowerCase(),
        step: REGISTRATION_FLOW_STEPS.FULL_NAME_NATIONALITY,
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.NATIONALITY, {
        gender,
        step: GTM_ACTIONS.NATIONALITY,
      });
      setRouteName(ROUTE_NAMES.BIRTH_DETAILS);
    }
  };

  const changeValue = (value, inputType) => {
    switch (inputType) {
      case INPUT_TYPES.FIRST_NAME:
        setFirstNameError(null);
        setFirstName(value);
        break;
      case INPUT_TYPES.LAST_NAME:
        setLastNameError(null);
        setLastName(value);
        break;
      case INPUT_TYPES.SEARCH:
        setSearchValue(value);
        break;
      case INPUT_TYPES.GENDER:
        setGender(value);
        break;
    }
  };

  const clearValue = (inputType) => {
    switch (inputType) {
      case INPUT_TYPES.FIRST_NAME:
        setFirstName(null);
        break;
      case INPUT_TYPES.LAST_NAME:
        setLastName(null);
        break;
      case INPUT_TYPES.SEARCH:
        setSearchValue(null);
        break;
    }
  };

  const validation = (inputType: string, onContinue: boolean) => {
    switch (inputType) {
      case INPUT_TYPES.FIRST_NAME:
        const firstNameValidationResult = validateFirstName(
          firstName,
          onContinue
        );
        if (!firstNameValidationResult?.success) {
          const message: TErrorMessage = getErrorMessage(
            allErrorData,
            firstNameValidationResult.error
          );
          setFirstNameError(message?.message);
          return false;
        }
        setFirstNameError(null);
        return true;
      case INPUT_TYPES.LAST_NAME:
        const lastNameValidationResult = validateLastName(lastName, onContinue);
        if (!lastNameValidationResult?.success) {
          const message: TErrorMessage = getErrorMessage(
            allErrorData,
            lastNameValidationResult.error
          );
          setLastNameError(message?.message);
          return false;
        }
        setLastNameError(null);
        return true;
      case INPUT_TYPES.SEARCH:
        const nationalityValidationResult = validateNationality(nationality);
        if (!nationalityValidationResult?.success) {
          const message: TErrorMessage = getErrorMessage(
            allErrorData,
            nationalityValidationResult.error
          );
          setNationalityError(message?.message);
          return false;
        }
        return true;
    }
  };

  const allFieldsValidation = () => {
    const onContinue = true;
    const firstNameValid = validation(INPUT_TYPES.FIRST_NAME, onContinue);
    const lastNameValid = validation(INPUT_TYPES.LAST_NAME, onContinue);
    const nationalityValid = validation(INPUT_TYPES.SEARCH, onContinue);

    return firstNameValid && lastNameValid && nationalityValid;
  };

  const dependencyArray = [isFlagDrawerOpen, nationality, firstName, lastName];
  UseHandleKeyDown(dependencyArray, !isFlagDrawerOpen, onConfirm);
  return (
    <NationalityPageMgaGra
      description={description}
      pageData={nationalityPageData}
      selectedFlagUuid={selectedFlagUuid}
      nationality={nationality}
      openCountrySelector={() => {
        setEnableNationalitySelector(true);
        toggleFlagDrawer();
      }}
      onConfirm={onConfirm}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enableNationalitySelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      searchValue={searchValue}
      setNationality={changeNationality}
      userValues={userValues}
      stepNumber={stepNumber}
      firstName={firstName}
      lastName={lastName}
      changeValue={changeValue}
      clearValue={clearValue}
      nationalityError={nationalityError}
      firstNameError={firstNameError}
      lastNameError={lastNameError}
      lastNameHasFocus={lastNameHasFocus}
      firstNameHasFocus={firstNameHasFocus}
      setFirstNameHasFocus={setFirstNameHasFocus}
      setLastNameHasFocus={setLastNameHasFocus}
      gender={gender}
      genderList={genderList}
    />
  );
};
