import { Button, Flex } from '../atoms';
import { LimitsComponent, PaymentWo, DepositWidget } from '../components';
import { TDepositLimitsPage, TRegistrationUserDetails } from 'Src/typedefs';
import { TermsContainer } from 'Components/termsAndConditions/termsContainer';
import { OPTED_OUT_WELCOME_OFFER_ID } from 'Src/constants';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  userValues: TRegistrationUserDetails;
  onConfirm: () => void;
  description: string;
  depositLimitsPageData: TDepositLimitsPage;
  changeAmount: (value, limitType) => void;
  clearLimit: (limitType) => void;
  monthlyLimit: number | null;
  weeklyLimit: number | null;
  dailyLimit: number | null;
  monthlyLimitError: string;
  weeklyLimitError: string;
  dailyLimitError: string;
  validateLimits: (onFinalValidation: boolean) => void;
  termsGTM: () => void;
  privacyPolicyGTM: () => void;
  stepNumber?: string;
  isMgaGra?: boolean;
  setUpLaterClick?: () => void;
  currency?: string;
};

export const DepositLimitsPage = ({
  onConfirm,
  userValues,
  description,
  depositLimitsPageData,
  changeAmount,
  clearLimit,
  monthlyLimit,
  weeklyLimit,
  dailyLimit,
  monthlyLimitError,
  weeklyLimitError,
  dailyLimitError,
  validateLimits,
  termsGTM,
  privacyPolicyGTM,
  stepNumber,
  isMgaGra,
  setUpLaterClick,
  currency,
}: Props) => {
  const enableConfirmButton =
    dailyLimit &&
    weeklyLimit &&
    monthlyLimit &&
    !dailyLimitError &&
    !weeklyLimitError &&
    !monthlyLimitError;
  return (
    <Flex className='flex-1'>
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          {userValues?.selectedWelcomeOffer?.name && (
            <PaymentWo
              welcomeOffer={userValues.selectedWelcomeOffer}
              description={description}
            />
          )}
          {userValues?.selectedWelcomeOffer?.name ===
            OPTED_OUT_WELCOME_OFFER_ID &&
            userValues?.depositAmount && (
              <DepositWidget
                depositAmount={userValues?.depositAmount}
                currency={userValues?.currency}
                pageData={depositLimitsPageData}
              />
            )}
          <Flex className="flex-1 justify-between">
            <LimitsComponent
              depositLimitsPageData={depositLimitsPageData}
              currency={currency}
              changeAmount={changeAmount}
              clearLimit={clearLimit}
              monthlyLimit={monthlyLimit}
              weeklyLimit={weeklyLimit}
              dailyLimit={dailyLimit}
              monthlyLimitError={monthlyLimitError}
              weeklyLimitError={weeklyLimitError}
              dailyLimitError={dailyLimitError}
              validateLimits={validateLimits}
              stepNumber={stepNumber}
              isMgaGra={isMgaGra}
            />
          </Flex>
          {!isMgaGra && (
            <Flex className="mb-sm mt-2xs mx-xs items-baseline">
              <TermsContainer
                className="my-sm text-grey-35"
                gtmEvent={privacyPolicyGTM}
                termsText={
                  depositLimitsPageData?.generalTermsAndConditionsText || ''
                }
                privacyText={depositLimitsPageData?.privacyPolicyText || ''}
                isPrivacyPolicy={true}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <ActionButtons
        primaryBtnText={depositLimitsPageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        secondaryBtnText={depositLimitsPageData?.setUpLater}
        secondaryBtnOnClick={setUpLaterClick}
        enabled={enableConfirmButton}
      />
    </Flex>
  );
};
