/* eslint-disable prettier/prettier */
import { Button, Flex } from '../atoms';
import {
  EmailAndPhoneComponent,
  PaymentWo,
  DepositWidget,
  PhonePrefixSelector,
} from '../components';
import { TEmailAndPhonePage, TFlags } from 'Src/typedefs';
import { OPTED_OUT_WELCOME_OFFER_ID } from 'Src/constants';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  isMgaGra?: boolean;
  description: string;
  pageData: TEmailAndPhonePage;
  changeValue: (value: any, inputType: any) => void;
  clearValue: (inputType: any) => void;
  email: string;
  password?: string;
  phoneNumber: string;
  validateEmail: (onContinue: boolean) => void;
  validatePassword?: (onContinue: boolean) => void;
  validatePhone: (onContinue: boolean) => void;
  emailError: string;
  passwordError?: string;
  phoneNumberError: string;
  selectedCountry: TFlags | null;
  openPrefixSelector: () => void;
  onConfirm: () => void;
  countryFlagsData: TFlags[];
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: () => void;
  changeSearchValue: (value: any) => void;
  searchValue: string;
  selectedPrefix: string;
  setPhoneCountry: (country: TFlags) => void;
  userValues: any;
  stepNumber: string;
  handleRedirectLogin?: () => void;
};

export const EmailAndPhonePage = ({
  isMgaGra = false,
  description,
  pageData,
  changeValue,
  clearValue,
  email,
  password,
  phoneNumber,
  validateEmail,
  validatePassword,
  validatePhone,
  emailError,
  passwordError,
  phoneNumberError,
  selectedCountry,
  openPrefixSelector,
  onConfirm,
  countryFlagsData,
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeSearchValue,
  searchValue,
  setPhoneCountry,
  userValues,
  stepNumber,
}: Props) => {
  return (
    <Flex className='flex-1'>
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      ><Flex className="mb-[70px] bg-grey-0">
          <PaymentWo
            welcomeOffer={userValues.selectedWelcomeOffer}
            description={description}
          />

          {userValues?.selectedWelcomeOffer?.name ===
            OPTED_OUT_WELCOME_OFFER_ID &&
            userValues?.depositAmount && (
              <DepositWidget
                depositAmount={userValues?.depositAmount}
                currency={userValues?.currency}
                pageData={pageData}
              />
            )}
          <Flex className="h-full flex-1 justify-between">
            <EmailAndPhoneComponent
              isMgaGra={isMgaGra}
              emailAndPhonePageData={pageData}
              changeValue={changeValue}
              clearValue={clearValue}
              email={email}
              password={password}
              phoneNumber={phoneNumber}
              validateEmail={validateEmail}
              validatePassword={validatePassword}
              validatePhone={validatePhone}
              emailError={emailError}
              passwordError={passwordError}
              phoneNumberError={phoneNumberError}
              selectedCountry={selectedCountry}
              openPrefixSelector={openPrefixSelector}
              stepNumber={stepNumber}
            />
          </Flex>
        </Flex>
        {enableDrawer && isDrawerOpen && (
          <PhonePrefixSelector
            drawerTitle={pageData?.flagDrawerTitle}
            countryFlagsData={countryFlagsData}
            enableDrawer={enableDrawer}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            changeValue={changeSearchValue}
            placeholder={pageData?.searchPlaceholder}
            searchValue={searchValue}
            selectedCountry={selectedCountry}
            setPhoneCountry={setPhoneCountry}
          />
        )}
      </Flex>
      <ActionButtons
        primaryBtnText={pageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        enabled={email &&
          !emailError &&
          phoneNumber &&
          !phoneNumberError &&
          (isMgaGra ? password && !passwordError : true)}
      />
    </Flex>
  );
};
