import { TAddress } from 'Src/typedefs';
import { GOOGLE_ADDRESS_COMPONENT_NAMES } from './constants';

export const adaptDetailedAddress = (addressFromGoogle) => {
  if (
    !addressFromGoogle ||
    !addressFromGoogle.address_components ||
    addressFromGoogle.address_components.length === 0
  ) {
    console.warn(
      `Adapter on google address result cannot return a valid address! PlaceId: ${addressFromGoogle?.place_id}`
    );
    return;
  }
  const addressComponents = addressFromGoogle.address_components;
  const country = addressComponents?.find((component) =>
    component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.COUNTRY)
  );
  const city = addressComponents?.find(
    (component) =>
      component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.CITY) ||
      component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.TOWN)
  );
  const postalCode = addressComponents?.find((component) =>
    component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.POST_CODE)
  );
  const streetName = addressComponents?.find((component) =>
    component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.STREET_NAME)
  );
  const houseNumber = addressComponents?.find((component) =>
    component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.HOUSE_NUMBER)
  );
  const province = addressComponents?.find((component) =>
    component.types?.includes(GOOGLE_ADDRESS_COMPONENT_NAMES.PROVINCE)
  );

  const address: TAddress = {
    country: country?.short_name || '',
    city: city?.long_name || city?.short_name || '',
    postalCode: postalCode?.long_name || postalCode?.short_name || '',
    streetName: streetName?.long_name || streetName?.short_name || '',
    houseNumber: houseNumber?.long_name || houseNumber?.short_name || '',
    province: province?.short_name || '',
  };

  return address;
};
