import { gatekeeper } from '../../gatekeeper';
import { DEFAULT_LANGUAGE, DEFAULT_MARKET } from '../../constants';
import {
  type TLocaleCode,
  createTranslationsParams,
} from '@casumo/fabric-fundamentals';

interface IRegistrationFlowsData {
  flow_steps: {
    collection: string;
    item: {
      name: string;
      translations: {
        languages_code: { code: string };
      }[];
    };
  }[];
}

//this was included as without specificity jest test was failing.
// The any is there as not all properties being received are known these are not used here so type safety will not matter.
// The ones we are using here now have a higher level of specificity.
export const getStepData = (
  registrationFlowsData: IRegistrationFlowsData | unknown,
  registrationStep: string,
  collectionName?: string
): any => {
  const language = gatekeeper.localisation.localeCode || DEFAULT_LANGUAGE;

  if (!registrationFlowsData || !registrationStep) {
    return;
  }

  const collection = (
    registrationFlowsData as IRegistrationFlowsData
  )?.flow_steps?.find((step) => {
    if (collectionName) {
      return step?.collection === registrationStep && step?.item?.name === collectionName
    }

    return step?.collection === registrationStep
  });

  const langValue = language;
  const pageDataByLanguage = collection?.item?.translations.find(
    (translation) => translation?.languages_code?.code === langValue
  );

  return pageDataByLanguage || null;
};

const createDeepTranslationsFilter = (localeCode: TLocaleCode) => {
  const { filter, sort } = createTranslationsParams(localeCode, DEFAULT_MARKET);

  return {
    translations: {
      ...(sort && { _sort: sort }),
      _filter: filter,
    },
  };
};

export const createTranslationsFilterQuery = () => {
  const qs = new URLSearchParams();
  const lang = gatekeeper.localisation.localeCode as TLocaleCode;
  qs.set('deep', JSON.stringify(createDeepTranslationsFilter(lang)));
  return qs;
};
