import { useEffect, useState } from 'react';
import { TDirectusError, TErrorMessage } from 'Src/typedefs';
import { getErrorData } from '../../service';

export const UseGetErrorData = (error: string, errorData: TDirectusError[]) => {
  const [errorDetails, setErrorDetails] = useState<TErrorMessage | null>(null);

  useEffect(() => {
    const messages = getErrorData(errorData);
    const selectederror = messages?.find<TErrorMessage | undefined>(
      (message: TErrorMessage) => message?.errorName === error
    );
    setErrorDetails(selectederror || null);
  }, []);

  return { errorDetails };
};
