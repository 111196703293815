import {
  TDepositsPage,
  TPaymentMethod,
  TRegistrationUserDetails,
} from 'Src/typedefs';
import { Button, Flex } from '../atoms';
import { PaymentWo, EnterAmountComponent } from '../components';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  depositsPageData: TDepositsPage;
  paymentsApiData: TPaymentMethod[];
  depositClick: () => void;
  currency: string;
  depositAmount: number | null;
  changeAmount: (value, fromButton: boolean) => void;
  clearDeposit: () => void;
  depositLaterClick: () => void;
  depositError?: string;
  validateDepositAmount: () => boolean;
  setSelectedMethod: (methodId: string) => void;
  selectedMethod: string;
  userValues: TRegistrationUserDetails;
  description: string;
  stepNumber: string;
};

export const DepositAmountPage = ({
  depositsPageData,
  paymentsApiData,
  userValues,
  description,
  depositClick,
  currency,
  depositAmount,
  changeAmount,
  clearDeposit,
  depositLaterClick,
  depositError,
  validateDepositAmount,
  setSelectedMethod,
  selectedMethod,
  stepNumber,
}: Props) => {
  return (
    <Flex className='flex-1'>
      <Flex
        className={classNames(
          'w-full mb-[70px] bg-grey-0 justify-start tablet:rounded-b-3xl overflow-y-auto flex-grow'
        )}
      >
        <PaymentWo
          welcomeOffer={userValues?.selectedWelcomeOffer}
          description={description}
        />
        <EnterAmountComponent
          depositsPageData={depositsPageData}
          paymentsApiData={paymentsApiData}
          onClick={depositClick}
          currency={currency}
          depositAmount={depositAmount}
          changeAmount={changeAmount}
          clearDeposit={clearDeposit}
          depositLaterClick={depositLaterClick}
          error={depositError}
          validateDepositAmount={validateDepositAmount}
          selectPaymentMethod={setSelectedMethod}
          selectedMethod={selectedMethod}
          stepNumber={stepNumber}
        />
      </Flex>
      <ActionButtons
        secondaryBtnText={depositsPageData?.noDepositText}
        secondaryBtnOnClick={depositLaterClick}
      />
    </Flex>
  );
};
