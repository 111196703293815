import classNames from 'classnames';
import { Button, Flex } from '../../atoms';
import { DrawerHeader } from './drawerHeader';
import { TMessageDrawer } from 'Src/typedefs';

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
  children?: JSX.Element | JSX.Element[] | string;
  isDefaultView?: boolean;
  content: TMessageDrawer;
};

export const DrawerMessage = ({
  children,
  isOpen,
  content,
  isDefaultView,
  toggleDrawer,
}: Props) => {
  return (
    <Flex
      className={classNames(
        'bg-purple-90 text-white py-md px-md rounded-t-3xl tablet:rounded-3xl items-center',
        { drawerDefault: !isOpen },
        {
          drawerMessage: isOpen,
        },
        { drawerMessageInvisible: !isDefaultView && !isOpen }
      )}
    >
      <Flex className="flex-1 w-full h-full tablet:h-[345px] items-center overflow-y-auto modalScrollbar">
        <div className="flex flex-1 flex-col w-full items-center gap-sm">
          <DrawerHeader
            title={content.title}
            onClose={() => toggleDrawer()}
            enableCloseButton={true}
          />
          {content.messageComponent}
        </div>
      </Flex>
    </Flex>
  );
};
