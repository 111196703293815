import '@casumo/fabric-fundamentals';
import * as components from '@casumo/fabric-components';
import { createRoot } from 'react-dom/client';
import {
  createWebComponent,
  createApplicationContainer,
  createStylesLink,
} from './packager/index';
import { APPLICATION_NAME, NAMESPACE } from './constants';
import { App } from './app';
import './index.css';
import '../static/fonts.css';

components.define(NAMESPACE);

const renderApplication = async (node: HTMLElement, onReady: () => void) => {
  const root = createRoot(node);

  const Application = <App />;

  root.render(Application);

  requestIdleCallback(onReady);
};

createWebComponent(
  APPLICATION_NAME,
  (root, attributes, host, onReady) => {
    Promise.all([
      createApplicationContainer(root),
      createStylesLink(root, `${host}/index.css`),
    ]).then(([container]) => {
      renderApplication(container, onReady);
    });
  },
  (node) => console.debug('cleanup after ourselves 🧹', node)
);
