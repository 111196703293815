import { DangerousHtml, Flex } from '../../atoms';
import { TWelcomeOffer } from '../../typedefs';
import { ICON_SIZES } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { OPTED_OUT_WELCOME_OFFER_ID, TEST_ID } from '../../constants';
import { ValuableToken } from 'Components/valuableThumbnail/valuableThumbnail';
import { useDrawer } from 'Src/contexts/drawer-context';
import { WelcomeOfferMessage } from './welcomeOfferMessage';
import { UseGetWelcomeOfferPage } from 'Src/hooks/welcomeOffers';
import classNames from 'classnames';
import { gatekeeper } from 'Src/gatekeeper';

type Props = {
  welcomeOffer?: TWelcomeOffer;
  description?: string;
};

export const PaymentWo = ({ welcomeOffer, description }: Props) => {
  const { toggleMessageDrawer, updateMessageDrawerContent } = useDrawer();
  const { welcomeOfferPageData } = UseGetWelcomeOfferPage();

  if (
    !welcomeOffer ||
    !description ||
    welcomeOffer?.name === OPTED_OUT_WELCOME_OFFER_ID
  ) {
    return null;
  }
  const showWelcomeOfferMessage = () => {
    toggleMessageDrawer();
    updateMessageDrawerContent({
      title: welcomeOfferPageData.welcomeOfferMessageTitle || 'Select Bonus',
      enableCloseButton: true,
      messageComponent: (
        <WelcomeOfferMessage
          description={description}
          closeDrawer={toggleMessageDrawer}
        />
      ),
    });
  };

  const WidgetMessage = () => {
    const modifiedDescription = description
      .replace(/<div>/g, '<span>')
      .replace(/<\/div>/g, '</span>');
    return (
      <>
        <span>
          <span className="font-bold">{welcomeOffer.widgetTitle}: </span>
          <span dangerouslySetInnerHTML={{ __html: modifiedDescription }} />
        </span>
      </>
    );
  };

  const isSvMarket = gatekeeper.localisation.marketSlug === 'sv';
  const classes = classNames(
    'p-sm mb-sm bg-teal-50 rounded-3xl bg-opacity-10 cursor-pointer',
    { 'pointer-events-none': isSvMarket }
  );

  return (
    <Flex
      className={classes}
      onClick={showWelcomeOfferMessage}
      testID={TEST_ID.SELECT + welcomeOffer.name}
    >
      <Flex direction="flex-row" className="w-11/12 text-white items-center">
        <ValuableToken
          testId="available-bonus-widget-child-thumbnail-icon"
          size={ICON_SIZES.EXTRA_SMALL}
          welcomeOffer={welcomeOffer}
        />
        <Flex className="w-4/5 ml-sm text-black text-sm">
          <WidgetMessage />
        </Flex>
      </Flex>
    </Flex>
  );
};
