import { useEffect } from 'react';
import {
  UseRegistrationContext,
} from '../../../contexts';
import {
  GTM_PAYLOAD,
  OPTED_OUT_WELCOME_OFFER_ID,
  ROUTE_NAMES,
} from '../../../constants';
import { setWoGtmEvents, setWoTermsGtmEvent } from '../../../gtm';
import { WelcomeOfferPage } from '../../../pages/welcomeOfferPage';
import { UseGetWelcomeOfferPage } from '../../../hooks';
import { storeDataInLocalStorage } from '../../../utilities';
import { TWelcomeOffer } from 'Src/typedefs';

export const WelcomeOfferContainer = () => {
  const {
    routeName,
    setRouteName,
    userValues,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { welcomeOfferPageData, welcomeOffersData = [] } = UseGetWelcomeOfferPage();

  useEffect(() => {
    // Read stored welcome offer data and set it if there is any.
    storeDataInLocalStorage(ROUTE_NAMES.WELCOME_OFFER, userValues);
    setPreviousRouteName(ROUTE_NAMES.COUNTRY_OF_RESIDENCE);
    if (welcomeOffersData.length <= 1) {
      const selectedWelcomeOffer =
        welcomeOffersData.length === 1
          ? welcomeOffersData[0]
          : { name: OPTED_OUT_WELCOME_OFFER_ID };

      setUserValues({
        ...userValues,
        selectedWelcomeOffer,
      });

      setRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
    }
  }, [welcomeOfferPageData]);

  const selectWelcomeOffer = (name: string | null) => {
    const selectedWelcomeOfferName = name ?? OPTED_OUT_WELCOME_OFFER_ID;
    const selectedWelcomeOffer =
      welcomeOffersData?.find(
        (offer) => offer.name?.toString() === selectedWelcomeOfferName
      ) ?? ({ name: OPTED_OUT_WELCOME_OFFER_ID } as TWelcomeOffer);
    setUserValues({
      ...userValues,
      selectedWelcomeOffer,
    });

    setWoGtmEvents(name);
    setTimeout(() => {
      setRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
    }, 200);
  };

  const sendTermsGtmEvent = () => {
    setWoTermsGtmEvent(GTM_PAYLOAD.SELECT_WELCOME_OFFER);
  };

  if (routeName !== ROUTE_NAMES.WELCOME_OFFER) {
    return null;
  }

  return (
    <WelcomeOfferPage
      isMgaGra
      welcomeOffers={welcomeOffersData}
      welcomeOfferPage={welcomeOfferPageData}
      selectWelcomeOffer={selectWelcomeOffer}
      sendTermsGtmEvent={sendTermsGtmEvent}
      isPlayResponsiblyEnabled={true}
    />
  );
};
