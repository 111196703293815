import { useEffect, useState } from 'react';
import { UseDirectusContext } from '../../contexts';
import { TWelcomeOfferPage } from '../../typedefs';
import { selectWelcomeOfferPageData } from './WelcomeOfferPageDataSelector';

/*
 The main goal of this hook is to select and return a TWelcomeOfferPage object.
 @returns {TWelcomeOfferPage} welcomeOfferPageData what gives us the data that we need.
*/
export const UseGetWelcomeOfferPage = () => {
  const { registrationFlowsData, welcomeOffersData, termsData } =
    UseDirectusContext(); // Loads directus cms data.
  const [welcomeOfferPageData, setWelcomeOfferPageData] =
    useState<TWelcomeOfferPage | null>(null);
  useEffect(() => {
    setWelcomeOfferPageData(
      selectWelcomeOfferPageData(registrationFlowsData, termsData)
    );
  }, [registrationFlowsData]);

  return { welcomeOfferPageData, welcomeOffersData };
};
