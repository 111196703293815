import classNames from 'classnames';
import { getAssetURL } from '../../utilities';
import { Flex, Image, Input, Label, Span } from '../../atoms';
import DropDownArrowDown from '../../assets/svgs/dropDownArrowDown.svg';
import ClearInput from '../../assets/svgs/clearInputButton.svg';
import { TFlags } from 'Src/typedefs';
import { useRef } from 'react';
import useScrollIntoViewOnError from 'Src/hooks/useScrollIntoViewOnError';
import { TEST_ID } from 'Src/constants';

type Props = {
  selectedCountry: TFlags
  error?: string;
  placeholder?: string;
  phoneNumber?: string | null;
  hasFocus?: boolean;
  open?: () => void;
  changeValue?: (value) => void;
  clearValue?: () => void;
  setHasFocus?: (value) => void;
};

export const PhoneInput = ({
  selectedCountry,
  error,
  placeholder,
  phoneNumber,
  hasFocus,
  open,
  changeValue,
  clearValue,
  setHasFocus,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useScrollIntoViewOnError(error, inputRef);
  return (
    <>
      <Flex
        direction="flex-row"
        className={classNames(
          'h-3xl px-sm bg-grey-0 border-grey-5 border-2 rounded-2xl items-center overflow-hidden caret-blue-50 gap-sm',
          { 'border-purple-80': hasFocus && !error },
          { 'text-red-40 border-2 border-red-40': error }
        )}
      >
        <Flex
          direction="flex-row"
          className="gap-2 overflow-hidden justify-center cursor-pointer"
          onClick={open}
          testID={TEST_ID.COMBOBOX_TRIGGER + 'phonePrefix'}
        >
          <Flex direction="flex-row" className="items-center">
            <Flex className="w-md mr-2xs overflow-hidden">
              <Image
                src={getAssetURL(selectedCountry?.uuid)}
                className="max-w-none"
                width="auto"
                height="full"
                title="Flag"
                alt="Flag"
              />
            </Flex>
            <Span className="font-bold">{selectedCountry?.countryCode}</Span>
          </Flex>
          <Flex className="w-md items-center justify-center">
            <Image
              src={DropDownArrowDown}
              className="relative w-3.5"
              title="Open dropdown"
              alt="Open dropdown"
            />
          </Flex>
        </Flex>
        <Flex direction="flex-row" className="flex-1 justify-between gap-sm">
          <Flex className="flex-1 w-0">
            <Label
              className={classNames(
                'absolute top-[0.2rem] text-grey-50 text-xs font-bold min-w-[120px]',
                {
                  invisible: !hasFocus && !phoneNumber,
                }
              )}
            >
              {placeholder}
            </Label>
            <Input
              ref={inputRef}
              className={classNames(
                'focus:outline-none h-3xl bg-grey-0 font-bold',
                {
                  'pt-sm': hasFocus || phoneNumber,
                }
              )}
              type="tel"
              placeholder={hasFocus ? '' : placeholder || ''}
              value={phoneNumber?.toString() || ''}
              onChange={(event) => changeValue(event?.target?.value)}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
              testID={TEST_ID.INPUT + 'phoneNumber'}
            />
          </Flex>
          {phoneNumber &&
            <Flex className={classNames('items-center justify-center', {
              "hidden": !phoneNumber,
            })}>
              <Image
                src={ClearInput}
                className='w-md cursor-pointer'
                onClick={() => clearValue()}
                title="Clear"
                alt="Clear"
              />
            </Flex>
          }
        </Flex>
      </Flex>
      {error && <Flex className="text-red-30 text-xs">{error}</Flex>}
    </>
  );
};
