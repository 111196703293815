import { Flex, Image, Span } from '../../atoms';
import CloseDrawer from '../../assets/svgs/closeRegistration.svg';
import NotificationIcon from '../../assets/svgs/authenticationError.svg';

type Props = {
  onClose: () => void;
  title: string;
};

export const DrawerHeader = ({ onClose, title }: Props) => {
  return (
    <Flex className="font-bold bg-white items-between ">
      <Flex direction="flex-row justify-between items-start">
        <Image
          className="w-16"
          src={NotificationIcon}
          height="auto"
          onClick={onClose}
          title={'close'}
          alt={'close'}
        />

        <Image
          className="m-xs w-md h-md"
          src={CloseDrawer}
          onClick={onClose}
          title={'close'}
          alt={'close'}
        />
      </Flex>

      <Span className="mt-5 text-h-lg">{title}</Span>
    </Flex>
  );
};
