interface Props {
  className?: string;
  htmlClasses?: string;
  style?: object;
  html: string;
  testID?: string;
}

export const DangerousHtml = ({ html, className, htmlClasses, style, testID }: Props) => {
  const enhancedHtml = html?.replace(/<p>/g, `<p class="${htmlClasses} m-0">`);

  return (
    <div
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: enhancedHtml }}
      data-testid={testID}
    />
  );
};
