/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react';
import { fabricizeProps } from '../../utilities';
// https://github.com/Casumo/fabric-components/blob/master/src/components/button/Button.svelte
declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface IntrinsicElements {
      'fabric-registration-button': TFabricButtonProps;
    }
  }
}

export type TFabricButtonProps = {
  id: string;
  primary?: boolean;
  disabled?: boolean;
  pressed?: boolean;
  context?: 'grey' | 'purple' | 'dark';
  small?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export const FabricButton = (props: TFabricButtonProps) => {
  const { children, onClick, ...restProps } = props;
  const fabricProps = fabricizeProps<typeof props>(restProps);

  return (
    <fabric-registration-button
      {...fabricProps}
      id={fabricProps.id}
      // eslint-disable-next-line no-extra-boolean-cast
      onClick={onClick}
    >
      {children}
    </fabric-registration-button>
  );
};
