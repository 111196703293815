import * as React from 'react';
import { TDirectusError, TWelcomeOffer } from '../typedefs';

type TRegistrationContextProviderProps = {
  children: React.ReactNode;
};

type TDirectusContext = {
  registrationFlowsData: Array<object> | null;
  termsData: Array<object> | null;
  countryFlagsData: Array<object> | null;
  welcomeOffersData: Array<TWelcomeOffer> | null;
  errorData: Array<TDirectusError> | null;
  setRegistrationFlowsData: React.Dispatch<React.SetStateAction<unknown>>;
  setTermsData: React.Dispatch<React.SetStateAction<unknown>>;
  setCountryFlagsData: React.Dispatch<React.SetStateAction<unknown>>;
  setWelcomeOffersData: React.Dispatch<React.SetStateAction<unknown>>;
  setErrorData: React.Dispatch<React.SetStateAction<unknown>>;
};

// This context is responsibel to store and provide directus data through the whole registration flow.
export const DirectusContext = React.createContext<TDirectusContext>({
  registrationFlowsData: null,
  termsData: null,
  countryFlagsData: null,
  welcomeOffersData: null,
  errorData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRegistrationFlowsData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTermsData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCountryFlagsData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setWelcomeOffersData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorData: () => {},
});

export const DirectusContextProvider = ({
  children,
}: TRegistrationContextProviderProps) => {
  const [registrationFlowsData, setRegistrationFlowsData] =
    React.useState(null);
  const [termsData, setTermsData] = React.useState(null);
  const [countryFlagsData, setCountryFlagsData] = React.useState(null);
  const [welcomeOffersData, setWelcomeOffersData] = React.useState(null);
  const [errorData, setErrorData] = React.useState(null);

  return (
    <DirectusContext.Provider
      value={{
        registrationFlowsData,
        termsData,
        countryFlagsData,
        welcomeOffersData,
        errorData,
        setRegistrationFlowsData,
        setTermsData,
        setCountryFlagsData,
        setWelcomeOffersData,
        setErrorData,
      }}
    >
      {children}
    </DirectusContext.Provider>
  );
};

export const UseDirectusContext = () => {
  return React.useContext(DirectusContext);
};
