/* eslint-disable fp/no-throw */
import { TMessageDrawer } from 'Src/typedefs';
import React, { createContext, useContext, useState, ReactNode } from 'react';

type DrawerContextType = {
  isDrawerOpen: boolean;
  isFlagDrawerOpen: boolean;
  isNotificationDrawerOpen: boolean;
  isMessageDrawerOpen: boolean;
  isDefaultView: boolean;
  isNotificationDrawerDefaultView: boolean;
  isMessageDrawerDefaultView: boolean;
  isProvinceDrawerOpen: boolean
  toggleProvinceDrawer: () => void;
  toggleFlagDrawer: () => void;
  toggleDrawer: () => void;
  toggleNotificationDrawer: () => void;
  toggleMessageDrawer: () => void;
  drawerContent: { title: string; description: string };
  notificationDrawerContent: {
    title: string;
    description: string;
    onClick: (id: string) => void;
    buttonText: string;
  };
  messageDrawerContent: TMessageDrawer;
  updateDrawerContent: (content: {
    title: string;
    description: string;
  }) => void;
  updateNotificationDrawerContent: (content: {
    title: string;
    description: string;
    onClick: (id: string) => void;
    buttonText: string;
  }) => void;
  updateMessageDrawerContent: (content: TMessageDrawer) => void;
  isAnyDrawerOpen: boolean;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export function useDrawer() {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
}

type DrawerProviderProps = {
  children: ReactNode;
};

export function DrawerContextProvider({ children }: DrawerProviderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);
  const [isMessageDrawerOpen, setIsMessageDrawerOpen] = useState(false);
  const [isFlagDrawerOpen, setIsFlagDrawerOpen] = useState(false);
  const [isProvinceDrawerOpen, setIsProvinceDrawerOpen] = useState(false);
  const [isDefaultView, setIsDefaultView] = useState(true);

  const [isMessageDrawerDefaultView, setIsMessageDrawerDefaultView] =
    useState(true);


  const [isNotificationDrawerDefaultView, setIsNotificationDrawerDefaultView] =
    useState(true);

  const [drawerContent, setDrawerContent] = useState({
    title: 'Default Title',
    description: 'Default Description',
  });

  const [notificationDrawerContent, setNotificationDrawerContent] = useState({
    title: 'Default Title',
    description: 'Default Description',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => { },
    buttonText: 'Default button',
  });

  const [messageDrawerContent, setMessageDrawerContent] =
    useState<TMessageDrawer>({
      title: 'Default Title',
      description: 'Default Description',
      primaryBtn: {
        onClick: () => { },
        title: 'Default button',
      },
      secondaryBtn: {
        onClick: () => { },
        title: 'Default button',
      },
    });

  const toggleDrawer = () => {
    setIsDefaultView(false);
    setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleNotificationDrawer = () => {
    setIsNotificationDrawerDefaultView(false);
    setIsNotificationDrawerOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleMessageDrawer = () => {
    setIsMessageDrawerDefaultView(false);
    setIsMessageDrawerOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleFlagDrawer = () => {
    setIsFlagDrawerOpen((prevIsOpen) => !prevIsOpen);
  };
  const toggleProvinceDrawer = () => {
    setIsProvinceDrawerOpen((prevIsOpen) => !prevIsOpen);
  };


  const updateDrawerContent = (content: {
    title: string;
    description: string;
  }) => {
    setDrawerContent(content);
  };

  const updateNotificationDrawerContent = (content: {
    title: string;
    description: string;
    onClick: () => void;
    buttonText: string;
  }) => {
    setNotificationDrawerContent(content);
  };

  const updateMessageDrawerContent = (content: TMessageDrawer) => {
    setMessageDrawerContent(content);
  };

  const isAnyDrawerOpen = isDrawerOpen ||
    isNotificationDrawerOpen ||
    isFlagDrawerOpen ||
    isMessageDrawerOpen ||
    isProvinceDrawerOpen;

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        isNotificationDrawerOpen,
        isMessageDrawerOpen,
        isFlagDrawerOpen,
        isProvinceDrawerOpen,
        toggleProvinceDrawer,
        toggleFlagDrawer,
        toggleDrawer,
        toggleNotificationDrawer,
        toggleMessageDrawer,
        drawerContent,
        notificationDrawerContent,
        messageDrawerContent,
        updateDrawerContent,
        updateNotificationDrawerContent,
        updateMessageDrawerContent,
        isDefaultView,
        isNotificationDrawerDefaultView,
        isMessageDrawerDefaultView,
        isAnyDrawerOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}
