import { useCallback, useEffect } from 'react';

const handleKeyDown = (
  event: KeyboardEvent,
  condition: boolean,
  func: Function
) => {
  if (event.key === 'Enter' && condition) {
    func();
  }
};

/*
 The main goal of this hook is to add eventlistener to handleKeyDown on enter.
 @returns undefined because it's just adding and removing the eventListener.
*/
export const UseHandleKeyDown = <T extends any>(
  dependencyArray: Array<T>,
  condition: boolean,
  onConfirm: Function
) => {
  const handleKeyDownWrapper = useCallback((event: KeyboardEvent) => {
    handleKeyDown(event, condition, onConfirm);
  }, dependencyArray);
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownWrapper);

    return () => {
      window.removeEventListener('keydown', handleKeyDownWrapper);
    };
  }, dependencyArray);
};
