import classNames from 'classnames';
import DropDownArrowDown from '../../assets/svgs/dropDownArrowDown.svg';
import { Flex, Image, Span } from '../../atoms';
import { getAssetURL } from '../../utilities';

type Props = {
  nationality?: string;
  open?: () => void;
  placeholder?: string;
  error?: string;
  selectedFlagUuid?: string;
  disabled?: boolean;
  testID?: string;
};

export const NationalityInput = ({
  nationality,
  open,
  placeholder,
  error,
  selectedFlagUuid,
  disabled = false,
  testID,
}: Props) => {
  return (
    <Flex>
      <Flex
        className={classNames('overflow-hidden justify-center', {
          'cursor-pointer': !disabled,
        })}
        onClick={open}
      >
        <Flex
          direction="flex-row"
          className={classNames(
            'h-3xl pl-sm bg-grey-0 border-grey-5 border-2 rounded-2xl items-center',
            { 'text-red-40 border-2 border-red-40': error }
          )}
          testID={testID}
        >
          {selectedFlagUuid && (
            <Flex
              className={classNames('w-md mr-2xs overflow-hidden', {
                'opacity-50': disabled,
              })}
            >
              <Image
                src={getAssetURL(selectedFlagUuid)}
                className="max-w-none"
                width="auto"
                height="full"
                title={'Flag'}
                alt={'Flag'}
              />
            </Flex>
          )}
          {nationality ? (
            <Span
              className={classNames('mx-2xs font-bold', {
                'text-grey-20': disabled,
              })}
            >
              {nationality}
            </Span>
          ) : (
            <Span className="text-[#9ca3af] font-bold">
              {placeholder || 'Nationality as shown on your ID'}
            </Span>
          )}
        </Flex>

        <Flex className="w-full h-0 items-end">
          {!disabled && (
            <Image
              src={DropDownArrowDown}
              className="relative bottom-8 right-[1.3rem] w-3.5"
              title={'Select'}
              alt={'Select'}
            />
          )}
        </Flex>
      </Flex>
      {error && <Flex className="text-red-40 text-xs">{error}</Flex>}
    </Flex>
  );
};
