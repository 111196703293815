import { TEmailAndPhonePage } from '../../../typedefs';

interface IEmailAndPhonePage {
  header_title?: string;
  message?: string;
  description?: string;
  welcome_message?: string;
  button_text?: string;
  email_placeholder: string;
  password_placeholder?: string;
  phone_placeholder: string;
  paymentImage: string;
  caviates_text: string;
  payment_message: string;
  prefix_search_placeholder: string;
  flag_drawer_title: string;
  login_redirect?: string;
}

export const adaptEmailAndPhonePage = (data: IEmailAndPhonePage) => {
  const result: TEmailAndPhonePage = {
    header_title: data?.header_title,
    message: data?.message,
    description: data?.description,
    woMessage: data?.welcome_message,
    btnContinue: data?.button_text,
    emailPlaceholder: data?.email_placeholder,
    passwordPlaceholder: data?.password_placeholder,
    phonePlaceholder: data?.phone_placeholder,
    caviatesText: data?.caviates_text,
    paymentMessage: data?.payment_message,
    searchPlaceholder: data?.prefix_search_placeholder,
    flagDrawerTitle: data?.flag_drawer_title,
    loginRedirectText: data?.login_redirect,
  };
  return result;
};
