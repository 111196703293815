import {
  BE_FE_ERRORS_MAP,
  HEADER_NAMES,
  REGISTRATION_API_RESPONSES,
  REGISTRATION_ERRORS,
} from '../../constants';
import { TUserRegistrationData, TRegistrationResponse } from 'Src/typedefs';
import { registrationServiceClient } from './client';
import { CASUMO_BRAND_ID } from '../../../env.constants';
import { clearDataInLocalStorage } from 'Src/utilities';
import axios from 'axios';
import { setRegistrationClosedWithErrorGtmEvent } from 'Src/gtm';

export const registerUser = async (
  userValues: TUserRegistrationData,
  pathUrl: string
): Promise<TRegistrationResponse> => {
  const maxRetries = 3;
  const retryDelay = 3000;
  
  let failedAttempts = 0;

  const attemptRegistration = async (attempt: number): Promise<TRegistrationResponse> => {
    try {
      const config = {
        headers: {
          [HEADER_NAMES.CASUMO_BRAND_ID]: `${CASUMO_BRAND_ID}`,
        },
      };

      const response = await registrationServiceClient.post(
        pathUrl,
        { ...userValues },
        config
      );

      if (response?.status >= 200 && response?.status < 400) {
        console.info('Successful registration status:', response?.status);
        clearDataInLocalStorage();
        console?.info('LocalStorage cleared');

        return {
          success: true,
          data: response?.data,
          statusCode: response?.status,
          failedAttempts: failedAttempts,
        };
      }

      console.error('Unhandled status code during registration:', response?.status);
      return {
        success: false,
        error: REGISTRATION_ERRORS.UNKNOWN,
        statusCode: response?.status,
      };
    } catch (error) {
      failedAttempts++;

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          console.error('Network error during registration:', error.message, error.response?.data?.reason);
        } else {
          console.error('API error status during registration:', error.response?.status);
          console.error('Error reason during registration:', error.response?.data?.reason);

          const errorCodeBE = error.response?.data?.reason || error.response?.data?.loginResponse?.reason ;
          const errorCodeFE =
            BE_FE_ERRORS_MAP[errorCodeBE] || REGISTRATION_ERRORS.UNKNOWN;

          console.error('Mapped frontend error code on fabric-registration:', errorCodeFE);
          return {
            success: false,
            error: errorCodeFE,
            statusCode: error?.response?.status || error?.status || 500, // Default to 500 if no status code is returned
            errorData: error.response?.data?.loginResponse?.reason === REGISTRATION_API_RESPONSES.KYC_REQUIRED ? {
              token: error.response?.data?.loginResponse?.identificationToken,
              pid: error.response?.data?.loginResponse?.playerId
            } : null
          };
        }
      } else {
        console.error('Unexpected error during registration:', error);
      }

      if (attempt < maxRetries) {
        console.info(`Retrying request... Attempt ${attempt + 1}`);
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        return attemptRegistration(attempt + 1);
      } else {
        setRegistrationClosedWithErrorGtmEvent(failedAttempts, userValues);
        return {
          success: false,
          error: REGISTRATION_ERRORS.NETWORK_ERROR,
          statusCode: error?.response?.status || error?.status || 500,
          failedAttempts: failedAttempts,
        };
      }
    }
  };

  return attemptRegistration(1);
};
