import classNames from 'classnames';

export interface Props {
  className?: string;
  muted?: boolean;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string | number;
}

export const Paragraph: React.FC<Props> = ({
  children,
  className,
  style = {},
}) => {
  const paragraphClass = classNames('leading-tight', className);

  return (
    <p className={paragraphClass} style={style}>
      {children}
    </p>
  );
};
