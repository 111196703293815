import { Flex, Image } from '../../atoms';
import CloseRegistration from '../../assets/svgs/closeRegistration.svg';
import BackIconRegistration from '../../assets/svgs/chevron-left.svg';


import RebrandLogo from '../../assets/svgs/rebrandLogo.svg';
import { useHeader } from '../../contexts';
import classNames from 'classnames';

type Props = {
  onClose: () => void;
  onClickBack: () => void;
  stepBackEnabled: boolean;
  error?: boolean
};

export const Header = ({ onClickBack, onClose, stepBackEnabled, error }: Props) => {
  const { headerTitle } = useHeader();

  const imageClasses = classNames(
    'w-full',
    'bg-white',
    'items-center',
    'justify-between',
    'tablet:px-2xl tablet:py-md',
    {
      'tablet:hidden': error,
    }
  );
  return (
    <Flex direction="flex-row" className={imageClasses}>
      <Flex className='p-sm tablet:p-0'>
        {stepBackEnabled ? (
          <Image
            className="w-6 h-6 cursor-pointer"
            src={BackIconRegistration}
            title="Back"
            alt="back"
            onClick={onClickBack}
          />
        ) : <div className='w-6' />
        }
      </Flex>

      <div className="text-grey-90 font-bold text-lg h-md tablet:flex justify-center hidden">
        {headerTitle}
      </div>

      <div className="flex justify-center tablet:hidden">
        <Image
          src={RebrandLogo}
          title="rebrand-Logo"
          alt="logo"
          onClick={onClose}
        />
      </div>
      <div className='p-sm tablet:p-xs'>
        <Image
          className="w-6 h-6 cursor-pointer tablet:hidden"
          src={CloseRegistration}
          title="CloseRegistration"
          alt="Close"
          onClick={onClose}
        />
      </div>
    </Flex>
  );
};
