import { AVAILABLE_LANGUAGES } from '$lib/services/locale/constants';
import type { TMarketSlug as TMarketSlugFundamentals } from '@casumo/fabric-fundamentals';
import type { TMarket, TMarketSlug } from './types';
import { MARKET_SLUGS } from './slugs';
import { JURISDICTION } from './jurisdictions';
import { LOCALES } from '../locale/locales';
import type { PartialRecord } from '../helpers/types';

export const AVAILABLE_MARKETS: Record<TMarketSlug, TMarket> = {
    [MARKET_SLUGS.EN]: {
        slug: MARKET_SLUGS.EN,
        losServiceSlug: 'en',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.EN]],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: LOCALES.EN,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.IE]: {
        slug: MARKET_SLUGS.IE,
        losServiceSlug: 'ie',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.IE]],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: LOCALES.IE,
        jurisdiction: JURISDICTION.GIB
    },
    [MARKET_SLUGS.EN_GB]: {
        slug: MARKET_SLUGS.EN_GB,
        losServiceSlug: 'engb',
        targetGeoLocations: [
            {
                country: 'GB',
                region: null
            }
        ],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.EN_GB]],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: LOCALES.EN_GB,
        jurisdiction: JURISDICTION.UKGC
    },
    [MARKET_SLUGS.SV]: {
        slug: MARKET_SLUGS.SV,
        losServiceSlug: 'sv',
        targetGeoLocations: [
            {
                country: 'SE',
                region: null
            }
        ],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.SV]],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: LOCALES.SV,
        jurisdiction: JURISDICTION.SGA
    },
    [MARKET_SLUGS.ROG]: {
        slug: MARKET_SLUGS.ROG,
        losServiceSlug: 'rog',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.ROG]],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: LOCALES.ROG,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.ROW]: {
        slug: MARKET_SLUGS.ROW,
        losServiceSlug: 'row',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.ROW]],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: LOCALES.ROW,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.EN_CA]: {
        slug: MARKET_SLUGS.EN_CA,
        losServiceSlug: 'enca',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.EN_CA]],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: LOCALES.EN_CA,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.ON]: {
        slug: MARKET_SLUGS.ON,
        losServiceSlug: 'on',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.ON]],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: LOCALES.ON,
        jurisdiction: JURISDICTION.AGCO
    },
    [MARKET_SLUGS.EN_NZ]: {
        slug: MARKET_SLUGS.EN_NZ,
        losServiceSlug: 'ennz',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.EN_NZ]],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: LOCALES.EN_NZ,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.FI]: {
        slug: MARKET_SLUGS.FI,
        losServiceSlug: 'fi',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.FI]],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: LOCALES.FI,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.NO]: {
        slug: MARKET_SLUGS.NO,
        losServiceSlug: 'no',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.NO]],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: LOCALES.NO,
        jurisdiction: JURISDICTION.MGA
    },
    [MARKET_SLUGS.ES]: {
        slug: MARKET_SLUGS.ES,
        losServiceSlug: 'es',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES[LOCALES.ES]],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: LOCALES.ES,
        jurisdiction: JURISDICTION.DGOJ
    }
};

export const URLPrefixToAPIMarket: Record<TMarketSlug, TMarketSlugFundamentals> = Object.freeze({
    [MARKET_SLUGS.EN]: '___en',
    [MARKET_SLUGS.EN_CA]: 'ca_en',
    [MARKET_SLUGS.FI]: 'fi_fi',
    [MARKET_SLUGS.EN_GB]: 'gb_en',
    [MARKET_SLUGS.IE]: 'ie_en',
    [MARKET_SLUGS.NO]: 'no_no',
    [MARKET_SLUGS.EN_NZ]: 'nz_en',
    [MARKET_SLUGS.SV]: 'se_sv',
    [MARKET_SLUGS.ES]: 'es_es',
    [MARKET_SLUGS.ON]: 'on_en',
    [MARKET_SLUGS.ROG]: '___en',
    [MARKET_SLUGS.ROW]: '___en'
});

export const APIMarketToURLPrefix: PartialRecord<TMarketSlugFundamentals, TMarketSlug> =
    Object.freeze({
        ___en: MARKET_SLUGS.EN,
        ca_en: MARKET_SLUGS.EN_CA,
        fi_fi: MARKET_SLUGS.FI,
        gb_en: MARKET_SLUGS.EN_GB,
        ie_en: MARKET_SLUGS.IE,
        no_no: MARKET_SLUGS.NO,
        nz_en: MARKET_SLUGS.EN_NZ,
        se_sv: MARKET_SLUGS.SV,
        es_es: MARKET_SLUGS.ES,
        on_en: MARKET_SLUGS.ON
    });
