import { useState, useEffect } from 'react';
import { Flex, Span, DangerousHtml } from '../../atoms';
import { TPaymentMethod, TDepositsPage } from 'Src/typedefs';
import { AddMoneyButton, RegistrationInput, PaymentMethodSelector } from '..';
import { setDepositAmountButtonsGtmEvent } from '../../gtm';
import { capitalize } from '../../utilities';
import { PAYMENT_METHOD_TITLES, TEST_ID } from 'Src/constants';

type Props = {
  depositsPageData: TDepositsPage;
  paymentsApiData: TPaymentMethod[];
  onClick: () => void;
  currency: string;
  depositAmount: number | null;
  changeAmount: (value, fromButton: boolean) => void;
  clearDeposit: () => void;
  depositLaterClick: () => void;
  error?: string;
  validateDepositAmount: () => boolean;
  selectPaymentMethod: (methodId: string) => void;
  selectedMethod: string;
  stepNumber: string;
};

export const EnterAmountComponent = ({
  depositsPageData,
  paymentsApiData,
  onClick,
  currency,
  depositAmount,
  changeAmount,
  clearDeposit,
  error,
  validateDepositAmount,
  selectPaymentMethod,
  selectedMethod,
  stepNumber,
}: Props) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const selectedPaymentMethod = paymentsApiData?.find(
    (data) => data.id === selectedMethod
  );

  const paymentProviderName = selectedPaymentMethod?.name;
  const smallDeposit = selectedPaymentMethod?.suggestedAmounts[0]?.amount;
  const mediumDeposit = selectedPaymentMethod?.suggestedAmounts[1]?.amount;
  const largeDeposit = selectedPaymentMethod?.suggestedAmounts[2]?.amount;

  useEffect(() => {
    if (!hasFocus) {
      validateDepositAmount();
    }
  }, [hasFocus]);

  const addMoneyButtonClick = (deposit: number) => {
    changeAmount(depositAmount + deposit, true);
    setDepositAmountButtonsGtmEvent(deposit);
  };

  const changeDeposit = (value: any) => {
    changeAmount(value, false);
  };

  return (
    <Flex className="gap-sm p-sm tablet:p-md py-md bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Flex className="justify-between" direction="flex-row">
          <Span className="text-purple-80">{stepNumber || ''}</Span>
        </Flex>
        <Flex className="text-black font-bold text-h">
          <DangerousHtml html={depositsPageData?.message} />
        </Flex>
      </Flex>
      <div className="text-white flex w-full gap-2xs justify-between mt-2xs">
        <div className="w-1/3">
          <AddMoneyButton
            currency={currency}
            onClick={() => addMoneyButtonClick(smallDeposit)}
            amount={smallDeposit}
          />
        </div>
        <div className="w-1/3">
          <AddMoneyButton
            currency={currency}
            onClick={() => addMoneyButtonClick(mediumDeposit)}
            amount={mediumDeposit}
          />
        </div>
        <div className="w-1/3">
          <AddMoneyButton
            currency={currency}
            onClick={() => addMoneyButtonClick(largeDeposit)}
            amount={largeDeposit}
          />
        </div>
      </div>

      <RegistrationInput
        currency={currency}
        value={depositAmount}
        hasFocus={hasFocus}
        changeValue={changeDeposit}
        placeholder={depositsPageData?.placeholder || 'Deposit Amount'}
        setHasFocus={setHasFocus}
        clearValue={clearDeposit}
        error={error}
        testID={TEST_ID.INPUT + 'depositLimit'}
      />

      <PaymentMethodSelector
        paymentsApiData={paymentsApiData}
        onClick={selectPaymentMethod}
        selectedMethod={selectedMethod}
      />

      <Flex>
        <fabric-registration-button
          onClick={onClick}
          id="btn"
          primary={true}
          {...(!depositAmount || error ? { disabled: true } : null)}
          data-testid={TEST_ID.BUTTON + 'deposit'}
        >
          <Span>
            {`${depositsPageData?.depositText} ${capitalize(
              PAYMENT_METHOD_TITLES[paymentProviderName]
            )}` || 'Deposit'}
          </Span>
        </fabric-registration-button>
      </Flex>
    </Flex>
  );
};
