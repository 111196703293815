import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type TRootState, type TToast, useAppSelector, addToast, removeToast } from 'Store';

export const useToast = () => {
  const dispatch = useDispatch();
  const toasts = useAppSelector((state: TRootState) => state.toast.toasts);

  const addItem = useCallback(
    (toast: TToast) => {
      dispatch(addToast(toast));
    },
    [dispatch]
  );

  const removeItem = useCallback(
    (id: string) => {
      dispatch(removeToast(id));
    },
    [dispatch]
  );

  return { addItem, removeItem, toasts };
};
