import { Flex, Text, Image } from '../../atoms';
import AuthenticationError from '../../assets/svgs/authenticationError.svg';
import { UseGetErrorData } from '../../hooks';
import { UseDirectusContext, UseRegistrationContext } from '../../contexts';
import { FabricButton } from '../fabricButton';
import { REGISTRATION_ERRORS } from 'Src/constants';
import { gatekeeper } from 'Src/gatekeeper';
import { useRegistrationModal } from 'Src/contexts/modal-registration-context';

type Props = {
  error?: string | null | undefined;
  onClick?: () => void;
  hasTwoButtons?: boolean;
  onSecondaryClick?: () => void;
  onClose?: () => void;
};

/*
 The main goal of this component is to display the ErrorPage what is a full page component that displays specific error information.
 @param {string} error - the unique error name that will be used to get all error information from UseDirectusContext.
 @param {void} onClick - the function what is executed on the primary button click.
 @param {boolean} hasTwoButtons - value that specifies if we have two buttons. Currently it's always true.
 @param {void} onSecondaryClick - the function that is executed on secondary button click.
 @param {void} onClose - function that closes the registartion modal.
 @returns {JSX.Element} that displays an entire page with all the error information.
*/

export const ErrorPage = ({
  error,
  onClick,
  hasTwoButtons,
  onSecondaryClick,
  onClose,
}: Props) => {
  const { errorData } = UseDirectusContext();
  const { errorDetails } = UseGetErrorData(error, errorData);
  const { registrationErrorData } = UseRegistrationContext();
  const { closeModal } = useRegistrationModal();
  console.error('Registration MFE error:', error);

  if (!error) {
    return;
  }
  const redirectToKYC = () => {
    gatekeeper?.navigation?.navigate('/accountverification/:token/:pid', {
      params: {
        token: registrationErrorData?.token,
        pid: registrationErrorData?.pid,
      },
    });
  };

  const handlePrimaryBtnClick = () => {
    if (error === REGISTRATION_ERRORS.KYC_REQUIRED) {
      closeModal();
      redirectToKYC();
    } else {
      onClick();
    }
  };

  const handleSecondaryBtnClick = () => {
    onSecondaryClick();
    closeModal();
  };

  return (
    <Flex className="flex-1 w-full h-full modalScrollbar overflow-y-auto px-sm py-lg bg-white tablet:px-lg">
      <Flex direction="flex-row" className="justify-start">
        <img
          className="errorSvg"
          src={AuthenticationError}
          alt="AuthenticationError SVG"
        />
      </Flex>
      <Flex className="flex-1 justify-between ">
        <Flex className="h-full flex-1 pb-[120px]">
          <Text className="text-h-lg my-5 ">{errorDetails?.title}</Text>
          <Text className="my-0">{errorDetails?.message || error}</Text>
        </Flex>
        {hasTwoButtons ? (
          <Flex
            direction="flex-col "
            className="gap-xs bottom-fixed-button bg-white w-full h-[140px] px-md tablet:h-[84px] tablet:flex-row-reverse tablet:rounded-b-3xl tablet:px-xl"
          >
            <Flex className="tablet:w-1/2 ">
              <FabricButton
                onClick={handlePrimaryBtnClick}
                id="btnErrorPageDefault"
                context="purple"
              >
                {errorDetails?.buttonText || 'Try again'}
              </FabricButton>
            </Flex>
            <Flex className="tablet:w-1/2">
              <FabricButton
                onClick={handleSecondaryBtnClick}
                id="btnErrorPageSecondary"
                primary={false}
                context="grey"
              >
                {errorDetails?.buttonSecondaryText || 'Get in Touch'}
              </FabricButton>
            </Flex>
          </Flex>
        ) : (
          <Flex className="my-md">
            <FabricButton
              onClick={onClick}
              id="btnErrorPage"
              primary={false}
              context="purple"
            >
              {errorDetails?.buttonText || 'Try again'}
            </FabricButton>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
