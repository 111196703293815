import { TTerm } from 'Src/typedefs';
import {
  getTermsData,
  ITermsData,
} from '../../service/directus/termsAndConditions';
import { UseDirectusContext, useDrawer } from '../../contexts';
import { TERMS_AND_CONDITIONS_TYPES } from 'Src/constants';
import { TermsComponent } from './termsComponent';
import { TDrawerData, TTermsContainerProps } from './types';



// This component is responsible to return terms data based on isPrivacyPolicy prop.
// If it's falsy the general terms are returned if truthy then privacy policy is returned.
export const TermsContainer = ({
  termsText,
  privacyText,
  className,
  isPrivacyPolicy = false,
  gtmEvent,
}: TTermsContainerProps) => {
  const { termsData } = UseDirectusContext();

  const { toggleDrawer, updateDrawerContent } = useDrawer();

  const termsAndConditionsData: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.GENERAL_TERMS
  );

  const PrivacyPolicyData: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.PRIVACY_POLICY
  );

  const sportTermsData: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.SPORTS_GENERAL_TERMS_AND_CONDITIONS
  );

  const drawerData: TDrawerData = {
    termsAndConditionsData: {
      title: termsAndConditionsData.title,
      description: termsAndConditionsData.description,
    },
    ...(isPrivacyPolicy && {
      PrivacyPolicyData: {
        title: PrivacyPolicyData.title,
        description: PrivacyPolicyData.description,
      },
    }),
  };

  if (sportTermsData.title && sportTermsData.description) {
    drawerData.sportTermsData = {
      title: sportTermsData.title,
      description: sportTermsData.description,
    };
  }

  const onOpenDrawer = (textContent: {
    title: string;
    description: string;
  }) => {
    toggleDrawer();
    updateDrawerContent(textContent);
  };

  return (
    <TermsComponent
      drawersData={drawerData}
      termsText={termsText}
      privacyText={privacyText}
      onOpenDrawer={onOpenDrawer}
      gtmEvent={gtmEvent}
      className={className}
    />
  );
};
