import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  INPUT_TYPES,
  REGISTRATION_ERRORS,
  GTM_ERRORS,
  REGISTRATION_FLOW_STEPS,
  GTM_FEATURES,
  GTM_ACTIONS,
} from '../../../constants';
import { getEmailAndPhonePageData } from '../../../service/directus/emailAndPhone';
import {
  TErrorMessage,
  TEmailValidationResponse,
  TPhoneValidationResponse,
  TPasswordValidationResponse,
  TFlags,
} from 'Src/typedefs';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
  getCountryCodeFromCountryName,
} from '../../../utilities';
import {
  emailValidationResult,
  passwordValidationResult,
  phoneValidationResult,
} from '../../../validations';
import { getErrorData } from '../../../service/directus/errors';
import { setAlertGtmEvent, sendGtmEvent } from '../../../gtm';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { adaptCountryFlags, countryHasWelcomeOffer } from '../../../service/directus/countryFlags/adapters';
import { EmailAndPhonePage } from '../../../pages/emailAndPhonePage';
import { gatekeeper } from 'Src/gatekeeper';
import { EmailMessage } from 'Components/emailAndPhone/emailMessage';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const EmailAndPhoneContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const stepNumber =
    getStepNumberTranslation('1', '4')[gatekeeper.localisation.marketSlug] ||
    getStepNumberTranslation('1', '4')?.default;

  const { setHeaderTitle } = useHeader();
  const {
    registrationFlowsData,
    errorData,
    countryFlagsData,
    welcomeOffersData = [],
  } = UseDirectusContext();
  const emailAndPhonePageData = getEmailAndPhonePageData(registrationFlowsData);
  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData);
  const allErrorData: TErrorMessage[] = getErrorData(errorData);
  const {
    isFlagDrawerOpen,
    toggleFlagDrawer,
    toggleMessageDrawer,
    updateMessageDrawerContent,
  } = useDrawer();

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneCountry, setPhoneCountry] = useState<TFlags | null>(null);
  const [enablePrefixSelector, setEnablePrefixSelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const selectedCountry = (): TFlags[] => {
    return adaptedCountryFlags.filter(
      (country) => country.name === userValues?.countryOfResidence);
  };

  useEffect(() => {
    if (!countryHasWelcomeOffer(countryFlagsData, userValues?.countryOfResidence)) {
      setPreviousRouteName(ROUTE_NAMES.COUNTRY_OF_RESIDENCE);
    } else if (welcomeOffersData.length <= 1) {
      setPreviousRouteName(ROUTE_NAMES.COUNTRY_OF_RESIDENCE);
    } else {
      setPreviousRouteName(ROUTE_NAMES.WELCOME_OFFER);
    }

    storeDataInLocalStorage(ROUTE_NAMES.EMAIL_AND_PHONE, userValues);
    setHeaderTitle(emailAndPhonePageData.header_title);

    // Read stored data and set it if there is any.
    if (userValues?.email) {
      setEmail(userValues?.email);
    }
    if (userValues?.password) {
      setPassword(`${userValues?.password}`);
    }
    if (userValues?.phoneNumber?.number) {
      setPhoneNumber(`${userValues?.phoneNumber?.number}`);
    }
    if (userValues?.phoneNumber?.country) {
      setPhoneCountry(userValues?.phoneNumber?.country);
    } else {
      setPhoneCountry(selectedCountry()[0]);
    }
  }, []);

  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          emailAndPhonePageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [emailAndPhonePageData, welcomeOffersData]);

  const changeValue = (value, inputType) => {
    if (inputType === INPUT_TYPES.EMAIL) {
      setEmailError(null);
      setEmail(value);
    }
    if (inputType === INPUT_TYPES.PASSWORD) {
      setPasswordError(null);
      setPassword(value);
    }
    if (inputType === INPUT_TYPES.PHONE) {
      if (value.includes('.') || (isNaN(value) && value !== '+')) {
        return;
      }
      setPhoneNumberError(null);
      setPhoneNumber(value);
    }
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  const changePhoneCountry = (country: TFlags) => {
    if (!country) {
      return;
    }
    setPhoneCountry(country);
    toggleFlagDrawer();
    phoneValidation(false);
  };

  const clearValue = (inputType) => {
    if (inputType === INPUT_TYPES.EMAIL) {
      setEmailError(null);
      setEmail(null);
    }
    if (inputType === INPUT_TYPES.PASSWORD) {
      setPasswordError(null);
      setPassword(null);
    }
    if (inputType === INPUT_TYPES.PHONE) {
      setPhoneNumberError(null);
      setPhoneNumber(null);
    }
  };

  if (routeName !== ROUTE_NAMES.EMAIL_AND_PHONE) {
    return null;
  }

  const handleRedirectLogin = () => {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.GO_TO_LOGIN, {
      alert: GTM_ERRORS.ALREADY_HAS_AN_ACCOUNT,
      step: GTM_ACTIONS.ACCOUNT_DETAILS,
    });
    gatekeeper.navigation.navigate('/log-in');
  };

  const emailValidation = async (onContinue: boolean) => {
    if (!email && !onContinue) {
      setEmailError(null);
      return false;
    }
    const validationResult: TEmailValidationResponse =
      await emailValidationResult(email, onContinue);
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      if (validationResult.error === REGISTRATION_ERRORS.DUPLICATE_EMAIL) {
        toggleMessageDrawer();
        updateMessageDrawerContent({
          title: message.title,
          enableCloseButton: true,
          messageComponent: (
            <EmailMessage
              description={message.message}
              primaryBtnText={message.buttonText}
              secondaryBtnText={message.buttonSecondaryText}
              primaryBtnClick={() => toggleMessageDrawer()}
              secondaryBtnClick={() => handleRedirectLogin()}
            />
          ),
        });
        setAlertGtmEvent({
          alert: GTM_ERRORS.EMAIL_TAKEN,
          step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
        });
      } else {
        setEmailError(message?.message);
      }
      return false;
    }
    setEmailError(null);
    return true;
  };

  const passwordValidation = async (onContinue: boolean) => {
    if (!password && !onContinue) {
      setPasswordError(null);
      return false;
    }
    const validationResult: TPasswordValidationResponse =
      await passwordValidationResult(password, onContinue);
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      setPasswordError(message?.message);
      return false;
    }
    setPasswordError(null);
    return true;
  };

  const phoneValidation = async (onContinue: boolean) => {
    if (!phoneNumber && !onContinue) {
      setPhoneNumberError(null);
      return false;
    }
    const validationResult: TPhoneValidationResponse =
      await phoneValidationResult(phoneCountry.countryCode, phoneNumber, onContinue);
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      if (validationResult.error === REGISTRATION_ERRORS.DUPLICATE_PHONE) {
        setAlertGtmEvent({
          alert: GTM_ERRORS.PHONE_TAKEN,
          step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
        });
      }
      setPhoneNumberError(message?.message);
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const onConfirm = async () => {
    const onContinue = true; // This is value is resposnible to do full validation before continue in the registration.
    const isEmailCorrect = await emailValidation(onContinue);
    const isPasswordCorrect = await passwordValidation(onContinue);
    const isPhoneCorrect = await phoneValidation(onContinue);
    if (isEmailCorrect && isPasswordCorrect && isPhoneCorrect) {
      setUserValues({
        ...userValues,
        email: email,
        password: password,
        phoneNumber: {
          country: phoneCountry,
          number: phoneNumber,
        },
      });
      storeDataInLocalStorage(ROUTE_NAMES.EMAIL_AND_PHONE, {
        ...userValues,
        email: email,
        password: password,
        phoneNumber: {
          country: phoneCountry,
          number: phoneNumber,
        },
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ACCOUNT_DETAILS, {
        phone_country: getCountryCodeFromCountryName(
          phoneCountry.name
        )?.toLowerCase(),
        step: GTM_ACTIONS.ACCOUNT_DETAILS,
      });
      setRouteName(ROUTE_NAMES.ADDRESS);
    }
  };

  const dependencyArray = [isFlagDrawerOpen, email, password, phoneNumber];
  UseHandleKeyDown(dependencyArray, !isFlagDrawerOpen, onConfirm);

  return (
    <EmailAndPhonePage
      isMgaGra
      description={description}
      pageData={emailAndPhonePageData}
      changeValue={changeValue}
      clearValue={clearValue}
      email={email}
      password={password}
      phoneNumber={phoneNumber}
      validateEmail={emailValidation}
      validatePassword={passwordValidation}
      validatePhone={phoneValidation}
      emailError={emailError}
      passwordError={passwordError}
      phoneNumberError={phoneNumberError}
      selectedCountry={phoneCountry}
      openPrefixSelector={() => {
        setEnablePrefixSelector(true);
        toggleFlagDrawer();
      }}
      onConfirm={onConfirm}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enablePrefixSelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      changeSearchValue={changeSearchValue}
      searchValue={searchValue}
      selectedPrefix={phoneCountry?.countryCode}
      setPhoneCountry={changePhoneCountry}
      userValues={userValues}
      stepNumber={stepNumber}
      handleRedirectLogin={handleRedirectLogin}
    />
  );
};
