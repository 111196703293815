import { useEffect, useState } from 'react';
import { Flex } from '../../atoms';
import { TAddress, TAddressErrors, TAddressPage } from 'Src/typedefs';
import { RegistrationInput } from '..';
import { ADDRESS_COMPONENTS, TEST_ID } from '../../constants';
import { NationalityInput } from 'Components/nationality/nationalityInput';
import { sanitizeInput } from '../../utilities';

type Props = {
  addressPageData: TAddressPage;
  changeValue: (value: string, addressComponent: string) => void;
  address?: TAddress | null;
  selectedFlagUuid?: string;
  countryOfResidence?: string;
  error: TAddressErrors;
};

export const ManualAddressComponent = ({
  addressPageData,
  changeValue,
  address,
  selectedFlagUuid,
  countryOfResidence,
  error,
}: Props) => {
  const [houseNumberHasFocus, setHouseNumberHasFocus] =
    useState<boolean>(false);
  const [postalCodeHasFocus, setPostalCodeHasFocus] = useState<boolean>(false);
  const [streetHasFocus, setStreetHasFocus] = useState<boolean>(false);
  const [cityHasFocus, setCityHasFocus] = useState<boolean>(false);

  return (
    <Flex className="w-full gap-sm bg-white rounded-3xl">
      <Flex direction="flex-row" className="text-black justify-between">
        {/* HouseNumber input */}
        <Flex className="w-[48%]">
          <RegistrationInput
            currency={null}
            value={address.houseNumber || ''}
            hasFocus={houseNumberHasFocus}
            setHasFocus={() => {
              if (houseNumberHasFocus) {
                changeValue(
                  address.houseNumber,
                  ADDRESS_COMPONENTS.HOUSE_NUMBER
                );
              }
              setHouseNumberHasFocus(!houseNumberHasFocus);
            }}
            changeValue={(value) =>
              changeValue(value, ADDRESS_COMPONENTS.HOUSE_NUMBER)
            }
            placeholder={addressPageData?.houseNumberPlaceholder || 'House No.'}
            clearValue={() => changeValue('', ADDRESS_COMPONENTS.HOUSE_NUMBER)}
            error={
              error?.addressErrors?.find(
                (error) => error.type === ADDRESS_COMPONENTS.HOUSE_NUMBER
              )?.message
            }
            testID={TEST_ID.INPUT + 'houseNumber'}
          />
        </Flex>
        <Flex className="w-[48%]">
          {/* Postal Code input */}
          <RegistrationInput
            currency={null}
            value={address.postalCode || ''}
            hasFocus={postalCodeHasFocus}
            setHasFocus={() => {
              if (postalCodeHasFocus) {
                changeValue(address.postalCode, ADDRESS_COMPONENTS.POSTAL_CODE);
              }
              setPostalCodeHasFocus(!postalCodeHasFocus);
            }}
            changeValue={(value) => {
              const trimmedValue = value.replace(/\s+$/, '').trim();
              const uppercaseValue = trimmedValue.toUpperCase();
              changeValue(uppercaseValue, ADDRESS_COMPONENTS.POSTAL_CODE);
            }}
            placeholder={addressPageData?.postcodePlaceholder || 'Postcode'}
            clearValue={() => changeValue('', ADDRESS_COMPONENTS.POSTAL_CODE)}
            error={
              error?.addressErrors?.find(
                (error) => error.type === ADDRESS_COMPONENTS.POSTAL_CODE
              )?.message
            }
            testID={TEST_ID.INPUT + 'postcode'}
          />
        </Flex>
      </Flex>
      <Flex className="text-black justify-between">
        {/* StreetAddress input */}
        <RegistrationInput
          currency={null}
          value={address.streetName || ''}
          hasFocus={streetHasFocus}
          setHasFocus={() => {
            if (streetHasFocus) {
              changeValue(address.streetName, ADDRESS_COMPONENTS.STREET_NAME);
            }
            setStreetHasFocus(!streetHasFocus);
          }}
          changeValue={(value) =>
            changeValue(value, ADDRESS_COMPONENTS.STREET_NAME)
          }
          placeholder={addressPageData?.streetPlaceholder || 'Street address'}
          clearValue={() => changeValue('', ADDRESS_COMPONENTS.STREET_NAME)}
          error={
            error?.addressErrors?.find(
              (error) => error.type === ADDRESS_COMPONENTS.STREET_NAME
            )?.message
          }
          testID={TEST_ID.INPUT + 'street'}
        />
      </Flex>
      <Flex className="text-black justify-between">
        {/* City input */}
        <RegistrationInput
          currency={null}
          value={address.city || ''}
          hasFocus={cityHasFocus}
          setHasFocus={() => {
            if (cityHasFocus) {
              changeValue(address.city, ADDRESS_COMPONENTS.CITY);
            }
            setCityHasFocus(!cityHasFocus);
          }}
          changeValue={(value) =>
            changeValue(sanitizeInput(value), ADDRESS_COMPONENTS.CITY)
          }
          placeholder={addressPageData?.cityPlaceholder || 'City'}
          clearValue={() => changeValue('', ADDRESS_COMPONENTS.CITY)}
          error={
            error?.addressErrors?.find(
              (error) => error.type === ADDRESS_COMPONENTS.CITY
            )?.message
          }
          testID={TEST_ID.INPUT + 'city'}
        />
      </Flex>
      <Flex className="text-black justify-between">
        <NationalityInput
          selectedFlagUuid={selectedFlagUuid}
          nationality={countryOfResidence}
          disabled={true}
          testID={TEST_ID.COMBOBOX_TRIGGER + 'countryOfResidence'}
        />
      </Flex>
    </Flex>
  );
};
