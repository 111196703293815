import { ComponentChildren, h } from "preact";
import { useErrorBoundary } from "preact/hooks";
import { Fallback } from "./Fallback";

export function FallbackContainer({
  children,
}: {
  children: ComponentChildren;
}) {
  const [error, resetError] = useErrorBoundary();

  if (error) {
    return <Fallback error={error} resetError={resetError} />;
  }

  return children;
}
