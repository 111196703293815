export function Fallback({
  error,
  resetError,
}: {
  error: { message: string };
  resetError: () => void;
}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>Here's the error message: {error.message}</pre>
      <button onClick={resetError}>Try again</button>
    </div>
  );
}
