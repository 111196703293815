import { parse } from 'date-fns';
import { CURRENCY_BY_COUNTRY_CODE } from 'Src/currencyByCountryCode';
import { gatekeeper } from '../../gatekeeper';
import { TRegistrationUserDetails, TKeycloakUser } from '../../typedefs';
import { getDateFormat } from 'Src/utilities';

export interface IRegisterUserData {
  email: string;
  firstName: string;
  lastName: string;
  plainTextPassword?: string;
  phonePrefix: string;
  phoneNumber: string;
  phoneVerified: boolean;
  socialSecurityNumber?: string;
  dayOfBirth: number;
  monthOfBirth: number;
  yearOfBirth: number;
  placeOfBirth?: {
    city: string | null,
    country: string | null  // This should be changed and me mandatory in the future
  },
  gender: string;
  address: {
    country: string;
    city: string;
    postalCode: string;
    streetName: string;
    isVerified: boolean;
    province?: string;
    houseNumber?: string;
    //the example in Swagger shows 'verified' but actually expects 'isVerfied"
  };
  nationality: string;
  welcomeOfferId: string;
  trackingId: string;
  marketCode?: string;
  subscribeToMarketingMaterial?: { [key: string]: { [key: string]: boolean } } | boolean | null;
  marketingConsent?: { [key: string]: { [key: string]: boolean } } | boolean | null;
  limit?: {
    depositType: String;
    depositLimit: {
      value: number;
      currency: string;
    };
  };
  limits?: {
    daily: {
      value: number;
      currency: string;
    };
    weekly: {
      value: number;
      currency: string;
    };
    monthly: {
      value: number;
      currency: string;
    };
  } | null;
  payAndPlay?: {
    amount: number;
    paymentMethodId: string;
  };
  playerId?: string;
  payAndPlayId?: string;
  statusCode?: string | number;
}

interface IBirthDate {
  day: number;
  month: number;
  year: number;
}

const seperateBirthdate = (birthdate: string, dateFormate: string) => {
  const parsedDate = parse(birthdate, dateFormate, new Date());

  const [numericDay, numericMonth, numericYear] = [
    parsedDate.getDate(),
    parsedDate.getMonth() + 1,
    parsedDate.getFullYear(),
  ];
  const birthday: IBirthDate = {
    day: Number(numericDay),
    month: Number(numericMonth),
    year: Number(numericYear),
  };
  return birthday;
};

export const adaptRegistrationUserData = (
  devcode: TKeycloakUser,
  registrationData: TRegistrationUserDetails,
  trackingId: string
) => {
  if (devcode && registrationData) {
    const birthdate = seperateBirthdate(devcode.birthdate, getDateFormat());
    const result: IRegisterUserData = {
      email: registrationData?.email,
      firstName: devcode?.given_name,
      lastName: devcode?.family_name,
      phonePrefix: registrationData?.phoneNumber?.country?.countryCode,
      phoneNumber: registrationData?.phoneNumber?.number,
      phoneVerified: false,
      socialSecurityNumber: devcode?.ssn,
      dayOfBirth: birthdate?.day,
      monthOfBirth: birthdate?.month,
      yearOfBirth: birthdate?.year,
      placeOfBirth: {
        city: registrationData.birthDetails?.placeOfBirth?.city || null,
        country: registrationData?.birthDetails?.placeOfBirth?.country || null
      },
      gender: devcode?.gender?.toLocaleLowerCase() || 'male',
      address: {
        country: devcode?.address_country,
        city: devcode.address_city,
        postalCode: devcode.address_postal_code,
        streetName: devcode.address_street_name,
        isVerified: devcode.address_is_verified,
        //the example in Swagger shows 'verified' but actually expects 'isVerfied"
      },
      nationality: registrationData?.nationalityCountryCode || 'SE',
      welcomeOfferId: registrationData.selectedWelcomeOffer?.name, // We use the name attribute for the wo ID.
      trackingId: trackingId,
      subscribeToMarketingMaterial: registrationData?.marketingConsent,
      limits: {
        daily: {
          value: registrationData?.depositLimits?.daily,
          currency:
            CURRENCY_BY_COUNTRY_CODE[
            registrationData?.countryOfResidenceCountryCode
            ] || 'SEK',
        },
        weekly: {
          value: registrationData?.depositLimits?.weekly,
          currency:
            CURRENCY_BY_COUNTRY_CODE[
            registrationData?.countryOfResidenceCountryCode
            ] || 'SEK',
        },
        monthly: {
          value: registrationData?.depositLimits?.monthly,
          currency:
            CURRENCY_BY_COUNTRY_CODE[
            registrationData?.countryOfResidenceCountryCode
            ] || 'SEK',
        },
      },
    };

    if (registrationData?.paymentMethodId && registrationData?.depositAmount) {
      return {
        ...result,
        payAndPlay: {
          amount: registrationData?.depositAmount,
          paymentMethodId: registrationData?.paymentMethodId,
        },
      };
    }

    return result;
  }
  return null;
};

export const adaptMgaGraRegistrationUserData = (
  registrationData: TRegistrationUserDetails,
  trackingId: string
) => {
  const market = gatekeeper?.localisation?.marketSlug;
  if (registrationData) {
    const birthdate = seperateBirthdate(
      registrationData.birthDetails.birthDate,
      getDateFormat()
    );
    const result: IRegisterUserData = {
      email: registrationData?.email,
      firstName: registrationData?.firstName,
      lastName: registrationData?.lastName,
      plainTextPassword: registrationData.password,
      phonePrefix: registrationData?.phoneNumber?.country?.countryCode,
      phoneNumber: registrationData?.phoneNumber?.number,
      phoneVerified: false,
      dayOfBirth: birthdate?.day,
      monthOfBirth: birthdate?.month,
      yearOfBirth: birthdate?.year,
      placeOfBirth: {
        city: registrationData?.birthDetails?.placeOfBirth?.city || null,
        country: registrationData?.birthDetails?.placeOfBirth?.country || null,
      },
      gender: registrationData.gender,
      address: {
        country: registrationData.address.country,
        city: registrationData.address.city,
        postalCode: registrationData.address.postalCode,
        streetName: registrationData.address.streetName,
        isVerified: true, // The example in Swagger shows 'verified' but actually expects 'isVerfied".
        houseNumber: registrationData.address.houseNumber,
        province:
          registrationData.address?.country?.toLowerCase() === 'canada'
            ? registrationData.selectedProvinceCode
            : registrationData.address.province || null,
      },
      nationality: registrationData?.nationalityCountryCode,
      welcomeOfferId: registrationData.selectedWelcomeOffer?.name,
      trackingId: trackingId,
      marketCode: market,
      subscribeToMarketingMaterial: registrationData?.marketingConsent,
      limit: registrationData?.depositLimits
        ? {
          depositType: registrationData?.depositLimits.depositType,
          depositLimit: {
            value: registrationData?.depositLimits?.depositLimit,
            currency:
              CURRENCY_BY_COUNTRY_CODE[
              registrationData?.countryOfResidenceCountryCode
              ] || 'EUR',
          },
        }
        : null,
    };

    return result;
  }
  return null;
};


export const adaptUKGCRegistrationUserData = (
  registrationData: TRegistrationUserDetails,
  trackingId: string
) => {
  const market = gatekeeper?.localisation?.marketSlug;
  if (registrationData) {
    const birthdate = seperateBirthdate(
      registrationData.birthDetails.birthDate,
      getDateFormat()
    );
    const result: IRegisterUserData = {
      email: registrationData?.email,
      firstName: registrationData?.firstName,
      lastName: registrationData?.lastName,
      plainTextPassword: registrationData.password,
      phonePrefix: registrationData?.phoneNumber?.country?.countryCode,
      phoneNumber: registrationData?.phoneNumber?.number,
      phoneVerified: false,
      dayOfBirth: birthdate?.day,
      monthOfBirth: birthdate?.month,
      yearOfBirth: birthdate?.year,
      gender: registrationData.gender,
      marketingConsent: registrationData.marketingConsent,
      placeOfBirth: {
        city: registrationData?.birthDetails?.placeOfBirth?.city || null,
        country: registrationData?.birthDetails?.placeOfBirth?.country || null,
      },
      address: {
        country: registrationData.address.country,
        city: registrationData.address.city,
        postalCode: registrationData.address.postalCode,
        streetName: registrationData.address.streetName,
        isVerified: true, // The example in Swagger shows 'verified' but actually expects 'isVerfied".
        houseNumber: registrationData.address.houseNumber,
        province: registrationData.address.province || null
      },
      nationality: registrationData?.nationalityCountryCode,
      welcomeOfferId: registrationData.selectedWelcomeOffer?.name,
      trackingId: trackingId,
      marketCode: market,
      limit: registrationData?.depositLimits
        ? {
          depositType: registrationData?.depositLimits.depositType,
          depositLimit: {
            value: registrationData?.depositLimits?.depositLimit,
            currency:
              CURRENCY_BY_COUNTRY_CODE[
              registrationData?.countryOfResidenceCountryCode
              ] || 'EUR',
          },
        }
        : null,
    };

    return result;
  }
  return null;
};
