import { useEffect, useRef } from 'react';
import { Flex } from '../../atoms';
import { useHeader, UseRegistrationContext } from '../../contexts';
import { ROUTE_NAMES } from '../../constants';
import { WelcomeOfferContainer } from '../../markets/mgaGra/containers/welcomeOfferContainer';
import { AddressContainer } from '../../markets/mgaGra/containers/addressContainer';
import { CountryOfResidenceContainer } from '../../markets/mgaGra/containers/countryOfResidenceContainer';
import { NationalityContainer } from '../../markets/mgaGra/containers/nationalityContainer';
import GoogleApiLoader from '../../scripts/googleApis';
import { EmailAndPhoneContainer } from 'Src/markets/mgaGra/containers/emailAndPhoneContainer';
import { DepositLimitsContainer } from 'Src/markets/mgaGra/containers/depositLimitsContainer';
import { BirthDetailsContainer } from 'Src/markets/mgaGra/containers/birthDetailsContainer';
import { MarketingConsentContainer } from 'Src/markets/mgaGra/containers/marketingConsentContainer';
import classNames from 'classnames';

// This is not a real router. We are not going to change the url because of the microFE setup
// but we will display the requested page from the flow based on the page name.
export const MgaGraRegistrationRouter = () => {
  const { routeName } = UseRegistrationContext();
  const { setHideHeader } = useHeader();
  const scrollElementRef = useRef(null);

  useEffect(() => {
    if (scrollElementRef?.current) {
      scrollElementRef.current.scrollTop = 0;
    }

    if (routeName === ROUTE_NAMES.WELCOME_OFFER) {
      setHideHeader(true)
    } else {
      setHideHeader(false)
    }
  }, [routeName]);

  const registrationPageSelector = () => {
    switch (routeName) {
      case ROUTE_NAMES.WELCOME_OFFER:
        return <WelcomeOfferContainer />;

      case ROUTE_NAMES.EMAIL_AND_PHONE:
        return <EmailAndPhoneContainer />;

      case ROUTE_NAMES.ADDRESS:
        return <AddressContainer />;

      case ROUTE_NAMES.NATIONALITY:
        return <NationalityContainer />;

      case ROUTE_NAMES.LIMITS:
        return <DepositLimitsContainer />;

      case ROUTE_NAMES.BIRTH_DETAILS:
        return <BirthDetailsContainer />;

      case ROUTE_NAMES.MARKETING_CONSENT:
        return <MarketingConsentContainer />;

      default:
        return <CountryOfResidenceContainer />;
    }
  };

  return (
    <Flex
      reference={scrollElementRef}
      className={classNames(
        'flex-1 bg-grey-0 modalScrollbar w-full overflow-y-auto',
        {
          'h-full tablet:h[500px] pt-sm px-sm tablet:pt-lg tablet:px-2xl':
            routeName !== ROUTE_NAMES.MARKETING_CONSENT,
          "!p-0 tablet:!p-0 no-scrollbar !bg-[#6E28FA]": routeName === ROUTE_NAMES.WELCOME_OFFER
        }
      )}
    >
      <GoogleApiLoader />
      {registrationPageSelector()}
    </Flex>
  );
};
