import classNames from 'classnames';
import ClearInput from '../../assets/svgs/clearInputButton.svg';
import { Flex, Image, Input, Label, Span } from '../../atoms';
import { FULLSTORY_MASK_CLASSNAME, TEST_ID } from 'Src/constants';
import { DateFormat, InputMode } from 'Src/typedefs';

type Props = {
  currency?: string;
  value?: string | number;
  hasFocus: boolean;
  changeValue?: (value, dateFormat) => void;
  placeholder?: string;
  setHasFocus?: (value) => void;
  setTriggerGTMEvent?: (value) => void;
  clearValue?: () => void;
  error?: string;
  type?: string;
  inputMode?: InputMode;
  dateFormat: DateFormat;
};

export const DateInput = ({
  value,
  hasFocus,
  changeValue,
  placeholder,
  dateFormat,
  setHasFocus,
  setTriggerGTMEvent,
  clearValue,
  error,
  type,
  inputMode,
}: Props) => {
  const formatValueWithPlaceholders = (value: string, format: string) => {
    const numericValue = value.replace(/[^\d]/g, '');
    let formattedValue = '';
    let valueIndex = 0;
    for (let i = 0; i < format.length; i++) {
      const char = format[i];
      if (/[YMD]/.test(char)) {
        formattedValue +=
          valueIndex < numericValue.length ? numericValue[valueIndex++] : char;
      } else {
        formattedValue += char;
      }
    }
    return formattedValue;
  };

  const formattedValue = formatValueWithPlaceholders(
    value?.toString() || '',
    dateFormat.toUpperCase()
  );

  return (
    <Flex>
      <Flex className="h-14 overflow-hidden inputBgTransparent">
        <Label
          className={classNames(
            'relative left-md w-sm h-0',
            {
              invisible: !value,
              'top-[33px]': hasFocus || value,
            },
            FULLSTORY_MASK_CLASSNAME
          )}
        >
          <Span className="invisible">{value}</Span>
        </Label>
        <Label
          className={classNames(
            'absolute top-0.5 left-[18px] text-grey-50 text-xs font-bold',
            {
              invisible: !hasFocus && !value,
            },
            FULLSTORY_MASK_CLASSNAME
          )}
        >
          {placeholder}
        </Label>
        <Label
          className={classNames(
            'absolute left-[18px] w-sm h-0 top-[21px] font-bold',
            {
              invisible: !hasFocus && !value,
              'text-red-40': error,
            },
            FULLSTORY_MASK_CLASSNAME
          )}
        >
          {formattedValue.split('').map((char, index, arr) => {
            const isNumeric = /\d/.test(char);
            const isPreviousNumber = /\d/.test(arr[index - 1]);
            const isSeparator = char === '/' || char === '.';

            const textColor = error
              ? 'text-red-40'
              : isNumeric || (isSeparator && isPreviousNumber)
                ? 'text-black'
                : 'text-grey-50';

            const padding = isSeparator ? 'px-0.5' : '';

            return (
              <span className={classNames(textColor, padding)} key={index}>
                {char}
              </span>
            );
          })}
        </Label>
        <Input
          className={classNames(
            'h-3xl pl-sm bg-grey-0 border-grey-5 border-2 rounded-2xl caret-none focus:border-2 font-bold',
            {
              'pt-2xs': hasFocus || value,
              "pr-2xl": value,
              "pr-sm": !value,
              'focus:border-purple-80': hasFocus && !error,
              'border-2 border-red-40': error
            },
            FULLSTORY_MASK_CLASSNAME
          )}
          onChange={(event) => changeValue(event?.target?.value, dateFormat)}
          onBlur={() => setHasFocus(false)}
          onFocus={() => {
            setHasFocus(true);
            setTriggerGTMEvent && setTriggerGTMEvent(true);
          }}
          value={value?.toString() || ''}
          placeholder={hasFocus ? '' : placeholder || ''}
          type={type}
          style={{ color: 'transparent' }}
          inputMode={inputMode}
          testID={TEST_ID.INPUT + 'dateOfBirth'}
        />

        <Flex className="w-full h-0 items-end">
          <Image
            src={ClearInput}
            className={classNames(
              'relative bottom-10 right-4 w-md cursor-pointer',
              {
                invisible: !value,
              }
            )}
            onClick={clearValue}
            title={'Clear'}
            alt={'Delete'}
          />
        </Flex>
      </Flex>
      <Flex className="text-red-40 text-xs">{error ? error : ''}</Flex>
    </Flex>
  );
};
