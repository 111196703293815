import { equals, propOr, pick } from 'ramda';
import { DateTime } from 'luxon';
import { type FabricTopicToPayload, isMobile, type TLocaleCode } from '@casumo/fabric-fundamentals';
import { CURRENCIES, DEVICES, ENVIRONMENT_URLS, LOCALE_CODES } from 'Constants';
import { gatekeeper } from 'Src/gatekeeper';
import { APPLICATION_NAME } from 'Src/fabric.constants';
import { type TDevice, type TCurrency } from 'Src/types';
import { type TGenericFunction } from './types';

export const getEnv = (): string => {
  const currentDomain = Object.values(ENVIRONMENT_URLS).find((domain) =>
    location.hostname.includes(domain)
  );
  return currentDomain && currentDomain !== ENVIRONMENT_URLS.dev
    ? currentDomain
    : ENVIRONMENT_URLS.test;
};

export const isDevEnv = () => getEnv() === ENVIRONMENT_URLS.dev;
export const isTestEnv = () =>
  getEnv() === ENVIRONMENT_URLS.test || getEnv() === ENVIRONMENT_URLS.test_es;
export const isStagingEnv = () => getEnv() === ENVIRONMENT_URLS.stage;
export const isProdEnv = () =>
  getEnv() === ENVIRONMENT_URLS.prod || getEnv() === ENVIRONMENT_URLS.prod_es;

export const noop = () => {};

export const isEmpty = (val: any) =>
  val == null || !(Object.keys(val) || val).length || val === 'undefined' || val === '';

export const isNullOrUndefined = (val: any): val is null | undefined =>
  val == null || val === 'undefined';

export const roundToDecimal = (number: number, decimalPlaces: number) => {
  const factor = 10 ** decimalPlaces;
  return Math.round(number * factor) / factor;
};

export const snakeToCamel = (str: string): string => {
  return str.replace(/([-_]\w)/g, (match: string) =>
    match.toUpperCase().replace('-', '').replace('_', '')
  );
};

/**
 * @param items - Array of objects with field and value keys
 * @returns {Record<string, any>} - Object with keys from items.field and values from items.value
 * @example mergeItems([{ field: 'foo', value: 'bar' }, { field: 'baz', value: 'qux' }]) - { foo: 'bar', baz: 'qux' };
 */
export const mergeItems = <T extends Record<string, any>>(items: T[]): T => {
  return items.reduce<T>(
    (result, { field, value }) => ({
      ...result,
      [field]: value,
    }),
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as T
  );
};

/**
 * @param fn - Function to be partially applied
 * @param firstArgs - Arguments to be applied to the function
 * @returns {Function} - Partially applied function
 * @example partial((a, b) => a + b, 1)(2) - 3
 */
export const partial = <A extends any[], B extends any[], R>(
  fn: TGenericFunction<[...A, ...B], R>,
  ...firstArgs: A
): ((...args: B) => R) => {
  return (...lastArgs: B): R => {
    return fn(...firstArgs, ...lastArgs);
  };
};

type FieldValuePair<T> = {
  field: keyof T;
  value: T[keyof T];
};

type TFormatCurrency = {
  iso4217CurrencyCode: string;
  amount: number;
  localeCode: string;
};

export const fieldOrUndefined = <T>(
  field: keyof T,
  value: T[keyof T]
): FieldValuePair<T> | undefined => {
  if (value !== undefined) {
    return { field, value };
  }
  return undefined;
};

const DEFAULT_NUMBER_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
  style: 'currency',
  currencyDisplay: 'narrowSymbol',
};

export const pathOrDefault = <
  T extends Record<string, string | number | null>,
  R extends string | number | undefined | null
>(
  defaultValue: R,
  path: Array<string | number>,
  obj: T
): R => {
  let result: any = obj;

  for (const key of path) {
    if (result === null || result === undefined) {
      return defaultValue;
    }

    result = result[key];
  }

  // Only return the default value if the result is strictly null
  return result === null ? defaultValue : result;
};

const formatCurrency = (
  shouldFormatZeroes = true,
  { iso4217CurrencyCode, amount, localeCode }: TFormatCurrency
) => {
  // Matches the number up to the n-th decimal place and turn it back into a number
  // Supports negative numbers as well
  const truncateToDecimalPlaces = (value: number, dp = 0) => {
    if (typeof value !== 'number') return value;

    const operator = value >= 0 ? 1 : -1;
    const positiveValue = operator * value;

    const regex = new RegExp(`^\\d+(?:\\.\\d{0,${dp}})?`);
    return operator * Number(positiveValue.toString().match(regex));
  };

  // For Norway we want to display "123 kr" instead of "kr 123"
  // And that format is conveniently the same as Sweden so... Thanks Sweden!
  const getNorwegianFormatterOverride = () => {
    return new Intl.NumberFormat('sv-SE', {
      ...DEFAULT_NUMBER_FORMAT_OPTIONS,
      currency: 'SEK',
    });
  };

  const isNorwegianCurrency = (localeCode: string, iso4217CurrencyCode: string) =>
    localeCode === 'no-NO' && iso4217CurrencyCode === 'NOK';

  const getIntlFormatter = ({
    localeCode,
    iso4217CurrencyCode,
  }: Omit<TFormatCurrency, 'amount'>) => {
    if (isNorwegianCurrency(localeCode, iso4217CurrencyCode)) {
      return getNorwegianFormatterOverride();
    }

    return new Intl.NumberFormat(localeCode, {
      ...DEFAULT_NUMBER_FORMAT_OPTIONS,
      currency: iso4217CurrencyCode,
    });
  };
  const formatter = getIntlFormatter({ iso4217CurrencyCode, localeCode });
  const findTrailingZerosRegex = /\D00(?=\D*$)/;
  if (shouldFormatZeroes) {
    return formatter.format(truncateToDecimalPlaces(amount, 2)).replace(findTrailingZerosRegex, '');
  } else {
    return formatter.format(truncateToDecimalPlaces(amount, 2));
  }
};

export const formatMoney = (
  localeCode: string,
  amount: any,
  currency = 'EUR',
  shouldFormatZeroes = true
): string | null => {
  try {
    const amountAsNumber = parseFloat(amount);

    if (isNaN(amountAsNumber) || !isFinite(amountAsNumber)) {
      throw new Error('Invalid amount: Please provide a valid numeric value.');
    }

    const isValidCurrency = getIsValidCurrency(currency);
    if (!isValidCurrency) {
      throw new Error('Invalid currency: Please provide a valid currency code.');
    }

    const formattedMoney = formatCurrency(shouldFormatZeroes, {
      iso4217CurrencyCode: currency,
      amount: amountAsNumber,
      localeCode,
    });
    if (localeCode === 'no-NO' && currency === 'NOK') {
      const repositionedSymbol = formattedMoney.replace('kr', '') + 'kr';
      return repositionedSymbol;
    }

    return formattedMoney;
  } catch (error) {
    return null;
  }
};

export const getDateTimeDifferenceFromNow = (value: DateTime) => {
  const duration = value.diff(DateTime.utc(), ['hours', 'minutes', 'seconds']);

  return pick(['hours', 'minutes', 'seconds'], duration);
};

export const convertTimestampToLuxonDate = (value: number | string) => {
  if (typeof value === 'string') {
    return DateTime.fromISO(value);
  }

  if (typeof value === 'number') {
    // Convert milliseconds to seconds
    const seconds = value / 1000;
    return DateTime.fromSeconds(seconds);
  }

  return value;
};

export const getExpiryTimeLeft = (timestamp: number = 0) => {
  const luxonDate = convertTimestampToLuxonDate(timestamp);

  return getDateTimeDifferenceFromNow(luxonDate);
};

export const getTimeInFormattedString = (timestamp: number | string, format = 'dd LLL hh:mm') =>
  convertTimestampToLuxonDate(timestamp).toFormat(format);

export const localizedFormatMoney = partial(
  formatMoney,
  gatekeeper?.localisation?.localeCode ?? 'se-SV'
);

export const log = ({
  level,
  message,
  error,
}: {
  level: FabricTopicToPayload['logger']['level'];
  message?: string;
  error?: Error;
}) => {
  gatekeeper.messageBus.publish('logger', {
    kind: 'log',
    level,
    message,
    error,
    mfe: APPLICATION_NAME,
  });
};

export const getPlatform = (): TDevice => {
  return isMobile() ? DEVICES.MOBILE : DEVICES.DESKTOP;
};

export const localeMapper = (localeCode: string) =>
  LOCALE_CODES[localeCode as keyof typeof LOCALE_CODES] ?? localeCode;

export function parseJsonGracefully<T>(data: T) {
  if (typeof data !== 'string') return {};
  try {
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
}

export const jurisdictionCheck = () => {
  const { jurisdiction } = gatekeeper.localisation;
  const isUKGC = equals('UKGC')(jurisdiction);
  const isDGOJ = equals('DGOJ')(jurisdiction);
  const isSGA = equals('SGA')(jurisdiction);
  return { isUKGC, isDGOJ, isSGA };
};

export const INTERPOLATION_REGEX = /{{1}\s*(\w+)\s*}{1}/gm;
export const CURRENCY_INTERPOLATION_REGEX = /{{1}\s*(\w+)\s* \|\s*€\s*}{1}/gm;
export const TEMPLATE_STRING_INTERPOLATION_REGEX = /\${(\w+)}/gm;

export const interpolate = (
  target: string = '',
  replacements: Record<string, string | number>,
  preservePlaceholder = true
) => {
  if (!target) return target;

  const replacer = (match: string, param: string) =>
    propOr<string, typeof replacements, string>(
      preservePlaceholder ? match : '',
      param,
      replacements
    );

  return target
    .replace(INTERPOLATION_REGEX, replacer)
    .replace(CURRENCY_INTERPOLATION_REGEX, replacer)
    .replace(TEMPLATE_STRING_INTERPOLATION_REGEX, replacer);
};

export const interpolateAll = (
  translations: Record<string, string>,
  replacements: Record<string, string | number>,
  preservePlaceholder = true
): Record<string, string> => {
  const interpolatedMessages: Record<string, string> = {};

  for (const key in translations) {
    // eslint-disable-next-line no-prototype-builtins
    if (translations.hasOwnProperty(key)) {
      const target = translations[key];
      interpolatedMessages[key] = interpolate(target, replacements, preservePlaceholder);
    }
  }

  return interpolatedMessages;
};

export const mappedLocaleCode = () => {
  const inLegacyMode = gatekeeper?.mode === 'legacy';
  const { localeCode: rawLocaleCode } = gatekeeper?.localisation;
  const localeCode = inLegacyMode ? (localeMapper(rawLocaleCode) as TLocaleCode) : rawLocaleCode;
  return localeCode;
};

export const getIsValidCurrency = (currency?: string | null) =>
  currency ? Object.values(CURRENCIES).includes(currency as TCurrency) : null;
