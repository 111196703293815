import { useEffect } from 'react';
import {
  UseRegistrationContext,
  UseDevcodeUserDataContext,
} from '../../../contexts';
import {
  GTM_PAYLOAD,
  OPTED_OUT_WELCOME_OFFER_ID,
  ROUTE_NAMES,
} from '../../../constants';
import { setWoGtmEvents, setWoTermsGtmEvent } from '../../../gtm';
import { WelcomeOfferPage } from '../../../pages/welcomeOfferPage';
import { UseGetWelcomeOfferPage } from '../../../hooks';
import { TWelcomeOffer } from 'Src/typedefs';

export const WelcomeOfferContainer = () => {
  const { routeName, setRouteName, setUserValues, setPreviousRouteName } =
    UseRegistrationContext();

  const { devcodeUser } = UseDevcodeUserDataContext();
  const { welcomeOfferPageData, welcomeOffersData } = UseGetWelcomeOfferPage();

  useEffect(() => {
    setPreviousRouteName(null);
  }, []);

  const selectWelcomeOffer = (name: string | null) => {
    const selectedWelcomeOfferName = name ?? OPTED_OUT_WELCOME_OFFER_ID;


    const selectedWelcomeOffer =
      welcomeOffersData?.find(
        (offer) => offer.name?.toString() === selectedWelcomeOfferName
      ) ?? ({ name: OPTED_OUT_WELCOME_OFFER_ID } as TWelcomeOffer);
    setUserValues((userValues) => {
      return {
        ...userValues,
        selectedWelcomeOffer,
      };
    });
    setWoGtmEvents(name);
    setTimeout(() => {
      setRouteName(ROUTE_NAMES.DEPOSIT_AMOUNT);
    }, 200);
  };

  const sendTermsGtmEvent = () => {
    setWoTermsGtmEvent(GTM_PAYLOAD.SELECT_WELCOME_OFFER);
  };

  if (routeName !== ROUTE_NAMES.WELCOME_OFFER) {
    return null;
  }

  return (
    <WelcomeOfferPage
      welcomeOffers={welcomeOffersData}
      welcomeOfferPage={welcomeOfferPageData}
      keycloakUser={devcodeUser}
      selectWelcomeOffer={selectWelcomeOffer}
      sendTermsGtmEvent={sendTermsGtmEvent}
    />
  );
};
