//@ts-expect-error: We know we're a script
const hostOrigin = new URL(document.currentScript?.src).origin;

export type TOnConnection = (
  root: ShadowRoot,
  attributes: Record<string, string>,
  host: string,
  onReady: () => void
) => void;

export type TRootNode<T> = ShadowRoot & { applicationNode?: T };
export type TOnDisconnection<T> = (node: TRootNode<T> | null) => void;

export function createWebComponent<T>(
  name: string,
  onConnection: TOnConnection,
  onDisconnection: TOnDisconnection<T>
) {
  customElements.define(
    name,
    class extends HTMLElement {
      mounted: boolean;
      host: ShadowRoot | null;

      constructor() {
        super();
        this.mounted = false;
        this.host = null;
      }

      connectedCallback() {
        const attributes = this.getAttributeNames().reduce(
          (obj, qualifiedName) => ({
            ...obj,
            [qualifiedName]: this.getAttribute(qualifiedName),
          }),
          {}
        );

        if (!this.mounted) {
          this.mounted = true;
          this.host = this.attachShadow({ mode: "open" });

          onConnection(
            this.host,
            attributes,
            hostOrigin,
            () => this.dispatchEvent(new Event("micro-frontend-attached")) // https://casumo.atlassian.net/browse/PBOB-295
          );
        }
      }
      disconnectedCallback() {
        onDisconnection(this.host);
      }
    }
  );
}

export async function createStylesLink(node: ShadowRoot, href: string) {
  return process.env.STANDALONE
    ? createStandaloneStylesLink(node)
    : createInjectedStylesLink(node, href);
}

export async function createStandaloneStylesLink(node: ShadowRoot) {
  try {
    // @ts-expect-error: we do the try-catch here
    return createInjectedStylesLink(node, node.host.querySelector("link").href);
  } catch (error) {
    console.error(
      "you need to put your tailwind index.css file in the index.html file for standalone development"
    );
  }
}

export async function createInjectedStylesLink(node: ShadowRoot, href: string) {
  await new Promise((resolve, reject) => {
    const styles = document.createElement("link");

    styles.href = href;
    styles.type = "text/css";
    styles.rel = "stylesheet";
    styles.onload = resolve;
    styles.onerror = reject;

    node.appendChild(styles);
  });
}

export async function createApplicationContainer(node: ShadowRoot) {
  const container = document.createElement("main");

  node.appendChild(container);

  return container;
}
