import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  REGISTRATION_FLOW_STEPS,
  GTM_FEATURES,
  GTM_ACTIONS,
} from '../../../constants';
import { getNationalityPageData } from '../../../service/directus/nationality';
import { TErrorMessage } from 'Src/typedefs';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
  getCountryCodeFromCountryName,
} from '../../../utilities';
import { validateNationality } from '../../../validations';
import { getErrorData } from '../../../service/directus/errors';
import { sendGtmEvent, setNationalitySelectorGtmEvent } from '../../../gtm';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { adaptCountryFlags } from 'Src/service/directus/countryFlags/adapters';
import { NationalityPage } from '../../../pages/nationalityPage';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const NationalityContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { registrationFlowsData, errorData, countryFlagsData } =
    UseDirectusContext();
  const { setHeaderTitle } = useHeader();

  const nationalityPageData = getNationalityPageData(registrationFlowsData);
  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData);

  const allErrorData: TErrorMessage[] = getErrorData(errorData);

  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [nationality, setNationality] = useState<string | null>(null);
  const [selectedFlagUuid, setSelectedFlagUuid] = useState<string | null>(null);
  const [nationalityCountryCode, setNationalityCountryCode] = useState<
    string | null
  >(null);
  const [enableNationalitySelector, setEnableNationalitySelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { isFlagDrawerOpen, toggleFlagDrawer } = useDrawer();

  const stepNumber = getStepNumberTranslation('4', '4')?.sv;

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
    storeDataInLocalStorage(ROUTE_NAMES.NATIONALITY, userValues);

    // Read stored data and set it if there is any.
    if (userValues?.nationality) {
      setNationality(userValues?.nationality);
      setSelectedFlagUuid(
        selectedNationality(userValues?.nationality)[0]?.uuid
      );
    } else {
      setNationality(selectedNationality()[0]?.name);
      setSelectedFlagUuid(selectedNationality()[0]?.uuid);
    }

    setHeaderTitle(nationalityPageData.header_title);
  }, []);

  const selectedNationality = (nationality = 'Sweden') => {
    return adaptedCountryFlags.filter(
      (country) => country.name === nationality
      //Once we add more markets this needs to be updated to set the correct default market
    );
  };

  useEffect(() => {
    setNationalityCountryCode(getCountryCodeFromCountryName(nationality));
    //BE and GTM expect country code instead of country name as nationality
  }, [nationality]);

  useEffect(() => {
    if (isFlagDrawerOpen) {
      setNationalitySelectorGtmEvent(REGISTRATION_FLOW_STEPS.NATIONALITY_STEP);
    }
  }, [isFlagDrawerOpen]);

  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          nationalityPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [nationalityPageData]);

  const changeNationality = (countryName: string) => {
    if (!countryName) {
      return;
    }
    const nationality = countryName
      ? countryName
      : selectedNationality()[0].name;
    if (countryName) {
      setNationality(nationality);
      setSelectedFlagUuid(selectedNationality(nationality)[0]?.uuid);

      setError(null);
    }
    toggleFlagDrawer();
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  if (routeName !== ROUTE_NAMES.NATIONALITY) {
    return null;
  }

  const nationalityValidation = () => {
    const validationResult = validateNationality(nationality);
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult.error
      );
      setError(message?.message);
      return false;
    }
    return true;
  };

  const onConfirm = () => {
    if (nationalityValidation()) {
      setUserValues({
        ...userValues,
        nationality,
        nationalityCountryCode,
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.NATIONALITY, {
        nationality: nationalityCountryCode,
      });
      setRouteName(ROUTE_NAMES.MARKETING_CONSENT);
    }
  };

  const dependencyArray = [isFlagDrawerOpen, nationality];
  UseHandleKeyDown(dependencyArray, !isFlagDrawerOpen, onConfirm);

  return (
    <NationalityPage
      description={description}
      pageData={nationalityPageData}
      selectedFlagUuid={selectedFlagUuid}
      nationality={nationality}
      openCountrySelector={() => {
        setEnableNationalitySelector(true);
        toggleFlagDrawer();
      }}
      onConfirm={onConfirm}
      error={error}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enableNationalitySelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      changeValue={changeSearchValue}
      searchValue={searchValue}
      setNationality={changeNationality}
      userValues={userValues}
      stepNumber={stepNumber}
    />
  );
};
