import React from 'react';
import { Span } from './span';
import { TEST_ID } from 'Src/constants';

type ToggleProps = {
  handleChange: (key: string, value: boolean) => void;
  isActive?: boolean;
  name: string;
  label: string;
};

const ToggleSwitch: React.FC<ToggleProps> = ({
  label,
  isActive = false,
  handleChange,
  name,
}) => {
  const handleClick = () => handleChange(name, !isActive);
  return (
    <div
      className="toggle-container cursor-pointer"
      onClick={handleClick}
      data-testid={TEST_ID.TOGGLE + name}
    >
      <Span className="text-[20px] text-white" testID={TEST_ID.TITLE + name}>{label}</Span>
      <div className={`toggle-switch ${isActive ? 'active' : ''}`}>
        <div className="toggle-knob" />
      </div>
    </div>
  );
};

export default ToggleSwitch;
