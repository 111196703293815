import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

interface Props {
  type?: 'submit' | 'reset' | 'button' | undefined;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: string;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  disableRounded?: boolean;
  style?: object;
  testID?: string;
}

export const Button: React.FC<Props> = (props) => {
  const {
    type = 'button',
    className = '',
    onClick,
    children,
    size = 'md',
    disabled = false,
    disableRounded = false,
    style,
    testID,
  } = props;

  const styleSizes = {
    sm: ['py-sm', 'px-2xs'],
    md: ['p-sm', 'px-ls'],
    full: ['p-0'],
  };

  const btnClass = classNames(
    'inline-block',
    'text-center',
    'align-middle',
    'select-none',
    { 'rounded-full': !disableRounded },
    { 'bg-grey': disabled },
    ...styleSizes[size as keyof typeof styleSizes],
    className
  );
  return (
    <button
      type={type}
      disabled={disabled}
      className={btnClass}
      onClick={onClick}
      style={style}
      data-testid={testID}
    >
      {children}
    </button>
  );
};
