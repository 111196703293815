import { Flex, Image, Span } from '../../atoms';
import CloseDrawer from '../../assets/svgs/closeDrawer.svg';

type Props = {
  onClose: () => void;
  title: string;
  enableCloseButton?: boolean;
};

export const DrawerHeader = ({ onClose, title, enableCloseButton = false }: Props) => {
  return (
    <Flex className="w-full font-bold items-between">
      <Flex direction="flex-row justify-between items-center">
        <Span className="text-h-lg">{title}</Span>
        {enableCloseButton && (
          <Image
            className="m-xs w-md h-md cursor-pointer"
            src={CloseDrawer}
            onClick={onClose}
            title={'close'}
            alt={'close'}
          />
        )}
      </Flex>
    </Flex>
  );
};
