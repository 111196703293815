import { TMarketingConsentPage } from '../../typedefs';
import {
  Button,
  DangerousHtml,
  Flex,
  Image,
  SpinnerIcon,
  Text,
} from 'Src/atoms';
import { DisableLayout } from 'Src/components';
import { TEST_ID } from 'Src/constants';

type Props = {
  marketingConsentPageData: TMarketingConsentPage;
  onConfirm: (value: boolean) => void;
  isLoader: boolean;
};

export const MarketingConsentComponent = ({
  marketingConsentPageData,
  onConfirm,
  isLoader,
}: Props) => {
  return (
    <Flex className="h-full w-full bg-[#FFE600]">
      <Image
        className={'w-full tablet:h-full object-cover absolute'}
        src={marketingConsentPageData?.image?.src}
      />
      <Flex className="flex flex-col items-center h-full relative py-3xl px-sm tablet:px-md text-center">
        <DangerousHtml
          className="text-[#6825F5] text-[90px] leading-[70px] tracking-[-0.5px] font-['Midnight_Pro'] uppercase mb-sm font-black tablet:px-md"
          html={marketingConsentPageData?.description}
        />
        <DangerousHtml
          className="text-black text-sm mt-md pb-[100px] tablet:text-base tablet:px-3xl"
          html={marketingConsentPageData?.message}
          testID={TEST_ID.DESCRIPTION}
        />
      </Flex>
      <Flex className="w-full px-md tablet:px-2xl flex gap-xs bottom-fixed-button">
        <Flex className="w-full">
          <Button
            size="full"
            className="font-bold py-md text-purple-60 left-0 w-full"
            onClick={() => onConfirm(false)}
            testID={TEST_ID.BUTTON_SECONDARY}
          >
            {marketingConsentPageData?.btnLater}
          </Button>
        </Flex>
        <Flex className="mb-md">
          <Flex className="transition-all select-none active:pt-[6px]">
            <fabric-registration-button
              id="btnAccountDetails"
              primary
              onClick={() => onConfirm(true)}
              data-testid={TEST_ID.BUTTON_PRIMARY}
            >
              {marketingConsentPageData?.btnContinue || 'Continue'}
            </fabric-registration-button>
          </Flex>
        </Flex>
      </Flex>

      {isLoader && (
        <DisableLayout isOpen={isLoader}>
          <Flex className=" w-full h-full bg-white justify-center items-center">
            <SpinnerIcon width="48" height="48" className="animate-spin" />
            <Flex className="text-sm font-bold mt-sm">
              {marketingConsentPageData?.loaderText || 'Loading'}
            </Flex>
          </Flex>
        </DisableLayout>
      )}
    </Flex>
  );
};
