import { FC, PropsWithChildren } from 'react';

const ModalContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="fixed inset-0 z-[60] flex h-[100vh] w-[100vw] justify-center bg-opacity-50 tablet:items-end">
            <div
                className="absolute inset-0 h-[100vh] w-[100vw] bg-black bg-opacity-50 backdrop-blur-sm"
                aria-label="overlay"
            />
            <div className="relative animate-fade-in">
                {children}
            </div>
        </div>
    );
};

export default ModalContainer;
