import { TAddressPage } from '../../../typedefs';
import { adaptAddressPage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getAddressPageData = (registrationFlowsData: unknown) => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.ADDRESS
  );

  const addressPage: TAddressPage = adaptAddressPage(data);
  return addressPage;
};
