import { Flex, Image, Span } from '../../atoms';
import CloseDrawer from '../../assets/svgs/closeDrawer.svg';
type Props = {
  onClose: () => void;
  title: string;
  titleSize: string;
};

export const DrawerHeader = ({ onClose, title, titleSize }: Props) => {
  return (
    <Flex
      direction="flex-row"
      className={`${titleSize} bg-purple-90 items-center justify-between`}
    >
      <Span>{title}</Span>
      <Flex className={'w-md h-md cursor-pointer'}>
        <Image
          src={CloseDrawer}
          width="full"
          onClick={onClose}
          title={'close'}
          alt={'close'}
        />
      </Flex>
    </Flex>
  );
};
