import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children?: JSX.Element | JSX.Element[] | string | number;
  htmlFor?: string;
}

export const Label: React.FC<Props> = (props) => {
  const { className = [], children, htmlFor } = props;
  const labelClass = classNames(className);

  return (
    <label className={labelClass} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
