import classNames from 'classnames';
import { useDrawer } from '../../contexts';
import { Flex } from '../../atoms';

type Props = {
  children?: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
  toggleDrawer?: () => void;
};

/*
 The main goal of this component is display an overlay over the mobile modal (including the header) when the drawer is opened
 @param {JSX.Element} children - the children components that will be rendered.
 @param {boolean} isOpen - the state that will be used to determine if the layout should be disabled or not.
 @returns {JSX.Element} that wraps the children components.
*/

export const Overlay = ({ children, isOpen, toggleDrawer }: Props) => {
  const { isAnyDrawerOpen } = useDrawer();
  return (
    <Flex
      className={classNames({
        overlay: isOpen,
        'fixed inset-0 min-h-[100vh] min-w-[100vw] z-[40] bg-black bg-opacity-50 backdrop-blur-sm': isAnyDrawerOpen,
      })}
      onClick={toggleDrawer}
    >
      {children}
    </Flex>
  );
};
