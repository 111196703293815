import { UseDirectusContext } from '../../contexts';
import { gatekeeper } from '../../gatekeeper';
import { useEffect, useState } from 'react';
import {
  getRegistrationFlowsData,
  getTermsAndConditionsData,
  getErrorData,
  getWelcomeOffersData,
  getCountryFlagsData,
} from '../../service/directus';

export const useGetDirectusData = () => {
  const language = gatekeeper.localisation.localeCode;
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const {
    setRegistrationFlowsData,
    setTermsData,
    setCountryFlagsData,
    setErrorData,
    setWelcomeOffersData,
  } = UseDirectusContext();

  useEffect(() => {
    Promise.all([
      getRegistrationFlowsData().then((response) => {
        setRegistrationFlowsData(response);
      }),
      getTermsAndConditionsData().then((response) => {
        setTermsData(response);
      }),
      getCountryFlagsData().then((response) => {
        setCountryFlagsData(response);
      }),
      getErrorData().then((response) => {
        setErrorData(response);
      }),
      getWelcomeOffersData(language).then((response) => {
        setWelcomeOffersData(response);
      }),
    ]).then(() => {
      setIsDataReady(true);
    });
  }, []);

  return { isDataReady };
};
