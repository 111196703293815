export const GOOGLE_ADDRESS_COMPONENT_NAMES = Object.freeze({
  HOUSE_NUMBER: 'street_number',
  STREET_NAME: 'route',
  CITY: 'locality',
  TOWN: 'postal_town',
  COUNTRY: 'country',
  POST_CODE: 'postal_code',
  PROVINCE: 'administrative_area_level_1',
});

export const ACCEPTABLE_POSTCODE_RESULTS = Object.freeze([
  'UNCONFIRMED_BUT_PLAUSIBLE',
  'CONFIRMED',
]);

export const GOOGLE_ADDRESS_VALIDATOR_COUNTRY_COVERAGE = Object.freeze([
  'AR',
  'AU',
  'AT',
  'BE',
  'BR',
  'BG',
  'CA',
  'CL',
  'CO',
  'HR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MY',
  'MX',
  'NL',
  'NO',
  'NZ',
  'PL',
  'PT',
  'PR',
  'SG',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'GB',
  'US',
]);
