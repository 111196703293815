import { TWelcomeOfferPage } from '../../../typedefs';
import { adaptWelcomeOfferPage } from './adapter';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getWelcomeOfferPageData = (
  registrationFlowsData: unknown
): TWelcomeOfferPage => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.WELCOME_OFFER_STEP
  );

  const welcomeOfferPage = adaptWelcomeOfferPage(data);
  return welcomeOfferPage;
};
