import { TEST_ID } from 'Src/constants';
import { TWelcomeOffer } from '../../typedefs';
import ValuableThumbnail from 'Components/valuableThumbnail';

type Props = {
  welcomeOffer: TWelcomeOffer;
  onClick: (id: string) => void;
  selectBtnText: string
};

export const WelcomeOffer = ({ welcomeOffer, onClick, selectBtnText }: Props) => {
  return (
    <div
      id={welcomeOffer?.name}
      className="flex flex-col rounded-2xl"
    >
      <div className="relative w-full">
        <ValuableThumbnail
          testId="available-bonus-widget-child-thumbnail-icon"
          welcomeOffer={welcomeOffer}
          className="min-h-[290px] tablet:min-h-auto rounded-2xl"
        />
        <div className="flex flex-col justify-between h-full items-center text-center p-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
          <div className='bg-[#FFE600] text-purple-60 text-xs font-bold rounded-2xl px-md py-3xs'>
            {welcomeOffer.title}
          </div>

          <div
            className="font-['Midnight_Pro'] font-extrabold text-[38px] leading-[34px] tablet:leading-[44px] tablet:text-[50px] uppercase text-white"
          >
            {welcomeOffer?.shortDesc}
          </div>
          <div className='min-w-full tablet:min-w-[350px]'>
            <fabric-registration-button
              onClick={() => onClick(welcomeOffer.name)}
              id="btnAccountDetails"
              primary
              data-testid={TEST_ID.PREFIX + welcomeOffer?.name}
            >
              {selectBtnText || "Select offer"}
            </fabric-registration-button>
          </div>
        </div>
      </div>
    </div>
  );
};
