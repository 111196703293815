import { useCallback } from 'react';
import { VALUABLE_STATE, VALUABLE_TYPE, LOCK_TYPE } from 'Services';
import { type TRewardEnrichedWithRR } from 'Services/rewards/rewardApi.types';
import { useMakeUseOfRewardMutation } from 'Services/rewards/rewardsApi';
import { log } from 'Utils';

export const useActivateValuable = () => {
  const [maeUseOfValuable, { isSuccess, isLoading }] = useMakeUseOfRewardMutation();

  const safeExecute = (cb?: () => void) => {
    typeof cb === 'function' && cb();
  };

  const onUseValuable = useCallback(
    async (
      valuable: TRewardEnrichedWithRR,
      successCallback?: () => void,
      errorCallback?: () => void
    ) => {
      const rewardType = valuable?.reward?.type;
      const isWageringLocked =
        valuable.lock?.type &&
        [LOCK_TYPE.GAME_WAGERING, LOCK_TYPE.WAGERING, LOCK_TYPE.GAME_CATEGORY_WAGERING].includes(
          valuable.lock?.type as any
        );

      if (rewardType === VALUABLE_TYPE.KAMBI_OFFER) {
        safeExecute(successCallback);
        return;
      }

      if (
        (valuable?.badgeId && valuable?.rewardState === VALUABLE_STATE.FRESH) ||
        Boolean(valuable?.lock?.type)
      ) {
        try {
          await maeUseOfValuable({ badgeId: valuable.badgeId });

          safeExecute(successCallback);
        } catch (error) {
          safeExecute(successCallback);
          safeExecute(errorCallback);

          log({
            level: 'debug',
            message: `Error activating valuable with badgeID: ${valuable.badgeId}`,
          });
        }
      }
      // continue with normal flow after being used, e.g redirect to deposit
      if (valuable.rewardState === VALUABLE_STATE.USED) {
        safeExecute(successCallback);

        if (isWageringLocked) {
          await maeUseOfValuable({ badgeId: valuable.badgeId });
        }
      }
    },
    [maeUseOfValuable]
  );

  return { onUseValuable, isSuccess, isLoading };
};
