import { useEffect, useState } from 'react';
import {
  UseDirectusContext,
  UseRegistrationContext,
  useHeader,
} from '../../../contexts';
import {
  UseGetDepositLimitsData,
  UseGetDepositLimitsPage,
} from '../../../hooks';
import {
  ROUTE_NAMES,
  LIMIT_TYPES,
  DEFAULT_MAX_LIMIT,
  GTM_PAYLOAD,
  GTM_ACTIONS,
  GTM_FEATURES,
  REGISTRATION_FLOW_STEPS,
  GTM_PARAMS,
} from '../../../constants';
import {
  notValidIntegerValue,
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
  getJurisdiction,
} from '../../../utilities';
import { mgaGraDepositLimitsValidationResult } from '../../../validations';
import { TErrorMessage, TOptionRadioBtton } from '../../../typedefs';
import {
  sendGtmEvent,
  setAlertGtmEvent,
  setLimitLaterGtmEvent,
  setShowPrivacyPolicyGtmEvent,
  setTermsGtmEvent,
} from '../../../gtm';
import { getErrorData } from '../../../service/directus/errors';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { CURRENCY_BY_COUNTRY_CODE } from 'Src/currencyByCountryCode';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';
import { DepositLimitsPageMgaGra } from 'Pages/depositLimitsPageMgaGra';
import { gatekeeper } from 'Src/gatekeeper';

export const DepositLimitsContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();
  const limits = userValues?.depositLimits;

  const { setHeaderTitle } = useHeader();
  const currency: string =
    CURRENCY_BY_COUNTRY_CODE[userValues?.countryOfResidenceCountryCode] ||
    'EUR';

  const { limitsData } = UseGetDepositLimitsData(
    currency,
    getJurisdiction(gatekeeper.localisation.marketSlug)
  );
  const { errorData } = UseDirectusContext();
  const allErrorData = getErrorData(errorData);

  const { depositLimitsPageData } = UseGetDepositLimitsPage();

  const [depositType, setDepositType] = useState<string | null>(null);
  const [despositLimitList, setDespositLimitList] = useState<
    TOptionRadioBtton[]
  >([]);
  const [depositLimit, setDepositLimit] = useState<number | null>(null);
  const [depositLimitError, setDepositLimitError] = useState<string | null>(
    null
  );
  const [description, setDescription] = useState<string | null>(null);
  const stepNumber =
    getStepNumberTranslation('5', '5')[gatekeeper.localisation.marketSlug] ||
    getStepNumberTranslation('5', '5')?.default;

  useEffect(() => {
    // Modify this when previous route is implemented
    setPreviousRouteName(ROUTE_NAMES.BIRTH_DETAILS);
    storeDataInLocalStorage(ROUTE_NAMES.LIMITS, userValues);

    // Read stored data and set it if there is any.
    if (limits && Object.keys(limits).length > 0) {
      setDepositLimit(limits.depositLimit);
      setDepositType(limits.depositType);
    } else {
      setDepositType(LIMIT_TYPES.DAILY);
    }

    setHeaderTitle(depositLimitsPageData?.header_title);
  }, []);

  useEffect(() => {
    setHeaderTitle(depositLimitsPageData?.header_title);
    setDespositLimitList([
      { value: LIMIT_TYPES.DAILY, label: depositLimitsPageData?.dailyText },
      { value: LIMIT_TYPES.WEEKLY, label: depositLimitsPageData?.weeklyText },
      { value: LIMIT_TYPES.MONTHLY, label: depositLimitsPageData?.monthlyText },
    ]);
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          depositLimitsPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [depositLimitsPageData]);

  const changeAmount = (value, limitType) => {
    if (limitType === LIMIT_TYPES.TYPE) {
      setDepositType(value || null);
      setDepositLimitError(null);
      setDepositLimit(null);
    }
    if (notValidIntegerValue(value, DEFAULT_MAX_LIMIT)) {
      return;
    }
    if (limitType === LIMIT_TYPES.LIMIT) {
      setDepositLimitError(null);
      setDepositLimit(Number(value) || null);
    }
  };

  const clearLimit = (limitType) => {
    if (limitType === LIMIT_TYPES.LIMIT) {
      setDepositLimitError(null);
      setDepositLimit(null);
    }
  };

  const setErrorMessages = (validationResult, onFinalValidation) => {
    const depositLimitSuccess = validationResult?.depositLimitResult?.success;
    if (
      (depositLimit && !depositLimitSuccess) ||
      (onFinalValidation && !depositLimitSuccess)
    ) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.depositLimitResult?.error
      );
      setDepositLimitError(
        `${message?.message} ${validationResult?.depositLimitResult?.value}`
      );
      setAlertGtmEvent(validationResult?.dailyResult?.error);
    } else {
      setDepositLimitError(null);
    }
  };

  const validateLimits = (onFinalValidation) => {
    const validationResult = mgaGraDepositLimitsValidationResult(
      limitsData,
      depositLimit,
      depositType
    );

    if (!validationResult) {
      return false;
    }

    setErrorMessages(validationResult, onFinalValidation);

    return validationResult?.depositLimitResult?.success;
  };

  const onConfirm = () => {
    if (validateLimits(true)) {
      const depositLimits = {
        depositLimit: depositLimit,
        depositType: depositType,
      };
      setUserValues({
        ...userValues,
        depositLimits,
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.LIMITS, {
        depositLimit: depositLimit,
        depositType: depositType,
        step: REGISTRATION_FLOW_STEPS.DEPOSIT_STEP,
        [GTM_PARAMS.DEPOSIT_LIMIT_DEFINED]: GTM_PAYLOAD.TRUE,
      });
      setRouteName(ROUTE_NAMES.MARKETING_CONSENT);
    }
  };

  const dependencyArray = [limitsData, onConfirm, depositLimit];
  UseHandleKeyDown(dependencyArray, true, onConfirm);

  const sendTermsGtmEvent = () => {
    setTermsGtmEvent(GTM_PAYLOAD.LIMIT_STEP);
  };

  const sendPrivacyPolicyGtmEvent = () => {
    setShowPrivacyPolicyGtmEvent(GTM_PAYLOAD.LIMIT_STEP);
  };

  if (routeName !== ROUTE_NAMES.LIMITS) {
    return null;
  }

  const setUpLaterClick = () => {
    setLimitLaterGtmEvent();
    setUserValues({
      ...userValues,
      depositLimits: null,
      depositAmount: null,
      currency: currency,
      bonusAmount: null,
      paymentMethodId: null,
      paymentMethodName: null,
    });
    setRouteName(ROUTE_NAMES.MARKETING_CONSENT);
  };

  return (
    <DepositLimitsPageMgaGra
      userValues={userValues}
      description={description}
      depositLimitsPageData={depositLimitsPageData}
      changeAmount={changeAmount}
      clearLimit={clearLimit}
      validateLimits={validateLimits}
      depositLimit={depositLimit}
      depositType={depositType}
      despositLimitList={despositLimitList}
      depositLimitError={depositLimitError}
      onConfirm={onConfirm}
      termsGTM={sendTermsGtmEvent}
      privacyPolicyGTM={sendPrivacyPolicyGtmEvent}
      stepNumber={stepNumber}
      isMgaGra
      setUpLaterClick={setUpLaterClick}
      currency={currency}
    />
  );
};
