import { gatekeeper } from '../../../gatekeeper';
import { DEFAULT_LANGUAGE } from '../../../constants';
import { TDirectusError, TErrorMessage } from '../../../typedefs';

export const adaptErrors = (data: TDirectusError[]) => {
  const language = gatekeeper.localisation.localeCode || DEFAULT_LANGUAGE;
  const result = data?.map((error: TDirectusError) => {
    const translation = error?.translations?.find(
      (translation) => translation?.languages_code?.code === language
    );
    const value: TErrorMessage = {
      errorName: error.key,
      title: translation?.title,
      message: translation?.message,
      buttonText: translation?.btn_main_text,
      buttonSecondaryText: translation?.btn_secondary_text,
    };
    return value;
  });
  return result;
};
