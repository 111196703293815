import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { TEST_ID } from 'Src/constants';
import useScrollIntoViewOnError from 'Src/hooks/useScrollIntoViewOnError';

const Checkbox = ({ checked, handleClick, name, isError }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useScrollIntoViewOnError(isError, inputRef);

  return (
    <label className="checkbox-container">
      <input
        ref={inputRef}
        name={name}
        type="checkbox"
        checked={!!checked}
        onChange={(event) => handleClick(name, event.target.checked)}
        data-testid={TEST_ID.CHECKBOX + name}
      />
      <span
        className={classNames('checkmark ', { 'checkmark-error': isError })}
      />
    </label>
  );
};

export default Checkbox;
