import {
  CASHIER_URL_PARAMS,
  CASUMO_REGISTRATION_ENABLED_MARKETS,
  CASUMO_TEST_REGISTRATION_ENABLED_MARKETS,
  COOKIE_NAMES,
  DEFAULT_ERROR,
  GRA_MARKETS,
  JURISDICTION,
  MARKETS,
  MGA_MARKETS,
  REGILY_COUNTRY_REV,
  REGILY_REMAINING_COUNTRIES_MAP,
  REGISTRATION_ERRORS,
  ROUTE_IDS,
  SGA_MARKETS,
  UKGC_MARKETS,
} from './constants';
import {
  DateFormat,
  TErrorMessage,
  TFlags,
  TKeycloakUser,
  TPredictions,
  TRegistrationUserDetails,
  TTerm,
  TWelcomeOffer,
} from './typedefs';
import { validateAge } from './validations';
import { COUNTRY_NAMES_BY_CODE, PREFIXES } from './countryCodesAndPrefixes';
import { gatekeeper } from './gatekeeper';
import { BONUS_TYPES } from './components/valuableThumbnail/valuableThumbnailConstants';
import { IMGIX_URL } from '../env.constants';
import { readCookie, storeInCookie } from './cookies';

const isFalsyValue = (value: any) =>
  value === null ||
  value === undefined ||
  // eslint-disable-next-line no-extra-boolean-cast
  !Boolean(value) ||
  value?.length === 0;

export const fabricizeProps = <T>(props: T | any): T => {
  const keys = Object.keys(props);
  return keys.reduce(
    (acc, key) => ({
      ...acc,
      ...(isFalsyValue(props[key]) ? {} : { [key]: props[key] }),
    }),
    {}
  ) as T;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const onRetry = () => {
  deleteCookieByName(COOKIE_NAMES.ERROR_NAME);
  if (window) {
    window.location.reload();
  }
};

/* 
This funtion is resposible to open the support chat.
@returns undefined. Triggers spine event to open support chat
*/
export const openSupportChat = () => {
  gatekeeper?.messageBus?.publish('support', { kind: 'open' });
};

export const depositBonusCalculator = (
  depositAmount: number,
  percentage: number
) => {
  return (depositAmount * percentage) / 100;
};

export const clearDataInLocalStorage = () => {
  try {
    localStorage?.removeItem('data');
  } catch (error) {
    console?.error('Error clearing data in localStorage:', error);
  }
};

export const storeDataInLocalStorage = (routeName, userValues) => {
  try {
    const data = {
      routeName,
      ...userValues,
    };

    localStorage.setItem('data', JSON.stringify(data));
  } catch (error) {
    console.error('Error storing data in localStorage:', error);
  }
};

export const getStoredItem = () => {
  try {
    const storedData = localStorage.getItem('data');
    const data = storedData ? JSON.parse(storedData) : null;

    if (!data) {
      return { routeName: null, userValues: null };
    }

    const { routeName, ...userValues } = data;
    return {
      routeName,
      userValues,
    };
  } catch (error) {
    console.error('Error retrieving data from localStorage:', error);
    return {
      routeName: null,
      userValues: null,
    };
  }
};

export const notValidIntegerValue = (value, maxAmount) => {
  return isNaN(value) || value > maxAmount || !Number.isInteger(Number(value));
};

export const selectRegistrationError = (user: TKeycloakUser, setError) => {
  if (!validateAge(user?.birthdate)) {
    setError(REGISTRATION_ERRORS.UNDERAGE);
    return;
  }

  // Check address and return error if one of the lines are missing.
  if (
    !user?.address_is_verified ||
    !user?.address_street_name ||
    !user?.address_postal_code ||
    !user?.address_country ||
    !user?.address_city
  ) {
    setError(REGISTRATION_ERRORS.ADDRESS_NOT_FOUND);
    return;
  }
};
export const getErrorMessage = (
  errorData: TErrorMessage[],
  errorName: string
): TErrorMessage | undefined => {
  const message = errorData?.find(
    (message) => message?.errorName === errorName
  );

  if (message?.message) {
    return message;
  }

  const unknownErrorMessage = errorData?.find(
    (message) => message?.errorName === REGISTRATION_ERRORS.UNKNOWN
  );

  return unknownErrorMessage?.message ? unknownErrorMessage : DEFAULT_ERROR;
};

export const creatPhonePrefix = (key: string) => {
  // eslint-disable-next-line fp/no-let
  let prefixFromObj = PREFIXES[key];

  //In some cases one country can have two prefixes.
  if (prefixFromObj?.includes('and')) {
    const numberArray = prefixFromObj.split('and');
    // eslint-disable-next-line fp/no-mutation
    prefixFromObj = numberArray[0]; //Here we return the first and deal with the second at another place.
  }
  if (!prefixFromObj?.includes('+')) {
    // eslint-disable-next-line fp/no-mutation
    prefixFromObj = `+${prefixFromObj}`;
  }
  return `\u00A0(${prefixFromObj})`; // \u00A0 is for space unicode.
};

export const isEmpty = (val: any) =>
  val == null || !(Object.keys(val) || val).length || val === 'undefined';

export function getAssetURL(id: string) {
  if (!isEmpty(id)) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${IMGIX_URL}/${id}?auto=compress,format`;
  }

  return undefined;
}

export const createCashierURL = (
  amount: number,
  paymentMethodId: string,
  payAndPlayId?: string
) => {
  if (paymentMethodId) {
    const path = `${gatekeeper?.navigation?.getRoute(
      ROUTE_IDS.PAYMENTS_PAY_AND_PLAY
    )}?`;

    const paramsArray = [
      [CASHIER_URL_PARAMS.PAY_AND_PLAY, 'true'],
      [CASHIER_URL_PARAMS.AMOUNT, amount?.toString()],
      [CASHIER_URL_PARAMS.PAYMENT_METHOD_ID, paymentMethodId],
      payAndPlayId && [CASHIER_URL_PARAMS.PAY_AND_PLAY_ID, payAndPlayId],
    ].filter(Boolean);

    const params = new URLSearchParams(paramsArray).toString();

    return `${path}${params}`;
  }
  return null;
};

export const getBonusMessage = (
  message: string,
  depositAmount: number,
  bonusAmount: number | string,
  currency: string
) => {
  if (!message) {
    return '';
  }
  const regexCurrency = new RegExp('{{currency}}', 'gi'); // The simple string solution didn't work.
  return message
    ?.replace('{{deposit_amount}}', depositAmount?.toString())
    ?.replace(regexCurrency, currency)
    ?.replace('{{bonus_amount}}', bonusAmount?.toString());
};

export const getPaymentWoDescription = (
  welcomeOffer: TWelcomeOffer,
  defaultBonusMessage: string,
  bonusMessage: string,
  depositAmount: number,
  currency: string
) => {
  if (!welcomeOffer || !bonusMessage) {
    return '';
  }
  if (welcomeOffer?.type === BONUS_TYPES?.SPORTS_DEPOSIT_BONUS) {
    return welcomeOffer?.title;
  }

  if (!depositAmount) {
    return getBonusMessage(
      bonusMessage,
      welcomeOffer.maxBonusAmount,
      `${welcomeOffer.percentage}%`,
      currency
    );
  }

  const bonus = depositBonusCalculator(depositAmount, welcomeOffer.percentage);

  return bonus <= welcomeOffer.maxBonusAmount
    ? getBonusMessage(defaultBonusMessage, depositAmount, bonus, currency)
    : getBonusMessage(
      defaultBonusMessage,
      depositAmount,
      welcomeOffer.maxBonusAmount,
      currency
    );
};

export const formatMoney = (
  amount: number,
  currency: string = 'SEK',
  lang: string
): string => {
  const formattedAmount = Intl.NumberFormat(lang, {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  }).format(amount);
  return formattedAmount;
};

export const mergeWelcomeOffersTerms = (
  welcomeOffers: TWelcomeOffer[],
  bonusTerms: TTerm
) => {
  // eslint-disable-next-line fp/no-let
  let textContent = '';
  welcomeOffers?.forEach((offer) => {
    // eslint-disable-next-line fp/no-mutation
    textContent = textContent + offer.fullTerms + '<br>';
  });
  // eslint-disable-next-line fp/no-mutation
  return textContent + bonusTerms?.description;
};

export const replaceFirstName = (message, playerName) => {
  return message?.replace(' {{firstname}}', ` ${playerName || ''}`);
};

export const randomNumberGenerator = (max: number, min?: number) => {
  min = Math.ceil(min) || 0;
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomStringGenerator = (length: number) => {
  const stringPool = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const character = stringPool[randomNumberGenerator(stringPool.length - 1)];
    result += character;
  }

  return result;
};

export const getCookieByName = (name: string) => {
  if (window) {
    const value = `; ${document?.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
};

export const deleteCookieByName = (cookieName: string) => {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export const extractNativeEventDataFromCookie = (cookie: string | undefined | null) => {
  if (cookie) {
    const parts = cookie.split(',');

    const result = {
      playerId: parts[0]?.trim(),
      welcomeOfferId: parts[1]?.trim()
    };
    return result;
  }
  return null;
};

export const capitalize = (text: string) => {
  if (text) {
    return text[0]?.toUpperCase() + text.slice(1)?.toLowerCase();
  }
};

export const areAllFieldsPresent = (obj: any) => {
  return Object.values(obj).every((value) => value);
};

export const sortCountries = (a: TFlags, b: TFlags, value: string) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  const aStartsWith = aName.startsWith(value);
  const bStartsWith = bName.startsWith(value);

  const aIncludes = aName.includes(value);
  const bIncludes = bName.includes(value);

  if (aStartsWith && !bStartsWith) return -1;
  if (!aStartsWith && bStartsWith) return 1;
  if (aIncludes && !bIncludes) return -1;
  if (!aIncludes && bIncludes) return 1;

  return 0;
};

export const loginUserAndRedirectToPayment = async (
  userValues: TRegistrationUserDetails,
  isNative: boolean
) => {
  const cashierUrl = createCashierURL(
    userValues?.depositAmount,
    userValues?.paymentMethodId,
    userValues?.payAndPlayId
  );

  if (isNative) {
    storeInCookie(
      COOKIE_NAMES.NATIVE_PAY_AND_PLAY,
      'native_pay_and_play',
      cashierUrl,
      {},
      { simpleString: true }
    );
    await gatekeeper.auth.logIn({});
    return;
  }

  await gatekeeper.auth.logIn({ redirectUrl: cashierUrl });
};


export const getJurisdiction = (marketSlug: string): string => {
  if (MGA_MARKETS.includes(marketSlug)) {
    return JURISDICTION.MGA;
  } else if (GRA_MARKETS.includes(marketSlug)) {
    return JURISDICTION.GRA;
  } else if (SGA_MARKETS.includes(marketSlug)) {
    return JURISDICTION.SGA;
  } else if (UKGC_MARKETS.includes(marketSlug)) {
    return JURISDICTION.UKGC;
  }
};

export const getDateFormat = () => {
  switch (gatekeeper.localisation.marketSlug) {
    case MARKETS.ENCA:
      return DateFormat.yyyy_MM_dd;
    case MARKETS.FI:
    case MARKETS.NO:
      return DateFormat.ddMMyyyy;
    case MARKETS.SV:
      return DateFormat.yyyy_mm_dd;
    default:
      return DateFormat.dd_MM_yyyy;
  }
};

export const getStepNumberTranslation = (
  number: string,
  totalNumber: string
) => {
  return {
    default: `Step ${number} of ${totalNumber}`,
    en: `Step ${number} of ${totalNumber}`,
    sv: `Steg ${number} av ${totalNumber}`,
    fi: `Askel ${number} / ${totalNumber}`,
  };
};

export const isRegistrationEnabled = (): boolean => {
  const testRegisterEnabled = new URLSearchParams(window.location.search).get('testRegisterEnabled') === 'true';
  return (
    (CASUMO_REGISTRATION_ENABLED_MARKETS).includes(gatekeeper.localisation.marketSlug) ||
    ((CASUMO_TEST_REGISTRATION_ENABLED_MARKETS).includes(gatekeeper.localisation.marketSlug) && testRegisterEnabled)
  );
};

export const redirectPlayerToCorrectMarket = async (
  selectedCountryCode: string,
  selectedProvinceCode?: string
) => {
  if (!selectedCountryCode || selectedCountryCode === 'ES') {
    return false;
  }
  let isPlayerRedirected = false;
  const market = gatekeeper.localisation.marketSlug;
  const selectedCountry = selectedCountryCode.toLowerCase();
  const selectedProvince = selectedProvinceCode?.toLowerCase();

  let newMarket = '';
  if (
    // Handle exception for canada ontario.
    selectedCountry === 'ca' &&
    selectedProvince === 'on'
  ) {
    newMarket = 'on';
  } else {
    // Default new market selection.
    newMarket =
      REGILY_COUNTRY_REV[selectedCountry] ||
      REGILY_REMAINING_COUNTRIES_MAP[selectedCountry] ||
      MARKETS.EN;
  }

  if (newMarket && market !== newMarket) {
    if (isRegistrationEnabled) {
      window.location.href = `/${newMarket}${location.search}`;
    } else {
      window.location.href = `/${newMarket}/register${location.search}`;
    }
    isPlayerRedirected = true;
  }
  return isPlayerRedirected;
};

export const getCountryDataFromCookie = (adaptedCountryFlags: TFlags[]) => {
  const kazumoKeycloakCookie: { country: string; region: string } = readCookie(
    COOKIE_NAMES.KAZUMOKEYCLOAK
  );
  const countryCode = kazumoKeycloakCookie?.country || '';
  const countryName = COUNTRY_NAMES_BY_CODE[countryCode]?.toLowerCase();
  const userCountryName = adaptedCountryFlags?.find(
    (country) => country.name?.toLowerCase() === countryName
  )?.name;
  const userRegionName = kazumoKeycloakCookie?.region || '';

  return { userRegionName, userCountryName };
};
export const getCountryCodeFromCountryName = (countryName) => {
  if (countryName?.toLowerCase()?.includes('ontario')) {
    return 'ON';
  }
  return Object.keys(COUNTRY_NAMES_BY_CODE).find(
    (key) => COUNTRY_NAMES_BY_CODE[key] === countryName
  );
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const setRegistrationStartNativeEvents = () => {
  gatekeeper?.messageBus?.publish('native', {
    kind: 'appLoaded',
    isLoggedIn: false,
    id: ''
  });

  gatekeeper?.messageBus?.publish('native', {
    kind: 'onRouteLoaded',
    isLoggedIn: false,
    id: window?.location?.pathname
  });
};

export const setRegistrationEndNativeEvents = (
  woId: string,
  userId: string
) => {
  storeInCookie(COOKIE_NAMES.REGISTRATIONFORM, 'welcomeOfferId', woId); // This is necessary to trigger LIS playerLogin event.

  gatekeeper?.messageBus?.publish('native', {
    kind: 'playerRegistered',
    selectedWelcomeOfferId: woId,
    userId,
  });
};

export const sanitizeInput = <T extends string>(value: T, regex: RegExp = /[!@#$%^&*()+=[\]{}\\|;:"<>/?]/g): T => {
  return value.replace(regex, "") as T;
};


export const handleFilterPredictions = (predictions: TPredictions, country: string, provinceCode: string, provinceName: string): TPredictions => {
  const isCanada = country.toLowerCase() === "ca";

  if (isCanada) {
    const provinceCodeLowerCase = provinceCode.toLowerCase()
    const isOntario = provinceCodeLowerCase === 'on'
    if (isOntario) return predictions
    else {

      // Filters options by comparing province code or name with terms in the address, ensuring no conflicts (e.g., "MB" in Ontario).
      // Converts terms to lowercase for comparison, avoiding mismatches like "MB" in Ontario when "ON" is the actual province.
      const filteredPredictions = predictions.filter(prediction => {
        const provinceNameLowerCase = provinceName.toLowerCase()
        const isOntarioProvinceIncluded = prediction.terms.some(term => term.value.toLowerCase() === "on")
        const matchedProvinceTermOption = prediction.terms.find(term => {
          const termItem = term.value.toLowerCase();
          return termItem === provinceNameLowerCase || termItem == provinceCodeLowerCase
        })
        return !isOntarioProvinceIncluded && matchedProvinceTermOption
      })
      return filteredPredictions
    }
  }

  return predictions
}
