import { TDepositLimitsPage } from '../../../typedefs';

interface ILimitsPage {
  header_title?: string;
  message?: string;
  welcome_message?: string;
  payment_message?: string;
  button_text?: string;
  monthly_deposit_placeholder?: string;
  weekly_deposit_placeholder?: string;
  daily_deposit_placeholder?: string;
  monthly_text?: string;
  weekly_text?: string;
  daily_text?: string;
  payment_img?: string;
  t_and_c_caveat?: string;
  privacy_policy_caveat?: string;
  description?: string;
  set_up_later?: string;
}

export const adaptLimitsPage = (data: ILimitsPage) => {
  const result: TDepositLimitsPage = {
    header_title: data?.header_title,
    message: data?.message,
    woMessage: data?.welcome_message,
    paymentMessage: data?.payment_message,
    btnContinue: data?.button_text,
    monthlyDepositPlaceholder: data?.monthly_deposit_placeholder,
    weeklyDepositPlaceholder: data?.weekly_deposit_placeholder,
    dailyDepositPlaceholder: data?.daily_deposit_placeholder,
    monthlyText: data?.monthly_text,
    weeklyText: data?.weekly_text,
    dailyText: data?.daily_text,
    generalTermsAndConditionsText: data?.t_and_c_caveat,
    privacyPolicyText: data?.privacy_policy_caveat,
    description: data?.description,
    setUpLater: data?.set_up_later,
  };
  return result;
};
