import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  GTM_FEATURES,
  GTM_ACTIONS,
  ADDRESS_COMPONENTS,
  REGISTRATION_FLOW_STEPS,
  EVENT_TYPES,
  GTM_ERRORS,
  GTM_PAYLOAD,
  REGISTRATION_ERRORS,
} from '../../../constants';
import {
  TAddress,
  TAddressErrors,
  TAddressPrediction,
  TErrorMessage,
  TGoogleAddressOptions,
} from 'Src/typedefs';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
} from '../../../utilities';
import { getErrorData } from '../../../service/directus/errors';
import { sendGtmEvent } from '../../../gtm';
import { AddressPage } from '../../../pages/addressPage';
import {
  getAddressPredictions,
  getDetailedAddress,
} from '../../../service/googleMaps/address';
import { validateAddress, validatePostalCode } from 'Src/validations';
import { gatekeeper } from 'Src/gatekeeper';
import { getAddressPageData } from 'Src/service/directus/address';
import { PostCodeMessage } from 'Components/address/postCodeMessage';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const AddressContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { setHeaderTitle } = useHeader();
  const { registrationFlowsData, errorData, welcomeOffersData } =
    UseDirectusContext();
  const dirictusErrors = getErrorData(errorData);
  const addressPageData = getAddressPageData(registrationFlowsData);
  const [address, setAddress] = useState<TAddressPrediction | null>(null); // Value based on google API.
  // detailedAddress is the real value confirmed by user. Will be stored on the BE.
  const [detailedAddress, setDetailedAddress] = useState<TAddress | null>({
    country: userValues.countryOfResidenceCountryCode,
    province: userValues.selectedProvinceCode,
    hidePostalCodePopup: !!userValues?.address?.hidePostalCodePopup,
    city: '',
    postalCode: '',
    streetName: '',
    houseNumber: '',
  });
  const [addressPredictions, setAddressPredictions] = useState<
    Array<TAddressPrediction>
  >([]);
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [description, setDescription] = useState<string | null>(null);
  const [error, setError] = useState<TAddressErrors | null>(null);
  const [manualAddressOpened, setManualAddressOpened] =
    useState<boolean>(false);

  const [enablePrefixSelector, setEnablePrefixSelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    isFlagDrawerOpen,
    toggleFlagDrawer,
    toggleMessageDrawer,
    updateMessageDrawerContent,
  } = useDrawer();

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
    storeDataInLocalStorage(ROUTE_NAMES.ADDRESS, userValues);
    setHeaderTitle(addressPageData?.headerTitle);
    // Read stored data and set it if there is any.
    if (userValues?.address) {
      setDetailedAddress(userValues?.address);
      setManualAddressOpened(true);
    }
  }, []);

  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          addressPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [addressPageData, welcomeOffersData]);

  const handleAddressPredictions = (newPredictions: TGoogleAddressOptions) => {
    if (!searchValue || !newPredictions) {
      setAddressPredictions([]);
    } else {
      setAddressPredictions(newPredictions);
    }
    setSearchLoader(false);
  };

  const handleSetDetailedAddress = (address: TAddress) => {
    setDetailedAddress(address);
    toggleFlagDrawer();
    setManualAddressOpened(true);
    setError(null);
    addressValidation({ ignorePostcodeValidation: true, address });
  };

  useEffect(() => {
    setSearchLoader(true);

    const timeoutId = setTimeout(() => {
      getAddressPredictions(
        searchValue,
        userValues.countryOfResidenceCountryCode,
        handleAddressPredictions,
        userValues.selectedProvinceCode,
        userValues.selectedProvince
      );
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    getDetailedAddress(address?.placeId, handleSetDetailedAddress);
    setAddress(null);
  }, [address]);

  const handleRemoveError = (addressType: string) => {
    const filteredAddressErrors = error?.addressErrors?.filter(
      (item) => item?.type !== addressType
    );

    setError({
      ...error,
      addressErrors: filteredAddressErrors,
    });
  };

  const changeManualAddressValue = (
    value: string,
    addressComponent: string
  ) => {
    switch (addressComponent) {
      case ADDRESS_COMPONENTS.CITY:
        setDetailedAddress({
          ...detailedAddress,
          [ADDRESS_COMPONENTS.CITY]: value,
        });
        handleRemoveError(ADDRESS_COMPONENTS.CITY);
        return;
      case ADDRESS_COMPONENTS.POSTAL_CODE:
        setDetailedAddress({
          ...detailedAddress,
          [ADDRESS_COMPONENTS.POSTAL_CODE]: value,
        });
        handleRemoveError(ADDRESS_COMPONENTS.POSTAL_CODE);
        return;
      case ADDRESS_COMPONENTS.STREET_NAME:
        setDetailedAddress({
          ...detailedAddress,
          [ADDRESS_COMPONENTS.STREET_NAME]: value,
        });
        handleRemoveError(ADDRESS_COMPONENTS.STREET_NAME);
        return;
      case ADDRESS_COMPONENTS.HOUSE_NUMBER:
        setDetailedAddress({
          ...detailedAddress,
          [ADDRESS_COMPONENTS.HOUSE_NUMBER]: value,
        });
        handleRemoveError(ADDRESS_COMPONENTS.HOUSE_NUMBER);
        return;
      default:
        return;
    }
  };

  const checkIfPostCodeFailed = async () => {
    const validationPostalCodeResult = await validatePostalCode(
      detailedAddress,
      userValues.countryOfResidenceCountryCode
    );

    if (!validationPostalCodeResult?.success && !detailedAddress.hidePostalCodePopup) {
      showPostCodeMessage();
      setDetailedAddress((prev) => ({
        ...prev,
        hidePostalCodePopup: true,
      }));
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ALERT, {
        step: REGISTRATION_FLOW_STEPS.RESIDENCY_ADDRESS,
        event: EVENT_TYPES.FABRIC_REGISTER_ALERT,
        alert: GTM_ERRORS.INVALID_POSTCODE,
      });

      return false;
    }

    return true;
  };

  const addressValidation = async ({
    ignorePostcodeValidation,
    address,
  }: {
    ignorePostcodeValidation: boolean;
    showPostcodeErrorMessage?: boolean;
    address?: TAddress | null;
  }) => {
    const validationResult = await validateAddress(address ||
      detailedAddress);

    if (!validationResult?.success) {
      const updatedErrors = validationResult.addressErrors?.map((error) => {
        const message: TErrorMessage = getErrorMessage(dirictusErrors, error.errorName);
        error.message = message?.message;
        return error;
      }).filter(Boolean); // Filter out null values

      setError({ addressErrors: updatedErrors });
      return false;
    }
    if (!ignorePostcodeValidation) {
      return await checkIfPostCodeFailed();
    }
    else {
      return true;
    }
  };

  const changeSearchValue = (value) => {
    if (!value) {
      setSearchValue(value);
      setAddressPredictions([]);
    }
    setSearchValue(value);
  };

  const toggleDrawer = () => {
    setEnablePrefixSelector(true);
    toggleFlagDrawer();
  };

  if (routeName !== ROUTE_NAMES.ADDRESS) {
    return null;
  }

  const onConfirm = async (ignoreValidation: boolean = false) => {
    const errors = await addressValidation({ ignorePostcodeValidation: ignoreValidation });
    const validAddress = errors;

    const newDetailedAddress = {
      ...detailedAddress,
      hidePostalCodePopup: ignoreValidation
    };

    if (validAddress) {
      setUserValues(prevUserValues => ({
        ...prevUserValues,
        address: newDetailedAddress,
        residentialAddress: manualAddressOpened,
      }));
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ALERT, {
        step: REGISTRATION_FLOW_STEPS.RESIDENCY_ADDRESS,
        manual_input: addressPredictions.length === 0 ? GTM_PAYLOAD.TRUE : GTM_PAYLOAD.FALSE,
        event: EVENT_TYPES.FABRIC_REGISTER_ADDRESS,
      });
      setRouteName(ROUTE_NAMES.NATIONALITY);
    } else {
      setManualAddressOpened(true);
    }
  };

  const dependencyArray = [isFlagDrawerOpen, onConfirm, detailedAddress];
  UseHandleKeyDown(dependencyArray, !isFlagDrawerOpen, () => onConfirm(detailedAddress?.hidePostalCodePopup));
  const stepNumber =
    getStepNumberTranslation('2', '4')[gatekeeper.localisation.marketSlug] ||
    getStepNumberTranslation('2', '4')?.default;

  const changePostcode = async () => {
    toggleMessageDrawer();
    const error: TErrorMessage = getErrorMessage(dirictusErrors, REGISTRATION_ERRORS.INVALID_POSTAL_CODE);
    setError({
      addressErrors: [...[error], {
        message: error.message,
        type: ADDRESS_COMPONENTS.POSTAL_CODE
      }]
    });
  };

  const proceedPostcode = async () => {
    toggleMessageDrawer();
    onConfirm(true);
  };

  const showPostCodeMessage = () => {
    if (detailedAddress.postalCode.length === 0) return;

    toggleMessageDrawer();
    updateMessageDrawerContent({
      title: addressPageData.postalCodeErrorMessageTitle || 'Confirm Postcode',
      enableCloseButton: true,
      messageComponent: (
        <PostCodeMessage
          changePostcode={changePostcode}
          proceedPostcode={proceedPostcode}
          changePostcodePlaceholder={addressPageData.changePostcodeButton}
          proceedPostcodePlaceholder={addressPageData.proceedPostcodeButton}
          postcodeMessagePlaceholder={addressPageData.postcodeErrorDescription}
        />
      ),
    });
  };

  return (
    <AddressPage
      onConfirm={() => onConfirm(detailedAddress.hidePostalCodePopup)}
      stepNumber={stepNumber}
      description={description}
      pageData={addressPageData}
      changeValue={changeManualAddressValue}
      drawerTitle={addressPageData?.searchDrawerTitle}
      enableDrawer={enablePrefixSelector}
      isDrawerOpen={isFlagDrawerOpen}
      toggleDrawer={() => toggleDrawer()}
      changeSearchValue={changeSearchValue}
      placeholder={addressPageData?.searchPlaceholder}
      searchValue={searchValue}
      searchLoader={searchLoader}
      userValues={userValues}
      manualAddressOpened={manualAddressOpened}
      setManualAddressOpened={setManualAddressOpened}
      setAddress={setAddress}
      addresses={addressPredictions}
      detailedAddress={detailedAddress}
      countryOfResidence={userValues?.countryOfResidence}
      selectedFlagUuid={userValues?.selectedFlagUuid}
      error={error}
      dirictusErrors={dirictusErrors}
    />
  );
};
