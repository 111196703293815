import classNames from 'classnames';
import { Flex, Image, Button } from '../../atoms';
import TrustlyBlackSvg from '../../assets/svgs/trustlyButtonBlack.svg';
import SwishBlackSvg from '../../assets/svgs/swissButtonBlack.svg';
import TrustlyWhiteSvg from '../../assets/svgs/trustlyButtonWhite.svg';
import SwishWhiteSvg from '../../assets/svgs/swissButtonWhite.svg';
import { TPaymentMethod } from 'Src/typedefs';
import { PAYMENT_METHOD_TYPES, TEST_ID } from '../../constants';

type Props = {
  paymentsApiData: TPaymentMethod[];
  onClick: (methodId: string) => void;
  selectedMethod: string;
};

export const PaymentMethodSelector = ({
  paymentsApiData,
  onClick,
  selectedMethod,
}: Props) => {
  const bgColour = (methodId) => {
    return methodId === selectedMethod ? 'bg-purple-60' : 'bg-grey-0';
  };

  const selected = (methodId) => {
    if (!methodId) {
      return;
    }
    return methodId === selectedMethod;
  };

  const swishData = paymentsApiData?.find(
    (data) => data.name === PAYMENT_METHOD_TYPES.SWISH
  );

  const swishViaTrustlyData = paymentsApiData?.find(
    (data) => data.name === PAYMENT_METHOD_TYPES.SWISH_VIA_TRUSTLY
  );

  const trustlyData = paymentsApiData?.find(
    (data) => data.name === PAYMENT_METHOD_TYPES.TRUSTLY
  );

  if (!paymentsApiData || paymentsApiData.length < 2) {
    return null;
  }

  return (
    <Flex
      direction="flex-row"
      className="bg-white justify-between items-center"
    >
      {swishData && (
        <Button
          className={classNames(
            'h-3xl w-full mr-sm rounded-2xl',
            bgColour(swishData?.id)
          )}
          size="full"
          onClick={() => onClick(swishData?.id)}
          disableRounded
          testID={TEST_ID.BUTTON + swishData.name.toLowerCase()}
        >
          <Image
            className="m-auto"
            src={selected(swishData?.id) ? SwishWhiteSvg : SwishBlackSvg}
            title="Swish"
            alt="Swish"
          />
        </Button>
      )}

      {(!swishData && swishViaTrustlyData) && (
        <Button
          className={classNames(
            'h-3xl w-full mr-sm rounded-2xl',
            bgColour(swishViaTrustlyData?.id)
          )}
          size="full"
          onClick={() => onClick(swishViaTrustlyData?.id)}
          disableRounded
          testID={TEST_ID.BUTTON + swishViaTrustlyData.name.toLowerCase()}
        >
          <Image
            className="m-auto"
            src={
              selected(swishViaTrustlyData?.id) ? SwishWhiteSvg : SwishBlackSvg
            }
            title="Swish via Trustly"
            alt="Swish via Trustly"
          />
        </Button>
      )}

      {trustlyData && (
        <Button
          className={classNames(
            'h-14 w-full items-center rounded-2xl',
            bgColour(trustlyData?.id)
          )}
          size="full"
          onClick={() => onClick(trustlyData?.id)}
          disableRounded
          testID={TEST_ID.BUTTON + trustlyData.name.toLowerCase()}
        >
          <Image
            className="m-auto"
            src={selected(trustlyData?.id) ? TrustlyWhiteSvg : TrustlyBlackSvg}
            title="Trustly"
            alt="Trustly"
          />
        </Button>
      )}
    </Flex>
  );
};
