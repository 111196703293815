import { useEffect, useState } from 'react';
import { Flex, Image, Span } from '../../atoms';
import { TAddress, TAddressErrors, TAddressPage } from 'Src/typedefs';
import { RegistrationInput } from '..';
import { ManualAddressComponent } from './manualAddress';
import { TEST_ID } from 'Src/constants';
import SearchIcon from '../../assets/svgs/Search.svg';

type Props = {
  addressPageData: TAddressPage;
  changeValue: (value: string, addressComponent: string) => void;
  address?: TAddress | null;
  stepNumber: string;
  manualAddressOpened: boolean;
  setManualAddressOpened: (value) => void;
  toggleDrawer: () => void;
  selectedFlagUuid?: string;
  countryOfResidence?: string;
  error: TAddressErrors;
};

export const AddressComponent = ({
  addressPageData,
  changeValue,
  stepNumber,
  address,
  manualAddressOpened = false,
  setManualAddressOpened,
  toggleDrawer,
  selectedFlagUuid,
  countryOfResidence,
  error,
}: Props) => {
  const [addressSearchHasFocus, setAddressSearchHasFocus] =
    useState<boolean>(false);

  useEffect(() => {
    if (addressSearchHasFocus) {
      toggleDrawer();
      setAddressSearchHasFocus(false);
    }
  }, [addressSearchHasFocus]);

  return (
    <Flex>
      <Flex className="p-sm tablet:p-md gap-sm bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
        <Flex className="gap-2xs px-xs tablet:px-0">
          {stepNumber && <Span className="text-purple-80">{stepNumber}</Span>}
          <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>
            {addressPageData?.message || 'Where do you live?'}
          </Flex>
          <Flex className="text-black text-sm">
            {addressPageData?.description ||
              'Almost there, we need a few more details in order to open your Casumo account.'}
          </Flex>
        </Flex>
        {!manualAddressOpened ? (
          <Flex className="text-black justify-between">
            {/* Address Search input */}
            <RegistrationInput
              currency={null}
              value={''}
              hasFocus={false}
              setHasFocus={setAddressSearchHasFocus}
              placeholder={
                addressPageData?.searchPlaceholder || 'Search your address'
              }
              testID={TEST_ID.COMBOBOX_TRIGGER + 'addressSearch'}
            />
          </Flex>
        ) : (
          <Flex className="gap-sm py-sm justify-center items-center">
            <ManualAddressComponent
              changeValue={changeValue}
              address={address}
              selectedFlagUuid={selectedFlagUuid}
              countryOfResidence={countryOfResidence}
              error={error}
              addressPageData={addressPageData}
            />
          </Flex>
        )}

      </Flex>
      {!manualAddressOpened ? (
        <Flex
          direction="flex-row"
          className="py-md text-sm justify-center items-center"
        >
          <Flex>
            {addressPageData?.cantFindText || 'Can’t find address?'}&nbsp;
          </Flex>
          <Flex
            className="text-purple-60 cursor-pointer"
            onClick={() => setManualAddressOpened(!manualAddressOpened)}
            testID={TEST_ID.TOGGLE + 'addressSearchType'}
          >
            {addressPageData?.enterManuallyText || `Enter manually`}
          </Flex>
        </Flex>
      ) : (
        <Flex className="py-md justify-center items-center">
          <Flex
            direction='flex-row'
            className='gap-2xs text-purple-60 cursor-pointer'
            onClick={() => setAddressSearchHasFocus(true)}>
            <Image
              src={SearchIcon}
              width="full"
              title={addressPageData?.searchPlaceholder}
              alt={addressPageData?.searchPlaceholder}
            />
            <Span>{addressPageData?.searchAdressButton || `Search your address`}</Span>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
