import { useState, useEffect } from 'react';
import { Flex, Span } from '../../atoms';
import { InputMode, TDepositLimitsPage, TOptionRadioBtton } from 'Src/typedefs';
import { RegistrationInput } from 'Components/registrationInput';
import { LIMIT_TYPES, TEST_ID } from '../../constants';
import { setLimitUpdateGtmEvent } from '../../gtm';
import RadioButtons from 'Components/RadioButtons/RadioButtons';

type Props = {
  depositLimitsPageData: TDepositLimitsPage;
  currency: string;
  changeAmount: (value, limitType) => void;
  clearLimit: (limitType) => void;
  depositLimit: number | null;
  depositLimitError: string | null;
  depositType: string | null;
  despositLimitList: TOptionRadioBtton[];
  validateLimits: (boolean) => void;
  stepNumber: string;
  isMgaGra: boolean;
};

export const LimitsComponentMgaGra = ({
  depositLimitsPageData,
  currency,
  changeAmount,
  clearLimit,
  depositLimit,
  depositLimitError,
  despositLimitList,
  depositType,
  validateLimits,
  stepNumber,
  isMgaGra,
}: Props) => {
  const [limitHasFocus, setLimitHasFocus] = useState<boolean>(false);
  const [triggerGTMEvent, setTriggerGTMEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!limitHasFocus && triggerGTMEvent) {
      validateLimits(false);
      setLimitUpdateGtmEvent(depositType);
    }
  }, [limitHasFocus]);

  const changeLimit = (value: number) => {
    changeAmount(value, LIMIT_TYPES.LIMIT);
  };

  const changeType = (value: string) => {
    changeAmount(value, LIMIT_TYPES.TYPE);
  };

  const clearLimitData = () => {
    clearLimit(LIMIT_TYPES.LIMIT);
  };

  if (!depositLimitsPageData) return;

  let {
    monthlyDepositPlaceholder,
    weeklyDepositPlaceholder,
    dailyDepositPlaceholder,
    message,
    description,
  } = depositLimitsPageData;


  const depositPlaceholder = () => {
    switch (depositType) {
      case LIMIT_TYPES.DAILY:
        return `${dailyDepositPlaceholder || "Daily in"} ${currency}`;
      case LIMIT_TYPES.WEEKLY:
        return `${weeklyDepositPlaceholder || "Weekly in"} ${currency}`;
      case LIMIT_TYPES.MONTHLY:
        return `${monthlyDepositPlaceholder || "Monthly in"} ${currency}`;
    }
  }

  return (
    <Flex className="gap-sm p-sm tablet:p-md bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Span className="text-purple-80">{stepNumber || ''}</Span>
        <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>{message}</Flex>
        {isMgaGra && (
          <Flex className="text-black text-sm">
            {description ||
              'The deposit limits can be changed and updated in your profile.'}
          </Flex>
        )}
      </Flex>
      <Flex className="gap-sm text-black justify-between">
        <RadioButtons
          selectedValue={depositType}
          options={despositLimitList}
          onSelect={changeType}
        />
        <RegistrationInput
          currency={currency}
          value={depositLimit}
          hasFocus={limitHasFocus}
          changeValue={changeLimit}
          placeholder={depositPlaceholder()}
          setHasFocus={setLimitHasFocus}
          setTriggerGTMEvent={setTriggerGTMEvent}
          clearValue={clearLimitData}
          error={depositLimitError}
          type="tel"
          testID={TEST_ID.INPUT + 'depositLimit'}
        />
      </Flex>
    </Flex>
  );
};
