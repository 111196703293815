import { AnimatedButton } from 'Components/animatedButton';
import { Drawer } from 'Components/drawer';
import { Flex, Span } from 'Src/atoms';
import ToggleSwitch from 'Src/atoms/toggleInput';
import { TEST_ID } from 'Src/constants';
import { TMarketingConsentDrawerUKGCProps } from 'Src/typedefs';

export const MarketingConsentDrawer = ({
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  onConfirm,
  marketingConsentPageData,
  handleChangeNotification,
  notifications,
}: TMarketingConsentDrawerUKGCProps) => {
  
  const notificationsMap = [
    { label: marketingConsentPageData.marketing_consent_notifications_sms, handleChange: handleChangeNotification, name: "sms", isActive: notifications["sms"] },
    { label: marketingConsentPageData.marketing_consent_notifications_email, handleChange: handleChangeNotification, name: "email", isActive: notifications["email"] },
    { label: marketingConsentPageData.marketing_consent_notifications_phone, handleChange: handleChangeNotification, name: "phone", isActive: notifications["phone"] },
  ]
  
  return (
    <Flex>
      {enableDrawer && (
        <Drawer
          title={marketingConsentPageData.marketing_consent_drawer_title}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          titleSize="text-h-lg font-bold "
          autoFocus={true}
        >
          <div className="mt-2 flex flex-col  justify-between h-full mt-6">
            <Flex>
              <Span className="text-base" testID={TEST_ID.TITLE + 'opt-in'}>
                {marketingConsentPageData.marketing_consent_drawer_description ||
                  'Opt-in for Casumo’s channels'}
              </Span>
              <div className="flex gap-2 mt-3 flex-col">
                {notificationsMap.map(
                  ({ label, handleChange, name, isActive }) => (
                    <ToggleSwitch
                      key={`${name}-${label}`}
                      label={label}
                      handleChange={handleChange}
                      name={name}
                      isActive={isActive}
                    />
                  )
                )}
              </div>
            </Flex>
            <Flex>
              <Flex className="mb-3">
                <Flex className="transition-all select-none ">
                  <fabric-registration-button
                    id="marketingConsentDrawerSaveAndContinue"
                    primary
                    onClick={() => onConfirm(true)}
                    data-testid={TEST_ID.BUTTON_SAVE_REGISTER}
                  >
                    {marketingConsentPageData.marketing_consent_secondary_button ||
                      'Save and register'}
                  </fabric-registration-button>
                </Flex>
              </Flex>
              <Flex>
                <AnimatedButton
                  styles="bg-teal-50 text-purple-60 text-base"
                  id="acceptAllAndRegister"
                  onClick={() => onConfirm(true, true)}
                >
                  <Flex
                    direction="flex-row justify-center items-center"
                    testID={TEST_ID.BUTTON_ACCEPT_ALL_REGISTER}
                  >
                    <Span className="mr-3xs">
                      {marketingConsentPageData.marketing_consent_primary_button ||
                        'Accept all and register'}
                    </Span>
                  </Flex>
                </AnimatedButton>
              </Flex>
            </Flex>
          </div>
        </Drawer>
      )}
    </Flex>
  );
};
