/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { TregistartionErrorData, TRegistrationUserDetails } from '../typedefs';

type TRegistrationContextProviderProps = {
  children: React.ReactNode;
};

type TRegistrationContext = {
  routeName: string;
  previousRouteName: string;
  registrationError: string;
  userValues: TRegistrationUserDetails;
  isNative: boolean;
  registrationErrorData: TregistartionErrorData,
  setRouteName: React.Dispatch<React.SetStateAction<string>>;
  setPreviousRouteName: React.Dispatch<React.SetStateAction<string>>;
  setUserValues: React.Dispatch<React.SetStateAction<unknown>>;
  setRegistrationError: React.Dispatch<React.SetStateAction<string>>;
  setIsNative: React.Dispatch<React.SetStateAction<boolean>>;
  setRegistrationErrorData: React.Dispatch<React.SetStateAction<unknown>>;
};

const defaultUserValues = {
  selectedWelcomeOffer: { id: '', tokenImage: null, thumbnailImage: null },
  depositAmount: null,
  bonusAmount: null,
  currency: null,
  paymentMethodId: null,
  paymentMethodName: null,
  payAndPlayId: null,
  depositLimits: null,
  birthDetails: null,
  email: '',
  password: null,
  phoneNumber: null,
  marketingConsent: null,
  nationality: null,
  nationalityCountryCode: null,
  isNative: false,
  countryOfResidence: null,
  countryOfResidenceCountryCode: null,
  countryRedirect: false,
  residentialAddress: false,
  selectedFlagUuid: null,
  address: null,
  firstName: null,
  lastName: null,
  selectedProvince: null,
  selectedProvinceCode: null,
  gender: 'male',
  isUserSelectedCountryManually: null,
  countryOfBirth: null,
  countryOfBirthCode: null
};

export const RegistrationContext = React.createContext<TRegistrationContext>({
  routeName: '',
  previousRouteName: '', // this is used to be able to step back in the registrtaion flow.
  userValues: defaultUserValues,
  registrationError: '',
  isNative: false,
  registrationErrorData: {},
  setRouteName: () => { },
  setPreviousRouteName: () => { },
  setUserValues: () => { },
  setRegistrationError: () => { },
  setIsNative: () => { },
  setRegistrationErrorData: () => { },
});

export const RegistrationContextProvider = ({
  children,
}: TRegistrationContextProviderProps) => {
  const [routeName, setRouteName] = React.useState('');
  const [userValues, setUserValues] = React.useState(defaultUserValues);
  const [previousRouteName, setPreviousRouteName] = React.useState('');
  const [registrationError, setRegistrationError] = React.useState('');
  const [isNative, setIsNative] = React.useState(false);
  const [registrationErrorData, setRegistrationErrorData] = React.useState({});

  return (
    <RegistrationContext.Provider
      value={{
        routeName,
        previousRouteName,
        setRouteName,
        setPreviousRouteName,
        userValues,
        setUserValues,
        registrationError,
        setRegistrationError,
        isNative,
        setIsNative,
        registrationErrorData,
        setRegistrationErrorData
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const UseRegistrationContext = () => {
  return React.useContext(RegistrationContext);
};
