import { TMarketingConsentPage } from '../../../typedefs';
import { getAssetURL } from '../../../utilities';
interface IMarketingConsentPage {
  header_title?: string;
  title?: string;
  description?: string;
  message?: string;
  button_text?: string;
  loader_text?: string;
  later_button_text?: string;
  image: { filename_disk: string };
  marketing_consent_drawer_title: string;
  marketing_consent_drawer_description: string;
  marketing_consent_notifications_email: string;
  marketing_consent_notifications_phone: string;
  marketing_consent_notifications_sms: string;
  marketing_consent_primary_button: string;
  marketing_consent_secondary_button: string;
  marketing_consent_subtitle: string
}

export const adaptMarketingConsentPage = (data: IMarketingConsentPage) => {
  const result: TMarketingConsentPage = {
    header_title: data?.header_title,
    title: data?.title,
    description: data?.description,
    message: data?.message,
    image: { src: getAssetURL(data?.image?.filename_disk) },
    loaderText: data?.loader_text,
    btnContinue: data?.button_text,
    btnLater: data?.later_button_text,
    marketing_consent_drawer_description: data?.marketing_consent_drawer_description,
    marketing_consent_drawer_title: data?.marketing_consent_drawer_title,
    marketing_consent_notifications_email: data?.marketing_consent_notifications_email,
    marketing_consent_notifications_phone: data?.marketing_consent_notifications_phone,
    marketing_consent_notifications_sms: data?.marketing_consent_notifications_sms,
    marketing_consent_primary_button: data?.marketing_consent_primary_button,
    marketing_consent_secondary_button: data?.marketing_consent_secondary_button,
    marketing_consent_subtitle: data?.marketing_consent_subtitle,
  };
  return result;
};
