import { UseRegistrationContext } from 'Src/contexts/registration-context';
import { useEffect, useState } from 'react';
import { TPlayResponsiblyProps } from './types';
import { REGISTRATION_MODAL_SESSION_STORAGE_KEY, TEST_ID } from 'Src/constants';



export const PlayResponsibly = ({ playResponsiblyPlaceholder }: TPlayResponsiblyProps) => {
  const { userValues } = UseRegistrationContext();
  const [age, setAge] = useState('18+');

  useEffect(() => {
    if (userValues?.countryOfResidenceCountryCode === 'CA') {
      setAge('19+');
    }
  }, [userValues]);

  const onPlayResponsiblyClick = () => {
    sessionStorage.removeItem(REGISTRATION_MODAL_SESSION_STORAGE_KEY);
    window.open('play-okay', '_blank');
  };
  return (
    <div className="flex justify-center items-center text-white text-2xs pb-xs" data-testid={TEST_ID.PREFIX + 'playResponsibly'}>
      <span className="leading-tight text-purple-0 text-2xs">{age} &nbsp;</span>
      <u
        className="leading-tight text-purple-0 cursor-pointer text-2xs"
        onClick={onPlayResponsiblyClick}
        data-testid={TEST_ID.SELECT + 'playResponsibly'}
      >
        {playResponsiblyPlaceholder
          ? playResponsiblyPlaceholder
          : 'Play Responsibly'}
      </u>
    </div>
  );
};
