import React from "react";
import Slider from "react-slick";
import classNames from "classnames";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TWelcomeOffer } from "Src/typedefs";
import { WelcomeOffer } from "./welcomeOffer";
import rightArrow from '../../assets/svgs/rightArrow.svg';
import leftArrow from '../../assets/svgs/leftArrow.svg';
import { Image } from "Src/atoms";
import { TEST_ID } from "Src/constants";

interface WelcomeOfferCarouselProps {
    welcomeOffers: TWelcomeOffer[];
    selectWelcomeOffer: (id: string) => void;
    currentSlideIndex: number;
    setCurrentSlideIndex: (index: number) => void;
    selectBtnText: string;
}

interface CustomArrowProps {
    direction: "next" | "prev";
    onClick?: () => void;
    isVisible: boolean;
}

const CustomArrow = ({ direction, onClick, isVisible }: CustomArrowProps) => {
    const isNext = direction === "next";

    if (!isVisible) return null;

    return (
        <button
            onClick={onClick}
            className={classNames("absolute top-1/2 transform -translate-y-1/2 z-20 bg-grey-0 text-white p-2 rounded-full shadow-lg",
                {
                    "right-3": isNext,
                    "left-3": !isNext
                }
            )}
            data-testid={TEST_ID.WELCOME_OFFER_ARROW}
        >
            <Image
                className="cursor-pointer rounded-full"
                src={isNext ? rightArrow : leftArrow}
                title="Back"
                alt="back"
            />
        </button>
    );
};


export const WelcomeOfferCarousel = ({ welcomeOffers, selectWelcomeOffer, currentSlideIndex, setCurrentSlideIndex, selectBtnText }: WelcomeOfferCarouselProps) => {

    const handleBeforeChange = (current: number, next: number) => {
        setCurrentSlideIndex(next);
    };

    const settings = {
        infinite: false,
        centerMode: true,
        centerPadding: "65px",
        slidesToShow: 1,
        speed: 500,
        dots: welcomeOffers?.length > 1,
        arrows: true,
        beforeChange: handleBeforeChange,
        nextArrow: (
            <CustomArrow
                direction="next"
                isVisible={currentSlideIndex < welcomeOffers.length - 1}
            />
        ),
        prevArrow: (
            <CustomArrow
                direction="prev"
                isVisible={currentSlideIndex > 0}
            />
        ),
        appendDots: (dots: React.ReactNode) => (
            <ul>
                {React.Children.map(dots, (dot, index) => (
                    <li data-testid={`${TEST_ID.WELCOME_OFFER_DOT}-${index}`}>
                        {dot}
                    </li>
                ))}
            </ul>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className="relative w-full mb-xl">
            <Slider {...settings}>
                {welcomeOffers.map((welcomeOffer, index) => (
                    <div key={welcomeOffer.id} className="px-3xs w-full">
                        <div
                            className={classNames(
                                "relative h-auto w-full flex items-center justify-center rounded-lg text-white text-lg font-semibold transition-transform duration-500 transform ease-in-out",
                                {
                                    "z-10": index === currentSlideIndex,
                                    "z-0": index !== currentSlideIndex,
                                },
                            )}
                        >
                            <WelcomeOffer
                                key={welcomeOffer?.id}
                                welcomeOffer={welcomeOffer}
                                onClick={selectWelcomeOffer}
                                selectBtnText={selectBtnText}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

