import { Flex } from '../atoms';
import { BirthDetailsComponentUKGC, PaymentWo, DepositWidget, NationalitySelector } from '../components';
import { TBirthDetailsComponentUKGCProps } from 'Src/typedefs';
import { BIRTH_TYPES, OPTED_OUT_WELCOME_OFFER_ID, ROUTE_NAMES } from 'Src/constants';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

export const BirthDetailsPage = ({
  onConfirm,
  userValues,
  description,
  birthDetailsPageData,
  changeValue,
  clearInput,
  birthDate,
  placeOfBirth,
  birthDateError,
  placeOfBirthError,
  validateBirthDate,
  validateBirthPlace,
  sendTermsGtmEvent,
  redirectToDepositLimit,
  stepNumber,
  dateFormat,
  termsAndPolicy,
  handleChangeTermsAndPolicy,
  termsAndPrivacyError,
  countryOfBirthError,
  searchValue,
  setCountryOfBirth,
  selectedFlagUuid,
  countryOfBirth,
  openCountrySelector,
  countryFlagsData,
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
}: TBirthDetailsComponentUKGCProps) => {
  // this term to handle the first time we check the terms checkboxs
  const isTermsAndPolicyChecked = !termsAndPrivacyError && Object.values(termsAndPolicy).every(item => item)
  const isNextButtonEnabled = birthDate && placeOfBirth && isTermsAndPolicyChecked && !birthDateError && !placeOfBirthError
  return (
    <Flex className="flex-1">
      <Flex
        className={classNames(
          'w-full justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className={'mb-[70px] bg-grey-0'}>
          <Flex className="bg-grey-0">
            {userValues?.selectedWelcomeOffer?.name && (
              <PaymentWo
                welcomeOffer={userValues.selectedWelcomeOffer}
                description={description}
              />
            )}
            {userValues?.selectedWelcomeOffer?.name ===
              OPTED_OUT_WELCOME_OFFER_ID &&
              userValues?.depositAmount && (
                <DepositWidget
                  depositAmount={userValues?.depositAmount}
                  currency={userValues?.currency}
                  pageData={birthDetailsPageData}
                />
              )}
            <Flex>
              <BirthDetailsComponentUKGC
                birthDetailsPageData={birthDetailsPageData}
                changeValue={changeValue}
                clearInput={clearInput}
                birthDate={birthDate}
                placeOfBirth={placeOfBirth}
                birthDateError={birthDateError}
                placeOfBirthError={placeOfBirthError}
                validateBirthDate={validateBirthDate}
                validateBirthPlace={validateBirthPlace}
                stepNumber={stepNumber}
                dateFormat={dateFormat}
                termsAndPrivacyError={termsAndPrivacyError}
                sendTermsGtmEvent={sendTermsGtmEvent}
                termsAndPolicy={termsAndPolicy}
                handleChangeTermsAndPolicy={handleChangeTermsAndPolicy}
                countryOfBirthError={countryOfBirthError}
                selectedFlagUuid={selectedFlagUuid}
                countryOfBirth={countryOfBirth}
                openCountrySelector={openCountrySelector}

              />
            </Flex>
            {enableDrawer && isDrawerOpen &&
              <NationalitySelector
                isCountryOfBirth
                drawerTitle={birthDetailsPageData?.countryOfBirthPlaceholder}
                countryFlagsData={countryFlagsData}
                enableDrawer={enableDrawer}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                changeValue={(value) => changeValue(value, BIRTH_TYPES.SEARCH)}
                placeholder={birthDetailsPageData?.countryOfBirthPlaceholder}
                searchValue={searchValue}
                setNationality={setCountryOfBirth}
                selectedNationality={countryOfBirth}
              />
            }
          </Flex>
        </Flex>
      </Flex>
      <ActionButtons
        primaryBtnText={birthDetailsPageData?.btnContinue}
        primaryBtnOnClick={() => onConfirm(ROUTE_NAMES.MARKETING_CONSENT)}
        secondaryBtnText={birthDetailsPageData?.depositLimitRedirectText}
        secondaryBtnOnClick={redirectToDepositLimit}
        enabled={isNextButtonEnabled}
      />
    </Flex>
  );
};
