import { useState, useEffect } from 'react';
import { parse, isValid, formatDate } from 'date-fns';
import { Flex, Span, Text } from '../../atoms';
import { DateFormat, InputMode, TBirthDetailsPage } from 'Src/typedefs';
import { RegistrationInput } from '..';
import { BIRTH_TYPES, TEST_ID } from '../../constants';
import { DateInput } from 'Components/dateInput';
import { sanitizeInput } from 'Src/utilities';
import { NationalityInput } from 'Components/nationality/nationalityInput';

type Props = {
  birthDetailsPageData: TBirthDetailsPage;
  changeValue: (value: string, inputType: string) => void;
  clearInput: (inputType: string) => void;
  birthDate: string | null;
  placeOfBirth: string | null;
  birthDateError: string;
  placeOfBirthError: string;
  validateBirthDate: (onContinue: boolean) => void;
  validateBirthPlace: (onContinue: boolean) => void;
  stepNumber: string;
  dateFormat: DateFormat;
  countryOfBirthError?: string;
  selectedFlagUuid: string;
  countryOfBirth: string;
  openCountrySelector: () => void;
};

export const BirthDetailsComponent = ({
  birthDetailsPageData,
  changeValue,
  clearInput,
  birthDate,
  placeOfBirth,
  birthDateError,
  placeOfBirthError,
  validateBirthDate,
  validateBirthPlace,
  stepNumber,
  dateFormat,
  countryOfBirth,
  countryOfBirthError,
  selectedFlagUuid,
  openCountrySelector,
}: Props) => {
  const [birthDateHasFocus, setBirthDateHasFocus] = useState<boolean>(false);
  const [placeOfBirthHasFocus, setPlaceOfBirthHasFocus] =
    useState<boolean>(false);

  const onContinue = false;
  useEffect(() => {
    if (!birthDateHasFocus) {
      validateBirthDate(onContinue);
    }
  }, [birthDateHasFocus]);

  useEffect(() => {
    if (!placeOfBirthHasFocus) {
      validateBirthPlace(onContinue);
    }
  }, [placeOfBirthHasFocus]);


  const handleInputChange = (value: string, dateFormat: string) => {
    const input = value.replace(/\D/g, '');

    if (input.length > 8) {
      return
    }

    if (input.length === 8) {
      const parsedDate = parse(input, dateFormat?.replace(/[./]/g, ''), new Date());

      if (isValid(parsedDate)) {
        const formattedDate = formatDate(parsedDate, dateFormat);
        changeValue(formattedDate, BIRTH_TYPES.BIRTH_DATE);
      } else {
        changeValue(value, BIRTH_TYPES.BIRTH_DATE);
      }
    } else {
      changeValue(value, BIRTH_TYPES.BIRTH_DATE);
    }
  };

  const changePlaceOfBirth = (value: string) => {
    changeValue(sanitizeInput(value), BIRTH_TYPES.PLACE_OF_BIRTH);
  };

  const clearPlaceOfBirth = () => {
    clearInput(BIRTH_TYPES.PLACE_OF_BIRTH);
  };

  const clearBirthDate = () => {
    clearInput(BIRTH_TYPES.BIRTH_DATE);
  };

  return (
    <Flex className="p-sm tablet:p-md gap-sm bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Span className="text-purple-80">{stepNumber || ''}</Span>
        <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>
          {birthDetailsPageData?.message || 'Enter your birth details.'}
        </Flex>
      </Flex>
      <Flex className="gap-sm text-black justify-between">
        {/* Birth date */}
        <DateInput
          currency={null}
          value={birthDate}
          hasFocus={birthDateHasFocus}
          changeValue={handleInputChange}
          placeholder={
            birthDetailsPageData?.birthDatePlaceholder ||
            'Date of birth as shown on your ID'
          }
          setHasFocus={setBirthDateHasFocus}
          clearValue={clearBirthDate}
          error={birthDateError}
          type="text"
          inputMode={InputMode.Numeric}
          dateFormat={dateFormat}
        />
        {/* Place of birth */}
        <RegistrationInput
          currency={null}
          value={placeOfBirth}
          hasFocus={placeOfBirthHasFocus}
          changeValue={changePlaceOfBirth}
          placeholder={
            birthDetailsPageData?.birthPlacePlaceholder ||
            'Place of birth as shown on your ID'
          }
          setHasFocus={setPlaceOfBirthHasFocus}
          clearValue={clearPlaceOfBirth}
          error={placeOfBirthError}
          testID={TEST_ID.INPUT + 'cityOfBirth'}
        />
        <Flex className="text-black justify-between">
          <Text className="my-xs text-sm">
            {birthDetailsPageData?.countryOfBirthPlaceholder}
          </Text>
          <NationalityInput
            selectedFlagUuid={selectedFlagUuid}
            placeholder={birthDetailsPageData?.countryOfBirthPlaceholder}
            nationality={countryOfBirth}
            open={openCountrySelector}
            error={countryOfBirthError}
           testID={TEST_ID.COMBOBOX_TRIGGER + 'countryOfBirth'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
