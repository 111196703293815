import { Flex, Span, Text } from '../../atoms';
import { TNationalityPage, TOptionRadioBtton } from 'Src/typedefs';
import { NationalityInput } from './nationalityInput';
import { INPUT_TYPES, TEST_ID } from 'Src/constants';
import { RegistrationInput } from 'Components/registrationInput';
import { sanitizeInput } from '../../utilities';
import RadioButtons from 'Components/RadioButtons/RadioButtons';

type Props = {
  nationalityPageData: TNationalityPage;
  nationality: string | null;
  openCountrySelector: () => void;
  selectedFlagUuid: string;
  stepNumber: string;
  isMgaGra?: boolean;
  changeValue?: (value, inputType) => void;
  clearValue?: (inputType) => void;
  firstName?: string;
  lastName?: string;
  nationalityError?: string;
  firstNameError?: string;
  lastNameError?: string;
  firstNameHasFocus?: boolean;
  lastNameHasFocus?: boolean;
  setFirstNameHasFocus?: (value) => void;
  setLastNameHasFocus?: (value) => void;
  enableProvinceSelector?: boolean;
  provincePlaceholder?: string;
  selectedProvince?: string;
  openProvinceSelector?: () => void;
  provinceError?: string;
  searchInputID?: string;
  genderList?: TOptionRadioBtton[] | null;
  gender?: string | null;
};

export const NationalityComponent = ({
  nationalityPageData,
  nationality,
  selectedFlagUuid,
  stepNumber,
  isMgaGra,
  openCountrySelector,
  changeValue,
  clearValue,
  firstName,
  lastName,
  nationalityError,
  firstNameError,
  lastNameError,
  firstNameHasFocus,
  lastNameHasFocus,
  setFirstNameHasFocus,
  setLastNameHasFocus,
  enableProvinceSelector,
  provincePlaceholder,
  selectedProvince,
  openProvinceSelector,
  provinceError,
  searchInputID,
  gender,
  genderList
}: Props) => {

  const changeGender = (value: string) => {
    changeValue(value, INPUT_TYPES.GENDER);
  };

  return (
    <Flex className="p-sm tablet:p-md gap-sm bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Span className="text-purple-80">{stepNumber || ''}</Span>
        <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>
          {nationalityPageData?.message}
        </Flex>
        {!isMgaGra && (
          <Flex className="text-black text-sm">
            {nationalityPageData?.description}
          </Flex>
        )}
      </Flex>
      {isMgaGra && (
        <Flex className="gap-sm">
          <RegistrationInput
            value={firstName}
            changeValue={(value) =>
              changeValue(sanitizeInput(value), INPUT_TYPES.FIRST_NAME)
            }
            clearValue={() => clearValue(INPUT_TYPES.FIRST_NAME)}
            hasFocus={firstNameHasFocus}
            setHasFocus={setFirstNameHasFocus}
            error={firstNameError}
            placeholder={nationalityPageData?.firstNamePlaceholder}
            testID={TEST_ID.INPUT + 'firstName'}
          />
          <RegistrationInput
            value={lastName}
            changeValue={(value) =>
              changeValue(sanitizeInput(value), INPUT_TYPES.LAST_NAME)
            }
            clearValue={() => clearValue(INPUT_TYPES.LAST_NAME)}
            hasFocus={lastNameHasFocus}
            setHasFocus={setLastNameHasFocus}
            error={lastNameError}
            placeholder={nationalityPageData?.lastNamePlaceholder}
            testID={TEST_ID.INPUT + 'lastName'}
          />
        </Flex>
      )}
      <Flex className="text-black justify-between">
        {isMgaGra && (
          <Text className="my-xs text-sm">
            {nationalityPageData?.flagDrawerTitle}
          </Text>
        )}
        <NationalityInput
          selectedFlagUuid={selectedFlagUuid}
          placeholder={nationalityPageData?.nationalityPlaceholder}
          nationality={nationality}
          open={openCountrySelector}
          error={nationalityError}
          testID={TEST_ID.COMBOBOX_TRIGGER + searchInputID}
        />
        {enableProvinceSelector && (
          <Flex className="mt-sm">
            <NationalityInput
              placeholder={provincePlaceholder || 'Select province'}
              nationality={selectedProvince}
              open={openProvinceSelector}
              error={provinceError}
              testID={TEST_ID.COMBOBOX_TRIGGER + 'province'}
            />
          </Flex>
        )}
      </Flex>
      <RadioButtons
        selectedValue={gender}
        options={genderList}
        onSelect={changeGender}
        testID='gender'
      />
    </Flex>
  );
};
