import classNames from 'classnames';
import { Button, Flex } from 'Src/atoms';
import { TEST_ID } from 'Src/constants';

export const ActionButtons = ({
  primaryBtnText,
  primaryBtnOnClick,
  secondaryBtnOnClick,
  secondaryBtnText,
  enabled = true,
  secondaryBtnEnabled,
}: {
  primaryBtnText?: string;
  primaryBtnOnClick?: () => void;
  secondaryBtnText?: string;
  secondaryBtnOnClick?: () => void;
  enabled?: boolean;
  secondaryBtnEnabled?: boolean;
}) => {
  const isSecondaryButtonDisabled =
    typeof secondaryBtnEnabled !== 'undefined'
      ? !secondaryBtnEnabled
      : !enabled;
  const isSecondaryButtonActive =
    typeof secondaryBtnEnabled !== 'undefined' ? secondaryBtnEnabled : enabled;

  return (
    <Flex className="sticky bottom-0 bg-grey-0 shadow-[0_-15px_33px_44px_rgba(239,242,244,1)]">
      {secondaryBtnText && (
        <Flex>
          <Button
            disabled={isSecondaryButtonDisabled}
            className={classNames(
              'font-bold py-md left-0 w-full text-grey-20',
              {
                'text-purple-60': isSecondaryButtonActive,
              }
            )}
            onClick={secondaryBtnOnClick}
            testID={TEST_ID.BUTTON_SECONDARY}
          >
            {secondaryBtnText}
          </Button>
        </Flex>
      )}
      {primaryBtnText && (
        <Flex className="mb-md">
          <fabric-registration-button
            onClick={primaryBtnOnClick}
            id="btnAccountDetails"
            primary
            {...(enabled ? null : { disabled: true })}
            data-testid={TEST_ID.BUTTON_PRIMARY}
          >
            {primaryBtnText || 'Continue'}
          </fabric-registration-button>
        </Flex>
      )}
    </Flex>
  );
};
