import { TNationalityPage } from '../../../typedefs';

interface INationalityPage {
  header_title?: string;
  message?: string;
  description?: string;
  welcome_message?: string;
  button_text?: string;
  paymentImage: string;
  caviates_text: string;
  payment_message: string;
  nationality_placeholder: string;
  country_search_placeholder: string;
  flag_drawer_title: string;
  first_name_placeholder: string;
  last_name_placeholder: string;
  province_placeholder: string;
  province_search_header_title: string;
}

export const adaptNationalityPage = (data: INationalityPage) => {
  const result: TNationalityPage = {
    header_title: data?.header_title,
    message: data?.message,
    description: data?.description,
    woMessage: data?.welcome_message,
    btnContinue: data?.button_text,
    caviatesText: data?.caviates_text,
    paymentMessage: data?.payment_message,
    nationalityPlaceholder: data?.nationality_placeholder,
    searchPlaceholder: data?.country_search_placeholder,
    flagDrawerTitle: data?.flag_drawer_title,
    firstNamePlaceholder: data?.first_name_placeholder,
    lastNamePlaceholder: data?.last_name_placeholder,
    provincePlaceholder: data?.province_placeholder,
    provinceSearchHeaderTitle: data?.province_search_header_title,
  };
  return result;
};
