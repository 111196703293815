import { gatekeeper } from 'Src/gatekeeper';
import { type TGameListItem } from './games.types';
import { type useLazyGetGameIdBySlugQuery, type useLazyGetGamesBySlugOrIdQuery } from './gamesApi';
import { isEmpty } from 'ramda';
import { log } from 'Utils';
import { type TLaunchGamePayload } from 'Src/types';

export const transformGamesList = (gamesList: TGameListItem[]) =>
  gamesList.map((game) => {
    return {
      id: game.id,
      category: game.category,
      slug: game.slug,
      title: game.title,
      logo: game.logo,
      backgroundImage: game.backgroundImage,
      likesCount: game.likesCount ?? undefined,
      isInMaintenance: game.isInMaintenance,
      hasPlayForFun: game.hasPlayForFun,
      jackpot: game.jackpot ?? undefined,
      liveCasinoLobby: game.liveCasinoLobby ?? undefined,
      gameStudio: game.gameStudio,
    };
  });

export const launchGame = (game: TLaunchGamePayload) => {
  const playForFun = Boolean(game?.playForFun);

  gatekeeper.messageBus.publish('launch', {
    kind: 'game-launch',
    ...game,
    playForFun,
  });
};

export const getGame = async ({
  gameSlugs,
  getGameIdBySlug,
  getGamesBySlugOrId,
}: {
  gameSlugs?: string[];
  getGameIdBySlug: ReturnType<typeof useLazyGetGameIdBySlugQuery>[0];
  getGamesBySlugOrId: ReturnType<typeof useLazyGetGamesBySlugOrIdQuery>[0];
}) => {
  if (!gameSlugs || gameSlugs.length <= 0) {
    return null;
  }

  try {
    const gameIdsPromises = gameSlugs.map(async (slug) => await getGameIdBySlug(slug).unwrap());
    const gameIds = await Promise.all(gameIdsPromises);

    if (isEmpty(gameIds)) return null;

    const ids = gameIds.map((entry) => entry.id);
    const games = await getGamesBySlugOrId({ ids }).unwrap();
    const [game] = games;

    if (isEmpty(game)) return null;

    return game;
  } catch (error) {
    log({
      level: 'debug',
      message: `getGame(): fetch games failed - ${gameSlugs.join(',')}`,
    });
    return null;
  }
};

export const getGameSlugFromRoute = () => {
  const params = gatekeeper.navigation.getCurrentRoute().params;
  return params?.gameSlug ?? params?.slug;
};
