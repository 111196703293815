import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  size?: boolean;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string;
}

export const HeadingOne: React.FC<Props> = (props) => {
  const { className = [], children, style = {} } = props;
  const headingClass = classNames(className);

  return (
    <h1 className={headingClass} style={style}>
      {children}
    </h1>
  );
};
