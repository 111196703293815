import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { Flex, Span } from '../../atoms';
import { Drawer } from '..';
import { CANADA_PROVNCES } from 'Src/countryCodesAndPrefixes';
import { TEST_ID } from 'Src/constants';

type ItemProps = {
  setProvince?: Dispatch<SetStateAction<string>>;
  selected?: boolean;
  provinceName?: string;
  imageUuid?: string;
};

const ProvinceItem = ({ provinceName, setProvince, selected }: ItemProps) => {
  return (
    <Flex
      direction="flex-row"
      className={classNames(
        'h-3xl p-sm mb-2xs rounded-2xl items-center text-h-sm font-bold cursor-pointer',
        {
          'bg-purple-80': !selected,
          'bg-purple-60': selected,
        }
      )}
      onClick={() => setProvince(provinceName)}
      testID={TEST_ID.PROVINCE + provinceName}
    >
      <Span>{provinceName}</Span>
    </Flex>
  );
};

type Props = {
  enableDrawer?: boolean;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: () => void;
  changeValue?: (value) => void;
  placeholder?: string;
  searchValue?: string;
  setProvince?: Dispatch<SetStateAction<string>>;
  selectedProvince?: string;
  drawerTitle: string;
};

export const ProvinceSelector = ({
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeValue,
  placeholder,
  searchValue,
  setProvince,
  selectedProvince,
  drawerTitle,
}: Props) => {
  const provinces = () => {
    const provinceNames: string[] = Object.values(CANADA_PROVNCES);
    provinceNames.sort((a, b) => a.localeCompare(b));
    const filteredProvinces = provinceNames.filter((name) =>
      name.toLowerCase()?.includes(searchValue.toLowerCase())
    );
    return filteredProvinces;
  };

  return (
    <Flex>
      {enableDrawer && (
        <Drawer
          title={drawerTitle}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          titleSize={'text-h-lg font-bold'}
          enableSearch={true}
          changeValue={changeValue}
          placeholder={placeholder}
          searchValue={searchValue}
          autoFocus={true}
          testID="province"
        >
          {provinces().map((province) => {
            return (
              <ProvinceItem
                key={`${province}`}
                provinceName={province}
                setProvince={setProvince}
                selected={province === selectedProvince}
              />
            );
          })}
        </Drawer>
      )}
    </Flex>
  );
};
