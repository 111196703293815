import { TDepositsPage } from '../../../typedefs';

interface IDepositsPage {
  header_title?: string;
  default_welcome_message?: string;
  bonus_welcome_message?: string;
  placeholder?: string;
  button_text?: string;
  caviates_text?: string;
  skip_deposit_button_text?: string;
  message?: string;
}

export const adaptDepositsPage = (data: IDepositsPage) => {
  const result: TDepositsPage = {
    header_title: data?.header_title,
    message:
      data?.message || 'Add money to start playing over 4000 games at Casumo',
    bonusMessage: data?.bonus_welcome_message,
    placeholder: data?.placeholder,
    depositText: data?.button_text,
    caviatesText: data?.caviates_text,
    noDepositText: data?.skip_deposit_button_text,
    defaultBonusMessage: data?.default_welcome_message,
  };
  return result;
};
