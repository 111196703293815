import {
  GTM_ERRORS,
  GTM_ACTIONS,
  GTM_FEATURES,
  GTM_BASE,
  GTM_PAYLOAD,
  KEYCLOAK_ADDRESS_KEYS,
  GTM_PARAMS,
  REGISTRATION_FLOW_STEPS,
  GOOGLE_MAPS_ERRORS_MESSAGE,
} from './constants';
import {
  TKeycloakUser,
  TUserDepositLimits,
  TRegistrationDoneGTM,
  MarketingConsentGtmPayload,
} from './typedefs';
import { gatekeeper } from './gatekeeper';
import pjson from '../package.json';

// Description:
// If we want a gtm event to be pushed like:
//  dataLayer.push({
//   'event': 'fabric.register.fail',
//   'error_code': 'PROVIDER_NOT_RESPONDING'
// });
// then we will need to triger this event through spine:
// gatekeeper.messageBus.publish(GTM_BASE.ANALYTICS, {
//   kind: GTM_BASE.GTAG,
//   names: {
//     feature: GTM_FEATURES.REGISTER, // Refers to second part of the event name.
//     action: GTM_ACTIONS.FAIL, // Refers to third part of the event name.
//   },
//   data: {
//     errorCode: GTM_ERRORS.PROVIDER_NOT_RESPONDING, // Any data that we want to send to GTM.
//   },
// });

export const sendGtmEvent = (
  feature: string,
  action: string,
  data: object = {}
) => {
  gatekeeper.messageBus.publish(GTM_BASE.TOPIC, {
    kind: GTM_BASE.KIND,
    names: {
      feature,
      action,
    },
    data: {
      ...data,
    },
  });
};

export const setDevcodeGtmEvents = (user: TKeycloakUser) => {
  if (!user) {
    return;
  }
  // Needs BE to porvide this data.
  // if (!(user.providers?.spar?.meta?.http_status_code === 200)) {
  //   sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.FAIL, {
  //     errorCode: GTM_ERRORS.PROVIDER_NOT_RESPONDING,
  //   });
  // } else
  if (!user.is_fully_registered) {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.REGISTRATION_START, {
      variant: `${gatekeeper.localisation.marketSlug}-${pjson?.version}`,
    });
  }
  if (!user.address_is_verified) {
    const missingFields = Object.keys(user).reduce((accumulator, key) => {
      if (KEYCLOAK_ADDRESS_KEYS.includes(key) && !user[key]) {
        return [...accumulator, key];
      }
      return accumulator;
    }, []);
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.FAIL, {
      errorCode: GTM_ERRORS.NO_ADDRESS,
      error_detail: missingFields?.toString(),
    });
  } else {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ADDRESS);
  }
};
export const setStartGtmEvents = () => {
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.REGISTRATION_START, {
    variant: `${gatekeeper.localisation.marketSlug}-${pjson?.version}`,
  });
};

export const setWoGtmEvents = (woId: string) => {
  if (woId) {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.WELCOME_OFFER, {
      wo_used: GTM_PAYLOAD.TRUE,
      wo_id: woId,
      step: GTM_PAYLOAD.SELECT_WELCOME_OFFER,
    });
  } else {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.WELCOME_OFFER, {
      wo_used: GTM_PAYLOAD.FALSE,
      wo_id: undefined, // Required explicitly from SEO.
    });
  }
};

export const setSearchAddressSentGtmEvent = (
  searchQuery: string,
  timeStart: number
) => {
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.PLACES_API_REQUEST_SENT, {
    timestamp: timeStart,
    endpoint: GTM_PAYLOAD.NEAR_BY_SEARCH,
    parameters: searchQuery,
    URL: window.location.href,
  });
};

export const setSearchAddressReceivedGtmEvent = (
  searchQuery: string,
  timeStart: number,
  status: google.maps.places.PlacesServiceStatus,
  response
) => {
  const currentTime = new Date().getTime();
  sendGtmEvent(
    GTM_FEATURES.REGISTER,
    GTM_ACTIONS.PLACES_API_RESPONSE_RECEIVED,
    {
      timestamp: currentTime,
      responseTime: currentTime - timeStart,
      status: status,
      response: response,
      endpoint: GTM_PAYLOAD.NEAR_BY_SEARCH,
      parameters: searchQuery,
      URL: window.location.href,
    }
  );
};

export const setSearchAddressErrorGtmEvent = (
  searchQuery: string,
  status: google.maps.places.PlacesServiceStatus
) => {
  const currentTime = new Date().getTime();
  sendGtmEvent(
    GTM_FEATURES.REGISTER,
    GTM_ACTIONS.PLACES_API_RESPONSE_RECEIVED,
    {
      timestamp: currentTime,
      errorCode: status,
      errorMessage: GOOGLE_MAPS_ERRORS_MESSAGE[status],
      endpoint: GTM_PAYLOAD.NEAR_BY_SEARCH,
      parameters: searchQuery,
      URL: window.location.href,
    }
  );
};

export const setselectAddressGtmEvent = (selectedAddress) => {
  const currentTime = new Date().getTime();
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.PLACES_SELECTED_ADDRESS, {
    timestamp: currentTime,
    selected: selectedAddress,
    endpoint: GTM_PAYLOAD.NEAR_BY_SEARCH,
    URL: window.location.href,
  });
};

export const setWoTermsGtmEvent = (step: string) => {
  // Steps are declared in GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.SHOW_BONUS_TERMS, {
    step,
  });
};

export const setTermsGtmEvent = (step: string) => {
  // Steps are declared in GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.SHOW_TERMS, {
    step,
  });
};

export const setShowPrivacyPolicyGtmEvent = (step: string) => {
  // Steps are declared in GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.SHOW_PRIVACY_POLICY, {
    step,
  });
};

export const setDepositAmountButtonsGtmEvent = (amount: number) => {
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.DEPOSIT_AMOUNT_CLICK, {
    deposit_amount: amount,
  });
};

export const setDepositGtmEvent = (amount: number | null) => {
  if (amount) {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.DEPOSIT, {
      deposit_defined: GTM_PAYLOAD.YES,
      deposit_amount: amount,
    });
  } else {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.DEPOSIT, {
      deposit_defined: GTM_PAYLOAD.NO,
    });
  }
};

export const setAlertGtmEvent = (alert: string | object) => {
  // Alert is coming from GTM_PAYLOAD or REGISTRATION_ERRORS const.
  if (typeof alert === 'object') {
    sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ALERT, alert);
    return;
  }

  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ALERT, {
    alert,
  });
};

export const setLimitUpdateGtmEvent = (limitType: string) => {
  // LimitType is coming from GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.LIMIT_UPDATE, {
    limit_type: limitType,
    step: REGISTRATION_FLOW_STEPS.DEPOSIT_STEP,
    [GTM_PARAMS.DEPOSIT_LIMIT_DEFINED]: GTM_PAYLOAD.TRUE,
  });
};

export const setLimitLaterGtmEvent = () => {
  // Event is fired when user clicks "Set up later" button.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.LIMIT_UPDATE, {
    [GTM_PARAMS.DEPOSIT_LIMIT_DEFINED]: GTM_PAYLOAD.FALSE,
  });
};

export const setLimitsGtmEvent = (limits: TUserDepositLimits) => {
  // LimitType is coming from GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.LIMITS, {
    daily_limit: limits?.daily,
    weekly_limit: limits?.weekly,
    monthly_limit: limits?.monthly,
    step: REGISTRATION_FLOW_STEPS.DEPOSIT_STEP,
    [GTM_PARAMS.DEPOSIT_LIMIT_DEFINED]: GTM_PAYLOAD.TRUE,
  });
};

export const setNationalitySelectorGtmEvent = (step: string) => {
  // Steps are declared in GTM_PAYLOAD const.
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.SHOW_NATIONALITY_DRAWER, {
    step,
  });
};

export const setCountryResidenceSelectorGtmEvent = (step: string) => {
  sendGtmEvent(
    GTM_FEATURES.REGISTER,
    GTM_ACTIONS.SHOW_COUNTRY_OF_RESIDENCE_DRAWER,
    {
      step,
    }
  );
};

export const setMarketingConsentGtmEvent = (consent: boolean, isUKGC?: boolean, marketingChannels?: string) => {

  const payload: MarketingConsentGtmPayload = {
    marketing_consent: consent,
    step: GTM_ACTIONS.MARKETING_CONSENT,
  }
  // Add marketing channel details based on conditions
  if (marketingChannels) {
    if (isUKGC) {
      payload.marketing_channels = marketingChannels;
    } else {
      payload.casino_marketing_consent = marketingChannels;
      payload.sports_marketing_consent = marketingChannels;
    }
  }
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.MARKETING_CONSENT, payload);
};

export const setRegistrationCompleteGtmEvent = (
  data: TRegistrationDoneGTM,
  action: string
) => {
  sendGtmEvent(GTM_FEATURES.REGISTER, action, {
    ...data,
    variant: `${gatekeeper?.localisation?.marketSlug}-${pjson?.version}`,
  });
};

export const setRegistrationClosedGtmEvent = () => {
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.REGISTRATION_CLOSED);
};

export const setRegistrationClosedWithErrorGtmEvent = (failedAttempts, userValues) => {
  sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.REGISTRATION_CLOSED, { failedAttempts, userValues });
};
