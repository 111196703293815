import { useEffect, useState } from 'react';
import {
  UseDirectusContext,
  UseRegistrationContext,
  useHeader,
  useDrawer,
} from '../../../contexts';
import {
  UseGetDepositLimitsData,
  UseGetDepositLimitsPage,
} from '../../../hooks';
import {
  ROUTE_NAMES,
  LIMIT_TYPES,
  DEFAULT_MAX_LIMIT,
  GTM_PAYLOAD,
} from '../../../constants';
import {
  notValidIntegerValue,
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
  getJurisdiction,
} from '../../../utilities';
import { limitsValidationResult } from '../../../validations';
import {
  TLimitsValidationResult,
  TErrorMessage,
  TUserDepositLimits,
} from '../../../typedefs';
import {
  setAlertGtmEvent,
  setLimitsGtmEvent,
  setShowPrivacyPolicyGtmEvent,
  setTermsGtmEvent,
} from '../../../gtm';
import { getErrorData } from '../../../service/directus/errors';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { DepositLimitsPage } from '../../../pages/depositLimitsPage';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';
import { gatekeeper } from 'Src/gatekeeper';
import { CURRENCY_BY_COUNTRY_CODE } from 'Src/currencyByCountryCode';

export const DepositLimitsContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();
  const limits = userValues?.depositLimits;

  const { setHeaderTitle } = useHeader();

  const { limitsData } = UseGetDepositLimitsData(
    CURRENCY_BY_COUNTRY_CODE.SE,
    getJurisdiction(gatekeeper.localisation.marketSlug)
  );
  const { errorData } = UseDirectusContext();
  const allErrorData = getErrorData(errorData);

  const { depositLimitsPageData } = UseGetDepositLimitsPage();

  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);
  const [monthlyLimitError, setMonthlyLimitError] = useState<string | null>(
    null
  );
  const [weeklyLimit, setWeeklyLimit] = useState<number | null>(null);
  const [weeklyLimitError, setWeeklyLimitError] = useState<string | null>(null);
  const [dailyLimit, setDailyLimit] = useState<number | null>(null);
  const [dailyLimitError, setDailyLimitError] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const stepNumber = getStepNumberTranslation('2', '4')?.sv;

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.DEPOSIT_AMOUNT);
    storeDataInLocalStorage(ROUTE_NAMES.LIMITS, userValues);

    // Read stored data and set it if there is any.
    if (limits && Object.keys(limits).length > 0) {
      setDailyLimit(limits?.daily);
      setWeeklyLimit(limits?.weekly);
      setMonthlyLimit(limits?.monthly);
    }

    setHeaderTitle(depositLimitsPageData?.header_title);
  }, []);

  useEffect(() => {
    setHeaderTitle(depositLimitsPageData?.header_title);

    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          depositLimitsPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [depositLimitsPageData]);

  const changeAmount = (value, limitType) => {
    if (notValidIntegerValue(value, DEFAULT_MAX_LIMIT)) {
      return;
    }
    if (limitType === LIMIT_TYPES.MONTHLY) {
      setMonthlyLimit(Number(value) || null);
      setMonthlyLimitError(null);
    }
    if (limitType === LIMIT_TYPES.WEEKLY) {
      setWeeklyLimit(Number(value) || null);
      setWeeklyLimitError(null);
    }
    if (limitType === LIMIT_TYPES.DAILY) {
      setDailyLimit(Number(value) || null);
      setDailyLimitError(null);
    }
  };

  const clearLimit = (limitType) => {
    if (limitType === LIMIT_TYPES.MONTHLY) {
      setMonthlyLimit(null);
      setMonthlyLimitError(null);
    }
    if (limitType === LIMIT_TYPES.WEEKLY) {
      setWeeklyLimit(null);
      setWeeklyLimitError(null);
    }
    if (limitType === LIMIT_TYPES.DAILY) {
      setDailyLimit(null);
      setDailyLimitError(null);
    }
  };

  const setErrorMessages = (
    validationResult: TLimitsValidationResult,
    onFinalValidation: boolean
  ) => {
    const dailySuccess = validationResult?.dailyResult?.success;
    const weeklySuccess = validationResult?.weeklyResult?.success;
    const monthlySuccess = validationResult?.monthlyResult?.success;

    // Set daily validation result.
    if ((onFinalValidation && !dailySuccess) || (dailyLimit && !dailySuccess)) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.dailyResult?.error
      );
      setDailyLimitError(
        `${message?.message} ${validationResult?.dailyResult?.value}`
      );
      setAlertGtmEvent(validationResult?.dailyResult?.error);
    } else {
      setDailyLimitError(null);
    }

    // Set weekly validation result.
    if (
      (onFinalValidation && !weeklySuccess) ||
      (weeklyLimit && !weeklySuccess)
    ) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.weeklyResult?.error
      );

      setWeeklyLimitError(
        `${message?.message} ${validationResult?.weeklyResult?.value}`
      );
      setAlertGtmEvent(validationResult?.weeklyResult?.error);
    } else {
      setWeeklyLimitError(null);
    }

    // Set monthly validation result.
    if (
      (onFinalValidation && !monthlySuccess) ||
      (monthlyLimit && !monthlySuccess)
    ) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.monthlyResult?.error
      );
      setMonthlyLimitError(
        `${message?.message} ${validationResult?.monthlyResult?.value}`
      );
      setAlertGtmEvent(validationResult?.monthlyResult?.error);
    } else {
      setMonthlyLimitError(null);
    }
  };

  const validateLimits = (onFinalValidation: boolean) => {
    const validationResult: TLimitsValidationResult = limitsValidationResult(
      limitsData,
      userValues?.depositAmount,
      monthlyLimit,
      weeklyLimit,
      dailyLimit
    );
    if (!validationResult) {
      return false;
    }

    setErrorMessages(validationResult, onFinalValidation);

    const dailyValidationSuccess = validationResult?.dailyResult?.success;
    const weeklyValidationSuccess = validationResult?.weeklyResult?.success;
    const monthlyValidationSuccess = validationResult?.monthlyResult?.success;
    return (
      dailyValidationSuccess &&
      weeklyValidationSuccess &&
      monthlyValidationSuccess
    );
  };

  const onConfirm = () => {
    if (validateLimits(true)) {
      const depositLimits: TUserDepositLimits = {
        monthly: monthlyLimit,
        weekly: weeklyLimit,
        daily: dailyLimit,
      };
      setUserValues({
        ...userValues,
        depositLimits,
      });
      setLimitsGtmEvent(depositLimits);
      setRouteName(ROUTE_NAMES.EMAIL_AND_PHONE);
    } else {
      console.error(
        `Error on validating limits! Daily:${dailyLimit} Weekly:${weeklyLimit} Monthly: ${monthlyLimit}`
      );
      console.error(
        `Errors on limits validation: dailyLimitError: ${dailyLimitError}, weeklyLimitError: ${weeklyLimitError}, monthlyLimitError: ${monthlyLimitError}`
      );
    }
  };

  const { isDrawerOpen, isFlagDrawerOpen, isMessageDrawerOpen } = useDrawer();

  const dependencyArray = [
    monthlyLimit,
    weeklyLimit,
    dailyLimit,
    limitsData,
    isDrawerOpen,
    isFlagDrawerOpen,
    isMessageDrawerOpen,
  ];
  UseHandleKeyDown(
    dependencyArray,
    !isDrawerOpen && !isFlagDrawerOpen && !isMessageDrawerOpen,
    onConfirm
  );

  const sendTermsGtmEvent = () => {
    setTermsGtmEvent(GTM_PAYLOAD.LIMIT_STEP);
  };

  const sendPrivacyPolicyGtmEvent = () => {
    setShowPrivacyPolicyGtmEvent(GTM_PAYLOAD.LIMIT_STEP);
  };

  if (routeName !== ROUTE_NAMES.LIMITS) {
    return null;
  }

  return (
    <DepositLimitsPage
      userValues={userValues}
      description={description}
      depositLimitsPageData={depositLimitsPageData}
      changeAmount={changeAmount}
      clearLimit={clearLimit}
      monthlyLimit={monthlyLimit}
      weeklyLimit={weeklyLimit}
      dailyLimit={dailyLimit}
      monthlyLimitError={monthlyLimitError}
      weeklyLimitError={weeklyLimitError}
      dailyLimitError={dailyLimitError}
      validateLimits={validateLimits}
      onConfirm={onConfirm}
      termsGTM={sendTermsGtmEvent}
      privacyPolicyGTM={sendPrivacyPolicyGtmEvent}
      stepNumber={stepNumber}
      currency={userValues.currency}
    />
  );
};
