/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
// Timer functions to measure how much time the user spent on the registartion flow.
import * as React from 'react';
import { COOKIE_NAMES } from '../constants';
import { readCookie, storeInCookie } from '../cookies';

type TTimerContextProviderProps = {
  children: React.ReactNode;
};

type TTimerResult = {
  timeSpent: number | null;
  numberOfOccasions: number | null;
};

type TTimerContext = {
  timerStart: () => void;
  storeTimerInfo: () => void;
  calculateTimeSpent: () => TTimerResult | null;
};

export const TimerContext = React.createContext<TTimerContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  timerStart: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  storeTimerInfo: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  calculateTimeSpent: () => {
    return { timeSpent: null, numberOfOccasions: null };
  },
});

export const TimerContextProvider = ({
  children,
}: TTimerContextProviderProps) => {
  const [startTime, setStartTime] = React.useState(null);

  const timerStart = () => {
    setStartTime(new Date()); // Being set on every occasion when user clicks "sign in and play"
  };

  const getTimeDifferenceInSeconds = (start, end) => {
    if (!start || !end) {
      return;
    }
    const timeDiff: number = end - start; // In ms
    // Strip the ms.
    const seconds: number = timeDiff / 1000;
    // Get seconds.
    return Math.round(seconds);
  };

  // If user is closing the registration modal then we store the time ellapsed in a cookie.
  const storeTimerInfo = () => {
    if (!startTime) {
      return;
    }

    let result;
    const storedCookie = readCookie(COOKIE_NAMES.TIME_SPENT, {});
    const now = new Date();
    if (!Object.keys(storedCookie)?.length) {
      result = {
        timeSpent: getTimeDifferenceInSeconds(startTime, now),
        numberOfOccasions: 2,
      };
    } else {
      const timeSpentSoFar = storedCookie?.userTimeSpent?.timeSpent;
      result = {
        timeSpent: timeSpentSoFar + getTimeDifferenceInSeconds(startTime, now),
        numberOfOccasions: storedCookie?.userTimeSpent?.numberOfOccasions + 1,
      };
    }

    storeInCookie(COOKIE_NAMES.TIME_SPENT, 'userTimeSpent', result);
  };

  const calculateTimeSpent = () => {
    if (!startTime) {
      return;
    }
    try {
      const storedCookie = readCookie(COOKIE_NAMES.TIME_SPENT, {});

      let result = 0;
      let numberOfOccasions = 1;
      const now = new Date();
      if (!Object.keys(storedCookie)?.length) {
        result = getTimeDifferenceInSeconds(startTime, now);
      } else {
        result =
          getTimeDifferenceInSeconds(startTime, now) +
          storedCookie?.userTimeSpent?.timeSpent;

        numberOfOccasions = storedCookie?.userTimeSpent?.numberOfOccasions;
      }

      setStartTime(null);
      if (result == null || numberOfOccasions == null) {
        console.error('result or numberOfOccasions is not valid', {
          result,
          numberOfOccasions,
        });
        return;
      }
      return {
        timeSpent: result,
        numberOfOccasions,
      };
    } catch (error) {
      console.error('calculateTimeSpent error', error);
      return;
    }
  };

  return (
    <TimerContext.Provider
      value={{
        calculateTimeSpent,
        timerStart,
        storeTimerInfo,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export const UseTimerContext = () => {
  return React.useContext(TimerContext);
};
