import { Flex } from '../atoms';
import { BirthDetailsComponent, PaymentWo, DepositWidget, NationalitySelector } from '../components';
import {
  DateFormat,
  TBirthDetailsPage,
  TFlags,
  TRegistrationUserDetails,
} from 'Src/typedefs';
import { TermsContainer } from 'Components/termsAndConditions/termsContainer';
import { BIRTH_TYPES, OPTED_OUT_WELCOME_OFFER_ID, ROUTE_NAMES } from 'Src/constants';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  userValues: TRegistrationUserDetails;
  onConfirm: (path: string) => void;
  description: string;
  birthDetailsPageData: TBirthDetailsPage;
  changeValue: (value: string, inputType: string) => void;
  clearInput: (inputType: string) => void;
  birthDate: string | null;
  placeOfBirth: string | null;
  birthDateError: string;
  placeOfBirthError: string;
  validateBirthDate: (onContinue: boolean) => void;
  validateBirthPlace: (onContinue: boolean) => void;
  sendTermsGtmEvent: (gtmAction: string) => void;
  redirectToDepositLimit: () => void;
  stepNumber?: string;
  dateFormat: DateFormat;
  selectedFlagUuid: string;
  countryOfBirth: string;
  openCountrySelector: () => void;
  countryFlagsData: TFlags[];
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: () => void;
  searchValue: string;
  setCountryOfBirth: (countryName: string) => void;
  countryOfBirthError?: string;
};

export const BirthDetailsPage = ({
  onConfirm,
  userValues,
  description,
  birthDetailsPageData,
  changeValue,
  clearInput,
  birthDate,
  placeOfBirth,
  birthDateError,
  placeOfBirthError,
  validateBirthDate,
  validateBirthPlace,
  sendTermsGtmEvent,
  redirectToDepositLimit,
  stepNumber,
  dateFormat,
  countryOfBirthError,
  searchValue,
  setCountryOfBirth,
  selectedFlagUuid,
  countryOfBirth,
  openCountrySelector,
  countryFlagsData,
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
}: Props) => {
  return (
    <Flex className="flex-1">
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          {userValues?.selectedWelcomeOffer?.name && (
            <PaymentWo
              welcomeOffer={userValues.selectedWelcomeOffer}
              description={description}
            />
          )}
          {userValues?.selectedWelcomeOffer?.name ===
            OPTED_OUT_WELCOME_OFFER_ID &&
            userValues?.depositAmount && (
              <DepositWidget
                depositAmount={userValues?.depositAmount}
                currency={userValues?.currency}
                pageData={birthDetailsPageData}
              />
            )}
          <Flex>
            <BirthDetailsComponent
              birthDetailsPageData={birthDetailsPageData}
              changeValue={changeValue}
              clearInput={clearInput}
              birthDate={birthDate}
              placeOfBirth={placeOfBirth}
              birthDateError={birthDateError}
              placeOfBirthError={placeOfBirthError}
              validateBirthDate={validateBirthDate}
              validateBirthPlace={validateBirthPlace}
              stepNumber={stepNumber}
              dateFormat={dateFormat}
              countryOfBirthError={countryOfBirthError}
              selectedFlagUuid={selectedFlagUuid}
              countryOfBirth={countryOfBirth}
              openCountrySelector={openCountrySelector}
            />
            {enableDrawer && isDrawerOpen &&
              <NationalitySelector
                drawerTitle={birthDetailsPageData?.countryOfBirthPlaceholder}
                countryFlagsData={countryFlagsData}
                enableDrawer={enableDrawer}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                changeValue={(value) => changeValue(value, BIRTH_TYPES.SEARCH)}
                placeholder={birthDetailsPageData?.countryOfBirthPlaceholder}
                searchValue={searchValue}
                setNationality={setCountryOfBirth}
                selectedNationality={countryOfBirth}
                isCountryOfBirth
              />
            }
            <Flex className="mt-2xs mx-xs items-baseline">
              <TermsContainer
                className="my-sm text-grey-35"
                gtmEvent={sendTermsGtmEvent}
                termsText={
                  birthDetailsPageData?.generalTermsAndConditionsText || ''
                }
                privacyText={birthDetailsPageData?.privacyPolicyText || ''}
                isPrivacyPolicy={true}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <ActionButtons
        primaryBtnText={birthDetailsPageData?.btnContinue}
        primaryBtnOnClick={() => onConfirm(ROUTE_NAMES.MARKETING_CONSENT)}
        secondaryBtnText={birthDetailsPageData?.depositLimitRedirectText}
        secondaryBtnOnClick={redirectToDepositLimit}
        enabled={birthDate && placeOfBirth && !birthDateError && !placeOfBirthError}
      />
    </Flex>
  );
};
