// import { ADDRESS_COMPONENTS, REGISTRATION_ERRORS } from 'Src/constants';
import { TAddress, TPredictions } from 'Src/typedefs';
import { googleAddressValidatorClient } from '.';
import { adaptDetailedAddress } from './adapter';
import {
  ACCEPTABLE_POSTCODE_RESULTS,
  GOOGLE_ADDRESS_COMPONENT_NAMES,
} from './constants';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { setSearchAddressErrorGtmEvent, setSearchAddressReceivedGtmEvent, setSearchAddressSentGtmEvent } from 'Src/gtm';
import { handleFilterPredictions } from 'Src/utilities';

export const getAddressPredictions = (
  input: string,
  countryRestrictions: string,
  handleAddressPredictions: Function,
  provinceCode: string,
  provinceName: string
) => {
  if (!(window && window.google) || !input) {
    return;
  }

  const timeStart = new Date().getTime();
  setSearchAddressSentGtmEvent(input, timeStart);

  // Create service
  const service = new google.maps.places.AutocompleteService();

  // Perform request.
  const options: google.maps.places.AutocompletionRequest = {
    input,
    componentRestrictions: { country: countryRestrictions },
    types: ["address"],
  };

  const callback = (predictions: TPredictions, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
      const filteredPredictions = handleFilterPredictions(predictions, countryRestrictions, provinceCode, provinceName);
      const response = filteredPredictions?.map((prediction) => {
        return {
          description: prediction?.description,
          placeId: prediction.place_id,
        };
      });
      setSearchAddressReceivedGtmEvent(input, timeStart, status, response);
      handleAddressPredictions(response);
    } else {
      handleAddressPredictions(null);
      setSearchAddressErrorGtmEvent(input, status);
    }
  };

  service.getPlacePredictions(options, callback);
};

export const getDetailedAddress = (
  placeId: string,
  handleDetailedAddress: Function
) => {
  if (!(window && window.google) || !placeId) {
    return;
  }
  // Create service
  const service = new google.maps.Geocoder();

  service.geocode({ placeId }).then(({ results }) => {
    if (results.length === 0) {
      console.error(`No places were returned on placeID: ${placeId}`);
      return;
    } else if (results.length > 1) {
      console.warn(`More than one places were returned on placeID: ${placeId}`);
    }
    const address = adaptDetailedAddress(results[0]);
    handleDetailedAddress(address);
  });
};

export const validateAddressOnGoogle = async (
  address: TAddress,
  countryCode: string
) => {
  if (!address || !countryCode) {
    return;
  }
  const payload = {
    address: {
      regionCode: countryCode,
      locality: address.city,
      postalCode: address.postalCode,
      addressLines: address.houseNumber?.concat(' ', address?.streetName),
    },
  };
  const response = await googleAddressValidatorClient.post('', payload);
  if (response.status === 200) {
    const postcodeResult =
      response?.data?.result?.address?.addressComponents.find(
        (component) =>
          (component.componentType = GOOGLE_ADDRESS_COMPONENT_NAMES.POST_CODE)
      );
    if (!postcodeResult || validatePostcode(postcodeResult)) {
      console.warn(
        `Google API did not provided information about post_code on this address: ${payload}`
      );
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  }
};

const validatePostcode = (postcodeResult) => {
  if (!postcodeResult?.confirmationLevel) {
    console.warn(
      `Google API did not provided confirmationLevel: ${postcodeResult}`
    );
    return true;
  }
  return ACCEPTABLE_POSTCODE_RESULTS.includes(
    postcodeResult?.confirmationLevel
  );
};

export const isValidPostalCode = (
  countryOfResidenceCountryCode: string,
  postalCode: string
) => {
  const isPostcodeValidatorExistsForCountry = postcodeValidatorExistsForCountry(
    countryOfResidenceCountryCode
  );

  if (!isPostcodeValidatorExistsForCountry) return false;

  return postcodeValidator(postalCode, countryOfResidenceCountryCode);
};
