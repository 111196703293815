import { Span } from 'Src/atoms';
import {
  GTM_ACTIONS,
  TERMS_AND_CONDITIONS_TYPES,
  TEST_ID,
} from 'Src/constants';
import { UseDirectusContext, useDrawer } from 'Src/contexts';
import { getTermsData, ITermsData } from 'Src/service';
import { TTerm } from 'Src/typedefs';
import { TTermsCookiesPolicy } from './types';
import classNames from 'classnames';

const TermsCookiesPolicy = ({
  termsText,
  gtmEvent,
  privacyText,
  isError,
}: TTermsCookiesPolicy) => {
  const { termsData } = UseDirectusContext();

  const directusTermsAndConditions: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.GENERAL_TERMS
  );
  const termsAndConditionsData = {
    title: directusTermsAndConditions.title,
    description: directusTermsAndConditions.description,
  };
  const directusPrivacyPolicyData: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.PRIVACY_POLICY
  );

  const privacyPolicyData = {
    title: directusPrivacyPolicyData.title,
    description: directusPrivacyPolicyData.description,
  };

  const directusCookiesPrivacyData: TTerm = getTermsData(
    termsData as unknown as ITermsData,
    TERMS_AND_CONDITIONS_TYPES.COOKIES_POLICY
  );
  const cookiesPolicyData = {
    title: directusCookiesPrivacyData.title,
    description: directusCookiesPrivacyData.description,
  };
  const { toggleDrawer, updateDrawerContent } = useDrawer();

  const onOpenDrawer = (textContent: {
    title: string;
    description: string;
  }) => {
    toggleDrawer();
    updateDrawerContent(textContent);
  };

  const errorClasses = classNames('text-purple-60  cursor-pointer', {
    'text-red-30': isError,
  });

  return (
    <div className="text-2xs mt-1 ml-1 tablet:mt-2">
      {termsText && (
        <div
          className="leading-[15px]"
          data-testid={TEST_ID.PREFIX + 'termsAndConditions'}
        >
          <Span
            className={classNames('text-grey-35 pr-3xs ', {
              'text-red-30': isError,
            })}
          >
            {termsText}
          </Span>
          <Span
            onClick={() => {
              if (gtmEvent) gtmEvent(GTM_ACTIONS.SHOW_TERMS);
              onOpenDrawer(termsAndConditionsData);
            }}
            className={errorClasses}
            testID={TEST_ID.SELECT + 'termsAndConditions'}
          >
            <u>{termsAndConditionsData.title || 'Terms and Conditions'}</u>
          </Span>
        </div>
      )}
      {privacyText && (
        <div
          className="leading-[15px]"
          data-testid={TEST_ID.PREFIX + 'privacyPolicy'}
        >
          <Span
            className={classNames('text-grey-35 pr-3xs ', {
              'text-red-30': isError,
            })}
          >
            {privacyText}
          </Span>
          <Span
            onClick={() => {
              if (gtmEvent) gtmEvent(GTM_ACTIONS.SHOW_PRIVACY_POLICY);
              onOpenDrawer(privacyPolicyData);
            }}
            className={errorClasses}
            testID={TEST_ID.SELECT + 'privacyPolicy'}
          >
            <u>{privacyPolicyData.title || 'Privacy Policy'}</u>
          </Span>
          <Span
            className={classNames('text-grey-35 px-3xs   ', {
              'text-red-30': isError,
            })}
          >
            &
          </Span>
          <Span
            onClick={() => {
              if (gtmEvent) gtmEvent(GTM_ACTIONS.SHOW_COOKIES_POLICY);
              onOpenDrawer(cookiesPolicyData);
            }}
            className={errorClasses}
            testID={TEST_ID.SELECT + 'cookiesPolicy'}
          >
            <u>{cookiesPolicyData.title || 'Cookies Policy'}</u>
          </Span>
        </div>
      )}
    </div>
  );
};

export default TermsCookiesPolicy;
