import { TEST_ID } from 'Src/constants';
import { Flex, Span } from '../../atoms';

type Props = {
  currency: string;
  onClick: () => void;
  amount: number;
};

export const AddMoneyButton = ({ currency, onClick, amount }: Props) => {
  return (
    <Flex>
      <fabric-registration-button
        onClick={onClick}
        id="btn"
        primary
        data-testid={TEST_ID.BUTTON + amount}
      >
        <Flex direction="flex-row justify-center items-center">
          <Span className="mr-3xs">{amount}</Span>
          <Span>{currency}</Span>
        </Flex>
      </fabric-registration-button>
    </Flex>
  );
};
