import { directusClient } from './client';
import { TCountryFlags, TDirectusError, TWelcomeOffer } from '../../typedefs';
import { TTermsAndConditions } from 'Src/typedefs';
import { adaptWelcomeOffers } from './welcomeOffers/adapter';
import { gatekeeper } from '../../gatekeeper';
import { createTranslationsFilterQuery } from './common';
import { MARKETS, REGISTRATION_FLOW_NAMES } from '../../constants';

// Here we have the list of all the functions that are doing the actual calls to directus.
// We are doing these calls once in the begginig of the registration flow and then we
// store this information in DirectusContext.

const getRegistrationFlowName = () => {
  switch (gatekeeper.localisation.marketSlug) {
    case MARKETS.SV:
      return REGISTRATION_FLOW_NAMES.SWEDEN;
    case MARKETS.ENGB:
      return REGISTRATION_FLOW_NAMES.UKGC;
    default:
      return REGISTRATION_FLOW_NAMES.MGA_GRA;
  }
};

export const getRegistrationFlowsData = async (): Promise<any> => {
  try {
    const flowName = getRegistrationFlowName();
    const qs = createTranslationsFilterQuery();
    const response = await directusClient.get(
      `/flows?filter[name][_eq]=${flowName}&fields=*.*.*.*.*&${qs}`);

    return response?.data?.data[0];
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getWelcomeOffersData = async (
  language: string
): Promise<TWelcomeOffer[]> => {
  try {
    const qs = createTranslationsFilterQuery();
    const welcomeOffersResponse = await directusClient.get(
      `/welcome_offers?filter[market_config][market_config_id][slug][_eq]=${gatekeeper.localisation.marketSlug}&fields=*.*.*.*&${qs}`
    );

    const welcomeOffers = adaptWelcomeOffers(
      welcomeOffersResponse?.data?.data,
      language
    );
    return welcomeOffers;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTermsAndConditionsData =
  async (): Promise<TTermsAndConditions> => {
    try {
      const qs = createTranslationsFilterQuery();
      const response = await directusClient.get(
        `/terms_and_conditions/?fields=*.*.*&${qs}`
      );
      const data: TTermsAndConditions = response?.data?.data;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

export const getCountryFlagsData = async (): Promise<TCountryFlags> => {
  try {
    const response = await directusClient.get(
      '/country_flags/?limit=300&fields=*.*.*'
    );
    const data: TCountryFlags = response?.data?.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getErrorData = async (): Promise<TDirectusError[]> => {
  try {
    const qs = createTranslationsFilterQuery();
    const response = await directusClient.get(
      `/flow_messages/?fields=*.*.*&${qs}`
    );

    return response?.data?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
