import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  width?: string;
  onClick?: () => void;
  height?: string;
  src: string;
  title?: string;
  alt?: string;
  style?: object;
}

export const Image: React.FC<Props> = (props) => {
  const { className, width, onClick, height, src, title, alt, style } = props;
  const imgClass = classNames('object-cover', className);
  return (
    <img
      width={width || 'auto'}
      height={height || 'auto'}
      src={src}
      title={title}
      alt={alt}
      className={imgClass}
      onClick={onClick}
      style={style}
    />
  );
};
