export const FRAMEWORK = "fabric";
export const NAMESPACE = "player-services";
export const APPLICATION_NAME = `${FRAMEWORK}-${NAMESPACE}`;

export const CURRENCIES = {
  EUR: "EUR",
  SEK: "SEK",
} as const;
export type TCurrencyCode = (typeof CURRENCIES)[keyof typeof CURRENCIES]; // ISO 4217

export const WEB_COMPONENT_TYPES = {
  RG_BAR: "rg-bar",
  SWEDISH_LOGIN_LIMITS: "swedish-login-limits",
  SEON_SESSION: "seon-session",
  INTERCOM_CHAT: "intercom-chat",
  LIVE_CHAT: "livechat",
  MANDATORY_MESSAGES: "mandatory-messages",
  SESSION_CONFIGURATION: "session-configuration",
  GAME_INFO_BAR: "game-info-bar",
  BEFORE_LOGOUT_MODAL: "before-logout-modal",
  SESSIONS: "sessions",
  COOKIES_NOTICE: "cookies-notice",
  COOKIES_SETTINGS: "cookies-settings",
  ACCOUNT_VERIFICATION: "account-verification",
  KAMBI: "kambi",
} as const;

export const MARKETS = {
  default: "___en",
  sweden: "se_sv",
  spain: "es_es",
  ontario: "on_en",
} as const;

export const MARKETS_ALL = {
  ___en: "___en",
  ca_en: "ca_en",
  de_de: "de_de",
  fi_fi: "fi_fi",
  gb_en: "gb_en",
  in_en: "in_en",
  no_no: "no_no",
  on_en: "on_en",
  se_sv: "se_sv",
  es_es: "es_es",
  nz_en: "nz_en",
  jp_ja: "jp_ja",
  at_de: "at_de",
  ie_en: "ie_en",
} as const;

export const KAMBI_MARKETS_LOS_TO_LIS: { [key: string]: string } = {
  en: "___en",
  en_ca: "ca_en",
  fi: "fi_fi",
  "en-gb": "gb_en",
  no: "no_no",
  on: "on_en",
  sv: "se_sv",
  es: "es_es",
  "en-nz": "nz_en",
  ie: "ie_en",
  rog: "___en",
  row: "___en",
} as const;

export const JURISDICTIONS = {
  DGOJ: "DGOJ",
  SGA: "SGA",
  AGCO: "AGCO",
  UKGC: "UKGC",
  MGA: "MGA",
} as const;
