import * as React from 'react';
import { TKeycloakUser } from '../typedefs';

type TDevcodeUserDataContextProviderProps = {
  children: React.ReactNode;
};

type TDevcodeUserDataContext = {
  devcodeUser: TKeycloakUser | null;
  setDevcodeUser: React.Dispatch<React.SetStateAction<TKeycloakUser>>;
};

export const DevcodeUserDataContext =
  React.createContext<TDevcodeUserDataContext>({
    devcodeUser: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDevcodeUser: () => {},
  });

export const DevcodeUserDataContextProvider = ({
  children,
}: TDevcodeUserDataContextProviderProps) => {
  const [devcodeUser, setDevcodeUser] = React.useState(null);

  return (
    <DevcodeUserDataContext.Provider
      value={{
        devcodeUser,
        setDevcodeUser,
      }}
    >
      {children}
    </DevcodeUserDataContext.Provider>
  );
};

export const UseDevcodeUserDataContext = () => {
  return React.useContext(DevcodeUserDataContext);
};
