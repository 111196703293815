import { TNationalityPage } from '../../../typedefs';
import { adaptNationalityPage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getNationalityPageData = (registrationFlowsData: unknown, collectionName?: string) => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.NATIONALITY_STEP,
    collectionName
  );

  const nationalityPage: TNationalityPage = adaptNationalityPage(data);
  return nationalityPage;
};
