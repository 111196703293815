import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  size?: boolean;
  color?: string;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string;
}

export const HeadingTwo: React.FC<Props> = (props) => {
  const { className = [], children, size = false, style = {} } = props;
  const headingClass = classNames({ 'text-2xl': !size }, className);

  return (
    <h2 className={headingClass} style={style}>
      {children}
    </h2>
  );
};
