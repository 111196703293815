import React from 'react';
import { Flex, DangerousHtml, Text } from '../../atoms';
import { TWelcomeOffer, TWelcomeOfferPage } from '../../typedefs';
import { useDrawer } from '../../contexts';
import { capitalize } from '../../utilities';
import { TEST_ID } from 'Src/constants';

type Props = {
    welcomeOffers?: TWelcomeOffer[];
    welcomeOfferPage?: TWelcomeOfferPage;
    sendTermsGtmEvent?: () => void;
    currentSlideIndex: number;
};

export const WoTermsDetails: React.FC<Props> = ({
    welcomeOffers = [],
    welcomeOfferPage,
    sendTermsGtmEvent,
    currentSlideIndex,
}) => {
    const { toggleDrawer, updateDrawerContent } = useDrawer();

    const handleTermsClick = (event: React.MouseEvent<HTMLDivElement>, welcomeOffer: TWelcomeOffer) => {
        const target = event.target as HTMLElement;
        if (target.tagName === 'A' && sendTermsGtmEvent) {
            event.preventDefault();
            sendTermsGtmEvent();
            toggleDrawer();
            updateDrawerContent({ title: capitalize(welcomeOffer?.title), description: welcomeOffer?.fullTerms });
        }
    };

    return (
        <Flex className="flex flex-col gap-[12px] my-md px-md tablet:px-[65px]">
            {welcomeOffers?.[currentSlideIndex] && (
                <Flex key={welcomeOffers[currentSlideIndex].id} className='gap-3xs'>
                    <Text className="text-purple-0 text-center font-bold text-xs leading-5 m-0" testID={TEST_ID.TITLE + welcomeOffers[currentSlideIndex].name}>
                        {`${capitalize(welcomeOffers[currentSlideIndex].title)} - ${welcomeOffers[currentSlideIndex].shortDesc}`}
                    </Text>
                    <div className="text-2xs text-center" onClick={(event) => handleTermsClick(event, welcomeOffers[currentSlideIndex])}>
                        <DangerousHtml
                            htmlClasses="contents text-2xs text-grey-35 font-normal"
                            html={`${welcomeOffers[currentSlideIndex].significantTerms} ${welcomeOfferPage?.readTermsDescription || ""}`}
                            testID={TEST_ID.DESCRIPTION + '-' + welcomeOffers[currentSlideIndex].name}
                        />
                    </div>
                </Flex>
            )}
        </Flex>
    );
};
