import React, { useEffect } from 'react';
import {
  useHeader,
  UseRegistrationContext,
  UseDirectusContext,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  COOKIE_NAMES,
  REGISTRATION_FLOW_ENDPOINTS,
  NOTIFICATIONS_CHANNELS,
} from '../../../constants';
import { storeDataInLocalStorage, getCookieByName } from '../../../utilities';
import { getMarketingConsentPageData } from '../../../service';
import { adaptMgaGraRegistrationUserData } from '../../../service/registration/adapters';
import useInitiateRegistration from '../../../hooks/marketingConsent';
import { setMarketingConsentGtmEvent } from '../../../gtm';
import { MarketingConsentPage } from 'Pages/marketingConsentPage';
import { gatekeeper } from 'Src/gatekeeper';

export const MarketingConsentContainer: React.FC = () => {
  const { routeName, userValues, setPreviousRouteName } =
    UseRegistrationContext();
  const { registrationFlowsData } = UseDirectusContext();
  const { setHeaderTitle } = useHeader();
  const marketingConsentPageData = getMarketingConsentPageData(
    registrationFlowsData
  );

  const { initiateRegistration, isLoader, setIsLoader } =
    useInitiateRegistration();

  useEffect(() => {
    setPreviousRouteName(userValues?.depositLimits ? ROUTE_NAMES.LIMITS : ROUTE_NAMES.BIRTH_DETAILS);
    storeDataInLocalStorage(ROUTE_NAMES.MARKETING_CONSENT, userValues);
    setHeaderTitle(marketingConsentPageData.header_title);
  }, []);

  const onConfirm = async (accepted: boolean) => {
    setIsLoader(true);
    const userRegistrationData = adaptMgaGraRegistrationUserData(
      userValues,
      getCookieByName(COOKIE_NAMES.KAZUMO_TRACKING_ID)
    );
    const values = {
      ...userRegistrationData,
      subscribeToMarketingMaterial: accepted,
    };

    if (accepted) setMarketingConsentGtmEvent(accepted, false, NOTIFICATIONS_CHANNELS)
    else setMarketingConsentGtmEvent(accepted)

    await initiateRegistration(
      values,
      REGISTRATION_FLOW_ENDPOINTS.MGA_GRA_REGISTRATION,
      gatekeeper.localisation.marketSlug
    );
  };

  if (routeName !== ROUTE_NAMES.MARKETING_CONSENT) {
    return null;
  }

  return (
    <MarketingConsentPage
      marketingConsentPageData={marketingConsentPageData}
      onConfirm={onConfirm}
      isLoader={isLoader}
    />
  );
};
