import '@casumo/fabric-fundamentals';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { type Root, createRoot } from 'react-dom/client';
import { MARKET_SLUGS_FROM_SPINE, MARKETS, URL_PREFIXES } from 'Constants';
import { gatekeeper } from 'Src/gatekeeper';
import { StoreProvider } from 'Components/StoreProvider';
import {
  DirectusProvider,
  IntlProvider,
  CmsMarketConfigProvider,
  FTSDKContextProvider,
} from 'Hooks';
import { type TRootNode } from './packager/index';
import { type TWebComponent, type TWebComponentAttributes } from 'Components/types';
import { ErrorFallback } from 'Components/ErrorFallback';

const ComponentNotFound = () => <div>Component Not Found</div>;
const Loading = () => <div></div>;

const MarketingWidget = lazy(async () => await import('./components/MarketingWidget'));
const Notifications = lazy(async () => await import('./components/Notifications'));
const ValuablesList = lazy(async () => await import('./components/ValuablesList'));
const AdventureProgressBar = lazy(async () => await import('./components/AdventureProgressBar'));
const AdventureCasumoAvatar = lazy(async () => await import('./components/AdventureCasumoAvatar'));
const AchievementsList = lazy(async () => await import('./components/AchievementsList'));
const PromotionDetailsPage = lazy(async () => await import('./components/PromotionDetails'));
const PromotionsPage = lazy(async () => await import('./components/Promotions/PromotionsPage'));
const PromotionsCarousel = lazy(
  async () => await import('./components/Promotions/PromotionsCarousel')
);
const ReelRacePromoPage = lazy(async () => await import('./components/ReelRacePromoPage'));
const AdventurePromoPage = lazy(async () => await import('./components/AdventurePromoPage'));
const RidabodoWidget = lazy(
  async () => await import('./components/inGameRidabodoWidget/RidabodoWidgetContainer')
);
const CuratedCard = lazy(async () => await import('./components/CuratedCard'));
const ReelRacesPage = lazy(async () => await import('./components/ReelRacesPage'));
const HallOfFamePage = lazy(async () => await import('./components/FamePage'));
const InGameAdventureWidgetContainer = lazy(
  async () => await import('Components/InGameAdventureWidget/InGameAdventureWidgetContainer')
);
const DepositValuablePicker = lazy(
  async () => await import('Components/DepositValuablePicker/DepositValuablePickerContainer')
);
const ReelRaceWidget = lazy(
  async () => await import('Components/ReelRacesDrawerWidgets/ReelRaceWidget')
);
const RidabodoInviteDrawer = lazy(
  async () => await import('Components/RidabodoInviteDrawer/RidabodoInviteDrawerContainer')
);
const ReelRaceRestartStandalone = lazy(
  async () => await import('Components/ReelRaceRestartModals/ReelRaceRestartStandalone')
);
const AdventureProfileWidget = lazy(
  async () => await import('./components/AdventureProfileWidgetSpine/AdventureProfileWidgetSpine')
);
const RidabodoEventPopUp = lazy(
  async () => await import('./components/RidabodoEventPopUp/RidabodoEventPopUp')
);
const InGameRecentValuable = lazy(
  async () => await import('./components/InGameRecentValuable/InGameRecentValuable')
);
const InGameAvatarWidget = lazy(
  async () => await import('Components/InGameAvatarWidget/InGameAvatarWidgetContainer')
);

const WEB_COMPONENT_MAP: Record<
  TWebComponent,
  React.LazyExoticComponent<
    ((props: any) => JSX.Element | null) | React.NamedExoticComponent<object>
  >
> = {
  notifications: Notifications,
  'marketing-widget': MarketingWidget,
  'valuables-list': ValuablesList,
  'adventure-progress-bar': AdventureProgressBar, // Deprecated: it was replaced with adventure-profile-widget
  'adventure-casumo-avatar': AdventureCasumoAvatar, // Deprecated: it was replaced with adventure-profile-widget
  'achievements-list': AchievementsList,
  'promotion-details-page': PromotionDetailsPage,
  'promotions-page': PromotionsPage,
  'promotions-carousel': PromotionsCarousel,
  'reel-race-promo-page': ReelRacePromoPage,
  'curated-card': CuratedCard,
  'reel-races-page': ReelRacesPage,
  'hall-of-fame-page': HallOfFamePage,
  'in-game-adventure-widget': InGameAdventureWidgetContainer,
  'adventure-promo-page': AdventurePromoPage,
  'in-game-ridabodo-widget': RidabodoWidget,
  'ridabodo-invite-pop-up': RidabodoInviteDrawer,
  'deposit-valuable-picker': DepositValuablePicker,
  'in-game-reel-races-widget': ReelRaceWidget,
  'reel-race-restart-pop-up': ReelRaceRestartStandalone,
  'adventure-profile-widget': AdventureProfileWidget,
  'ridabodo-event-pop-up': RidabodoEventPopUp,
  'in-game-recent-valuable': InGameRecentValuable,
  'in-game-avatar-widget': InGameAvatarWidget,
};

type Props = {
  attributes: TWebComponentAttributes;
};

const MappedWebComponent = ({ attributes }: Props) => {
  const Component = WEB_COMPONENT_MAP[attributes?.component] as React.LazyExoticComponent<
    (props: TWebComponentAttributes) => JSX.Element
  >;

  if (!Component) {
    return <ComponentNotFound />;
  }

  return <Component {...attributes} />;
};

const gatekeeperIsLegacy = gatekeeper.mode === 'legacy';

const marketMapper = (marketSlug: string) => {
  if (!gatekeeperIsLegacy) {
    return marketSlug;
  }

  const validSlugFromReactStack = Object.keys(MARKETS).includes(marketSlug.toLowerCase())
    ? MARKETS[marketSlug as keyof typeof MARKETS]
    : null;
  const validSlugFromSpine = Object.keys(MARKET_SLUGS_FROM_SPINE).includes(marketSlug.toLowerCase())
    ? MARKET_SLUGS_FROM_SPINE[marketSlug as keyof typeof MARKET_SLUGS_FROM_SPINE]
    : null;

  if (validSlugFromSpine) return validSlugFromSpine; // url is same as slug in this case
  if (validSlugFromReactStack) return URL_PREFIXES[validSlugFromReactStack];
  return '';
};

export const renderApplication = async (
  rootNode: TRootNode<Root>,
  node: HTMLElement,
  attributes: TWebComponentAttributes,
  onReady: () => void
) => {
  const root = createRoot(node);

  function App() {
    const { localeCode, marketSlug } = gatekeeper.localisation;

    const WrappedSuspenseWebComponent = () => {
      if (gatekeeperIsLegacy) {
        return (
          <>
            {attributes ? (
              <Suspense fallback={<Loading />}>
                <MappedWebComponent attributes={attributes} />
              </Suspense>
            ) : (
              <ComponentNotFound />
            )}
          </>
        );
      }

      return (
        <FTSDKContextProvider>
          {attributes ? (
            <Suspense fallback={<Loading />}>
              <MappedWebComponent attributes={attributes} />
            </Suspense>
          ) : (
            <ComponentNotFound />
          )}
        </FTSDKContextProvider>
      );
    };

    return (
      <ErrorBoundary
        FallbackComponent={({ error }: { error: Error }) => (
          <ErrorFallback error={error} webComponent={attributes?.component} />
        )}
      >
        <StoreProvider>
          <DirectusProvider>
            <CmsMarketConfigProvider slug={marketMapper(marketSlug)}>
              <IntlProvider locale={localeCode}>
                <WrappedSuspenseWebComponent />
              </IntlProvider>
            </CmsMarketConfigProvider>
          </DirectusProvider>
        </StoreProvider>
      </ErrorBoundary>
    );
  }

  root.render(<App />);

  rootNode.applicationNode = root;

  requestIdleCallback(onReady);
};
