import { useEffect, useState } from 'react';
import { getPaymentMethods } from '../../service';
import { TPaymentMethod } from '../../typedefs';

export const UseGetPaymentsApiData = (market) => {
  const [paymentsApiData, setPaymentsApiData] = useState<
    TPaymentMethod[] | undefined | null
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const paymentsApi = await getPaymentMethods(market);
      setPaymentsApiData(paymentsApi || null);
    };
    fetchData();
  }, []);

  return { paymentsApiData };
};
