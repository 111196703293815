/**
 * Avoid using this, currently is being used by legacy fetch in fabric-spine-v2
 * Maps a *market slug* to a *market code*.
 *
 * This can be useful when communicating with Casumo backend api's when only the market slug is known.
 *
 * For *market slug* @see {@link https://github.com/Casumo/fabric-directus/blob/master/packages/directus-api/src/spine/types.ts#L56}
 * and for *market code* @see {@link https://github.com/Casumo/market-service/blob/master/src/main/java/com/casumo/market/domain/Market.java#L38}
 *
 * @param marketSlug - The market slug to map to a market code
 * @throws Will throw an error if the market slug can't be mapped
 */
export const marketSlugToMarketCode = (marketSlug: string) => {
  switch (marketSlug) {
    case 'sv':
      return 'se_sv'
    case 'en-gb':
      return 'gb_en'
    case 'en':
      return '___en'
    default:
      throw Error(`Don't know how to map market slug: ${marketSlug}`)
  }
}
