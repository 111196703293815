import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  muted?: boolean;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string;
  testID?: string;
}

export const Text: React.FC<Props> = ({
  children,
  className,
  muted,
  style = {},
  testID,
}) => {
  const paragraphClass = classNames({ 'text-muted': muted }, className);

  return (
    <p className={paragraphClass} style={style} data-testid={testID}>
      {children}
    </p>
  );
};
