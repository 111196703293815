import { useState, useEffect } from 'react';
import { SwedishRegistrationRouter } from '../../../routers';
import { parseJWT } from '../../../authentication/parser';
import { gatekeeper } from '../../../gatekeeper';
import { TKeycloakUser } from 'Src/typedefs';
import { DefaultLayoutPage } from '../../../pages/defaultLayout';
import {
  UseDevcodeUserDataContext,
  UseRegistrationContext,
  UseTimerContext,
} from '../../../contexts';
import {
  deleteCookieByName,
  getCookieByName,
  getStoredItem,
  selectRegistrationError,
  storeDataInLocalStorage,
} from '../../../utilities';
import {
  setDevcodeGtmEvents,
  setRegistrationClosedGtmEvent,
} from '../../../gtm';
import { COOKIE_NAMES, ROUTE_NAMES } from 'Src/constants';
import { useGetDirectusData } from 'Src/hooks/directus';
import { useRegistrationModal } from 'Src/contexts/modal-registration-context';

const SwedishPlayNow = () => {
  const {
    routeName,
    userValues,
    previousRouteName,
    setRouteName,
    setUserValues,
    registrationError,
    setIsNative,
  } = UseRegistrationContext();
  const { isRegistraionModalOpen, openModal, closeModal } = useRegistrationModal();
  const { timerStart, storeTimerInfo } = UseTimerContext();
  const { isDataReady } = useGetDirectusData();

  const [isLoaderDone, setIsLoaderDone] = useState<boolean>(false);
  const [jwt, setJwt] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const [user, setUser] = useState<TKeycloakUser | null>(null);
  const { setDevcodeUser } = UseDevcodeUserDataContext();


  useEffect(() => {
    if (isDataReady) {
      setTimeout(() => {
        setIsLoaderDone(true);
      }, 10);
    }
  }, [isDataReady]);


  useEffect(() => {
    const isNativeCookie = getCookieByName(COOKIE_NAMES.IS_NATIVE_COOKIE);
    if (isNativeCookie) {
      setIsNative(true);
    }
    const resolvePlayerStatus = async () => {
      const jwt = await gatekeeper.auth.getAccessToken();

      setJwt(jwt || null);
    };

    if (jwt === undefined) {
      resolvePlayerStatus();
    }
  }, []);

  const handleRegistrationError = (user: TKeycloakUser) => {
    selectRegistrationError(user, setError);
  };

  useEffect(() => {
    if (!jwt) {
      return;
    }
    const user: TKeycloakUser = parseJWT(jwt);
    setUser(user);
    setDevcodeUser(user); // Put devdode user into context.
    setDevcodeGtmEvents(user);
    handleRegistrationError(user);
  }, [jwt]);

  useEffect(() => {
    const { routeName, userValues } = getStoredItem();
    setRouteName(routeName || ROUTE_NAMES.WELCOME_OFFER); // Initial routeselection.
    setUserValues(userValues);
    timerStart(); // Start measuring the time spent on registration.
  }, []);

  useEffect(() => {
    if (registrationError) {
      setError(registrationError);
    }
  }, [registrationError]);

  const closeRegistrationModal = async () => {
    storeDataInLocalStorage(routeName, userValues);
    storeTimerInfo();
    setRegistrationClosedGtmEvent();
    deleteCookieByName(COOKIE_NAMES.ERROR_NAME);

    if(gatekeeper.navigation.isActiveRoute("/register")) {
      await gatekeeper.navigation.navigate("/")
    }

    closeModal();
  };

  const stepBack = () => {
    setRouteName(previousRouteName);
  };

  const stepBackEnabled = () => {
    return routeName !== ROUTE_NAMES.WELCOME_OFFER && !error;
  };

  if (
    !isRegistraionModalOpen ||
    !jwt ||
    !isLoaderDone ||
    !user ||
    user?.is_fully_registered
  ) {
    return null;
  }

  if (error) {
    console.error(error); // We need this to see the error on FullStory.
  }

  return (
    <DefaultLayoutPage
      closeModal={closeRegistrationModal}
      stepBack={stepBack}
      stepBackEnabled={stepBackEnabled}
      error={error}
      RouterComponent={SwedishRegistrationRouter}
    />
  );
};

export default SwedishPlayNow;
