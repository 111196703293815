import { Flex } from '../atoms';
import { TFlags, TNationalityPage } from '../typedefs';
import {
  PaymentWo,
  DepositWidget,
  NationalityComponent,
  NationalitySelector,
} from '../components';
import { OPTED_OUT_WELCOME_OFFER_ID } from 'Src/constants';
import { ProvinceSelector } from 'Components/nationality/provinceSelector';
import classNames from 'classnames';
import { ActionButtons } from 'Components/ActionButtons';

type Props = {
  description: string;
  pageData: TNationalityPage;
  selectedFlagUuid: string;
  nationality: string;
  openCountrySelector: () => void;
  onConfirm: () => void;
  error: string;
  countryFlagsData: TFlags[];
  enableDrawer: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: () => void;
  changeValue: (value: any) => void;
  searchValue: string;
  setNationality: (countryName: string) => void;
  userValues: any;
  stepNumber?: string;
  enableProvinceSelector?: boolean;
  isProvinceDrawerOpen?: boolean;
  setEnableProvinceDrawer?: () => void;
  provincePlaceholder?: string;
  setProvince?: (value) => void;
  selectedProvince?: string;
  provinceDrawerTitle?: string;
  openProvinceSelector?: () => void;
  provinceError?: string;
};

export const NationalityPage = ({
  description,
  pageData,
  selectedFlagUuid,
  nationality,
  openCountrySelector,
  onConfirm,
  error,
  countryFlagsData,
  enableDrawer,
  isDrawerOpen,
  setIsDrawerOpen,
  changeValue,
  searchValue,
  setNationality,
  userValues,
  stepNumber,
  enableProvinceSelector,
  isProvinceDrawerOpen,
  setEnableProvinceDrawer,
  provincePlaceholder,
  setProvince,
  selectedProvince,
  provinceDrawerTitle,
  openProvinceSelector,
  provinceError,
}: Props) => {
  const isButtonDisabled = () => {
    if (!nationality) return true;
    if (nationality.toLowerCase() === 'canada' && !selectedProvince)
      return true;
    return false;
  };

  return (
    <Flex className="flex-1">
      <Flex
        className={classNames(
          'w-full bg-grey-0 justify-between tablet:rounded-b-3xl bg-grey-0 overflow-y-auto flex-grow'
        )}
      >
        <Flex className="mb-[70px] bg-grey-0">
          <PaymentWo
            welcomeOffer={userValues?.selectedWelcomeOffer}
            description={description}
          />

          {userValues?.selectedWelcomeOffer?.name ===
            OPTED_OUT_WELCOME_OFFER_ID &&
            userValues?.depositAmount && (
              <DepositWidget
                depositAmount={userValues?.depositAmount}
                currency={userValues?.currency}
                pageData={pageData}
              />
            )}
          <Flex className="justify-between">
            <NationalityComponent
              selectedFlagUuid={selectedFlagUuid}
              nationalityPageData={pageData}
              nationality={nationality}
              openCountrySelector={openCountrySelector}
              stepNumber={stepNumber}
              nationalityError={error}
              enableProvinceSelector={enableProvinceSelector}
              provincePlaceholder={provincePlaceholder}
              selectedProvince={selectedProvince}
              openProvinceSelector={openProvinceSelector}
              provinceError={provinceError}
              searchInputID={'countryOfResidence'}
            />
          </Flex>
          {enableDrawer && isDrawerOpen && (
            <NationalitySelector
              drawerTitle={pageData?.flagDrawerTitle}
              countryFlagsData={countryFlagsData}
              enableDrawer={enableDrawer}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              changeValue={changeValue}
              placeholder={pageData?.searchPlaceholder}
              searchValue={searchValue}
              setNationality={setNationality}
              selectedNationality={nationality}
            />
          )}
          {enableProvinceSelector && isProvinceDrawerOpen && (
            <ProvinceSelector
              enableDrawer={enableProvinceSelector}
              isDrawerOpen={isProvinceDrawerOpen}
              setIsDrawerOpen={setEnableProvinceDrawer}
              changeValue={changeValue}
              placeholder={provincePlaceholder}
              searchValue={searchValue}
              setProvince={setProvince}
              selectedProvince={selectedProvince}
              drawerTitle={provinceDrawerTitle || 'Select province'}
            />
          )}
        </Flex>
      </Flex>
      <ActionButtons
        primaryBtnText={pageData?.btnContinue}
        primaryBtnOnClick={onConfirm}
        enabled={Boolean(nationality)}
      />
    </Flex>
  );
};
