import { useDrawer, useHeader } from '../contexts';
import CloseRegistration from '../assets/svgs/Close.svg';
import { DangerousHtml, Flex, Image } from '../atoms';
import { Drawer, DrawerNotifications, ErrorPage, Header } from '../components';
import { Overlay } from 'Components/overlay';
import { openSupportChat, onRetry } from '../utilities';
import { DrawerMessage } from 'Components/drawerMessage';
import ModalContainer from 'Components/modalContainer';

type Props = {
  error?: string | null | undefined;
  closeModal?: () => void;
  stepBack?: () => void;
  stepBackEnabled: () => boolean;
  RouterComponent: () => JSX.Element;
};

export const DefaultLayoutPage = ({
  error,
  closeModal,
  stepBack,
  stepBackEnabled,
  RouterComponent,
}: Props) => {
  const {
    isDrawerOpen,
    toggleDrawer,
    drawerContent,
    isNotificationDrawerOpen,
    toggleNotificationDrawer,
    toggleFlagDrawer,
    notificationDrawerContent,
    isMessageDrawerOpen,
    messageDrawerContent,
    toggleMessageDrawer,
    isDefaultView,
    isNotificationDrawerDefaultView,
    isMessageDrawerDefaultView,
    isFlagDrawerOpen,
    isProvinceDrawerOpen,
    toggleProvinceDrawer,
    isAnyDrawerOpen,
  } = useDrawer();
  const { hideHeader } = useHeader();


  const hanldeToggleDrawers = () => {
    if (isDrawerOpen) toggleDrawer();
    else if (isFlagDrawerOpen) toggleFlagDrawer();
    else if (isMessageDrawerOpen) toggleMessageDrawer();
    else if (isProvinceDrawerOpen) toggleProvinceDrawer();
    else toggleNotificationDrawer();
  };

  return (
    <ModalContainer>
      <Flex className='w-full relative'>
        <Flex className="hidden tablet:flex !absolute top-[-17px] right-[-100px] z-[20] bg-purple-80 m-sm rounded-full">
          <Image
            className="p-sm cursor-pointer"
            src={CloseRegistration}
            title="CloseRegistration"
            alt="Close"
            onClick={closeModal}
          />
        </Flex>
        <Overlay
          isOpen={isAnyDrawerOpen}
          toggleDrawer={() => hanldeToggleDrawers()}
        />
        <Flex className='h-[100dvh] tablet:h-[90vh] w-[100vw] tablet:w-[650px] bg-white fixed tablet:relative tablet:rounded-t-[32px] left-1/2 transform -translate-x-1/2 tablet:overflow-hidden border-0 z-50'>
        {!hideHeader &&
            <Header
              error={!!error}
              onClose={closeModal}
              onClickBack={stepBack}
              stepBackEnabled={stepBackEnabled()}
            />
        }
          <Overlay
            isOpen={isAnyDrawerOpen}
            toggleDrawer={() => hanldeToggleDrawers()}
          />
          {isDrawerOpen &&
            <Drawer
              isDefaultView={isDefaultView}
              title={drawerContent?.title}
              isOpen={isDrawerOpen}
              setIsOpen={() => toggleDrawer()}
            >
              <Flex className="text-white">
                <DangerousHtml
                  className="pb-[24px]"
                  html={drawerContent?.description}
                />
              </Flex>
            </Drawer>
          }
          {
            isNotificationDrawerOpen &&
            <DrawerNotifications
              isDefaultView={isNotificationDrawerDefaultView}
              title={notificationDrawerContent?.title}
              isOpen={isNotificationDrawerOpen}
              setIsOpen={() => toggleNotificationDrawer()}
              onClick={notificationDrawerContent?.onClick}
              buttonText={notificationDrawerContent.buttonText}
            >
              <Flex>
                <DangerousHtml
                  className="text-black modalScrollbar overflow-y-auto pb-[24px]"
                  html={notificationDrawerContent?.description}
                />
              </Flex>
            </DrawerNotifications>
          }
          {
            isMessageDrawerOpen &&
            <DrawerMessage
              content={messageDrawerContent}
              isOpen={isMessageDrawerOpen}
              isDefaultView={isMessageDrawerDefaultView}
              toggleDrawer={() => toggleMessageDrawer()}
            >
              <Flex>
                <DangerousHtml
                  className="text-white modalScrollbar overflow-y-auto pb-[24px]"
                  html={messageDrawerContent?.description}
                />
              </Flex>
            </DrawerMessage>
          }
          {
            error ? (
              <ErrorPage
                error={error}
                onClose={closeModal}
                onClick={onRetry}
                hasTwoButtons={true}
                onSecondaryClick={openSupportChat}
              />
            ) : (
              <RouterComponent />
            )
          }
        </Flex>

      </Flex>
    </ModalContainer>

  );
};
