import { TTerm } from '../../../typedefs';

interface ITerm {
  content?: string;
  id: number;
  title?: string;
  languages_code?: {
    code: string;
    direction: string;
    name: string;
  };
  terms_and_conditions_id?: object;
}

export const adaptTermsAndConditions = (data: ITerm) => {
  const result: TTerm = {
    termId: data?.id?.toString(),
    title: data?.title,
    description: data?.content,
  };

  return result;
};
