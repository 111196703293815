export const ICON_SIZES = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
  EXTRA_SMALL: 'xs',
} as const;

export const ICON_WIDTH = {
  lg: 178,
  md: 116,
  sm: 90,
  xs: 45,
};

export const VALUABLE_TYPES = {
  FREE_SPINS: 'simpleBonusSpins',
  DEPOSIT_BONUS: 'depositBonus',
  SPORTS_FREE_BET: 'kambiFreeBet',
  SPORTS_DEPOSIT_BONUS: 'sportsDepositBonus',
} as const;

export const BONUS_TYPES = {
  FREE_SPINS: 'simple_bonus_spins',
  DEPOSIT_BONUS: 'deposit_bonus',
  SPORTS_FREE_BET: 'kambi_free_bet',
  SPORTS_DEPOSIT_BONUS: 'sports_deposit_bonus',
} as const;

export const WO_VERTICALS = {
  CASINO: 'casino',
  SPORTS: 'sports',
} as const;
