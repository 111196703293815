import { TDepositLimitsPage } from '../../../typedefs';
import { adaptLimitsPage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getDepositLimitsPageData = (
  registrationFlowsData: unknown
): TDepositLimitsPage => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.LIMITS_STEP
  );
  const limitsPage: TDepositLimitsPage = adaptLimitsPage(data);
  return limitsPage;
};
