import { ValuableToken } from 'Components/valuableThumbnail/valuableThumbnail';
import { ICON_SIZES } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { Flex } from 'Src/atoms/flex';
import { UseGetWelcomeOfferPage } from 'Src/hooks/welcomeOffers';
import { TWelcomeOffer } from 'Src/typedefs';
import { gatekeeper } from 'Src/gatekeeper';
import { DEFAULT_LANGUAGE, OPTED_OUT_WELCOME_OFFER_ID, TEST_ID } from 'Src/constants';
import { UseRegistrationContext } from 'Src/contexts/registration-context';
import { setWoGtmEvents } from 'Src/gtm';

type Props = {
  description?: string;
  closeDrawer: () => void;
};

export const WelcomeOfferMessage = ({ closeDrawer }: Props) => {
  const { welcomeOffersData, welcomeOfferPageData } = UseGetWelcomeOfferPage();
  const { userValues, setUserValues } = UseRegistrationContext();
  const lang = gatekeeper.localisation.localeCode || DEFAULT_LANGUAGE;

  const selectWelcomeOffer = (name: string | null) => {
    const selectedWelcomeOfferName = name ?? OPTED_OUT_WELCOME_OFFER_ID;

    const selectedWelcomeOffer =
      welcomeOffersData?.find(
        (offer) => offer.name?.toString() === selectedWelcomeOfferName
      ) ?? ({ name: OPTED_OUT_WELCOME_OFFER_ID } as TWelcomeOffer);
    setUserValues({
      ...userValues,
      selectedWelcomeOffer,
    });
    setWoGtmEvents(name);
    closeDrawer();
  };

  return (
    <Flex className="flex-1 gap-2xs w-full">
      <div className="flex flex-1 flex-col justify-center items-center grow gap-2xs w-full">
        {welcomeOffersData?.map((offer) => {
          return (
            <Flex
              key={offer.id}
              direction="row"
              className="flex-1 p-sm gap-sm rounded-2xl bg-purple-60 cursor-pointer w-full max-h-28 "
              onClick={() => selectWelcomeOffer(offer.name)}
              testID={TEST_ID.PREFIX + offer.name}
            >
              <ValuableToken
                testId={offer.id}
                size={ICON_SIZES.SMALL}
                welcomeOffer={offer}
              />
              <div className="flex items-center justify-center">
                <span>
                  <span className="font-bold">{offer.title}: </span>
                  {offer.shortDesc}
                </span>
              </div>
            </Flex>
          );
        })}
      </div>
      <Flex
        className="justify-center items-center flex-1 p-sm h-md rounded-2xl bg-purple-80 cursor-pointer max-h-14"
        onClick={() => selectWelcomeOffer(null)}
        testID={TEST_ID.BUTTON_NO_BONUS}
      >
        {welcomeOfferPageData?.dontWant || 'I don’t want a bonus'}
      </Flex>
    </Flex>
  );
};
