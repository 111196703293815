import { useEffect, useState } from 'react';
import { getDepositLimits } from '../../service';
import { TDepositLimits } from '../../typedefs';

export const UseGetDepositLimitsData = (currency: string, jurisdiction: string) => {
  const [limitsData, setLimitsData] = useState<TDepositLimits | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const limits = await getDepositLimits(currency, jurisdiction);
      setLimitsData(limits?.depositLimits || null);
    };
    fetchData();
  }, []);

  return { limitsData };
};
