import classNames from 'classnames';

interface Props {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string | number;
  testID?: string;
}

export const Span: React.FC<Props> = ({
  className = '',
  children,
  onClick,
  testID,
}) => {
  const paragraphClass = classNames('leading-tight', className);

  return (
    <span onClick={onClick} className={paragraphClass} data-testid={testID}>
      {children}
    </span>
  );
};
