import React, { ChangeEventHandler, FocusEventHandler, LegacyRef } from 'react';
import classNames from 'classnames';
import { InputMode } from 'Src/typedefs';

interface Props {
  id?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  style?: object;
  name?: string;
  label?: string;
  value?: string;
  defaultChecked?: boolean;
  type?: string;
  rounded?: string;
  placeholder?: string | undefined;
  maxLength?: number | undefined;
  min?: string | undefined;
  max?: string | undefined;
  size?: string;
  defaultValue?: string | undefined;
  autoFocus?: boolean | undefined;
  inputMode?: InputMode;
  testID?: string;
}

export const Input = React.forwardRef(
  (props: Props, ref: LegacyRef<HTMLInputElement> | undefined) => {
    const {
      name,
      onBlur,
      onChange,
      onFocus,
      type = 'text',
      placeholder,
      maxLength,
      min,
      max,
      className = '',
      value,
      defaultValue,
      style,
      autoFocus,
      inputMode,
      testID,
    } = props;

    const inputClass = classNames('focus:outline-none', className);

    return (
      <input
        ref={ref}
        name={name}
        type={type}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        min={min}
        max={max}
        className={inputClass}
        value={value}
        defaultValue={defaultValue}
        style={style}
        autoFocus={autoFocus}
        inputMode={inputMode}
        data-testid={testID}
      />
    );
  }
);
