import { Flex } from 'Src/atoms/flex';
import classNames from 'classnames';

type Props = {
  styles?: string;
  id: string;
  children: JSX.Element | JSX.Element[] | string;
  testID?: string;
  onClick: () => void;
};

export const AnimatedButton = ({
  styles,
  id,
  children,
  testID,
  onClick,
}: Props) => {
  return (
    <Flex className="min-h-[58px] flex-1" onClick={onClick} testID={testID}>
      <Flex
        id={id}
        className="flex-1 transition-all select-none active:pt-[6px]"
      >
        <Flex
          className={classNames(
            'flex-1 max-h-[52px] cursor-pointer transition-all border-b-[rgba(0,0,0,0.3)] shadow-2 active:shadow-1 py-[13px] rounded-full bg-purple-60 border-b-[6px] font-bold',
            styles
          )}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
