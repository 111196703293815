import { TBirthDetailsPage } from '../../../typedefs';
import { adaptBirthDetailsPage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getBirthDetailsPageData = (
  registrationFlowsData: unknown
): TBirthDetailsPage => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.BIRTH_DETAILS_STEP
  );
  const birthDetailsPage: TBirthDetailsPage = adaptBirthDetailsPage(data);
  return birthDetailsPage;
};
