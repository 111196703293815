import { DangerousHtml, Flex, Image } from '../../atoms';
import BonusMoney from '../../assets/svgs/bonusMoney.svg';

type Props = {
  depositAmount?: number;
  currency?: string;
  pageData?: any;
};

// This component is used when there's no selected welcome offer but the player want's to deposit.
export const DepositWidget = ({ depositAmount, currency, pageData }: Props) => {
  const message = pageData?.paymentMessage
    ?.replace('{{deposit_amount}}', depositAmount.toString())
    ?.replace('{{currency}}', currency);
  return (
    <Flex className="p-sm mb-md bg-teal-50 rounded-3xl bg-opacity-10">
      <Flex direction="flex-row" className="w-full text-white items-center">
        <Image
          className="mr-sm w-10"
          src={BonusMoney || pageData?.paymentImage} // Change order when images are fixed in directus.
          title="LimitImage"
          alt="Deposit"
        />
        <Flex className="w-11/12 text-black">
          <DangerousHtml className="pb-[24px]" html={message} />
        </Flex>
      </Flex>
    </Flex>
  );
};
