import { MARKETS } from 'Constants';
import { useLazyGetWarmUpDetailsQuery } from 'Services/playerWarmUp/playerWarmUpApi';
import { gatekeeper } from 'Src/gatekeeper';
import { useEffect, useState } from 'react';

export const useFameReelRacesVisibility = () => {
  const { marketSlug } = gatekeeper.localisation;
  const skipWarmUpQuery = marketSlug !== MARKETS.es_es;
  const [hideReelRaces, setHideReelRaces] = useState(true);

  const [
    fetchWarmUp,
    {
      data: warmUpData,
      isLoading: isWarmUpQueryLoading,
      isError: isWarmUpQueryError,
      isSuccess: isWarmUpQuerySuccess,
    },
  ] = useLazyGetWarmUpDetailsQuery();

  useEffect(() => {
    if (!skipWarmUpQuery) fetchWarmUp();
  }, [fetchWarmUp, skipWarmUpQuery]);

  useEffect(() => {
    setHideReelRaces(
      marketSlug === MARKETS.se_sv ||
        (!skipWarmUpQuery &&
          (isWarmUpQueryError || (isWarmUpQuerySuccess && warmUpData?.inWarmupPhase))) ||
        false
    );
  }, [
    marketSlug,
    skipWarmUpQuery,
    isWarmUpQueryError,
    isWarmUpQuerySuccess,
    warmUpData?.inWarmupPhase,
    setHideReelRaces,
  ]);

  const isReelRaceCheckLoading = isWarmUpQueryLoading;
  return {
    isReelRaceCheckLoading,
    hideReelRaces,
  };
};
