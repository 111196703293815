import { MarketingConsentComponent } from '../components';
import { TMarketingConsentPage } from 'Src/typedefs';

type Props = {
  marketingConsentPageData: TMarketingConsentPage;
  onConfirm: (value: boolean) => void;
  isLoader: boolean;
};

export const MarketingConsentPage = ({
  marketingConsentPageData,
  onConfirm,
  isLoader,
}: Props) => {
  return (
    <MarketingConsentComponent
      marketingConsentPageData={marketingConsentPageData}
      onConfirm={onConfirm}
      isLoader={isLoader}
    />
  );
};
