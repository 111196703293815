import { useDrawer } from '../../contexts';
import { TermsComponent } from './termsComponent';
import { mergeWelcomeOffersTerms } from '../../utilities';
import { Flex } from 'Src/atoms';
import { TWelcomeOfferTermsContainerProps } from './types';


export const WelcomeOfferTermsContainer = ({
  caviatesText,
  className,
  title = '',
  gtmEvent,
  welcomeOffers = [],
  bonusTerms,
}: TWelcomeOfferTermsContainerProps) => {
  const { toggleDrawer, updateDrawerContent } = useDrawer();

  // eslint-disable-next-line fp/no-let
  const textContent = mergeWelcomeOffersTerms(welcomeOffers, bonusTerms);

  const onOpenDrawer = (content: { title: string; description: string }) => {
    if (gtmEvent) {
      gtmEvent();
    }

    toggleDrawer();
    updateDrawerContent(content);
  };
  return (
    <Flex className="mb-md">
      <TermsComponent
        drawersData={{
          termsAndConditionsData: { title: title, description: textContent },
        }}
        termsText={caviatesText}
        onOpenDrawer={onOpenDrawer}
        className={className}
      />
    </Flex>
  );
};
