import React, { useState } from 'react';
import { Flex, Button, DangerousHtml, Image } from '../atoms';
import {
  TWelcomeOffer,
  TWelcomeOfferPage,
  TKeycloakUser,
  TErrorMessage,
} from '../typedefs';
import { UseDirectusContext, useDrawer } from '../contexts';
import { getErrorData } from '../service/directus/errors';
import { REGISTRATION_ERRORS, TEST_ID } from '../constants';
import { WelcomeOfferTermsContainer } from 'Components/termsAndConditions/welcomOfferTermsContainer';
import { replaceFirstName } from '../utilities';
import { PlayResponsibly } from 'Components/termsAndConditions/playResponsibly';
import { WelcomeOfferCarousel } from 'Components/welcomeOffer/welcomeOfferCarousel';
import { WoTermsDetails } from 'Components/welcomeOffer/WoTermsDetails';
import { WelcomeOfferHeader } from 'Components/welcomeOffer/welcomeOfferHeader';
import WelcomeOfferBG from '../assets/svgs/welcomeOfferBG.svg';

type Props = {
  isMgaGra?: boolean;
  isUKGC?: boolean;
  welcomeOffers?: TWelcomeOffer[];
  welcomeOfferPage?: TWelcomeOfferPage;
  keycloakUser?: TKeycloakUser;
  selectWelcomeOffer: (id: string) => void;
  sendTermsGtmEvent: () => void;
  isPlayResponsiblyEnabled?: boolean;
};

export const WelcomeOfferPage = ({
  isUKGC,
  isMgaGra,
  welcomeOffers,
  welcomeOfferPage,
  keycloakUser,
  selectWelcomeOffer,
  sendTermsGtmEvent,
  isPlayResponsiblyEnabled,
}: Props) => {

  const { errorData } = UseDirectusContext();
  const { toggleNotificationDrawer, updateNotificationDrawerContent } =
    useDrawer();

  const allErrorData = getErrorData(errorData);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const noWoNotification = allErrorData?.find(
    (message: TErrorMessage) =>
      message?.errorName === REGISTRATION_ERRORS.NO_WELCOME_OFFER_NOTIFICATION
  );
  const drawerContent = {
    title: noWoNotification?.title,
    description: noWoNotification?.message,
    onClick: selectWelcomeOffer,
    buttonText: noWoNotification?.buttonText || "I don't want the offer",
  };

  const welcomeMessage = replaceFirstName(
    welcomeOfferPage?.message,
    keycloakUser?.given_name
  );

  const skipWelcomeOffer = () => {
    if (isMgaGra || isUKGC) {
      selectWelcomeOffer(null);
    } else {
      toggleNotificationDrawer();
      updateNotificationDrawerContent(drawerContent);
    }
  };

  const welcomeOffersSorted = () => {
    const sortedOffers = welcomeOffers.sort((a, b) => {
      const containsCasinoA = a?.vertical?.toLowerCase().includes('casino');
      const containsCasinoB = b?.vertical?.toLowerCase().includes('casino');

      if (containsCasinoA && !containsCasinoB) return -1;
      if (!containsCasinoA && containsCasinoB) return 1;
      return 0;
    });
    return sortedOffers;
  };

  return (
    <Flex className="flex-1 h-full">
      <Image
        className={'w-full h-full object-cover fixed inset-0'}
        src={WelcomeOfferBG}
      />
      <WelcomeOfferHeader welcomeOfferPageData={welcomeOfferPage} />
      <Flex className="w-full overflow-y-auto flex-grow">
        <Flex className="flex flex-col items-center relative text-center my-lg">
          <DangerousHtml
            htmlClasses="max-w-[285px] tablet:max-w-[430px] font-['Midnight_Pro'] text-[32px] tablet:text-[48px] leading-[31px] tablet:leading-[45px] text-white uppercase !m-0" html={welcomeMessage}
            testID={TEST_ID.TEXT}
          />
        </Flex>
        <WelcomeOfferCarousel
          selectBtnText={welcomeOfferPage?.selectOfferBtnText}
          welcomeOffers={welcomeOffersSorted()}
          selectWelcomeOffer={selectWelcomeOffer}
          setCurrentSlideIndex={setCurrentSlideIndex}
          currentSlideIndex={currentSlideIndex}
        />
        {isPlayResponsiblyEnabled && (
          <PlayResponsibly
            playResponsiblyPlaceholder={welcomeOfferPage?.playResponsibly}
          />
        )}
        {isUKGC ? (
          <WoTermsDetails
            currentSlideIndex={currentSlideIndex}
            welcomeOffers={welcomeOffersSorted()}
            welcomeOfferPage={welcomeOfferPage}
            sendTermsGtmEvent={sendTermsGtmEvent}
          />
        ) : (
          <WelcomeOfferTermsContainer
            title={welcomeOfferPage?.terms?.title}
            gtmEvent={sendTermsGtmEvent}
            welcomeOffers={welcomeOffers}
            bonusTerms={welcomeOfferPage?.terms}
            className='text-purple-0'
          />
        )}
      </Flex>
      <Flex className="sticky bottom-0 py-sm bg-gradient-to-b from-[rgba(76,0,194,0)] via-[rgba(76,0,194,0.9)] to-[#4C00C2]">
        <Button
          className={'font-bold left-0 w-full text-grey-20 text-white'}
          onClick={skipWelcomeOffer}
          testID={TEST_ID.BUTTON_SECONDARY}
        >
          {welcomeOfferPage?.dontWant}
        </Button>
      </Flex>
    </Flex>
  );
};
