import { gatekeeper } from 'Src/gatekeeper';
import { type TToastLabel, type TToastKind } from 'Components/Notifications/Toast/Toast.types';
import { type TMarketingWidgetLabel } from 'Components/MarketingWidget/MarketingWidget.types';
import { type TGtmEventSubmitArgs } from './gtm.types';
import { GTM_ACTIONS, GTM_BASE, GTM_FEATURES } from './gtm.constants';
import { type TQuickEmailMarketingWidgetLabel } from 'Components/QuickEmailMarketingWidget/QuickEmailMarketingWidget.types';
import { type Dispatch, type SetStateAction } from 'react';

const gtmEventSubmit = (
  feature: TGtmEventSubmitArgs['feature'],
  action: TGtmEventSubmitArgs['action'],
  payload?: TGtmEventSubmitArgs['payload']
) => {
  gatekeeper.messageBus.publish(GTM_BASE.TOPIC, {
    kind: GTM_BASE.KIND,
    names: {
      feature,
      action,
    },
    data: payload,
  });
};

export const gtmToastDisplayed = (toastKind: TToastKind, name: string) => {
  const payload = {
    type: toastKind,
    name,
  };
  gtmEventSubmit(GTM_FEATURES.TOASTS, GTM_ACTIONS.SHOWN, payload);
};

export const gtmToastClicked = (toastKind: TToastKind, label: TToastLabel, name: string) => {
  const payload = {
    type: toastKind,
    label,
    name,
  };
  gtmEventSubmit(GTM_FEATURES.TOASTS, GTM_ACTIONS.CLICK, payload);
};

export const gtmValuableDetailsModalClicked = (valuableType: string, label: string) => {
  const payload = {
    valuable_type: valuableType,
    label,
  };
  gtmEventSubmit(GTM_FEATURES.VALUABLE_DETAILS_MODAL, GTM_ACTIONS.CLICK, payload);
};

export const gtmMarketingWidgetDisplayed = () => {
  gtmEventSubmit(GTM_FEATURES.MARKETING_WIDGET, GTM_ACTIONS.SHOWN);
};

export const gtmMarketingWidgetClicked = (label: TMarketingWidgetLabel) => {
  const payload = {
    label,
  };
  gtmEventSubmit(GTM_FEATURES.MARKETING_WIDGET, GTM_ACTIONS.CLICK, payload);
};

export const gtmQuickEmailMarketingWidgetAccepted = (label: TQuickEmailMarketingWidgetLabel) => {
  const payload = {
    label,
  };
  gtmEventSubmit(GTM_FEATURES.QUICK_EMAIL_MARKETING_WIDGET, GTM_ACTIONS.CLICK, payload);
};

export const gtmQuickEmailMarketingWidgetShown = (label: TQuickEmailMarketingWidgetLabel) => {
  const payload = {
    label,
  };
  gtmEventSubmit(GTM_FEATURES.QUICK_EMAIL_MARKETING_WIDGET, GTM_ACTIONS.SHOWN, payload);
};

export const gtmLosBannerDisplayed = () => {
  gtmEventSubmit(GTM_FEATURES.LOS_BANNER_WIDGET, GTM_ACTIONS.SHOWN);
};

export const gtmLosBannerClicked = () => {
  gtmEventSubmit(GTM_FEATURES.LOS_BANNER_WIDGET, GTM_ACTIONS.PLAY_NOW);
};

export const gtmLosBannerTermsClicked = () => {
  gtmEventSubmit(GTM_FEATURES.LOS_BANNER_WIDGET, GTM_ACTIONS.SHOW_TERMS);
};

export const gtmPlayerDetailsPopUpShown = (visitedPlayerId: string) => {
  const payload = {
    visitedPlayerId,
  };
  gtmEventSubmit(GTM_FEATURES.PLAYER_DETAILS_MODAL, GTM_ACTIONS.SHOWN, payload);
};

export const gtmPlayerDetailsPopUpGameLaunch = () => {
  gtmEventSubmit(GTM_FEATURES.PLAYER_DETAILS_MODAL, GTM_ACTIONS.GAME_LAUNCH);
};

// todo: need to initiate calling this after there was a dedicated page for hall of fame
export const gtmHallOfFamePageShown = () => {
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_PAGE, GTM_ACTIONS.SHOWN);
};

export const gtmHallOfFameGroupListShown = () => {
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_GROUP, GTM_ACTIONS.SHOWN);
};

export const gtmHallOfFameGroupCardClicked = () => {
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_GROUP, GTM_ACTIONS.CLICK);
};

export const gtmFameLeaderboardShown = () => {
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_LEADERBOARD, GTM_ACTIONS.SHOWN);
};

export const gtmHallOfFameListShown = () => {
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_LIST, GTM_ACTIONS.SHOWN);
};

export const gtmHallOfFameListCardClicked = (visitedPlayerId: string) => {
  const payload = {
    visitedPlayerId,
  };
  gtmEventSubmit(GTM_FEATURES.HALL_OF_FAME_LIST, GTM_ACTIONS.CLICK, payload);
};

export const gtmAchievementsCarouselShown = () => {
  gtmEventSubmit(GTM_FEATURES.ACHIEVEMENTS_CAROUSEL, GTM_ACTIONS.SHOWN);
};

export const gtmAchievementsPopUpShown = () => {
  gtmEventSubmit(GTM_FEATURES.ACHIEVEMENTS_MODAL, GTM_ACTIONS.SHOWN);
};

export const gtmValuablesListShown = () => {
  gtmEventSubmit(GTM_FEATURES.VALUABLES_LIST, GTM_ACTIONS.SHOWN);
};

export const gtmValuablePopUpClose = () => {
  gtmEventSubmit(GTM_FEATURES.VALUABLE_DETAILS_MODAL, GTM_ACTIONS.CLOSE);
};

// REEL RACE EVENTS START //

export const gtmReelRaceHeroShown = () => {
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO, GTM_ACTIONS.SHOWN);
};

export const gtmReelRaceHeroCardClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO_CARD, GTM_ACTIONS.CLICK, payload);
};

export const gtmReelRaceHeroCardOptinClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO_CARD, GTM_ACTIONS.OPTIN_CLICK, payload);
};

export const gtmReelRaceHeroCardPrizesClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO_CARD, GTM_ACTIONS.PRIZES_CLICK, payload);
};

export const gtmReelRaceHeroCardPlayClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO_CARD, GTM_ACTIONS.PLAY_CLICK, payload);
};

export const gtmReelRaceHeroCardLeaderboardClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_HERO_CARD, GTM_ACTIONS.LEADERBOARD_CLICK, payload);
};

export const gtmReelRaceUpcomingShown = () => {
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_UPCOMING, GTM_ACTIONS.SHOWN);
};

export const gtmReelRaceUpcomingCardClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_UPCOMING_CARD, GTM_ACTIONS.CLICK, payload);
};

export const gtmReelRaceUpcomingCardOptinClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_UPCOMING_CARD, GTM_ACTIONS.OPTIN_CLICK, payload);
};

export const gtmReelRaceUpcomingCardPrizesClicked = (reelRaceId?: string) => {
  const payload = {
    reelRaceId: reelRaceId ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.REEl_RACES_UPCOMING_CARD, GTM_ACTIONS.PRIZES_CLICK, payload);
};

// REEL RACE EVENTS END //

export const gtmRidabodoWidgetInGameDisplayed = (leaderboardId?: string, game_slug?: string) => {
  const payload = {
    leaderboardId: leaderboardId ?? '',
    game_slug: game_slug ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_IN_GAME_WIDGET, GTM_ACTIONS.SHOWN, payload);
};

export const gtmRidadbodoWidgetInGameClickedCta = (
  clicked_on: 'leaderboard' | 'optin' | 'rules' | 'terms' | 'info-icon' | 'play',
  leaderboardId: string
) => {
  const payload = {
    clicked_on,
    leaderboardId,
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_IN_GAME_WIDGET, GTM_ACTIONS.CLICK, payload);
};

export const gtmRidadbodoInfoDrawerClickedCta = (
  tab: 'Rules' | 'Prizes' | 'Leaderboard' | 'Games' | 'Games-or-Rules',
  clicked_on: 'game' | 'optin' | 'terms',
  leaderboardId: string,
  game_slug?: string
) => {
  const payload = {
    tab,
    clicked_on,
    leaderboardId,
    game_slug: game_slug ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_INFO_DRAWER, GTM_ACTIONS.CLICK, payload);
};

export const gtmRidadbodoInviteDrawerClickedCta = (
  clicked_on: 'game' | 'optin' | 'terms',
  leaderboardId: string,
  game_slug?: string
) => {
  const payload = {
    clicked_on,
    leaderboardId,
    game_slug: game_slug ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_INVITE_DRAWER, GTM_ACTIONS.CLICK, payload);
};

export const gtmRidadbodoPromotionDetailsClickedCta = (
  clicked_on: 'game' | 'optin' | 'terms' | 'play' | 'leaderboard',
  leaderboardId: string,
  game_slug?: string
) => {
  const payload = {
    clicked_on,
    leaderboardId,
    game_slug: game_slug ?? '',
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_PROMOTION_PAGE, GTM_ACTIONS.CLICK, payload);
};

export const gtmRidadbodoOptinToastShown = (leaderboardId: string) => {
  const payload = {
    leaderboardId,
  };
  gtmEventSubmit(GTM_FEATURES.RIDABODO_TOAST, GTM_ACTIONS.SHOWN, payload);
};

export const gtmRRWidgetInGameDisplayed = () => {
  gtmEventSubmit(GTM_FEATURES.REEL_RACES_IN_GAME_WIDGET, GTM_ACTIONS.SHOWN);
};

export const gtmRRWidgetInGameClickedCta = (type: 'prizes' | 'optin' | 'play') => {
  const payload = {
    type,
  };
  gtmEventSubmit(GTM_FEATURES.REEL_RACES_IN_GAME_WIDGET, GTM_ACTIONS.CLICK, payload);
};

export const gtmCuratedCardListShown = () => {
  gtmEventSubmit(GTM_FEATURES.CURATED_CARD_LIST, GTM_ACTIONS.SHOWN);
};

export const gtmCuratedCardListScrolled = ({
  setScrollEventFired,
  scrollEventFired,
}: {
  setScrollEventFired: Dispatch<SetStateAction<boolean>>;
  scrollEventFired: boolean;
}) => {
  if (scrollEventFired) return;
  gtmEventSubmit(GTM_FEATURES.CURATED_CARD_LIST, GTM_ACTIONS.SCROLL);
  setScrollEventFired(true);
};

export const gtmCuratedCardShown = (cardName: string) => {
  const payload = {
    cardName,
  };
  gtmEventSubmit(GTM_FEATURES.CURATED_CARD, GTM_ACTIONS.SHOWN, payload);
};

export const gtmCuratedCardClicked = (clickType: 'card' | 'button' | 'game', cardName: string) => {
  const payload = {
    cardName,
  };

  if (clickType === 'card') {
    gtmEventSubmit(GTM_FEATURES.CURATED_CARD, GTM_ACTIONS.CLICK, payload);
  } else if (clickType === 'button') {
    gtmEventSubmit(GTM_FEATURES.CURATED_CARD_BUTTON, GTM_ACTIONS.CLICK, payload);
  } else if (clickType === 'game') {
    gtmEventSubmit(GTM_FEATURES.CURATED_CARD_GAME, GTM_ACTIONS.CLICK, payload);
  }
};
