import { SVGProps } from 'react';

export const SpinnerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.6395 5.60895C39.58 6.7434 42.2682 8.44592 44.5507 10.6193C46.8332 12.7927 48.6653 15.3943 49.9423 18.2757C51.2194 21.1571 51.9164 24.2619 51.9936 27.4126C52.0708 30.5634 51.5266 33.6985 50.3921 36.639C49.2577 39.5795 47.5552 42.2677 45.3818 44.5502C43.2084 46.8327 40.6068 48.6648 37.7253 49.9419C34.8439 51.2189 31.7392 51.9159 28.5884 51.9931C25.4377 52.0703 22.3025 51.5261 19.3621 50.3916C16.4216 49.2572 13.7333 47.5547 11.4508 45.3813C9.16833 43.2079 7.33625 40.6063 6.0592 37.7249C4.78215 34.8435 4.08514 31.7387 4.00797 28.588C3.9308 25.4372 4.47497 22.3021 5.60943 19.3616C6.74388 16.4211 8.44639 13.7329 10.6198 11.4504C12.7931 9.16786 15.3948 7.33578 18.2762 6.05873C21.1576 4.78168 24.2623 4.08467 27.4131 4.0075C30.5639 3.93032 33.699 4.4745 36.6395 5.60895L36.6395 5.60895Z"
      stroke="#DAD2FF"
      strokeWidth="8"
    />
    <path
      d="M36.6395 5.60895C41.3681 7.43327 45.3978 10.7077 48.151 14.963C50.9042 19.2183 52.2395 24.236 51.9657 29.2969"
      stroke="#4C00C2"
      strokeWidth="8"
      strokeLinecap="round"
    />
  </svg>
);
