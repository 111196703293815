import { TTerm } from '../../../typedefs';
import { adaptTermsAndConditions } from './adapters';
import { DEFAULT_LANGUAGE } from '../../../constants';
import { gatekeeper } from '../../../gatekeeper';

export interface ITermsData {
  translations: {
    [key: string]: any;
    languages_code: { code: string };
  };
  [key: string]: any; // Allow additional unknown properties
}

/* This was included as without specificity jest test was failing.
   The any is there as not all properties being received are known these are not used here so type safety will not matter.
   The ones we are using here now have a higher level of specificity.

   This function is returning the necessary terms based on the termsAndConditionsType. 
   For more reference see TERMS_AND_CONDITIONS_TYPES
*/

export const getTermsData = (
  termsData: ITermsData,
  termsAndConditionsType: string
): TTerm => {
  if (!termsData) {
    return;
  }

  const language = gatekeeper.localisation.localeCode;

  const lang = language || DEFAULT_LANGUAGE;

  const termsType = termsData?.filter(
    (type) => type.name === termsAndConditionsType
  );
  const terms = termsType[0]?.translations.find(
    (translation) => translation?.languages_code?.code === lang
  );
  const termsAndConditions: TTerm = adaptTermsAndConditions(terms);
  return termsAndConditions;
};
