import { useEffect, useState } from 'react';
import {
  UseRegistrationContext,
  UseDirectusContext,
  useDrawer,
  useHeader,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  INPUT_TYPES,
  REGISTRATION_ERRORS,
  GTM_ERRORS,
  REGISTRATION_FLOW_STEPS,
  GTM_FEATURES,
  GTM_ACTIONS,
} from '../../../constants';
import { getEmailAndPhonePageData } from '../../../service/directus/emailAndPhone';
import {
  TErrorMessage,
  TEmailValidationResponse,
  TPhoneValidationResponse,
  TFlags,
} from 'Src/typedefs';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getBonusMessage,
  getStepNumberTranslation,
} from '../../../utilities';
import {
  emailValidationResult,
  phoneValidationResult,
} from '../../../validations';
import { getErrorData } from '../../../service/directus/errors';
import { setAlertGtmEvent, sendGtmEvent } from '../../../gtm';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { adaptCountryFlags } from '../../../service/directus/countryFlags/adapters';
import { EmailAndPhonePage } from '../../../pages/emailAndPhonePage';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';

export const EmailAndPhoneContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();

  const { setHeaderTitle } = useHeader();

  const { registrationFlowsData, errorData } = UseDirectusContext();
  const emailAndPhonePageData = getEmailAndPhonePageData(registrationFlowsData);

  const { countryFlagsData } = UseDirectusContext();
  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData);

  const allErrorData: TErrorMessage[] = getErrorData(errorData);

  const [email, setEmail] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneCountry, setPhoneCountry] = useState<TFlags | null>(null);
  const [enablePrefixSelector, setEnablePrefixSelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { isFlagDrawerOpen, toggleFlagDrawer } = useDrawer();
  const stepNumber = getStepNumberTranslation('3', '4')?.sv;

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.LIMITS);
    storeDataInLocalStorage(ROUTE_NAMES.EMAIL_AND_PHONE, userValues);
    setHeaderTitle(emailAndPhonePageData.header_title);

    const selectedCountry = (prefix = '+46') => {
      return adaptedCountryFlags.filter(
        (country) => country.countryCode === prefix
      );
    };

    // Read stored data and set it if there is any.
    if (userValues?.email) {
      setEmail(userValues?.email);
    }
    if (userValues?.phoneNumber?.number) {
      setPhoneNumber(`${userValues?.phoneNumber?.number}`);
    }
    if (userValues?.phoneNumber?.country) {
      const country = userValues?.phoneNumber?.country;
      setPhoneCountry(country);
    } else {
      setPhoneCountry(selectedCountry()[0]);
    }
  }, []);

  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          emailAndPhonePageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [emailAndPhonePageData]);

  const changeValue = (value, inputType) => {
    if (inputType === INPUT_TYPES.EMAIL) {
      setEmail(value);
      setEmailError(null);
    }
    if (inputType === INPUT_TYPES.PHONE) {
      if (value.includes('.') || (isNaN(value) && value !== '+')) {
        return;
      }
      setPhoneNumber(value);
      setPhoneNumberError(null);
    }
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  const changePhoneCountry = (country: TFlags) => {
    if (!country) {
      return;
    }
    setPhoneCountry(country);
    toggleFlagDrawer();
    if (!phoneNumber) {
      setPhoneNumberError(null);
    }
  };

  const clearLimit = (inputType) => {
    if (inputType === INPUT_TYPES.EMAIL) {
      setEmail(null);
      setEmailError(null);
    }
    if (inputType === INPUT_TYPES.PHONE) {
      setPhoneNumber(null);
      setPhoneNumberError(null);
    }
  };

  if (routeName !== ROUTE_NAMES.EMAIL_AND_PHONE) {
    return null;
  }

  // onContinue refers to the option if the validation happens on a click
  // action or it happens on a blur action.
  const emailValidation = async (onContinue: boolean) => {
    if (!email && !onContinue) {
      setEmailError(null);
      return false;
    }
    const validationResult: TEmailValidationResponse =
      await emailValidationResult(email, onContinue);
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      if (validationResult.error === REGISTRATION_ERRORS.DUPLICATE_EMAIL) {
        setAlertGtmEvent({
          alert: GTM_ERRORS.EMAIL_TAKEN,
          step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
        });
      }
      setEmailError(message?.message);
      return false;
    }
    setEmailError(null);
    return true;
  };

  // onContinue refers to the option if the validation happens on a click
  // action or it happens on a blur action.
  const phoneValidation = async (onContinue: boolean) => {
    if (!phoneNumber && !onContinue) {
      setPhoneNumberError(null);
      return false;
    }
    const validationResult: TPhoneValidationResponse =
      await phoneValidationResult(
        phoneCountry.countryCode,
        phoneNumber,
        onContinue
      );
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      if (validationResult.error === REGISTRATION_ERRORS.DUPLICATE_PHONE) {
        setAlertGtmEvent({
          alert: GTM_ERRORS.PHONE_TAKEN,
          step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
        });
      }
      setPhoneNumberError(message?.message);
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const onConfirm = async () => {
    const onContinue = true; // This is value is resposnible to do full validation before continue in the registration.
    const isEmailCorrect = await emailValidation(onContinue);
    const isPhoneCorrect = await phoneValidation(onContinue);
    if (isEmailCorrect && isPhoneCorrect) {
      setUserValues({
        ...userValues,
        email: email,
        phoneNumber: {
          country: phoneCountry,
          number: phoneNumber,
        },
      });
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.ACCOUNT_DETAILS);
      setRouteName(ROUTE_NAMES.NATIONALITY);
    }
  };

  const dependencyArray = [email, phoneNumber, isFlagDrawerOpen];
  UseHandleKeyDown(dependencyArray, !isFlagDrawerOpen, onConfirm);

  return (
    <EmailAndPhonePage
      description={description}
      pageData={emailAndPhonePageData}
      changeValue={changeValue}
      clearValue={clearLimit}
      email={email}
      phoneNumber={phoneNumber}
      validateEmail={emailValidation}
      validatePhone={phoneValidation}
      emailError={emailError}
      phoneNumberError={phoneNumberError}
      selectedCountry={phoneCountry}
      openPrefixSelector={() => {
        setEnablePrefixSelector(true);
        toggleFlagDrawer();
      }}
      onConfirm={onConfirm}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enablePrefixSelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      changeSearchValue={changeSearchValue}
      searchValue={searchValue}
      selectedPrefix={phoneCountry?.countryCode}
      setPhoneCountry={changePhoneCountry}
      userValues={userValues}
      stepNumber={stepNumber}
    />
  );
};
