import { useEffect, useState } from 'react';
import { UseDirectusContext } from '../../contexts';
import { TDepositLimitsPage } from '../../typedefs';
import { selectDepositLimitsPageData } from './depositLimitsPageDataSelector';

export const UseGetDepositLimitsPage = () => {
  const { registrationFlowsData } = UseDirectusContext(); // Loads directus cms data.
  const [depositLimitsPageData, setDepositLimitsPageData] =
    useState<TDepositLimitsPage | null>(null);

  useEffect(() => {
    setDepositLimitsPageData(
      selectDepositLimitsPageData(registrationFlowsData)
    );
  }, [registrationFlowsData]);

  return { depositLimitsPageData };
};
