import { Flex, Image, Text } from 'Src/atoms';
import CloseRegistration from '../../assets/svgs/close-icon-white.svg';
import SecureIcon from '../../assets/svgs/Check.svg';
import BackIconRegistration from '../../assets/svgs/back-icon-white.svg';
import CasumoLogo from '../../assets/svgs/Casumo-Logo-white.svg';
import { gatekeeper } from 'Src/gatekeeper';
import { REGISTRATION_MODAL_SESSION_STORAGE_KEY } from 'Src/constants';
import { UseRegistrationContext, UseTimerContext } from 'Src/contexts';
import { setRegistrationClosedGtmEvent } from 'Src/gtm';
import { TWelcomeOfferPage } from 'Src/typedefs';
import { useRegistrationModal } from 'Src/contexts/modal-registration-context';

interface WelcomeOfferHeaderProps {
    welcomeOfferPageData: TWelcomeOfferPage;
}

export const WelcomeOfferHeader = ({ welcomeOfferPageData }: WelcomeOfferHeaderProps) => {
    const { setRouteName, previousRouteName } = UseRegistrationContext();
    const { storeTimerInfo } = UseTimerContext();
    const { closeModal } = useRegistrationModal();

    const onCLose = () => {
        storeTimerInfo();
        setRegistrationClosedGtmEvent();
        closeModal();
    }
    const onBack = () => {
        setRouteName(previousRouteName);
    }

    return (
        <Flex direction="flex-row" className="sticky top-0 bg-[#6E28FA] w-full items-center justify-between tablet:px-2xl tablet:py-md z-10">
            <Flex className='p-sm tablet:p-0'>
                {previousRouteName ?
                    <Image
                        className="w-6 h-6 text-white cursor-pointer"
                        src={BackIconRegistration}
                        title="Back"
                        alt="back"
                        onClick={onBack}
                    />
                    : <div className='w-6' />}
            </Flex>
            <Flex direction='flex-row' className="text-grey-90 font-bold text-lg h-md tablet:flex gap-[7px] align-items-center justify-center hidden">
                <Image
                    className="w-6 h-6"
                    src={SecureIcon}
                    title="CloseRegistration"
                    alt="Close"
                />
                <Text className='m-0 text-white'>{welcomeOfferPageData?.header_title}</Text>
            </Flex>
            <Flex className="flex justify-center tablet:hidden">
                <Image
                    src={CasumoLogo}
                    title="rebrand-Logo"
                    alt="logo"
                    onClick={() => {
                        onCLose();
                        gatekeeper.navigation.navigate("/")
                    }}
                />
            </Flex>
            <Flex className='p-sm tablet:p-xs'>
                <Image
                    className="w-6 h-6 cursor-pointer tablet:hidden"
                    src={CloseRegistration}
                    title="CloseRegistration"
                    alt="Close"
                    onClick={onCLose}
                />
            </Flex>
        </Flex>
    );
};
