import { DEFAULT_LANGUAGE } from '../../../constants';
import {
  TWelcomeOfferPage,
  TWelcomeOffer,
  IWelcomeOfferValuable,
  DirectusImage
} from 'Src/typedefs';

interface IWelcomeOfferTranslation {
  title: string | null;
  short_description: string | null;
  significant_terms: string | null;
  full_terms: string | null;
  description: string | null;
  languages_code: {
    code: string;
  };
  widget_title: string | null;
}
interface IWelcomeOffer {
  id: string;
  valuable: IWelcomeOfferValuable | null;
  type: string | null;
  name: string | null;
  vertical: string | null;
  big_image: string | null;
  small_image: string | null;
  min_deposit_amount: number | null;
  max_bonus_amount: number | null;
  percent: number | null;
  translations: Array<IWelcomeOfferTranslation>;
  thumbnail_image: DirectusImage | null;
  token_image: DirectusImage | null;
}

// export interface IWelcomeOfferValuable {
//   collection: string | null;
//   id: number | null;
//   item: IValuableItem;
// }

export interface IValuableItem {
  translations: Array<IValuableItemData>;
}

export interface IValuableItemData {
  caveat: string | null;
  content: string | null;
  thumbnail_image: string | null;
  languages_code: string | null;
  title: string | null;
}

interface IWelcomeOfferPage {
  header_title: string;
  message: string;
  skip_offer: string;
  widget_title_sport: string;
  widget_title_casino: string;
  welcome_offer_message_title: string;
  play_responsibly: string;
  read_terms_description?: string
  select_offer_button: string;
}

export const adaptWelcomeOfferPage = (data: IWelcomeOfferPage) => {
  const result: TWelcomeOfferPage = {
    message: data?.message || null,
    dontWant: data?.skip_offer || null,
    header_title: data?.header_title || null,
    casinoWidgetTitle: data?.widget_title_casino,
    sportWidgetTitle: data?.widget_title_sport,
    welcomeOfferMessageTitle: data?.welcome_offer_message_title,
    playResponsibly: data?.play_responsibly || null,
    readTermsDescription: data?.read_terms_description || null,
    selectOfferBtnText: data?.select_offer_button
  };
  return result;
};

export const adaptWelcomeOffers = (
  welcomeOffers: IWelcomeOffer[],
  language: string
) => {
  if (!welcomeOffers || welcomeOffers.length < 1) {
    return;
  }

  const lang = language || DEFAULT_LANGUAGE;
  const result: TWelcomeOffer[] = welcomeOffers.map((wo) => {
    const translation: IWelcomeOfferTranslation = wo?.translations?.find(
      (translation) => translation.languages_code?.code === lang
    );

    return {
      id: wo.id?.toString(),
      name: wo.name,
      vertical: wo.vertical,
      valuable: wo.valuable,
      type: wo.type,
      bigImage: wo.big_image,
      smallImage: wo.small_image,
      title: translation?.title,
      shortDesc: translation?.short_description,
      minDepositAmount: wo.min_deposit_amount,
      maxBonusAmount: wo.max_bonus_amount,
      percentage: wo.percent,
      significantTerms: translation?.significant_terms,
      fullTerms: translation?.full_terms,
      thumbnailImage: wo.thumbnail_image,
      tokenImage: wo.token_image,
      widgetTitle: translation.widget_title
    };
  });
  return result;
};
