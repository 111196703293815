import { MarketingConsentDrawer, MarketingConsentUKGCComponent } from '../components';
import { TMarketingConsentPageUKGCProps } from 'Src/typedefs';

export const MarketingConsentPageUKGC = ({
  marketingConsentPageData,
  onConfirm,
  isLoader,
  isDrawerOpen,
  toggleDrawer,
  enableDrawer,
  handleOpenDrawer,
  notifications,
  handleChangeNotification,
}: TMarketingConsentPageUKGCProps) => {
  return (
    <>
      <MarketingConsentUKGCComponent
        marketingConsentPageData={marketingConsentPageData}
        onConfirm={onConfirm}
        isLoader={isLoader}
        handleOpenDrawer={handleOpenDrawer}
      />
      {isDrawerOpen &&
        <MarketingConsentDrawer
          notifications={notifications}
          handleChangeNotification={handleChangeNotification}
          marketingConsentPageData={marketingConsentPageData}
          onConfirm={onConfirm}
          enableDrawer={enableDrawer}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={toggleDrawer} />}
    </>

  );
};
