import {
  REGISTRATION_ERRORS,
  HEADER_NAMES,
  BE_FE_ERRORS_MAP,
  REGISTRATION_API_RESPONSES,
} from '../../constants';
import {
  TEmailValidationResponse,
  TPhoneValidationResponse,
} from 'Src/typedefs';
import { registrationServiceClient } from './client';
import { CASUMO_BRAND_ID } from '../../../env.constants';

export const validateEmailOnBE = async (
  email: string
): Promise<TEmailValidationResponse> => {
  try {
    const config = {
      headers: {
        [HEADER_NAMES.CASUMO_BRAND_ID]: `${CASUMO_BRAND_ID}`,
      },
    };
    const response = await registrationServiceClient.post(
      '/validation/email',
      {
        email,
      },
      config
    );
    if (response?.data?.result === REGISTRATION_API_RESPONSES.VALID) {
      return { success: true };
    }
    return {
      success: false,
      error: REGISTRATION_ERRORS.UNKNOWN,
    };
  } catch (error) {
    const errorCodeBE = error?.response?.data?.result;
    const errorCodeFE =
      BE_FE_ERRORS_MAP[errorCodeBE] || REGISTRATION_ERRORS.UNKNOWN;

    return {
      success: false,
      error: errorCodeFE,
    };
  }
};

export const validatePhoneNumberOnBE = async (
  prefix: string,
  number: string
): Promise<TPhoneValidationResponse> => {
  try {
    const config = {
      headers: {
        [HEADER_NAMES.CASUMO_BRAND_ID]: `${CASUMO_BRAND_ID}`,
      },
    };
    const response = await registrationServiceClient.post(
      '/validation/phone-number',
      {
        prefix,
        number,
      },
      config
    );
    if (response?.data?.result === REGISTRATION_API_RESPONSES.VALID) {
      return { success: true };
    }
    return {
      success: false,
      error: REGISTRATION_ERRORS.UNKNOWN,
    };
  } catch (error) {
    const errorCodeBE = error?.response?.data?.result;
    const errorCodeFE =
      BE_FE_ERRORS_MAP[errorCodeBE] || REGISTRATION_ERRORS.UNKNOWN;

    return {
      success: false,
      error: errorCodeFE,
    };
  }
};
