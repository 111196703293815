import React, { useEffect } from 'react';
import { GOOGLE_PLACES_API_KEY } from '../constants';

declare global {
  interface Window {
    initMap: () => void;
  }
}

const GoogleApiLoader = () => {
  useEffect(() => {
    window.initMap = function () {
      console.log(
        'Google Maps API script loaded successfully and initMap called'
      );
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&loading=async&libraries=places&callback=initMap`;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, [GOOGLE_PLACES_API_KEY]);

  return <></>;
};

export default GoogleApiLoader;
