import { useState, useEffect } from 'react';
import { MgaGraRegistrationRouter } from '../../../routers';
import { DefaultLayoutPage } from '../../../pages/defaultLayout';
import { UseRegistrationContext, UseTimerContext } from '../../../contexts';
import {
  getCookieByName,
  getStoredItem,
  redirectPlayerToCorrectMarket,
  storeDataInLocalStorage,
} from '../../../utilities';
import { setRegistrationClosedGtmEvent } from '../../../gtm';
import { COOKIE_NAMES, ROUTE_NAMES } from 'Src/constants';
import { useGetDirectusData } from 'Src/hooks/directus';
import { useRegistrationModal } from 'Src/contexts/modal-registration-context';
import { gatekeeper } from 'Src/gatekeeper';

const MgaGraRegistration = () => {
  const {
    routeName,
    userValues,
    previousRouteName,
    setRouteName,
    setUserValues,
    registrationError,
    setIsNative,
  } = UseRegistrationContext();
  const { timerStart, storeTimerInfo } = UseTimerContext();
  const { isDataReady } = useGetDirectusData();

  const [isLoaderDone, setIsLoaderDone] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const { isRegistraionModalOpen, closeModal } = useRegistrationModal();

  useEffect(() => {
    if (isDataReady) {
      setTimeout(() => {
        setIsLoaderDone(true);
      }, 10);
    }
  }, [isDataReady]);


  useEffect(() => {
    const isNativeCookie = getCookieByName(COOKIE_NAMES.IS_NATIVE_COOKIE);
    if (isNativeCookie) {
      setIsNative(true);
    }
  }, []);

  useEffect(() => {
    const { routeName, userValues } = getStoredItem();
    if (
      userValues?.countryOfResidenceCountryCode &&
      routeName !== ROUTE_NAMES.COUNTRY_OF_RESIDENCE
    ) {
      redirectPlayerToCorrectMarket(
        userValues.countryOfResidenceCountryCode,
        userValues.selectedProvinceCode
      ).then(() => {
        setRouteName(routeName || ROUTE_NAMES.COUNTRY_OF_RESIDENCE);
      });
    } else {
      setRouteName(routeName || ROUTE_NAMES.COUNTRY_OF_RESIDENCE); // Initial routeselection.
    }
    setUserValues(userValues);
    timerStart(); // Start measuring the time spent on registration.
  }, []);

  useEffect(() => {
    if (registrationError) {
      setError(registrationError);
    }
  }, [registrationError]);

  const closeRegistrationModal = async () => {
    storeDataInLocalStorage(routeName, userValues);
    storeTimerInfo();
    setRegistrationClosedGtmEvent();

    if (gatekeeper.navigation.isActiveRoute("/register")) {
      await gatekeeper.navigation.navigate("/")
    }

    closeModal();
  };

  const stepBack = () => {
    setRouteName(previousRouteName);
  };

  const stepBackEnabled = () => {
    return Boolean(previousRouteName);
  };

  if (!isRegistraionModalOpen || !isLoaderDone) {
    return null;
  }

  if (error) {
    console.error(error); // We need this to see the error on FullStory.
  }

  return (
    <DefaultLayoutPage
      closeModal={closeRegistrationModal}
      stepBack={stepBack}
      stepBackEnabled={stepBackEnabled}
      error={error}
      RouterComponent={MgaGraRegistrationRouter}
    />
  );
};

export default MgaGraRegistration;