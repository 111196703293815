import React, { createContext, useContext, useState } from 'react';
import { REGISTRATION_MODAL_SESSION_STORAGE_KEY } from 'Src/constants';
import { setStartGtmEvents } from 'Src/gtm';

const ModalRegistrationContext = createContext({ isRegistraionModalOpen: false, openModal: () => { }, closeModal: () => { } });

export const ModalRegistrationContextProvider = ({ children }) => {
    const [isRegistraionModalOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        setStartGtmEvents();
    }
    const closeModal = () => {
        setIsOpen(false);
        sessionStorage.removeItem(REGISTRATION_MODAL_SESSION_STORAGE_KEY);
    };

    return (
        <ModalRegistrationContext.Provider value={{ isRegistraionModalOpen, openModal, closeModal }}>
            {children}
        </ModalRegistrationContext.Provider>
    );
};

export const useRegistrationModal = () => {
    return useContext(ModalRegistrationContext);
};
