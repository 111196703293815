import { TPaymentMethod } from 'Src/typedefs';
import { paymentServiceClient } from './client';

export const getPaymentMethods = async (
  market: string
): Promise<TPaymentMethod[]> => {
  try {
    const response = await paymentServiceClient.get(`/pay-and-play/${market}`);
    if (response?.status === 200) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const noneBlockedMethods = response?.data.filter(
        (method) => !method?.permissions?.blocked
      );
      if (noneBlockedMethods?.length > 0) {
        return noneBlockedMethods;
      }
    }
    // We are returning null to indicate that either all the payment methods are blocked
    // or payment API is down so we know that we need to skip the deposit step.
    return null;
  } catch (error) {
    return null;
  }
};

const mockSwishData = {
  id: '3a8dd762-97a9-4d23-93ef-883bcaebdbcb',
  type: 'PAYMENT_METHOD',
  name: 'SWISH',
  suggestedAmounts: [
    {
      amount: 115,
      iso4217CurrencyCode: 'SEK',
    },
    {
      amount: 220,
      iso4217CurrencyCode: 'SEK',
    },
    {
      amount: 420,
      iso4217CurrencyCode: 'SEK',
    },
  ],
  limits: {
    maxAmount: {
      amount: 800,
      iso4217CurrencyCode: 'SEK',
    },
    minAmount: {
      amount: 50,
      iso4217CurrencyCode: 'SEK',
    },
    minLimitType: 'PAYMENT_METHOD_LIMIT',
    maxLimitType: 'PAYMENT_METHOD_LIMIT',
  },
  group: null,
  providerType: 'PIQ',
  recommended: true,
  permissions: {
    blocked: false,
    blockReasons: ['string'],
  },
};
