import TermsCookiesPolicy from 'Components/termsAndConditions/termsCookiesPolicy';
import Checkbox from 'Src/atoms/checkbox';
import { TCheckBoxMolecule } from 'Src/typedefs';

const CheckBoxMolecule = ({
  sendTermsGtmEvent,
  birthDetailsPageData,
  termsAndPolicy,
  handleChangeTermsAndPolicy,
  termsAndPrivacyError,
}: TCheckBoxMolecule) => {
  const commonProps = {
    handleClick: handleChangeTermsAndPolicy,
    termsAndPolicy,
  };
  const terms = [
    {
      name: 'termsAndConditions',
      termsText: birthDetailsPageData.generalTermsAndConditionsText as string,
      isError: termsAndPrivacyError && !termsAndPolicy.termsAndConditions,
      termsAndPrivacyError,
      checked: termsAndPolicy.termsAndConditions,
      ...commonProps,
      privacyText: null,
    },
    {
      name: 'privacyPolicy',
      privacyText: birthDetailsPageData.privacyPolicyText as string,
      isError: termsAndPrivacyError && !termsAndPolicy.privacyPolicy,
      termsAndPrivacyError,
      checked: termsAndPolicy.privacyPolicy,
      ...commonProps,
      termsText: null,
    },
  ];
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    name: string,
    checked: boolean
  ) => {
    if (event.key === ' ' || event.key === 'Enter')
      handleChangeTermsAndPolicy(name, !checked);
  };

  return (
    <div className="flex flex-col  justify-between align-items-center gap-5 mt-5 ">
      {terms.map((term, index) => (
        <div
          className="flex flex-row align-items-center"
          key={index}
          role="checkbox"
          aria-checked={term.checked}
          aria-label={term.name}
          aria-invalid={term.isError}
          tabIndex={index}
          onKeyDown={(event) => handleKeyDown(event, term.name, term.checked)}
        >
          <Checkbox
            name={term.name}
            checked={term.checked}
            handleClick={term.handleClick}
            isError={term.isError}
          />
          <TermsCookiesPolicy
            gtmEvent={sendTermsGtmEvent}
            termsText={term?.termsText}
            privacyText={term?.privacyText}
            isError={term.isError}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckBoxMolecule;
