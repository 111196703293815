import { randomNumberGenerator, randomStringGenerator } from '../utilities';
import { TKeycloakUser } from '../typedefs';

export const updateTestAddress = (devcodeUser: TKeycloakUser) => {
  if (window) {
    const urlParams = new URLSearchParams(window.location.search);
    const withAddress = urlParams.get('withMockedAddress');
    if (withAddress !== 'true') {
      return devcodeUser;
    }
    const address = craeteRandomAddress();
    return { ...devcodeUser, ...address };
  }
  return devcodeUser;
};

const craeteRandomAddress = () => {
  const randomStreetName = `${randomStringGenerator(8)} street`;
  const randomPostalCode = `11${randomNumberGenerator(999, 100)}`;
  return {
    address_country: 'SE',
    address_city: 'STOCKHOLM',
    address_is_verified: true,
    address_street_name: randomStreetName,
    address_postal_code: randomPostalCode,
  };
};
