import { getAssetURL } from '../../utilities';
import { ICON_WIDTH, type ICON_SIZES } from './valuableThumbnailConstants';
import { TWelcomeOffer } from '../../typedefs';
import { Image } from '../../atoms';
import { DEFAULT_LANGUAGE } from '../../constants';
import { gatekeeper } from '../../gatekeeper';
import classNames from 'classnames';

type TProps = {
  size?: (typeof ICON_SIZES)[keyof typeof ICON_SIZES];
  welcomeOffer: TWelcomeOffer;
  testId?: string;
  className?: string;
};

/*
  This is the component that are responsible for selecting and diferentiating between the building blocks of the ValuableSvg.
  @param {string} size - the size that will be used to display the ValuableSvg.
  @param {TWelcomeOffer} welcomeOffer - the welcomeOffer from directus CMS.
  @param {string} testId - the testId that will be used to test the component.
  @returns {JSX.Element} that displays ValuableSvg based on bonus type.
*/

export const ValuableThumbnail = ({ size, welcomeOffer, testId, className }: TProps) => {
  const lang = gatekeeper.localisation.localeCode || DEFAULT_LANGUAGE;

  const width = ICON_WIDTH[size] || 'full';

  let imageId;

  if (welcomeOffer?.valuable) {
    imageId = welcomeOffer?.valuable?.translations?.find(
      (translation) => translation.languages_code?.code === lang
    )?.thumbnail_image?.filename_disk;
  } else {
    imageId = welcomeOffer?.thumbnailImage?.filename_disk;
  }

  return (
    <div style={{ maxWidth: width, minWidth: width }} data-testid={testId}>
      <Image
        className={classNames(`w-[${width}]`, className)}
        src={getAssetURL(imageId)} />
    </div>
  );
};

export const ValuableToken = ({ size, welcomeOffer, testId }: TProps) => {
  const lang = gatekeeper.localisation.localeCode || DEFAULT_LANGUAGE;

  const width = ICON_WIDTH[size] || 'full';

  let imageId;

  if (welcomeOffer?.valuable) {
    imageId = welcomeOffer?.valuable?.translations?.find(
      (translation) => translation.languages_code?.code === lang
    )?.token_image?.filename_disk;
  } else {
    imageId = welcomeOffer?.tokenImage?.filename_disk;
  }

  return (
    <div style={{ maxWidth: width, minWidth: width }} data-testid={testId}>
      <Image className={`w-[${width}]`} src={getAssetURL(imageId)} />
    </div>
  );
};
