/* eslint-disable fp/no-throw */

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface HeaderContextProps {
  headerTitle: string;
  setHeaderTitle: React.Dispatch<React.SetStateAction<string>>;
  hideHeader: boolean;
  setHideHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export function useHeader() {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
}

type HeaderProviderProps = {
  children: ReactNode;
};

export function HeaderContextProvider({ children }: HeaderProviderProps) {
  const [headerTitle, setHeaderTitle] = useState<string>('Default Title');
  const [hideHeader, setHideHeader] = useState<boolean>(true);

  return (
    <HeaderContext.Provider value={{ headerTitle, setHeaderTitle, hideHeader, setHideHeader }}>
      {children}
    </HeaderContext.Provider>
  );
}
