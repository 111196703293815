import { useState, useEffect } from 'react';
import { Flex, Span } from '../../atoms';
import { TDepositLimitsPage } from 'Src/typedefs';
import { RegistrationInput } from '..';
import { GTM_PAYLOAD, LIMIT_TYPES, TEST_ID } from '../../constants';
import { setLimitUpdateGtmEvent } from '../../gtm';

type Props = {
  depositLimitsPageData: TDepositLimitsPage;
  currency: string;
  changeAmount: (value, limitType) => void;
  clearLimit: (limitType) => void;
  monthlyLimit: number | null;
  weeklyLimit: number | null;
  dailyLimit: number | null;
  monthlyLimitError: string;
  weeklyLimitError: string;
  dailyLimitError: string;
  validateLimits: (onFinalValidation: boolean) => void;
  stepNumber: string;
  isMgaGra: boolean;
};

export const LimitsComponent = ({
  depositLimitsPageData,
  currency,
  changeAmount,
  clearLimit,
  monthlyLimit,
  weeklyLimit,
  dailyLimit,
  monthlyLimitError,
  weeklyLimitError,
  dailyLimitError,
  validateLimits,
  stepNumber,
  isMgaGra,
}: Props) => {
  const [monthlyHasFocus, setMonthlyHasFocus] = useState<boolean>(false);
  const [weeklyHasFocus, setWeeklyHasFocus] = useState<boolean>(false);
  const [dailyHasFocus, setDailyHasFocus] = useState<boolean>(false);
  const [triggerGTMEvent, setTriggerGTMEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!monthlyHasFocus && triggerGTMEvent) {
      validateLimits(false);
      setLimitUpdateGtmEvent(GTM_PAYLOAD.MONTHLY);
    }
  }, [monthlyHasFocus]);

  useEffect(() => {
    if (!weeklyHasFocus && triggerGTMEvent) {
      validateLimits(false);
      setLimitUpdateGtmEvent(GTM_PAYLOAD.WEEKLY);
    }
  }, [weeklyHasFocus]);

  useEffect(() => {
    if (!dailyHasFocus && triggerGTMEvent) {
      validateLimits(false);
      setLimitUpdateGtmEvent(GTM_PAYLOAD.DAILY);
    }
  }, [dailyHasFocus]);

  const changeMonthly = (value) => {
    changeAmount(value, LIMIT_TYPES.MONTHLY);
  };

  const changeWeekly = (value) => {
    changeAmount(value, LIMIT_TYPES.WEEKLY);
  };

  const changeDaily = (value) => {
    changeAmount(value, LIMIT_TYPES.DAILY);
  };

  const clearMonthly = () => {
    clearLimit(LIMIT_TYPES.MONTHLY);
  };

  const clearWeekly = () => {
    clearLimit(LIMIT_TYPES.WEEKLY);
  };

  const clearDaily = () => {
    clearLimit(LIMIT_TYPES.DAILY);
  };

  if (!depositLimitsPageData) return;

  let {
    monthlyDepositPlaceholder,
    weeklyDepositPlaceholder,
    dailyDepositPlaceholder,
    message,
    description,
  } = depositLimitsPageData;

  monthlyDepositPlaceholder = `${monthlyDepositPlaceholder
      ? monthlyDepositPlaceholder
      : 'Monthly deposit limit in'
    } ${currency}`;

  weeklyDepositPlaceholder = `${weeklyDepositPlaceholder
      ? weeklyDepositPlaceholder
      : 'Weekly deposit limit in'
    } ${currency}`;

  dailyDepositPlaceholder = `${dailyDepositPlaceholder ? dailyDepositPlaceholder : 'Daily deposit limit in'
    } ${currency}`;

  return (
    <Flex className="gap-sm p-sm tablet:p-md bg-white rounded-3xl shadow-[0_7px_8px_-4px_rgba(0,0,0,0.1)]">
      <Flex className="gap-2xs px-xs tablet:px-0">
        <Span className="text-purple-80">{stepNumber || ''}</Span>
        <Flex className="text-black font-bold text-h" testID={TEST_ID.TEXT}>{message}</Flex>
        {isMgaGra && (
          <Flex className="text-black text-sm">
            {description ||
              'The deposit limits can be changed and updated in your profile.'}
          </Flex>
        )}
      </Flex>
      <Flex className="gap-sm text-black justify-between">
        {/* Monthly limit */}
        <RegistrationInput
          currency={currency}
          value={monthlyLimit}
          hasFocus={monthlyHasFocus}
          changeValue={changeMonthly}
          placeholder={monthlyDepositPlaceholder}
          setHasFocus={setMonthlyHasFocus}
          setTriggerGTMEvent={setTriggerGTMEvent}
          clearValue={clearMonthly}
          error={monthlyLimitError}
          type="tel"
          testID={TEST_ID.INPUT + 'monthly'}
        />
        {/* Weekly limit */}
        <RegistrationInput
          currency={currency}
          value={weeklyLimit}
          hasFocus={weeklyHasFocus}
          changeValue={changeWeekly}
          placeholder={weeklyDepositPlaceholder}
          setHasFocus={setWeeklyHasFocus}
          setTriggerGTMEvent={setTriggerGTMEvent}
          clearValue={clearWeekly}
          error={weeklyLimitError}
          type="tel"
          testID={TEST_ID.INPUT + 'weekly'}
        />
        {/* Daily limit */}
        <RegistrationInput
          currency={currency}
          value={dailyLimit}
          hasFocus={dailyHasFocus}
          changeValue={changeDaily}
          placeholder={dailyDepositPlaceholder}
          setHasFocus={setDailyHasFocus}
          setTriggerGTMEvent={setTriggerGTMEvent}
          clearValue={clearDaily}
          error={dailyLimitError}
          type="tel"
          testID={TEST_ID.INPUT + 'daily'}
        />
      </Flex>
    </Flex>
  );
};
