import { TDepositsPage } from '../../../typedefs';
import { adaptDepositsPage } from './adapters';
import { REGISTRATION_FLOW_STEPS } from '../../../constants';
import { getStepData } from '../common';

export const getDepositsPageData = (
  registrationFlowsData: unknown
): TDepositsPage => {
  const data = getStepData(
    registrationFlowsData,
    REGISTRATION_FLOW_STEPS.DEPOSIT_STEP
  );
  const paymentsPage: TDepositsPage = adaptDepositsPage(data);
  return paymentsPage;
};
