/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
import { getDaysInMonth, isLeapYear, isValid } from 'date-fns';
import validator from 'validator';
import {
  ADDRESS_COMPONENTS,
  GTM_ERRORS,
  LIMIT_TYPES,
  REGISTRATION_ERRORS,
  REGISTRATION_FLOW_STEPS,
} from './constants';
import { validateEmailOnBE, validatePhoneNumberOnBE } from './service';
import { setAlertGtmEvent } from './gtm';
import { TLimitsValidationResult, TAddress } from './typedefs';
import {
  validateAddressOnGoogle,
  isValidPostalCode,
} from './service/googleMaps/address';
import { GOOGLE_ADDRESS_VALIDATOR_COUNTRY_COVERAGE } from './service/googleMaps/constants';

export const isValidateField = (value: string | null, errorKey: string) => {
  if (!value) return { success: false, error: errorKey };
  if (validator?.isEmpty(value)) return { success: false, error: errorKey };
  return { success: true };
};

export const validateEmail = (email) => {
  return validator?.isEmail(email);
};

export const validatePassword = (password: string): boolean => {
  const minLength = 6;
  const containsSpecialCharacter = /[!@#$%^&*(),.?":{}|<>\\/\[\]`~;'\-=_+|£]/;
  const containsNumber = /\d/;
  const containsCharacter = /[a-zA-Z]/;

  return (
    password.length >= minLength &&
    containsSpecialCharacter.test(password) &&
    containsCharacter.test(password) &&
    containsNumber.test(password)
  );
};

const phoneNumberRegex = new RegExp(/^\d{5,12}$/);

export const validatePhone = (phoneNumber: string) => {
  const whitespaceRemoved = phoneNumber.replace(/\s/g, '');
  return phoneNumberRegex.test(whitespaceRemoved);
};

export const emailValidationResult = async (
  email: string | null | undefined,
  onContinue: boolean
) => {
  if (!email) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.EMAIL_IS_REQUIRED,
    };
  }
  if (!validateEmail(email)) {
    setAlertGtmEvent({
      alert: GTM_ERRORS.INVALID_EMAIL,
      step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
    });
    return {
      success: false,
      error: REGISTRATION_ERRORS.INCORRECT_EMAIL,
    };
  }

  if (onContinue) {
    return await validateEmailOnBE(email);
  }
  return { success: true };
};

export const passwordValidationResult = async (
  password: string | null | undefined,
  onContinue: boolean
) => {
  if (!password) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.PASSWORD_IS_REQUIRED,
    };
  }
  if (!validatePassword(password)) {
    setAlertGtmEvent({
      alert: GTM_ERRORS.INVALID_PASSWORD,
      step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
    });
    return {
      success: false,
      error: REGISTRATION_ERRORS.INCORRECT_PASSWORD,
    };
  }

  return { success: true };
};

export const phoneValidationResult = async (
  prefix: string | null | undefined,
  number: string | null | undefined,
  onContinue: boolean
) => {
  if (!number || !prefix) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.PHONE_IS_REQUIRED,
    };
  }
  // Create the full valid number.
  const cleanPrefix = `${prefix?.replace('-', '')}`;

  if (!validatePhone(number)) {
    setAlertGtmEvent({
      alert: GTM_ERRORS.INVALID_PHONE,
      step: REGISTRATION_FLOW_STEPS.ACCOUNT_DETAILS_STEP,
    });
    return {
      success: false,
      error: REGISTRATION_ERRORS.INCORRECT_PHONE_NUMBER,
    };
  }

  if (onContinue) {
    return await validatePhoneNumberOnBE(cleanPrefix, number);
  }
  return { success: true };
};

export const birthDateValidationResult = async (
  parsedDate: Date,
  countryOfResidenceCountryCode: string = null
) => {
  if (!parsedDate) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.BIRTH_DATE_IS_REQUIRED,
    };
  }

  if (!isValid(parsedDate)) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.INCORRECT_BIRTH_DAY,
    };
  }

  const today = new Date();

  // Check if the date is in the future
  if (parsedDate > today) {
    return { success: false, error: REGISTRATION_ERRORS.AGE_ERROR };
  }

  const [numericDay, numericMonth, numericYear] = [
    parsedDate.getDate(),
    parsedDate.getMonth() + 1,
    parsedDate.getFullYear(),
  ];

  const daysInMonth = getDaysInMonth(parsedDate);
  if (
    numericDay > daysInMonth ||
    (numericMonth === 2 &&
      ((isLeapYear(parsedDate) && numericDay > 29) ||
        (!isLeapYear(parsedDate) && numericDay > 28)))
  ) {
    return { success: false, error: REGISTRATION_ERRORS.INCORRECT_BIRTH_DAY };
  }

  let age = today.getFullYear() - numericYear;
  const monthDiff = today.getMonth() - (numericMonth - 1);
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < numericDay)) {
    age--;
  }
  if (!countryOfResidenceCountryCode) console.log("countryOfResidenceCountryCode Value >>>>>", { countryOfResidenceCountryCode })
  if (countryOfResidenceCountryCode.includes('CA') && age < 19) {
    return { success: false, error: REGISTRATION_ERRORS.UNDERAGE };
  }
  if (age < 18) {
    return { success: false, error: REGISTRATION_ERRORS.UNDERAGE };
  }
  if (
    age > 120 ||
    (age === 120 && monthDiff >= 0 && today.getDate() >= numericDay)
  ) {
    return { success: false, error: REGISTRATION_ERRORS.AGE_ERROR };
  }

  return { success: true };
};

export const validateLimit = (limitAmount, min, max) => {
  interface IError {
    success: boolean;
    error: string;
    value: string;
  }
  if (!limitAmount) {
    const error: IError = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_IS_REQUIRED,
      value: '',
    };
    return error;
  }
  if (limitAmount < min) {
    const error: IError = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_TOO_LOW,
      value: min,
    };
    return error;
  }
  if (limitAmount > max) {
    const error: IError = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_TOO_HIGH,
      value: max,
    };
    return error;
  }
  return {
    success: true,
    error: '',
    value: '',
  };
};

export const limitsValidationResult = (
  limitsData,
  depositamount,
  monthlyLimit,
  weeklyLimit,
  dailyLimit
): TLimitsValidationResult => {
  if (!limitsData) {
    return;
  }

  const minAllowedLimits = limitsData?.minAllowedLimits;
  const maxAllowedLimits = limitsData?.maxAllowedLimits;

  // Validate limits acording to playOk data.
  let dailyResult = validateLimit(
    dailyLimit,
    minAllowedLimits?.daily,
    maxAllowedLimits?.daily
  );
  let weeklyResult = validateLimit(
    weeklyLimit,
    minAllowedLimits?.weekly,
    maxAllowedLimits?.weekly
  );
  let monthlyResult = validateLimit(
    monthlyLimit,
    minAllowedLimits?.monthly,
    maxAllowedLimits?.monthly
  );

  // Validate limits according to depositAmount.
  if (dailyLimit && dailyLimit < depositamount) {
    dailyResult = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_IS_LOWER_THAN_DEPOSIT,
      value: '',
    };
  }

  if (weeklyLimit && weeklyLimit < depositamount) {
    weeklyResult = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_IS_LOWER_THAN_DEPOSIT,
      value: '',
    };
  }

  if (monthlyLimit && monthlyLimit < depositamount) {
    monthlyResult = {
      success: false,
      error: REGISTRATION_ERRORS.LIMIT_IS_LOWER_THAN_DEPOSIT,
      value: '',
    };
  }

  // Validate limits acording to each other.
  if (monthlyLimit && monthlyLimit < weeklyLimit) {
    monthlyResult = {
      success: false,
      error: REGISTRATION_ERRORS.MONTHLY_LIMIT_IS_LOWER_THAN_WEEKLY,
      value: '',
    };
  }

  if (weeklyLimit && weeklyLimit < dailyLimit) {
    weeklyResult = {
      success: false,
      error: REGISTRATION_ERRORS.WEEKLY_LIMIT_IS_LOWER_THAN_DAILY,
      value: '',
    };
  }

  return { dailyResult, weeklyResult, monthlyResult };
};

export const mgaGraDepositLimitsValidationResult = (
  limitsData,
  depositLimit,
  depositType
) => {
  if (!limitsData) {
    return;
  }

  const minAllowedLimits = limitsData?.minAllowedLimits;
  const maxAllowedLimits = limitsData?.maxAllowedLimits;

  const ammountMinMax = () => {
    switch (depositType) {
      case LIMIT_TYPES.DAILY:
        return { min: minAllowedLimits?.daily, max: maxAllowedLimits?.daily };
      case LIMIT_TYPES.WEEKLY:
        return { min: minAllowedLimits?.weekly, max: maxAllowedLimits?.weekly };
      case LIMIT_TYPES.MONTHLY:
        return {
          min: minAllowedLimits?.monthly,
          max: maxAllowedLimits?.monthly,
        };
    }
  };

  const depositLimitResult = validateLimit(
    depositLimit,
    ammountMinMax().min,
    ammountMinMax().max
  );
  return { depositLimitResult };
};

export const validateAge = (dateOfBirth: string | null | undefined) => {
  if (dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const monthDiff = Date.now() - birthDate.getTime();
    const ageDiff = new Date(monthDiff);
    const year = ageDiff.getFullYear();
    const age = Math.abs(year - 1970);

    return age >= 18;
  }

  return false;
};

export const validateNationality = (selectedNationality) => {
  if (!selectedNationality) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.NATIONALITY_IS_REQUIRED,
    };
  }
  return {
    success: true,
  };
};

export const validateFirstName = (firstName: string, onContinue: boolean) => {
  if (!firstName && onContinue) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.FIRST_NAME_REQUIRED,
    };
  }
  return {
    success: true,
  };
};

export const validateLastName = (lastName: string, onContinue: boolean) => {
  if (!lastName && onContinue) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.LAST_NAME_REQUIRED,
    };
  }
  return {
    success: true,
  };
};

export const validateAddress = async (
  detailedAddress: TAddress
) => {
  const addressErrors = [];
  let success = true;
  if (!detailedAddress.city) {
    addressErrors.push({
      type: ADDRESS_COMPONENTS.CITY,
      errorName: REGISTRATION_ERRORS.FIELD_IS_REQUIRED,
    });
    success = false;
  }
  if (!detailedAddress.houseNumber) {
    addressErrors.push({
      type: ADDRESS_COMPONENTS.HOUSE_NUMBER,
      errorName: REGISTRATION_ERRORS.FIELD_IS_REQUIRED,
    });
    success = false;
  }
  if (!detailedAddress.postalCode) {
    addressErrors.push({
      type: ADDRESS_COMPONENTS.POSTAL_CODE,
      errorName: REGISTRATION_ERRORS.FIELD_IS_REQUIRED,
    });
    success = false;
  }
  if (!detailedAddress.streetName) {
    addressErrors.push({
      type: ADDRESS_COMPONENTS.STREET_NAME,
      errorName: REGISTRATION_ERRORS.FIELD_IS_REQUIRED,
    });
    success = false;
  }


  return {
    success,
    addressErrors,
  };
};

export const validatePostalCode = async (
  detailedAddress: TAddress,
  countryOfResidenceCountryCode: string
) => {
  const addressErrors = [];
  let success = true;

  if (detailedAddress.postalCode) {
    const validPostalCode = isValidPostalCode(
      countryOfResidenceCountryCode,
      detailedAddress.postalCode
    );
    if (!validPostalCode) {
      addressErrors.push({
        type: ADDRESS_COMPONENTS.POSTAL_CODE,
        errorName: REGISTRATION_ERRORS.INVALID_POSTAL_CODE,
      });
      success = false;
    }
  }
  if (
    success === true &&
    GOOGLE_ADDRESS_VALIDATOR_COUNTRY_COVERAGE.includes(
      countryOfResidenceCountryCode
    )
  ) {
    const result = await validateAddressOnGoogle(
      detailedAddress,
      countryOfResidenceCountryCode
    );
    if (!result.success) {
      addressErrors.push({
        type: ADDRESS_COMPONENTS.POSTAL_CODE,
        errorName: REGISTRATION_ERRORS.INVALID_POSTAL_CODE,
      });
      success = false
    }
  }
  return {
    success,
    addressErrors,
  };
}

export const validateCountryOfResidence = (selectedCountry, province) => {
  if (!selectedCountry) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.COUNTRY_OF_RESIDENCE_IS_REQUIRED,
      reason: 'country',
    };
  }
  if (selectedCountry?.toLowerCase() === 'canada' && !province) {
    return {
      success: false,
      error: REGISTRATION_ERRORS.PROVINCE_IN_ADDRESS_REQUIRED,
      reason: 'province',
    };
  }
  return {
    success: true,
  };
};
