import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Flex } from '../../atoms';
import { Drawer } from '..';
import { TAddressPage, TAddressPrediction, TErrorMessage } from 'Src/typedefs';
import { REGISTRATION_ERRORS, TEST_ID } from 'Src/constants';
import { getErrorMessage } from 'Src/utilities';
import { AddressItem } from './addressItem';
import { NotFoundAddress } from './notFoundAddress';

type Props = {
  pageData: TAddressPage;
  enableDrawer?: boolean;
  isDrawerOpen?: boolean;
  toggleDrawer?: () => void;
  phonePrefix?: string;
  changeValue?: (value) => void;
  placeholder?: string;
  searchValue?: string;
  searchLoader?: boolean;
  setAddress?: Dispatch<SetStateAction<TAddressPrediction>>;
  addresses?: Array<TAddressPrediction>;
  drawerTitle: string;
  dirictusErrors: TErrorMessage[];
  setManualAddressOpened: Function;
};

export const AddressSelector = ({
  pageData,
  enableDrawer,
  isDrawerOpen,
  toggleDrawer,
  setManualAddressOpened,
  changeValue,
  placeholder,
  searchValue,
  searchLoader,
  setAddress,
  addresses,
  drawerTitle,
  dirictusErrors
}: Props) => {
  const isSearchInputHasError = !searchLoader && addresses.length <= 0 && searchValue.length > 0;

  const message: TErrorMessage = getErrorMessage(
    dirictusErrors,
    REGISTRATION_ERRORS.PREDICTIONS_GOOGLE_API_NOT_FOUND
  );

  const setManualAddress = () => {
    setManualAddressOpened(true);
    toggleDrawer();
  }

  return (
    <Flex>
      {enableDrawer && (
        <Drawer
          title={drawerTitle}
          isOpen={isDrawerOpen}
          setIsOpen={toggleDrawer}
          titleSize="text-h-lg font-bold"
          enableSearch={true}
          changeValue={changeValue}
          placeholder={placeholder}
          searchValue={searchValue}
          autoFocus={true}
          testID='address'
        >
          {isSearchInputHasError ? (
            <NotFoundAddress
              pageData={pageData}
              errorMessage={message.message}
              setManualAddress={setManualAddress}
            />
          ) : (
            addresses?.map((address, index) => {
              return (
                <AddressItem
                  key={`${address}-${index}`}
                  address={address}
                  setAddress={setAddress}
                />
              );
            })
          )}
        </Drawer>
      )}
    </Flex>
  );
};
